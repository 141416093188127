import styled, { keyframes } from "styled-components";
import { desktopMinSize, mq, mqmax, mqmin } from "../../styles/mixins";
import { Button as MuiButton } from '@material-ui/core';
import { colors } from '../../constants/colors';

const pulseAnimation = keyframes`
  0% { transform: scale(1); opacity: 0.5; }
  50% { transform: scale(1.05); opacity: 0.8; }
  100% { transform: scale(1); opacity: 0.5; }
`;

export const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(135deg, #17171A 0%, #1E1E24 100%);
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    @media (max-width: 768px) {
        width: 100%;
        padding: 0;
    }
`;

export const GlowBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #17171A 0%, #1E1E24 100%);
    pointer-events: none;
    z-index: -1;
`;

export const ContentWrapper = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    box-sizing: border-box;

    @media (max-width: 768px) {
        padding: 1rem;
        width: calc(100% - 2rem);
        margin: 0 auto;
        align-items: center;
    }
`;

export const ServicesGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 6rem 2.5rem;
    margin: 4rem 0;
    padding: 0.5rem;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        margin: 2rem 0;
    }

    @media (max-width: 480px) {
        margin: 1.5rem 0;
        padding: 0.25rem;
    }
`;

export const ServiceCard = styled.div`
    padding: 2rem;
    background: ${colors.gray[800]};
    border-radius: 24px;
    box-shadow: 
        0 10px 30px -10px rgba(0, 0, 0, 0.2),
        0 5px 15px -5px rgba(0, 0, 0, 0.1),
        0 2px 5px -2px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.05);
    position: relative;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
        padding: 1.5rem;
        border-radius: 20px;
    }

    @media (max-width: 480px) {
        padding: 1.25rem;
        border-radius: 16px;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 4px;
        background: ${colors.gradients.primary};
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    &:hover {
        transform: translateY(-5px);
        box-shadow: 
            0 20px 40px -15px rgba(0, 0, 0, 0.3),
            0 10px 20px -10px rgba(0, 0, 0, 0.2),
            0 5px 10px -5px rgba(0, 0, 0, 0.1);

        &:before {
            opacity: 1;
        }
    }

    @media (max-width: 768px) {
        &:hover {
            transform: translateY(-3px);
        }
    }
`;

export const ServiceHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 1.25rem;
    margin-bottom: 1.5rem;
    position: relative;
`;

export const ServiceIcon = styled.div`
    color: ${colors.white};
    font-size: 1.8rem;
    padding: 0.875rem;
    background: ${colors.primary};
    border-radius: 16px;
    box-shadow: 
        0 8px 16px -8px rgba(37, 99, 235, 0.3),
        0 4px 8px -4px rgba(37, 99, 235, 0.2);
    transition: all 0.3s ease;
`;

export const ServiceTitle = styled.h3`
    font-size: 1.4rem;
    color: ${colors.white};
    font-weight: 700;
    margin: 0;

    @media (max-width: 768px) {
        font-size: 1.25rem;
    }

    @media (max-width: 480px) {
        font-size: 1.1rem;
    }
`;

export const SubServicesList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    width: 100%;
    overflow: hidden;
    flex: 1;
`;

export const SubServiceItem = styled.li`
    display: flex;
    align-items: center;
    gap: 1.25rem;
    padding: 1.25rem;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 16px;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    width: 100%;
    box-sizing: border-box;
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    min-height: 72px;

    @media (max-width: 768px) {
        padding: 1rem;
        gap: 1rem;
        min-height: 64px;
    }

    @media (max-width: 480px) {
        padding: 0.875rem;
        gap: 0.75rem;
        min-height: 56px;
        border-radius: 12px;
    }

    &:hover {
        transform: translateY(-3px);
        background: rgba(255, 255, 255, 0.1);
        box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);

        ${ServiceIcon} {
            transform: scale(1.1);
        }
    }

    @media (max-width: 768px) {
        &:hover {
            transform: translateY(-2px);
        }
    }
`;

export const SubServiceIcon = styled(ServiceIcon)`
    font-size: 1.5rem;
    padding: 0.75rem;
    background: ${colors.primary};
    border-radius: 14px;
    transition: all 0.3s ease;
    min-width: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    svg {
        color: ${colors.white};
        transition: color 0.3s ease;
    }
`;

export const SubServiceTitle = styled.h4`
    font-size: 1.1rem;
    color: ${colors.white};
    font-weight: 600;
    margin: 0;
    transition: color 0.3s ease;
    flex: 1;
    text-align: left;
    line-height: 1.4;
    word-break: break-word;
    hyphens: auto;

    ${SubServiceItem}:hover & {
        color: ${colors.primaryLight};
    }
`;

export const SpecializedServicesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin: 3rem 0;
`;

export const Section = styled.section`
    padding: 2.5rem;
    background: ${colors.background.dark};
    border-radius: 16px;
    box-shadow: ${colors.shadows.card};
    transition: all 0.3s ease;
    border: 1px solid rgba(0, 0, 0, 0.05);

    &:hover {
        transform: translateY(-5px);
        box-shadow: ${colors.shadows.hover};
    }
`;

export const SectionTitle = styled.h2`
    font-size: 2.5rem;
    color: ${colors.white};
    text-align: center;
    font-weight: 700;

    @media (max-width: 768px) {
        font-size: 2rem;
    }

    @media (max-width: 480px) {
        font-size: 1.75rem;
    }
`;

export const SectionSubtitle = styled.h3`
    font-size: 1.4rem;
    color: ${colors.text.light};
    margin-bottom: 0.75rem;
    font-weight: 600;

    @media (max-width: 768px) {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
    }

    @media (max-width: 480px) {
        font-size: 1.1rem;
    }
`;

export const FeatureList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0 0 2rem 0;
`;

export const FeatureItem = styled.li`
    display: flex;
    align-items: flex-start;
    margin-bottom: 1.5rem;
    padding: 1.25rem;
    border-radius: 12px;
    background: ${colors.featureBackground};
    border: 1px solid rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease;

    &:hover {
        background: ${colors.white};
        box-shadow: ${colors.shadows.card};
    }

    &:last-child {
        margin-bottom: 0;
    }
`;

export const FeatureIcon = styled.span`
    margin-right: 1.25rem;
    color: ${colors.primary};
    font-size: 1.5rem;
    padding: 0.75rem;
    background: ${colors.white};
    border-radius: 12px;
    box-shadow: ${colors.shadows.card};
`;

export const FeatureText = styled.p`
    margin: 0;
    color: ${colors.text.secondary};
    font-size: 1rem;
    line-height: 1.6;
`;

export const CTAButton = styled.button`
    background: ${colors.gradients.primary};
    color: ${colors.white};
    border: none;
    padding: 1rem 2rem;
    border-radius: 12px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;
    margin-top: 1rem;
    box-shadow: ${colors.shadows.card};

    &:hover {
        transform: translateY(-2px);
        box-shadow: ${colors.shadows.hover};
    }

    &:active {
        transform: translateY(0);
    }
`;

export const DefaultContentDivWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 2.5rem;

    ${mqmax(desktopMinSize -1)} {
        gap: 1.5rem;
    }
`;

export const Title = styled.h1`
    line-height: calc(48 / var(--fontSize));
    font-style: normal;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 2.8rem;
`;

export const StyledPurpleText = styled.span`
    background: linear-gradient(89.03deg, #426FE5 -2.1%, #7A2BE9 104.93%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
`;

export const Presentation = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
`;

export const PresentationP = styled.p`
    max-width: 60%;
    line-height: 20px;

    ${mqmax(desktopMinSize -1)} {
        max-width: 100%;
    }
`;

export const PresentationButton = styled(MuiButton)`
    &.MuiButton-root {
        border: solid #2d80f2 calc(2rem / 16);
        border-radius: calc(24rem / 16);
        padding: calc(9rem / 16) calc(30rem / 16);
        color: #2d80f2;
        opacity: ${({ disabled }) => disabled ? 0.5 : 1};
        background: transparent;
        font-weight: bold;
        line-height: calc(18 / var(--fontSize));
        transition: ${['color', 'background'].map(prop => `${prop} 300ms ease-out`).join(', ')};

        &:hover {
            color: #fff;
            background: #2d80f2;
        }          

        ${mqmin(desktopMinSize)} {
            line-height: calc(16 / var(--fontSize));
        }
    }
`;

export const BlueStrong = styled.strong`
    color: ${colors.primary};
    font-weight: 600;
`;

export const DefaultContentParagraph = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 160.5%;

    & > strong {
        font-weight: 900;
    }
`;

export const ServicesSection = styled.section`
  margin: 4rem 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const ServicesDescription = styled.p`
  font-size: 1.2rem;
  color: ${colors.text.secondary};
  text-align: center;
  max-width: 800px;
  line-height: 1.6;
  margin: 0 auto 0;
`;

export const SpecializedServicesSection = styled(ServicesSection)`
  background: ${colors.background.card};
  padding: 3rem;
  border-radius: 24px;
  margin-top: 4rem;

  @media (max-width: 768px) {
    padding: 0.75rem;
  }
`;

export const SpecializedGrid = styled(ServicesGrid)`
    grid-template-columns: repeat(fauto-it, minmax(320px, 1fr));
    gap: 2rem;
    margin-top: 2rem;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 6rem 2rem;
    }
`;
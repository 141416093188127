import React from 'react';
import ReactDOM from 'react-dom';
import styled, { keyframes } from 'styled-components';
import { colors } from '../../../../constants/colors';
import { FaTimes } from 'react-icons/fa';
import { openTawkToChat } from '../../../../utils/tawk';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(8px);
`;

const PopupContainer = styled.div`
  background: ${colors.gray[800]};
  border-radius: 24px;
  padding: 2.5rem;
  max-width: 600px;
  width: 90%;
  position: relative;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.5);
  max-height: 90vh;
  overflow-y: auto;
  border: 1px solid rgba(255, 255, 255, 0.1);
  animation: ${fadeIn} 0.3s ease-out;

  @media (max-width: 768px) {
    padding: 1.5rem;
    width: 95%;
    max-height: 85vh;
    border-radius: 20px;
  }

  @media (max-width: 480px) {
    padding: 1.25rem;
    width: 98%;
    max-height: 80vh;
    border-radius: 16px;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.primary};
    border-radius: 4px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: ${colors.white};
  cursor: pointer;
  font-size: 1.5rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  border-radius: 50%;
  backdrop-filter: blur(4px);

  @media (max-width: 768px) {
    top: 1rem;
    right: 1rem;
    font-size: 1.25rem;
  }

  @media (max-width: 480px) {
    top: 0.75rem;
    right: 0.75rem;
    font-size: 1.1rem;
    padding: 0.375rem;
  }

  &:hover {
    background: ${colors.primary};
    transform: rotate(90deg);
  }
`;

const Title = styled.h2`
  font-size: 2rem;
  color: ${colors.white};
  margin-bottom: 2rem;
  font-weight: 700;
  padding-right: 2rem;
  background: linear-gradient(135deg, #fff 0%, #B4B4B4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 768px) {
    font-size: 1.75rem;
    margin-bottom: 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  gap: 1.5rem;

  @media (max-width: 768px) {
    gap: 1rem;
  }

  @media (max-width: 480px) {
    gap: 0.75rem;
  }
`;

const FeatureItem = styled.li`
  display: flex;
  align-items: flex-start;
  padding: 1.5rem;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.05);
  transition: all 0.2s ease;
  border: 1px solid rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(4px);

  @media (max-width: 768px) {
    padding: 1rem;
  }

  @media (max-width: 480px) {
    padding: 0.875rem;
    border-radius: 12px;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.08);
    transform: translateY(-2px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
`;

const FeatureIcon = styled.span`
  margin-right: 1.25rem;
  color: ${colors.white};
  font-size: 1.5rem;
  padding: 0.75rem;
  background: ${colors.primary};
  border-radius: 12px;
  box-shadow: 0 8px 16px -8px rgba(37, 99, 235, 0.3);
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    font-size: 1.25rem;
    padding: 0.625rem;
    margin-right: 1rem;
  }

  @media (max-width: 480px) {
    font-size: 1.1rem;
    padding: 0.5rem;
    margin-right: 0.75rem;
    border-radius: 8px;
  }

  ${FeatureItem}:hover & {
    transform: scale(1.1);
  }
`;

const FeatureContent = styled.div`
  flex: 1;
`;

const FeatureTitle = styled.h3`
  font-size: 1.2rem;
  color: ${colors.white};
  margin-bottom: 0.5rem;
  font-weight: 600;

  @media (max-width: 768px) {
    font-size: 1.1rem;
    margin-bottom: 0.375rem;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
    margin-bottom: 0.25rem;
  }
`;

const FeatureDescription = styled.p`
  color: ${colors.text.light};
  font-size: 1rem;
  line-height: 1.6;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 0.95rem;
    line-height: 1.5;
  }

  @media (max-width: 480px) {
    font-size: 0.875rem;
    line-height: 1.4;
  }
`;

const CTAButton = styled.button`
  background: ${colors.primary};
  color: ${colors.white};
  border: none;
  padding: 1.2rem 2rem;
  border-radius: 12px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  margin-top: 2rem;
  position: relative;
  overflow: hidden;
  
  @media (max-width: 768px) {
    padding: 1rem 1.5rem;
    font-size: 1rem;
    margin-top: 1.5rem;
  }

  @media (max-width: 480px) {
    padding: 0.875rem 1.25rem;
    font-size: 0.95rem;
    margin-top: 1rem;
    border-radius: 8px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    transition: 0.5s;
  }

  &:hover {
    transform: translateY(-2px);
    background: ${colors.gradients.primary};
    
    &:before {
      left: 100%;
    }
  }

  &:active {
    transform: translateY(0);
  }
`;

const ServicePopup = ({ isOpen, onClose, title, features, ctaText }) => {
  if (!isOpen) return null;

  const handleContactClick = () => {
    openTawkToChat(`Olá! Gostaria de saber mais sobre ${title}.`);
    onClose();
  };

  return ReactDOM.createPortal(
    <Overlay onClick={onClose}>
      <PopupContainer onClick={e => e.stopPropagation()}>
        <CloseButton onClick={onClose}>
          <FaTimes />
        </CloseButton>
        <Title>{title}</Title>
        <FeatureList>
          {features.map((feature, index) => (
            <FeatureItem key={index}>
              <FeatureIcon>
                {feature.icon}
              </FeatureIcon>
              <FeatureContent>
                <FeatureTitle>{feature.title}</FeatureTitle>
                <FeatureDescription>{feature.description}</FeatureDescription>
              </FeatureContent>
            </FeatureItem>
          ))}
        </FeatureList>
        <CTAButton onClick={handleContactClick}>
          {ctaText || 'Solicitar Informações'}
        </CTAButton>
      </PopupContainer>
    </Overlay>,
    document.body
  );
};

export default ServicePopup; 
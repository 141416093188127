import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import 'regenerator-runtime';
import 'es6-promise/auto';
import 'fetch-polyfill';
import './styles/mercadopago.css';
import { ShoppingCartProvider } from './context/ShoppingCartContext';
import { TutorialStepProvider } from './context/TutorialStepContext';
import store from '@Store';
import Private from '@Layouts/Private';
import Public from '@Layouts/Public';
import HomePage from '@Pages/HomePage';
import Plans from '@Pages/Plans';
import Download from '@Pages/Download';
import Contact from '@Pages/Contact';
import Faq from '@Pages/Faq';
import ForBusiness from '@Pages/ForBusiness';
import Blog from '@Pages/Blog';
import BlogSingle from '@Pages/BlogSingle/';
import LoginPage from '@Pages/LoginPage';
import RegisterPage from '@Pages/RegisterPage';
import RegisterConfirmationPage from '@Pages/RegisterConfirmationPage';
import ConfirmationSuccessPage from '@Pages/ConfirmationSuccessPage';
import OccasionalUsePage from '@Pages/OccasionalUsePage';
import MySessionsPage from '@Pages/MySessionsPage';
import ShopPage from '@Pages/ShopPage';
import PasswordForgot from '@Pages/PasswordForgot';
import CreatePassword from '@Pages/CreatePassword';
import SupportPage from '@Pages/SupportPage';
import MyProfilePage from '@Pages/MyProfilePage';
import DownloadPage from '@Pages/DownloadPage';
import MySettingsPage from '@Pages/SettingsPage';
import UpdatePasswordMobilePage from '@Pages/UpdatePasswordMobilePage';
import MaintenancePage from '@Pages/MaintenancePage';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { UserRoleProvider } from '@Context/UserRoleContext';
import MyComputerPage from './pages/MyComputerPage';
import PlanComparison from './pages/HomePage/Plans/PlanComparison';
import GoldImagesPanel from '@Pages/EnterpriseImagesGold';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import LanguageSelector from './components/LanguageSelector';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { ThemeProvider } from 'styled-components';
import SEOHelmet from './components/SEO/SEOHelmet';
import PageLoadingMessage from './components/PageLoadingMessage';

// Importando as páginas de landing pages otimizadas para SEO
import CloudGamingPage from './pages/CloudGamingPage';
import DesktopAsServicePage from './pages/DesktopAsServicePage';
import CloudComputerPage from './pages/CloudComputerPage';
import MachineLearningPage from './pages/MachineLearningPage';

// Tema padrão para o ThemeProvider
const theme = {
  colors: {
    primary: '#0070f3',
    secondary: '#1e1e1e',
    text: '#333333',
    background: '#ffffff',
  },
  fonts: {
    main: 'Arial, sans-serif'
  },
  breakpoints: {
    mobile: '480px',
    tablet: '768px',
    desktop: '1024px',
  }
};

// Layout público para envolver as páginas
const PublicLayout = ({ children }) => (
  <Public>{children}</Public>
);

// QueryClient para React Query
const queryClient = new QueryClient();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <TawkMessengerReact
          propertyId="673b71772480f5b4f59ff128"
          widgetId="1id036f06" />
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <ShoppingCartProvider>
              <TutorialStepProvider>
                <BrowserRouter>
                  <Switch>
                    <Route
                      exact
                      path='/'
                      render={props => (
                        <>
                          <SEOHelmet pageName="home" />
                          <Suspense fallback={<PageLoadingMessage />}>
                            <PublicLayout>
                              <HomePage {...props} />
                            </PublicLayout>
                          </Suspense>
                        </>
                      )}
                    />
                    <Route
                      exact
                      path='/planos'
                      render={props => (
                        <>
                          <SEOHelmet pageName="planos" />
                          <Suspense fallback={<PageLoadingMessage />}>
                            <PublicLayout>
                              <Plans {...props} />
                            </PublicLayout>
                          </Suspense>
                        </>
                      )}
                    />
                    <Route
                      exact
                      path='/cloud-gaming'
                      render={props => (
                        <>
                          <SEOHelmet pageName="cloud-gaming" />
                          <Suspense fallback={<PageLoadingMessage />}>
                            <PublicLayout>
                              <CloudGamingPage {...props} />
                            </PublicLayout>
                          </Suspense>
                        </>
                      )}
                    />
                    <Route
                      exact
                      path='/desktop-as-a-service'
                      render={props => (
                        <>
                          <SEOHelmet pageName="desktop-as-a-service" />
                          <Suspense fallback={<PageLoadingMessage />}>
                            <PublicLayout>
                              <DesktopAsServicePage {...props} />
                            </PublicLayout>
                          </Suspense>
                        </>
                      )}
                    />
                    <Route
                      exact
                      path='/computador-na-nuvem'
                      render={props => (
                        <>
                          <SEOHelmet pageName="computador-na-nuvem" />
                          <Suspense fallback={<PageLoadingMessage />}>
                            <PublicLayout>
                              <CloudComputerPage {...props} />
                            </PublicLayout>
                          </Suspense>
                        </>
                      )}
                    />
                    <Route
                      exact
                      path='/machine-learning'
                      render={props => (
                        <>
                          <SEOHelmet pageName="machine-learning" />
                          <Suspense fallback={<PageLoadingMessage />}>
                            <PublicLayout>
                              <MachineLearningPage {...props} />
                            </PublicLayout>
                          </Suspense>
                        </>
                      )}
                    />
                    <Route
                      exact
                      path='/for-business'
                      render={props => (
                        <>
                          <SEOHelmet pageName="for-business" />
                          <Suspense fallback={<PageLoadingMessage />}>
                            <PublicLayout>
                              <ForBusiness {...props} />
                            </PublicLayout>
                          </Suspense>
                        </>
                      )}
                    />
                    <Route 
                      exact 
                      path="/blog" 
                      render={props => (
                        <>
                          <Suspense fallback={<PageLoadingMessage />}>
                            <PublicLayout>
                              <Blog {...props} />
                            </PublicLayout>
                          </Suspense>
                        </>
                      )}
                    />
                    <Route 
                      exact 
                      path="/blog/:post_id" 
                      render={props => (
                        <>
                          <Suspense fallback={<PageLoadingMessage />}>
                            <PublicLayout>
                              <BlogSingle {...props} />
                            </PublicLayout>
                          </Suspense>
                        </>
                      )}
                    />
                    <Route 
                      exact 
                      path="/faq" 
                      render={props => (
                        <>
                          <SEOHelmet pageName="faq" />
                          <Suspense fallback={<PageLoadingMessage />}>
                            <PublicLayout>
                              <Faq {...props} />
                            </PublicLayout>
                          </Suspense>
                        </>
                      )}
                    />
                    <Route exact path="/login" component={LoginPage} />
                    <Route exact path="/password/forgot" component={PasswordForgot} />
                    <Route exact path="/password/create" component={CreatePassword} />
                    <Route exact path="/register" component={RegisterPage} />
                    <Route exact path="/register/confirmation" component={RegisterConfirmationPage} />
                    <Route exact path="/register/confirmation/success" component={ConfirmationSuccessPage} />
                    <Route exact path="/maintenance" component={MaintenancePage} />
                    <UserRoleProvider>
                      <Private
                        pages={[
                          { path: '/my/home', component: MyComputerPage },
                          { path: '/use/occasional/unit', component: OccasionalUsePage },
                          { path: '/use/occasional/classic', component: OccasionalUsePage },
                          { path: '/shop/:tab', component: ShopPage },
                          { path: '/my/sessions', component: MySessionsPage },
                          { path: '/support', component: SupportPage },
                          { path: '/my/profile', component: MyProfilePage },
                          { path: '/my/download', component: DownloadPage },
                          { path: '/update/password', component: UpdatePasswordMobilePage },
                          { path: '/my/settings', component: MySettingsPage },
                          { path: '/admin/images', component: GoldImagesPanel },
                        ]}
                      />
                    </UserRoleProvider>
                  </Switch>
                </BrowserRouter>
              </TutorialStepProvider>
            </ShoppingCartProvider>
          </QueryClientProvider>
        </Provider>
      </I18nextProvider>
    </ThemeProvider>
  );
}

export default App;

import React from 'react';
import * as S from './ContactStyles';
import discordIcon from '../Contact/discord.svg';
import emailIcon from '../Contact/email.svg';

const ContactComponent = () => {
  return (
    <S.ContactContainer>
      <S.ContactTitle>Entre em Contato</S.ContactTitle>
      <S.ContactDescription>
        Você pode optar por entrar em nosso servidor do Discord para ficar por dentro de todas as novidades,{' '}
        <strong>
          tirar dúvidas e conhecer melhor a equipe Noar.
        </strong>{' '}
        Ou entrar em contato pelo <strong>nosso e-mail!</strong>
      </S.ContactDescription>
      <S.ContactButtons>
        <S.ContactButton href="https://discord.com/invite/noar-798022821342609418" target="_blank" rel="noreferrer">
          <img src={discordIcon} alt="Discord" />
          Discord
        </S.ContactButton>
        <S.ContactButton href="mailto:contato@noarcloud.com" target="_blank" rel="noreferrer">
          <img src={emailIcon} alt="E-mail" />
          E-mail
        </S.ContactButton>
      </S.ContactButtons>
    </S.ContactContainer>
  );
};

export default ContactComponent; 
export const validCpf = (value) => {
    const cpf = value.replace(/[^\d]+/g,'').toString();
	if(cpf === '') return false;	
	// Elimina CPFs invalidos conhecidos	
	if (cpf.length !== 11 || 
		cpf === "00000000000" || 
		cpf === "11111111111" || 
		cpf === "22222222222" || 
		cpf === "33333333333" || 
		cpf === "44444444444" || 
		cpf === "55555555555" || 
		cpf === "66666666666" || 
		cpf === "77777777777" || 
		cpf === "88888888888" || 
		cpf === "99999999999")
			return false;		
	// Valida 1o digito	
	let add = 0;	
	for (let i=0; i < 9; i ++)		
		add += parseInt(cpf.charAt(i)) * (10 - i);	
		let rev = 11 - (add % 11);	
		if (rev === 10 || rev === 11)		
			rev = 0;	
		if (rev !== parseInt(cpf.charAt(9)))		
			return false;		
	// Valida 2o digito	
	add = 0;	
	for (let i = 0; i < 10; i ++)		
		add += parseInt(cpf.charAt(i)) * (11 - i);	
        rev = 11 - (add % 11);	
        if (rev === 10 || rev === 11)	
            rev = 0;	
        if (rev !== parseInt(cpf.charAt(10)))
            return false;		
	return true;
};

export const validDate = (value) => { //yyyy-mm-dd
    return !isNaN(new Date(value));
};

export const validCard = (number) => {
	const cartoes = {
		Visa: /^4[0-9]{12}(?:[0-9]{3})/,
		Master: /^5[1-5][0-9]{14}/,
		Amex: /^3[47][0-9]{13}/,
		Diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
		Discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
		JCB: /^(?:2131|1800|35\d{3})\d{11}/,
		Hipercard: /^606282|^3841(?:[0|4|6]{1})0/,
		Aura: /^((?!504175))^((?!5067))(^50[0-9])/,
		Elo: /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^50(4175|6699|67[0-6][0-9]|677[0-8]|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])|^627780|^63(6297|6368|6369)|^65(0(0(3([1-3]|[5-9])|4([0-9])|5[0-1])|4(0[5-9]|[1-3][0-9]|8[5-9]|9[0-9])|5([0-2][0-9]|3[0-8]|4[1-9]|[5-8][0-9]|9[0-8])|7(0[0-9]|1[0-8]|2[0-7])|9(0[1-9]|[1-6][0-9]|7[0-8]))|16(5[2-9]|[6-7][0-9])|50(0[0-9]|1[0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/
	};
	const cartao = Object.keys(cartoes).find(cartao => number.match(cartoes[cartao]));
	return cartao || false;
}
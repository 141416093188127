import React from 'react';
import styled from 'styled-components';

import { desktopMinSize, mqmin } from '@Styles/mixins';
import { mq, mqmax } from '../../styles/mixins';

import Intro from './Intro';
import Plans from './Plans';
import Download from './Download';
import Benefits from './Benefits';
import Requirements from './Requirements';
import HowToUse from './HowToUse';
import Blog from './Blog';
import Contact from './Contact';

export const Homepage = () => (
  <Main>
    <Intro />
    <Plans />
    <Download />
    <Benefits />
    <Requirements />
    <HowToUse />
    <Blog />
    <Contact />
  </Main>
);

export default Homepage;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 6rem;
  margin-bottom: calc(80rem / 24);
  overflow-x: hidden;

  ${mqmax(desktopMinSize)} {
    padding: 3rem 2rem 0 2rem;
  }

  ${Intro} {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }

  ${Benefits},
  ${Blog} {
    width: 100%;
    height: 100%;
    max-width: min(calc(304 / 360 * 100%), calc(1331rem / 16));
  }
`;

import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { colors } from '../../../../constants/colors';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const HeaderContainer = styled.header`
  text-align: center;
  padding: 6rem 2rem;
  background: ${colors.background.dark};
  border-radius: 24px;
  margin-bottom: 3rem;
  color: ${colors.white};
  position: relative;
  overflow: hidden;
  box-shadow: ${colors.shadows.hover};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at 70% 30%, rgba(45, 128, 242, 0.1) 0%, transparent 70%);
    animation: ${pulse} 8s infinite ease-in-out;
  }
`;

const Title = styled.h1`
  font-size: 4rem;
  font-weight: 800;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  position: relative;
  animation: ${fadeIn} 1s ease-out;
  color: ${colors.white};
  
  @media (max-width: 768px) {
    font-size: 2.8rem;
  }
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto 2rem auto;
  opacity: 0.95;
  font-weight: 400;
  animation: ${fadeIn} 1s ease-out 0.3s backwards;
  color: ${colors.text.light};
`;

const Highlight = styled.span`
  color: ${colors.primary};
  font-weight: 600;
`;

const ContactInfo = styled.a`
  margin-top: 2.5rem;
  font-size: 1.3rem;
  background: ${colors.primary};
  padding: 1.2rem 2.5rem;
  border-radius: 100px;
  display: inline-block;
  backdrop-filter: blur(10px);
  animation: ${fadeIn} 1s ease-out 0.6s backwards;
  transition: transform 0.3s ease, background 0.3s ease;
  cursor: pointer;
  text-decoration: none;
  
  &:hover {
    transform: translateY(-3px);
    background: ${colors.gradients.primary};
  }
  
  span {
    color: ${colors.white};
    text-decoration: none;
    font-weight: 600;
    position: relative;
  }
`;

const Stats = styled.div`
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin-top: 3rem;
  animation: ${fadeIn} 1s ease-out 0.9s backwards;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1.5rem;
  }
`;

const StatItem = styled.div`
  text-align: center;
`;

const StatNumber = styled.div`
  font-size: 2.5rem;
  font-weight: 800;
  color: ${colors.primary};
  margin-bottom: 0.5rem;
  min-width: 120px;
`;

const StatLabel = styled.div`
  font-size: 1rem;
  opacity: 0.9;
  color: ${colors.text.light};
`;

const AnimatedCounter = ({ end, duration = 2000, suffix = '', prefix = '' }) => {
  const [count, setCount] = useState(0);
  const countRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );

    if (countRef.current) {
      observer.observe(countRef.current);
    }

    return () => {
      if (countRef.current) {
        observer.unobserve(countRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!isVisible) return;

    let startTimestamp;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      
      setCount(Math.floor(progress * end));
      
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);
  }, [end, duration, isVisible]);

  return (
    <StatNumber ref={countRef}>
      {prefix}{count}{suffix}
    </StatNumber>
  );
};

const Header = () => {
  return (
    <HeaderContainer>
      <Title>Transforme sua Empresa com Cloud Computing</Title>
      <Subtitle>
        Maximize a produtividade da sua equipe com nossa solução 
        <Highlight> Enterprise </Highlight> 
        de computação em nuvem. Reduza custos e aumente a eficiência.
      </Subtitle>
      <Stats>
        <StatItem>
          <AnimatedCounter end={99} duration={2500} suffix=".9%" />
          <StatLabel>Uptime Garantido</StatLabel>
        </StatItem>
        <StatItem>
          <AnimatedCounter end={50} duration={2000} prefix="+" suffix="%" />
          <StatLabel>Economia em TI</StatLabel>
        </StatItem>
        <StatItem>
          <AnimatedCounter end={24} duration={1500} suffix="/7" />
          <StatLabel>Suporte Especializado</StatLabel>
        </StatItem>
      </Stats>
      <ContactInfo href="#contato-comercial">
        <span>Fale com nossos especialistas →</span>
      </ContactInfo>
    </HeaderContainer>
  );
};

export default Header; 
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

// Importando as traduções
import translationPTBR from './locales/pt-BR/translation.json';
import translationENUS from './locales/en-US/translation.json';
import translationES from './locales/es/translation.json';
import translationFR from './locales/fr/translation.json';
import translationDE from './locales/de/translation.json';
import translationIT from './locales/it/translation.json';

const resources = {
  'pt-BR': {
    translation: translationPTBR
  },
  'pt': {
    translation: translationPTBR
  },
  'en-US': {
    translation: translationENUS
  },
  'en': {
    translation: translationENUS
  },
  'es': {
    translation: translationES
  },
  'fr': {
    translation: translationFR
  },
  'de': {
    translation: translationDE
  },
  'it': {
    translation: translationIT
  }
};

// Verificação segura do ambiente de desenvolvimento
const isDevelopment = typeof window !== 'undefined' && 
  window.location && 
  (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1');

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'pt-BR',
    supportedLngs: ['pt-BR', 'pt', 'en-US', 'en', 'es', 'fr', 'de', 'it'],
    load: 'languageOnly',
    debug: isDevelopment,
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: 'preferredLanguage',
      caches: ['localStorage']
    },
    react: {
      useSuspense: false
    }
  });

export default i18n; 
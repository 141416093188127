export const colors = {
    // Cores principais
    primary: '#2563EB', // Azul mais vibrante
    primaryLight: '#60A5FA', // Azul claro para hover
    primaryDark: '#1E40AF', // Azul escuro para contraste
    secondary: '#7C3AED', // Roxo para elementos secundários

    // Cores de fundo
    background: '#F8FAFC', // Fundo principal mais suave
    white: '#FFFFFF',
    featureBackground: '#F1F5F9', // Fundo dos itens de feature mais suave

    // Cores de texto
    text: {
        primary: '#FFF', // Texto principal mais escuro
        secondary: '#475569', // Texto secundário
        light: '#64748B', // Texto mais claro
    },

    // Cores de status
    warning: '#F59E0B',
    success: '#10B981',
    error: '#EF4444',

    // Cores de destaque
    highlight: '#3B82F6',
    
    // Gradientes
    gradients: {
        primary: 'linear-gradient(135deg, #2563EB 0%, #1E40AF 100%)',
        secondary: 'linear-gradient(135deg, #7C3AED 0%, #5B21B6 100%)',
    },

    // Sombras
    shadows: {
        card: '0 4px 6px rgba(0, 0, 0, 0.05)',
        hover: '0 10px 15px rgba(0, 0, 0, 0.1)',
    },

    gray: {
        100: '#F7F9FC',
        200: '#EDF1F7',
        300: '#E4E9F2',
        400: '#C5CEE0',
        500: '#8F9BB3',
        600: '#2E3A59',
        700: '#222B45',
        800: '#1A1F33',
        900: '#101426'
    }
}; 
import React, { useState, useEffect } from 'react';
import Box from './Box';
import Container from './Container';
import Close from './Close';
import styled from 'styled-components';
import Header from './Header';
import Form from './Form';
import Input from './Input';
import Submit from './Submit';
import Back from './Back';
import { maskDateDayMonth } from '@Utils/masks';
import { mqmax, desktopMinSize } from '@Styles/mixins';
import { maskCard } from '../../../../utils/masks';
import { Link } from '@material-ui/core';
import { MP_PUBLIC_KEY, TERMS_PRIVACY_URL, TERMS_USE_URL } from '../../../../constants';
import { Observation } from '../../../../pages/HomePage/Intro/Content';
import MercadoPagoService from '../../../../services/MercadoPagoService';


export const PaymentCard = ({ close, handleViewControl, cardInfo, handleCardInfo, handleLoading }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [initError, setInitError] = useState(null);
  const [tokenizationError, setTokenizationError] = useState(null);
  const [issuerId, setIssuerId] = useState(null);
  const [cardType, setCardType] = useState(null);
  const [expirationDateError, setExpirationDateError] = useState(null);

  // Dados do cartão
  const [cardNumber, setCardNumber] = useState(cardInfo?.card?.number || '');
  const [cardName, setCardName] = useState(cardInfo?.card?.holder || '');
  const [expirationDate, setExpirationDate] = useState(getDefaultExpirationDate());
  const [cvv, setCvv] = useState(cardInfo?.card?.security_code || '');

  useEffect(() => {
    // Inicializa o SDK do Mercado Pago assim que o componente for montado
    const initMercadoPago = async () => {
      handleLoading(true);
      try {
        await MercadoPagoService.initialize(MP_PUBLIC_KEY);
        setIsInitialized(true);
        console.log('MercadoPago SDK inicializado com sucesso');
      } catch (error) {
        console.error('Erro ao inicializar MercadoPago SDK:', error);
        setInitError('Não foi possível inicializar o processamento de pagamento. Por favor, tente novamente.');
      } finally {
        handleLoading(false);
      }
    };

    initMercadoPago();
  }, [handleLoading]);

  // Detecta automaticamente a bandeira do cartão quando suficientes dígitos forem digitados
  useEffect(() => {
    const detectCardType = async () => {
      if (cardNumber && cardNumber.replace(/\D/g, '').length >= 6) {
        try {
          const bin = cardNumber.replace(/\D/g, '').substring(0, 6);
          const issuerResult = await MercadoPagoService.getIssuerId(bin);
          if (issuerResult) {
            setIssuerId(issuerResult);
            
            const cardInfo = await MercadoPagoService.getCardInfo(bin);
            if (cardInfo && cardInfo.results && cardInfo.results.length > 0) {
              setCardType(cardInfo.results[0].payment_method_id);
            }
          }
        } catch (error) {
          console.error('Erro ao identificar bandeira do cartão:', error);
        }
      }
    };

    if (isInitialized) {
      detectCardType();
    }
  }, [cardNumber, isInitialized]);

  const getDefaultExpirationDate = () => {
    if (cardInfo?.card?.expiration_month && cardInfo?.card?.expiration_year)
      return `${cardInfo?.card?.expiration_month}/${cardInfo?.card?.expiration_year}`;
    return '';
  }

  const handleDisableSubmitButton = ({ cardNumber }) => {
    if (cardNumber.length === 0) return true;
    if (cardNumber.includes('_')) return true;
    if (expirationDate.length === 0) return true;
    if (expirationDate.length !== 5 && expirationDate.length !== 7) return true;
    if (cvv.length < 3) return true;
    if (cardName.length < 4) return true;
    return false;
  };

  // EVENTS

  function onChangeCardNumber(event) {
    const cardNumber = event.target.value?.replace(/\s/g, '').replace(/[a-z]/gi, '');
    setCardNumber(cardNumber);
    
    // Quando o usuário digitar os primeiros 6 dígitos, identifica o emissor do cartão
    if (cardNumber.length >= 6) {
      const bin = cardNumber.substring(0, 6);
      MercadoPagoService.getIssuerId(bin)
        .then(id => {
          console.log('Emissor identificado:', id);
          setIssuerId(id);
        })
        .catch(error => {
          console.error('Erro ao identificar emissor:', error);
        });
    }
  }

  function onChangeCardName(event) {
    if (/^[a-zöüóőúéãáàűíÖÜÓŐÃÚÉÁÀŰÍçÇ ]{0,100}$/i.test(event.target.value)) return setCardName(event.target.value);
  }

  function onChangeExpirationDate(event) {
    const isValidDate = (valueToTest) => {
      // Se não tem valor não tem erro
      if (!valueToTest) return true;

      // Se não tem / então está incompleto
      if (!valueToTest.includes('/')) return false;

      // Pega mês e ano
      const [month, year] = valueToTest.split('/');

      // Valida formato
      if (month.length !== 2 || !year) return false;

      // Converte para número
      const monthNumber = Number(month);

      // Valida se é mês válido
      if (isNaN(monthNumber) || monthNumber < 1 || monthNumber > 12) return false;

      return true;
    };

    if (event.target.value.length > 7) {
      return;
    }

    // Validação do formato da data
    if (!isValidDate(event.target.value)) {
      setExpirationDateError('Data inválida');
    } else {
      setExpirationDateError(null);
    }
    
    setExpirationDate(event.target.value.substring(0, 7));
  }

  function onChangeCvv(event) {
    setCvv(event.target.value);
  }

  function removeSpecialCharacters(string) {
    return string?.replace(/[/.-]/g, '');
  }

  async function onSubmitForm(ev) {
    ev.preventDefault();
    handleLoading(true);
    setTokenizationError(null);

    if (!isInitialized) {
      setTokenizationError('O processador de pagamento não foi inicializado corretamente. Por favor, recarregue a página e tente novamente.');
      handleLoading(false);
      return;
    }

    try {
      // Validação dos campos do cartão
      if (!cardNumber.trim() || !cardName.trim() || !expirationDate.trim() || !cvv.trim()) {
        setTokenizationError('Todos os campos são obrigatórios. Por favor, preencha os dados do cartão.');
        handleLoading(false);
        return;
      }

      let finalExpirationDate = expirationDate;
      if (finalExpirationDate.length === 5) {
        const [month, year] = expirationDate.split('/');
        finalExpirationDate = `${month}/20${year}`;
      }
      
      const expMonth = finalExpirationDate.substring(0, 2);
      const expYear = finalExpirationDate.substring(3, 7);
      
      // Validação da data de expiração
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;
      
      if (parseInt(expYear) < currentYear || 
         (parseInt(expYear) === currentYear && parseInt(expMonth) < currentMonth)) {
        setTokenizationError('Cartão vencido. Por favor, verifique a data de validade.');
        handleLoading(false);
        return;
      }
      
      // Tokenizar o cartão com o Mercado Pago SDK
      const cardData = {
        number: removeSpecialCharacters(cardNumber),
        holder: cardName.replace(/\s+$/, ''),
        expiration_month: expMonth,
        expiration_year: expYear,
        security_code: cvv.replace(/\s+$/, ''),
      };
      
      // Obter o token do cartão
      const cardToken = await MercadoPagoService.createCardToken(cardData);
      
      // Obter o device ID
      const deviceId = MercadoPagoService.getDeviceId();
      
      // Passar os dados para o handler
      handleCardInfo({
        card: cardData,
        token: cardToken,
        deviceId: deviceId,
        issuerId: issuerId,
        paymentMethodId: cardType
      });
      
    } catch (error) {
      console.error('Erro ao processar o cartão:', error);
      
      // Mensagens de erro mais específicas baseadas no erro
      let errorMessage = 'Erro ao processar o cartão. Verifique os dados e tente novamente.';
      
      if (error.message) {
        if (error.message.includes('cardNumber')) {
          errorMessage = 'Número de cartão inválido. Verifique e tente novamente.';
        } else if (error.message.includes('expiration')) {
          errorMessage = 'Data de validade inválida. O formato correto é MM/AAAA.';
        } else if (error.message.includes('security')) {
          errorMessage = 'Código de segurança inválido. Verifique o código no verso do cartão.';
        } else if (error.message.includes('holder')) {
          errorMessage = 'Nome do titular do cartão inválido. Digite o nome completo como impresso no cartão.';
        } else {
          errorMessage = error.message;
        }
      }
      
      setTokenizationError(errorMessage);
    } finally {
      handleLoading(false);
    }
  }

  // Renderizar mensagem de erro de inicialização, se houver
  if (initError) {
    return (
      <div className="payment">
        <div className="payment-error-container">
          <p className="payment-error-message">{initError}</p>
          <button 
            className="payment-retry-button"
            onClick={() => window.location.reload()}
          >
            Tentar novamente
          </button>
        </div>
      </div>
    );
  }

  return (
    <Container>
      <Box>
        <ActionsDiv>
          <Back
            goBack={() => {
              handleViewControl(2);
            }}
          ></Back>
          <Close close={close} />
        </ActionsDiv>
        <Header />
        <Form onSubmit={onSubmitForm}>
          <FullWidthField>
            <Input
              type="text"
              name="cardNumber"
              autoComplete="cc-number"
              placeholder="Número do cartão"
              required
              autoFocus
              inputProps={{ maxLength: 19 }}
              value={maskCard(cardNumber)}
              variant="outlined"
              onChange={onChangeCardNumber}
            />
          </FullWidthField>
          <Input
            type="text"
            name="expirationDate"
            placeholder="Data de expiração"
            required
            value={maskDateDayMonth(expirationDate)}
            onChange={onChangeExpirationDate}
            inputProps={{ minLength: 5, maxLength: 7}}
            autoComplete="cc-exp"
            variant="outlined"
            error={Boolean(expirationDateError)}
            helperText={expirationDateError}
          />

          <Input
            type="text"
            name="cvv"
            placeholder="CVV"
            required
            autoComplete="cc-csc"
            inputProps={{ minLength: 3, maxLength: 4 }}
            value={cvv}
            onChange={onChangeCvv}
            variant="outlined"
          />
          <FullWidthField>
            <Input
              type="text"
              name="cardName"
              placeholder="Nome no cartão"
              autocomplete="cc-name"
              required
              value={cardName}
              onChange={onChangeCardName}
              maxLength={255}
              variant="outlined"
            />
          </FullWidthField>
          {tokenizationError && (
            <ErrorMessage>{tokenizationError}</ErrorMessage>
          )}
          <Div>{handleDisableSubmitButton({ cardNumber }) ? <Submit props={{ disabled: true }} /> : <Submit />}</Div>
        </Form>
        <Observation>*Ao clicar no botão "Continuar", você concorda com nossos <Link className='terms-form-box__terms__link' to={TERMS_USE_URL} target="_blank" download >Termos de Serviço</Link> e
                com nossa <Link className='terms-form-box__terms__link' to={TERMS_PRIVACY_URL} target="_blank" download>Políticas de Privacidade</Link>, confirma ter mais de 18 anos e aceita que a Noar
                renove automaticamente sua assinatura e cobre o preço da assinatura
                da sua forma de pagamento até você cancelar.
                Você pode cancelar quando quiser para evitar cobranças futuras.
                Para cancelar, acesse a página "Conta" e clique em "Cancelar Assinatura".</Observation>
      </Box>
    </Container>
  );
};

export default PaymentCard;

const ActionsDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: auto;
  ${mqmax(desktopMinSize - 1)} {
    height: calc(20 / 16 * 1rem);
  }
`;

const Div = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  grid-column-start: 1;
  grid-column-end: 3;
  margin-top: calc(22 / 16 * 1rem);
  ${mqmax(desktopMinSize - 1)} {
    margin-top: calc(4 / 16 * 1rem);
  }
`;

const FullWidthField = styled.div`
  width: 100%;
  grid-column-start: 1;
  grid-column-end: 3;

  &:first-child > input {
    max-width: 481px;
    width: 100%;
    height: 42px;
    border: 3px solid transparent;
    border-radius: 25px;
    padding-left: 23px;
    transition: border-color 350ms;

    &:focus {
      outline: 0;
      border-color: #2d80f2;
    }

    &::placeholder {
      color: #787878;
      font-size: 0.938rem;
      font-weight: 300;
      font-family: 'Poppins', sans-serif;
    }

    ${mqmax(desktopMinSize - 1)} {
      width: 266px;
      height: 31px;
      font-size: 0.75rem;
      padding-left: 16px;

      &::placeholder {
        font-size: 0.75rem;
      }
    }
  }
`;

const ErrorMessage = styled.div`
  color: #ff0000;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
  grid-column-start: 1;
  grid-column-end: 3;
`;

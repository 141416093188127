import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Title, Subtitle, Text } from '@Components/Public';
import { desktopMinSize, mqmin } from '@Styles/mixins';
import { 
  GetApp, 
  AccountCircle, 
  Apps, 
  Computer 
} from '@material-ui/icons';

const floatAnimation = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

const pulseAnimation = keyframes`
  0% { transform: scale(1); opacity: 0.5; }
  50% { transform: scale(1.05); opacity: 0.8; }
  100% { transform: scale(1); opacity: 0.5; }
`;

const glowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const slideInAnimation = keyframes`
  0% { transform: translateX(-20px); opacity: 0; }
  100% { transform: translateX(0); opacity: 1; }
`;

// Styled Components - Base Elements
const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6rem 1rem;
  background: linear-gradient(135deg, #17171A 0%, #1E1E24 100%);
  width: 100%;
  position: relative;
  overflow: hidden;

  ${mqmin(desktopMinSize)} {
    padding: 8rem 2rem;
  }
`;

const GlowBackground = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150%;
  height: 150%;
  background: radial-gradient(circle at center, 
    rgba(0,122,255,0.15) 0%, 
    rgba(160,11,246,0.1) 30%, 
    rgba(23,23,26,0.05) 70%
  );
  pointer-events: none;
  animation: ${pulseAnimation} 8s ease-in-out infinite;
  filter: blur(60px);
`;

const CardGlow = styled.div`
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.1),
    transparent
  );
  transition: 0.5s;
  pointer-events: none;
`;

// Styled Components - Header
const Header = styled.div`
  text-align: center;
  margin-bottom: 4rem;
  max-width: 600px;
  position: relative;
  z-index: 1;

  ${Title} {
    margin-bottom: 1rem;
    background: linear-gradient(135deg, #fff 0%, #B4B4B4 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 60px;
      height: 3px;
      background: linear-gradient(90deg, #007AFF, #6B3AFE);
      border-radius: 2px;
    }
  }

  ${Subtitle} {
    color: #007AFF;
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 2rem;
    text-align: center;
    text-shadow: 0 0 20px rgba(0,122,255,0.3);
    
    margin-top: 1rem;

    ${mqmin(desktopMinSize)} {
      font-size: 1.5rem;
    }
  }
`;

// Styled Components - Steps Grid
const StepsGrid = styled.div`
  display: grid;
  gap: 2rem;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 4rem;
  position: relative;
  z-index: 1;
  
  ${mqmin(desktopMinSize)} {
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
  }
`;

// Styled Components - Step Elements
const StepIcon = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: rgba(60, 60, 68, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1.5rem;
  transition: all 0.4s ease;

  svg {
    width: 32px;
    height: 32px;
    color: #007AFF;
    filter: drop-shadow(0 0 10px rgba(0,122,255,0.3));
    transition: color 0.4s ease;
  }
`;

const StepNumber = styled.div`
  position: absolute;
  top: 1rem;
  right: 2rem;
  width: 2.5rem;
  height: 2.5rem;
  background: linear-gradient(135deg, #007AFF 0%, #6B3AFE 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 1.25rem;
  box-shadow: 0 4px 15px rgba(0,122,255,0.3);
`;

const StepTitle = styled.h3`
  color: #fff;
  font-size: 1.25rem;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, #fff 0%, #B4B4B4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const StepDescription = styled(Text)`
  color: #B4B4B4;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
`;

const StepCard = styled.div`
  position: relative;
  background: rgba(41, 41, 50, 0.8);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  overflow: hidden;
  animation: ${slideInAnimation} 0.6s ease-out forwards;
  animation-delay: calc(0.2s * var(--index, 0));
  opacity: 0;

  &:nth-child(1) { --index: 1; }
  &:nth-child(2) { --index: 2; }
  &:nth-child(3) { --index: 3; }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, 
      rgba(0,122,255,0.1) 0%, 
      rgba(107,58,254,0.1) 100%
    );
    opacity: 0;
    transition: opacity 0.4s ease;
  }

  &:hover {
    transform: translateY(-10px) scale(1.02);
    border-color: transparent;
    box-shadow: 
      0 10px 30px -10px rgba(0,0,0,0.5),
      0 0 0 1px rgba(0,122,255,0.3);

    &::before {
      opacity: 1;
    }

    ${CardGlow} {
      left: 100%;
    }

    ${StepIcon} {
      transform: scale(1.1);
      background: linear-gradient(135deg, rgba(0,122,255,0.2) 0%, rgba(107,58,254,0.2) 100%);

      svg {
        color: #fff;
      }
    }
  }
`;

// Styled Components - Action Elements
const ActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  position: relative;
  z-index: 1;
  
`;

const ActionText = styled(Text)`
  color: #fff;
  font-size: 1.25rem;
  font-weight: 500;
  background: linear-gradient(135deg, #fff 0%, #B4B4B4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const ActionButton = styled.button`
  background: linear-gradient(135deg, #007AFF 0%, #6B3AFE 100%);
  color: #fff;
  border: none;
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  font-size: 1.125rem;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    transition: 0.5s;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0,122,255,0.4);
    background: linear-gradient(135deg, #0056B3 0%, #5B2AEE 100%);

    &::before {
      left: 100%;
    }
  }
`;

// Component
const HowToUse = () => {
  const scrollToDownload = () => {
    const downloadSection = document.querySelector('#download');
    downloadSection.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Section id="como-usar">
      <GlowBackground />
      <Header>
        <Title as="h2">
          <strong>Como começar?</strong>
        </Title>
        <Subtitle>
          Em apenas 3 passos simples você já estará utilizando seu supercomputador
        </Subtitle>
      </Header>
      
      <StepsGrid>
        <StepCard>
          <CardGlow />
          <StepIcon>
            <GetApp />
          </StepIcon>
          <StepNumber>1</StepNumber>
          <StepTitle>Baixe o App</StepTitle>
          <StepDescription>
            Escolha a versão da NOAR para seu dispositivo (Windows ou Android)
          </StepDescription>
        </StepCard>

        <StepCard>
          <CardGlow />
          <StepIcon>
            <AccountCircle />
          </StepIcon>
          <StepNumber>2</StepNumber>
          <StepTitle>Crie sua conta</StepTitle>
          <StepDescription>
            Faça seu cadastro gratuitamente
          </StepDescription>
        </StepCard>

        <StepCard>
          <CardGlow />
          <StepIcon>
            <Computer />
          </StepIcon>
          <StepNumber>3</StepNumber>
          <StepTitle>Comece a usar</StepTitle>
          <StepDescription>
            Pronto! Agora você tem um supercomputador à sua disposição!
          </StepDescription>
        </StepCard>
      </StepsGrid>

      <ActionWrapper>
        <ActionText>Pronto para começar?</ActionText>
        <ActionButton onClick={scrollToDownload}>
          Baixar agora
        </ActionButton>
      </ActionWrapper>
    </Section>
  );
};

export default HowToUse;

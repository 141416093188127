import { useState, useEffect } from 'react';

export const useTips = tips => {
  const [currentTipIndex, setCurrentTipIndex] = useState(0);
  const [timeToTipChange, setTimeToTipChange] = useState(null);

  useEffect(() => {
    console.log('[useTips] Iniciando effect para troca de tip');
    
    const timer = setTimeout(() => {
      console.log('[useTips] Executando timer para trocar tip');
      setCurrentTipIndex(i => {
        if (i >= tips.length - 1) return 0;
        return i + 1;
      });
    }, 10000);

    setTimeToTipChange(timer);

    return () => {
      console.log('[useTips] Limpando timer anterior:', timer);
      clearTimeout(timer);
    };
  }, [currentTipIndex, tips.length]);

  function nextTip() {
    console.log('[useTips] Chamando nextTip manualmente');
    if (timeToTipChange) {
      clearTimeout(timeToTipChange);
    }
    setCurrentTipIndex(i => {
      if (i >= tips.length - 1) return 0;
      return i + 1;
    });
  }

  function previousTip() {
    console.log('[useTips] Chamando previousTip manualmente');
    if (timeToTipChange) {
      clearTimeout(timeToTipChange);
    }
    setCurrentTipIndex(i => {
      if (i <= 0) return tips.length - 1;
      return i - 1;
    });
  }

  return {
    currentTipIndex,
    tip: tips[currentTipIndex],
    nextTip,
    previousTip,
  };
};

import React, { useState } from 'react';
import * as S from '../../styled';
import { FaWindows, FaServer, FaUsersCog, FaShieldAlt, FaDatabase, FaNetworkWired } from 'react-icons/fa';
import ServicePopup from '../../components/ServicePopup';

const WindowsServer = () => {
  const [selectedService, setSelectedService] = useState(null);

  const services = [
    {
      id: 'active-directory',
      icon: <FaUsersCog />,
      title: 'Active Directory',
      features: [
        {
          icon: <FaUsersCog />,
          title: 'Gerenciamento de Usuários',
          description: 'Controle centralizado de usuários e grupos.'
        },
        {
          icon: <FaShieldAlt />,
          title: 'Políticas de Grupo',
          description: 'Implementação e gestão de GPOs.'
        },
        {
          icon: <FaDatabase />,
          title: 'Replicação',
          description: 'Configuração de controladores de domínio e replicação.'
        }
      ]
    },
    {
      id: 'file-services',
      icon: <FaServer />,
      title: 'File Services',
      features: [
        {
          icon: <FaServer />,
          title: 'Compartilhamento',
          description: 'Gerenciamento de compartilhamentos de arquivos.'
        },
        {
          icon: <FaShieldAlt />,
          title: 'Permissões',
          description: 'Controle granular de permissões NTFS.'
        },
        {
          icon: <FaDatabase />,
          title: 'DFS',
          description: 'Implementação de Distributed File System.'
        }
      ]
    },
    {
      id: 'networking',
      icon: <FaNetworkWired />,
      title: 'Networking',
      features: [
        {
          icon: <FaNetworkWired />,
          title: 'DHCP',
          description: 'Configuração e gerenciamento de DHCP.'
        },
        {
          icon: <FaServer />,
          title: 'DNS',
          description: 'Administração de serviços DNS.'
        },
        {
          icon: <FaShieldAlt />,
          title: 'VPN',
          description: 'Implementação de conexões VPN seguras.'
        }
      ]
    }
  ];

  return (
    <S.ServiceCard>
      <S.ServiceHeader>
        <S.ServiceIcon>
          <FaWindows />
        </S.ServiceIcon>
        <S.ServiceTitle>Windows Server</S.ServiceTitle>
      </S.ServiceHeader>

      <S.SubServicesList>
        {services.map((service) => (
          <S.SubServiceItem
            key={service.id}
            onClick={() => setSelectedService(service)}
          >
            <S.SubServiceIcon>
              {service.icon}
            </S.SubServiceIcon>
            <S.SubServiceTitle>
              {service.title}
            </S.SubServiceTitle>
          </S.SubServiceItem>
        ))}
      </S.SubServicesList>

      {selectedService && (
        <ServicePopup
          isOpen={!!selectedService}
          onClose={() => setSelectedService(null)}
          title={selectedService.title}
          features={selectedService.features}
          ctaText="Solicitar Serviço"
        />
      )}
    </S.ServiceCard>
  );
};

export default WindowsServer; 
import React from 'react';
import MobileHeader from '../../components/Header';
import MyProfileBox from './MyProfileBox';
import Header from './Header';
import * as S from './styled';

function MyProfilePage() {
  return (
    <S.PageContainer>
      <MobileHeader />
      <S.ContentWrapper>
        <S.SectionTitle>Meu Perfil</S.SectionTitle>
        <S.ProfileCard>
          <Header />
          <MyProfileBox />
        </S.ProfileCard>
      </S.ContentWrapper>
    </S.PageContainer>
  );
}

export default MyProfilePage;

import React, { useEffect } from 'react';
import { getInstance } from '@Services/noar-api';

import { useSelector, useDispatch } from 'react-redux';

import Copy from '../../Computer/Presentation/Copy';
import { useTutorialStep } from '../../../../context/TutorialStepContext';
import {
  updateComputer
} from '@Store/ducks/computer/actions';

const Title = () => {
  const { ip_address, session } = useSelector(state => state.computer);
  const { state } = useTutorialStep();
  const dispatch = useDispatch();

  async function getInstanceData() {
    try {
      if (!session) {
        const response = await getInstance();
        if(response[0]?.session) {
          dispatch(updateComputer(response[0]));
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getInstanceData();
  }, []);

  return (
    <div className="onSectionContentWrapper">
      <div className="onSectionContentTitleWrapper">
        <strong>
          Você está conectado em: <span>{session?.image_name}</span>
        </strong>
        <p>
          Em caso de poblemas com a sua máquina, desligue por aqui
        </p>
      </div>
      {ip_address && (
        <div className="onSectionContentIPWrapper" step={state[2].step}>
          {ip_address && (
            <div className="onSectionContentIP">
              <strong>
                <span>IP: </span>
                {ip_address}
              </strong>
              <Copy />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Title;

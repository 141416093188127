import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { openConfirmationLightbox } from '../../../../store/ducks/global/actions';
import { processPayment } from '../../../../services/noar-api';
import { MP_PUBLIC_KEY } from '../../../../constants';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import MercadoPagoService from '../../../../services/MercadoPagoService';

// Estilização do componente
const FormContainer = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 10px 12px;
  box-sizing: border-box;
  overflow: hidden !important;
  display: flex;
  flex-direction: column;
  
  @media (max-width: 768px) {
    padding: 8px;
    max-width: 100%;
  }
  
  & > form {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
  
  /* Estilo específico para corrigir o problema do div.sc-jBqsNv.liaoNU */
  &.payment-form-container {
    overflow: hidden !important;
    max-width: 100% !important;
    box-sizing: border-box !important;
  }
`;

const FormTitle = styled.h3`
  margin-bottom: 10px;
  text-align: center;
  color: #fff;
  font-size: 1.1rem;
  width: 100%;
  box-sizing: border-box;
  
  @media (max-width: 480px) {
    font-size: 1.05rem;
    margin-bottom: 8px;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 12px;
  text-align: center;
  width: 100%;
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  background: linear-gradient(135deg, #7B2DF2 0%, #D92DF2 100%);
  color: white;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  font-size: 0.95rem;
  cursor: pointer;
  transition: opacity 0.2s ease;
  width: 100%;
  max-width: 320px;
  
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  @media (max-width: 480px) {
    font-size: 0.9rem;
    padding: 8px 15px;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
`;

const LoadingSpinner = styled.div`
  border: 3px solid rgba(123, 45, 242, 0.2);
  border-radius: 50%;
  border-top: 3px solid rgba(123, 45, 242, 1);
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-bottom: 15px;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const LoadingText = styled.div`
  color: #fff;
  font-size: 14px;
  max-width: 300px;
  margin: 0 auto;
`;

const ErrorMessage = styled.div`
  color: #f87171;
  padding: 16px;
  text-align: center;
  margin-bottom: 16px;
`;

// Componente para exibir o texto de políticas
const TermsText = styled.div`
  font-size: 9px;
  line-height: 1.3;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 10px;
  padding: 6px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
  word-wrap: break-word;
  
  @media (max-width: 480px) {
    font-size: 8px;
    padding: 5px;
    margin-top: 8px;
  }
  
  p {
    margin-bottom: 4px;
    max-width: 100%;
  }
  
  a {
    color: #8B5CF6;
    text-decoration: underline;
    cursor: pointer;
  }
`;

// Campo de entrada customizado com suporte a máscaras
const CustomInput = styled.input`
  width: 100%;
  height: 100%;
  background: transparent;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 0 8px;
  box-sizing: border-box;
  font-size: 13px;
  outline: none;
  max-width: 100%;
  text-overflow: ellipsis;
  
  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
  }
  
  @media (max-width: 480px) {
    font-size: 12px;
    padding: 0 6px;
  }
`;

// CSS específico para o formulário do Mercado Pago
const FormStyles = styled.div`
  /* Estilos exatamente como na documentação */
  #form-checkout {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    gap: 0;
  }

  .container {
    height: 38px;
    display: block;
    border: 1px solid #4c4f61;
    border-radius: 4px;
    padding: 0;
    margin-bottom: 6px;
    background: #1e2132;
    width: 100%;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    max-width: 100%;
  }
  
  .container.focused {
    border-color: #8B5CF6;
    box-shadow: 0 0 0 2px rgba(139, 92, 246, 0.25);
  }
  
  .container.has-error {
    border-color: #f87171;
  }

  .form-label {
    display: block;
    margin-bottom: 3px;
    font-weight: 500;
    color: #fff;
    font-size: 11px;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .row {
    display: flex;
    gap: 10px;
    width: 100%;
    margin-bottom: 3px;
    box-sizing: border-box;
    max-width: 100%;
    
    @media (max-width: 480px) {
      gap: 8px;
    }
  }

  .col {
    flex: 1;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
  }

  .progress-bar {
    width: 100%;
    height: 6px;
    margin-top: 5px;
  }
  
  /* Estilos específicos para os iframes do Mercado Pago */
  .mp-mercadopago-iframe-container,
  .mp-mercadopago-iframe {
    width: 100% !important;
    height: 100% !important;
    display: block;
    position: static !important;
    background: transparent !important;
    z-index: 1;
  }
  
  /* Garante que os iframes ocupem todo o espaço do container */
  .mp-mercadopago-iframe iframe {
    width: 100% !important;
    height: 38px !important;
    position: static !important;
    display: block !important;
    border: none !important;
    top: 0 !important;
    left: 0 !important;
    transform: none !important;
  }
  
  /* Esconde os campos seguros do Mercado Pago */
  .secure-field-container {
    display: none;
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;
    z-index: -1;
    overflow: hidden;
  }
  
  /* Campo de erro */
  .field-error {
    color: #f87171;
    font-size: 9px;
    margin-top: 1px;
    margin-bottom: 4px;
    display: block;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    word-break: break-word;
    overflow-wrap: break-word;
  }
  
  /* Info de parcelas */
  .form-info {
    font-size: 10px;
    color: rgba(255, 255, 255, 0.7);
    margin-top: 2px;
    margin-bottom: 6px;
  }

  .installment-info {
    font-size: 14px;
    padding: 8px 12px;
    background-color: rgba(123, 45, 242, 0.15);
    border-radius: 4px;
    color: #fff;
    font-weight: 500;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
  }
  
  /* Ajustes de responsividade para telas muito pequenas */
  @media (max-width: 360px) {
    .row {
      flex-direction: column;
      gap: 16px;
    }
    
    .col {
      width: 100%;
    }
  }
`;

// Funções para lidar com máscaras
const applyCardNumberMask = (value) => {
  if (!value) return '';
  
  // Remove todos os caracteres não numéricos
  value = value.replace(/\D/g, '');
  
  // Limita a 16 dígitos (padrão de cartões)
  value = value.substring(0, 16);
  
  // Adiciona espaço a cada 4 dígitos
  value = value.replace(/(\d{4})(?=\d)/g, '$1 ');
  
  return value;
};

const applyExpirationDateMask = (value) => {
  if (!value) return '';
  
  // Remove todos os caracteres não numéricos
  value = value.replace(/\D/g, '');
  
  // Limita a 4 dígitos (MM/YY)
  value = value.substring(0, 4);
  
  // Adiciona barra após os 2 primeiros dígitos (mês)
  if (value.length > 2) {
    value = value.substring(0, 2) + '/' + value.substring(2);
  }
  
  return value;
};

const applySecurityCodeMask = (value) => {
  if (!value) return '';
  
  // Remove todos os caracteres não numéricos
  value = value.replace(/\D/g, '');
  
  // Limita a 4 dígitos (tamanho máximo para AMEX, outros cartões usam 3)
  return value.substring(0, 4);
};

// Função para aplicar máscara de CPF
const applyMaskCPF = (value) => {
  if (!value) return '';
  
  // Remove todos os caracteres não numéricos
  value = value.replace(/\D/g, '');
  
  // Limita a 11 dígitos
  value = value.substring(0, 11);
  
  // Aplica a máscara de CPF
  value = value.replace(/(\d{3})(\d)/, '$1.$2');
  value = value.replace(/(\d{3})(\d)/, '$1.$2');
  value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  
  return value;
};

const UnifiedPaymentComponent = ({ 
  amount, 
  selectedProductList, 
  setPaymentId, 
  handleShowPayment, 
  setDisablePaymentContent,
  recurrent,
  discountVoucher
}) => {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  
  // Estado de inicialização
  const [isLoading, setIsLoading] = useState(true);
  const [formError, setFormError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  
  // Campos seguros do Mercado Pago
  const [secureFields, setSecureFields] = useState(null);
  
  // Estados para os campos personalizados
  const [cardNumber, setCardNumber] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [securityCode, setSecurityCode] = useState('');
  const [cardholderName, setCardholderName] = useState('');
  const [cpf, setCpf] = useState('');
  
  // Estados para validação de campos
  const [cardNumberError, setCardNumberError] = useState('');
  const [expirationDateError, setExpirationDateError] = useState('');
  const [securityCodeError, setSecurityCodeError] = useState('');
  const [cardholderNameError, setCardholderNameError] = useState('');
  const [cpfError, setCpfError] = useState('');
  
  // Estados para controle de foco
  const [cardNumberFocused, setCardNumberFocused] = useState(false);
  const [expirationDateFocused, setExpirationDateFocused] = useState(false);
  const [securityCodeFocused, setSecurityCodeFocused] = useState(false);
  const [cardholderNameFocused, setCardholderNameFocused] = useState(false);
  const [cpfFocused, setCpfFocused] = useState(false);
  
  // Referências para os inputs personalizados
  const cardNumberRef = useRef(null);
  const expirationDateRef = useRef(null);
  const securityCodeRef = useRef(null);
  const cardholderNameRef = useRef(null);
  const cpfRef = useRef(null);
  
  // Formata a data atual para exibir no texto de políticas
  const getCurrentDate = () => {
    return format(new Date(), "dd 'de' MMM. 'de' yyyy", { locale: ptBR });
  };
  
  // Obtém o nome do plano a partir do ID do produto
  const getPlanName = () => {
    if (!selectedProductList || selectedProductList.length === 0) return "Noar";
    
    const productId = selectedProductList[0].product_id;
    switch(productId) {
      case 49: return "Basic";
      case 50: return "Plus";
      case 51: return "Ultra";
      default: return "Noar";
    }
  };
  
  // Validação de campos
  const validateCardNumber = (value) => {
    if (!value) return 'Número do cartão é obrigatório';
    if (value.replace(/\s/g, '').length < 13) return 'Número do cartão inválido';
    return '';
  };
  
  const validateExpirationDate = (value) => {
    if (!value) return 'Data de expiração é obrigatória';
    if (value.length < 5) return 'Data de expiração inválida';
    
    const [month, year] = value.split('/');
    const currentYear = new Date().getFullYear() % 100; // Últimos 2 dígitos do ano atual
    const currentMonth = new Date().getMonth() + 1; // Mês atual (1-12)
    
    const numMonth = parseInt(month, 10);
    const numYear = parseInt(year, 10);
    
    if (numMonth < 1 || numMonth > 12) return 'Mês inválido';
    if (numYear < currentYear || (numYear === currentYear && numMonth < currentMonth)) {
      return 'Cartão expirado';
    }
    
    return '';
  };
  
  const validateSecurityCode = (value) => {
    if (!value) return 'Código de segurança é obrigatório';
    if (value.length < 3) return 'Código de segurança inválido';
    return '';
  };
  
  const validateCardholderName = (value) => {
    if (!value) return 'Nome do titular é obrigatório';
    if (value.trim().length < 3) return 'Nome muito curto';
    if (!/\s/.test(value)) return 'Informe o nome completo';
    return '';
  };
  
  const validateCpf = (value) => {
    const rawCpf = value.replace(/\D/g, '');
    if (!rawCpf) return 'CPF é obrigatório';
    if (rawCpf.length !== 11) return 'CPF deve conter 11 dígitos';
    
    // Validação básica de CPF
    if (/^(\d)\1{10}$/.test(rawCpf)) return 'CPF inválido';
    
    // Cálculo da validação do CPF
    let sum = 0;
    let remainder;
    
    for (let i = 1; i <= 9; i++) 
      sum = sum + parseInt(rawCpf.substring(i-1, i)) * (11 - i);
    remainder = (sum * 10) % 11;
    
    if ((remainder === 10) || (remainder === 11)) remainder = 0;
    if (remainder !== parseInt(rawCpf.substring(9, 10))) return 'CPF inválido';
    
    sum = 0;
    for (let i = 1; i <= 10; i++) 
      sum = sum + parseInt(rawCpf.substring(i-1, i)) * (12 - i);
    remainder = (sum * 10) % 11;
    
    if ((remainder === 10) || (remainder === 11)) remainder = 0;
    if (remainder !== parseInt(rawCpf.substring(10, 11))) return 'CPF inválido';
    
    return '';
  };
  
  // Função para validar todos os campos
  const validateAllFields = () => {
    const cardNumberErr = validateCardNumber(cardNumber);
    const expirationDateErr = validateExpirationDate(expirationDate);
    const securityCodeErr = validateSecurityCode(securityCode);
    const cardholderNameErr = validateCardholderName(cardholderName);
    const cpfErr = validateCpf(cpf);
    
    setCardNumberError(cardNumberErr);
    setExpirationDateError(expirationDateErr);
    setSecurityCodeError(securityCodeErr);
    setCardholderNameError(cardholderNameErr);
    setCpfError(cpfErr);
    
    return !cardNumberErr && !expirationDateErr && !securityCodeErr && !cardholderNameErr && !cpfErr;
  };
  
  // Handlers para mudanças nos campos personalizados
  const handleCardNumberChange = (e) => {
    const maskedValue = applyCardNumberMask(e.target.value);
    setCardNumber(maskedValue);
    
    // Sincronizar com o campo seguro do Mercado Pago se disponível
    if (secureFields?.cardNumber) {
      const numericValue = maskedValue.replace(/\D/g, '');
      secureFields.cardNumber.setValue(numericValue);
    }
    
    if (cardNumberError) {
      setCardNumberError(validateCardNumber(maskedValue));
    }
  };
  
  const handleExpirationDateChange = (e) => {
    const maskedValue = applyExpirationDateMask(e.target.value);
    setExpirationDate(maskedValue);
    
    // Sincronizar com o campo seguro do Mercado Pago se disponível
    if (secureFields?.expirationDate) {
      secureFields.expirationDate.setValue(maskedValue);
    }
    
    if (expirationDateError) {
      setExpirationDateError(validateExpirationDate(maskedValue));
    }
  };
  
  const handleSecurityCodeChange = (e) => {
    const maskedValue = applySecurityCodeMask(e.target.value);
    setSecurityCode(maskedValue);
    
    // Sincronizar com o campo seguro do Mercado Pago se disponível
    if (secureFields?.securityCode) {
      secureFields.securityCode.setValue(maskedValue);
    }
    
    if (securityCodeError) {
      setSecurityCodeError(validateSecurityCode(maskedValue));
    }
  };
  
  const handleCardholderNameChange = (e) => {
    setCardholderName(e.target.value);
    
    if (cardholderNameError) {
      setCardholderNameError(validateCardholderName(e.target.value));
    }
  };
  
  const handleCpfChange = (e) => {
    const maskedValue = applyMaskCPF(e.target.value);
    setCpf(maskedValue);
    
    if (cpfError) {
      setCpfError(validateCpf(maskedValue));
    }
  };
  
  // Handlers para eventos de foco
  const handleFocus = (field) => {
    switch (field) {
      case 'cardNumber':
        setCardNumberFocused(true);
        if (secureFields?.cardNumber) secureFields.cardNumber.focus();
        break;
      case 'expirationDate':
        setExpirationDateFocused(true);
        if (secureFields?.expirationDate) secureFields.expirationDate.focus();
        break;
      case 'securityCode':
        setSecurityCodeFocused(true);
        if (secureFields?.securityCode) secureFields.securityCode.focus();
        break;
      case 'cardholderName':
        setCardholderNameFocused(true);
        break;
      case 'cpf':
        setCpfFocused(true);
        break;
      default:
        break;
    }
  };
  
  const handleBlur = (field) => {
    switch (field) {
      case 'cardNumber':
        setCardNumberFocused(false);
        setCardNumberError(validateCardNumber(cardNumber));
        break;
      case 'expirationDate':
        setExpirationDateFocused(false);
        setExpirationDateError(validateExpirationDate(expirationDate));
        break;
      case 'securityCode':
        setSecurityCodeFocused(false);
        setSecurityCodeError(validateSecurityCode(securityCode));
        break;
      case 'cardholderName':
        setCardholderNameFocused(false);
        setCardholderNameError(validateCardholderName(cardholderName));
        break;
      case 'cpf':
        setCpfFocused(false);
        setCpfError(validateCpf(cpf));
        break;
      default:
        break;
    }
  };
  
  // Função para remover a máscara do CPF para envio
  const removeNonDigits = (value) => {
    return value.replace(/\D/g, '');
  };
  
  // Função para criar token diretamente usando a instância de MP global
  const createTokenDirectly = async (cardholderName, cpfNumber) => {
    console.log('[DEBUG-DIRECT-TOKEN] Iniciando criação direta de token');
    
    if (!window.mp) {
      console.error('[DEBUG-DIRECT-TOKEN] Instância MP não está disponível');
      throw new Error('SDK Mercado Pago não foi inicializado corretamente');
    }
    
    try {
      console.log('[DEBUG-DIRECT-TOKEN] Preparando dados para criação do token');
      
      // Extrair dados dos inputs customizados
      const cardNumberValue = cardNumber.replace(/\s/g, '');
      const [expirationMonth, expirationYear] = expirationDate.split('/');
      const securityCodeValue = securityCode;
      
      // Validação dos dados extraídos
      if (!cardNumberValue || cardNumberValue.length < 13) {
        throw new Error('Número do cartão inválido');
      }
      
      if (!expirationMonth || !expirationYear) {
        throw new Error('Data de expiração inválida');
      }
      
      if (!securityCodeValue || securityCodeValue.length < 3) {
        throw new Error('Código de segurança inválido');
      }
      
      // Dados para criar o token
      const cardData = {
        cardNumber: cardNumberValue,
        cardholderName: cardholderName,
        cardExpirationMonth: expirationMonth,
        cardExpirationYear: expirationYear,
        securityCode: securityCodeValue,
        identificationType: 'CPF',
        identificationNumber: cpfNumber,
        installments: 1 // Explicitamente definir como 1 parcela
      };
      
      console.log('[DEBUG-DIRECT-TOKEN] Dados preparados (sem valores sensíveis):', {
        cardholderName: cardholderName ? `${cardholderName.substring(0, 3)}...` : 'vazio',
        cardExpirationMonth: expirationMonth || 'vazio',
        cardExpirationYear: expirationYear || 'vazio',
        identificationType: 'CPF',
        identificationNumber: cpfNumber ? `*****${cpfNumber.substring(5, 8)}***` : 'vazio'
      });
      
      // Verificar se métodos necessários estão disponíveis
      if (typeof window.mp.createCardToken !== 'function') {
        console.error('[DEBUG-DIRECT-TOKEN] Método createCardToken não disponível na instância global');
        throw new Error('Método de criação de token não disponível');
      }
      
      // Criar token diretamente com a instância global
      console.log('[DEBUG-DIRECT-TOKEN] Chamando método createCardToken da instância global');
      const token = await window.mp.createCardToken(cardData);
      
      if (!token || !token.id) {
        console.error('[DEBUG-DIRECT-TOKEN] Token retornado não possui ID');
        throw new Error('Token do cartão inválido');
      }
      
      console.log('[DEBUG-DIRECT-TOKEN] Token criado com sucesso:', token.id.substring(0, 4) + '...');
      return token;
      } catch (error) {
      console.error('[DEBUG-DIRECT-TOKEN] Erro ao criar token direto:', error);
      
      // Melhorar a mensagem de erro para o usuário
      let errorMessage = 'Erro ao processar o cartão: ';
      
      if (error.message) {
        if (error.message.toLowerCase().includes('card')) {
          errorMessage += 'Dados do cartão inválidos. Verifique o número do cartão.';
        } else if (error.message.toLowerCase().includes('expir')) {
          errorMessage += 'A data de expiração é inválida.';
        } else if (error.message.toLowerCase().includes('security') || error.message.toLowerCase().includes('cvv')) {
          errorMessage += 'O código de segurança é inválido.';
        } else if (error.message.toLowerCase().includes('identification')) {
          errorMessage += 'O CPF informado é inválido.';
        } else {
          errorMessage += error.message;
        }
      } else {
        errorMessage += 'Verifique os dados do cartão e tente novamente.';
      }
      
      throw new Error(errorMessage);
    }
  };
  
  // Handler para o submit do formulário
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    
    console.log("[DEBUG-PAYMENT] Iniciando processamento de pagamento");
    
    if (isProcessing) {
      console.log("[DEBUG-PAYMENT] Processamento já em andamento, ignorando");
      return;
    }
    
    // Validar todos os campos antes de submeter
    if (!validateAllFields()) {
      console.log("[DEBUG-PAYMENT] Validação de campos falhou");
              return;
            }
            
            setIsProcessing(true);
            setDisablePaymentContent(true);
            
            try {
      // Obter o nome do titular do cartão do campo personalizado
      if (!cardholderName || cardholderName.trim().length < 3) {
        console.log("[DEBUG-PAYMENT] Nome do titular inválido");
        throw new Error("Por favor, preencha o nome completo do titular do cartão.");
      }
      
      // Validação do CPF (deve estar preenchido e ter 11 dígitos sem formatação)
      const rawCpf = removeNonDigits(cpf);
      if (!rawCpf || rawCpf.length !== 11) {
        console.log("[DEBUG-PAYMENT] CPF inválido");
        throw new Error("Por favor, preencha o CPF do titular do cartão corretamente (11 dígitos).");
      }
      
      console.log("[DEBUG-PAYMENT] Criando token do cartão...");
      
      let cardToken;
      try {
        // Estratégia 1: Tentativa usando método direto da instância global
        if (window.mp) {
          try {
            console.log("[DEBUG-PAYMENT] Tentando criar token via instância global (window.mp)");
            cardToken = await createTokenDirectly(cardholderName, rawCpf);
            console.log('[DEBUG-PAYMENT] Token criado com sucesso via método direto');
          } catch (directError) {
            console.error("[DEBUG-PAYMENT] Falha na criação via método direto:", directError);
            // Continuar para a próxima estratégia
          }
        }
        
        // Estratégia 2: Se a primeira falhar, tentar via MercadoPagoService
        if (!cardToken && MercadoPagoService.isInitialized()) {
          try {
            console.log("[DEBUG-PAYMENT] Tentando criar token via MercadoPagoService");
            
            // Extrair dados dos inputs customizados
            const cardNumberValue = cardNumber.replace(/\s/g, '');
            const [expirationMonth, expirationYear] = expirationDate.split('/');
            const securityCodeValue = securityCode;
            
            // Dados para criar o token completo
            const fullCardData = {
              cardNumber: cardNumberValue,
              cardholderName: cardholderName,
              cardExpirationMonth: expirationMonth,
              cardExpirationYear: expirationYear,
              securityCode: securityCodeValue,
              identificationType: 'CPF',
              identificationNumber: rawCpf
            };
            
            cardToken = await MercadoPagoService.createCardToken(fullCardData);
            console.log('[DEBUG-PAYMENT] Token criado com sucesso via MercadoPagoService');
          } catch (serviceError) {
            console.error("[DEBUG-PAYMENT] Falha na criação via MercadoPagoService:", serviceError);
            // Não há mais estratégias para tentar, deixar o erro propagar
            throw serviceError;
          }
        }
        
        // Verificar se obtivemos o token com sucesso
        if (!cardToken || !cardToken.id) {
          throw new Error('Token do cartão não foi gerado corretamente. Verifique os dados informados.');
        }
        
        console.log('[DEBUG-PAYMENT] Token do cartão criado com sucesso:', cardToken.id.substring(0, 4) + '...');
        
      } catch (tokenError) {
        console.error("[DEBUG-PAYMENT] Erro específico na criação do token:", tokenError);
        console.error("[DEBUG-PAYMENT] Mensagem do erro:", tokenError.message);
        
        // Mostrar erro mais amigável para o usuário
        let errorMessage = "Erro ao processar o cartão. ";
        
        if (tokenError.message && tokenError.message.includes("cardNumber")) {
          errorMessage += "Verifique o número do cartão.";
        } else if (tokenError.message && tokenError.message.includes("expiration")) {
          errorMessage += "Verifique a data de validade.";
        } else if (tokenError.message && tokenError.message.includes("security")) {
          errorMessage += "Verifique o código de segurança.";
        } else {
          errorMessage += "Verifique todos os dados do cartão e tente novamente.";
        }
        
        throw new Error(errorMessage);
      }
      
      // Preparar dados do pagamento - IMPORTANTE: O formato correto para a API
      const paymentInfo = {
        token: cardToken.id,
                device_id: window.deviceId || null,
                installments: 1 // Fixando parcelas em 1x explicitamente
              };
              
      console.log("[DEBUG-PAYMENT] Dados do produto selecionado:", selectedProductList);
      console.log("[DEBUG-PAYMENT] Valor recorrente:", recurrent);
      
      console.log("[DEBUG-PAYMENT] Enviando requisição para processamento...");
      
      // CORREÇÃO: Formato correto para a API conforme implementação em noar-api.js
      const response = await processPayment(
        paymentInfo,
        selectedProductList,
        recurrent,
        discountVoucher
      );
      
      console.log("[DEBUG-PAYMENT] Resposta recebida do processamento:", response.status);
              
              if (!response.ok) {
                // Processar erro
        console.log("[DEBUG-PAYMENT] Resposta com erro:", response.status);
                const responseBody = await response.json();
                let errorMessage = responseBody?.message || 
                  "Seu pagamento não foi aprovado. Por favor, verifique os dados do cartão e tente novamente.";
                
        console.log("[DEBUG-PAYMENT] Mensagem de erro:", errorMessage);
        
                dispatch(
                  openConfirmationLightbox(
                    () => { 
                      setIsProcessing(false);
                      setDisablePaymentContent(false);
                    },
                    errorMessage,
                    "Fechar",
                    "",
                    null,
                    null,
                    false,
                  )
                );
                return;
              }
      
      console.log("[DEBUG-PAYMENT] Pagamento processado com sucesso");
              
              // Processar resposta de sucesso
              const responseBody = await response.json();
      console.log("[DEBUG-PAYMENT] ID do pagamento:", responseBody?.id_payment);
              setPaymentId(responseBody?.id_payment);
              
              dispatch(
                openConfirmationLightbox(
                  () => {
                    if (recurrent) {
                      window.location.href = '/my/profile';
                    }
                  },
                  "Seu pagamento está sendo processado! Você pode acompanhar o status na página de perfil.",
                  "Ver meu perfil",
                  "Fechar",
                  null,
                  null,
                  false,
                )
              );
              
            } catch (error) {
      console.error('[DEBUG-PAYMENT] Erro ao processar pagamento:', error);
              
              dispatch(
                openConfirmationLightbox(
                  () => { 
                    setIsProcessing(false);
                    setDisablePaymentContent(false);
                  },
                  error.message || "Erro ao processar pagamento. Por favor, tente novamente.",
                  "Fechar",
                  "",
                  null,
                  null,
                  false,
                )
              );
            } finally {
      console.log("[DEBUG-PAYMENT] Finalizando processamento de pagamento");
              setIsProcessing(false);
              setDisablePaymentContent(false);
            }
  };
  
  // Inicializar o MercadoPagoService
  useEffect(() => {
    const initializeMercadoPago = async () => {
      try {
        setIsLoading(true);
        console.log('[DEBUG-MP-INIT] Iniciando inicialização do MercadoPago SDK');
        console.log('[DEBUG-MP-INIT] Chave pública:', MP_PUBLIC_KEY ? 'Presente' : 'Ausente');

        // Inicializa o MercadoPagoService
        await MercadoPagoService.initialize(MP_PUBLIC_KEY);
        console.log('[DEBUG-MP-INIT] MercadoPago SDK inicializado com sucesso');
        console.log('[DEBUG-MP-INIT] Estado da instância:', {
          inicializado: MercadoPagoService.isInitialized(),
          objetoMP: !!MercadoPagoService.mercadopago,
          fields: !!MercadoPagoService.mercadopago?.fields
        });
        
        // Inicializar o cardForm diretamente, sem depender dos campos customizados
        if (window.MercadoPago && MP_PUBLIC_KEY) {
          console.log('[DEBUG-MP-INIT] Inicializando instância direta do MercadoPago');
          window.mp = new window.MercadoPago(MP_PUBLIC_KEY, {
            locale: 'pt-BR'
          });
          console.log('[DEBUG-MP-INIT] Instância direta do MercadoPago criada', !!window.mp);
        }
        
        setIsLoading(false);
      } catch (error) {
        console.error('[DEBUG-MP-INIT] Erro ao inicializar MercadoPago SDK:', error);
        console.error('[DEBUG-MP-INIT] Mensagem:', error.message);
        console.error('[DEBUG-MP-INIT] Stack:', error.stack);
        setFormError('Não foi possível carregar o processador de pagamentos. Por favor, tente novamente.');
        setIsLoading(false);
      }
    };
    
    initializeMercadoPago();
            
            return () => {
      // Limpar instância global ao desmontar o componente
      if (window.mp) {
        console.log('[DEBUG-MP-CLEANUP] Limpando instância global do MP');
        window.mp = null;
      }
    };
  }, []);
  
  // Configurar os campos seguros após o componente ser montado
  useEffect(() => {
    // Só executamos esta lógica quando o componente não está mais carregando
    if (isLoading || !formRef.current) return;
    
    const setupSecureFields = async () => {
      try {
        console.log('[DEBUG-MP-FIELDS] Inicializando campos seguros do Mercado Pago...');
        console.log('[DEBUG-MP-FIELDS] Estado do MercadoPagoService:', {
          inicializado: MercadoPagoService.isInitialized(),
          objetoMP: !!MercadoPagoService.mercadopago,
          fields: !!MercadoPagoService.mercadopago?.fields
        });
        
        const fields = MercadoPagoService.createSecureFieldsWithMasks('form-checkout');
        console.log('[DEBUG-MP-FIELDS] Campos criados:', fields ? 'Sucesso' : 'Falha');
        
        if (fields) {
          console.log('[DEBUG-MP-FIELDS] Campos disponíveis:', {
            cardNumber: !!fields.cardNumber,
            expirationDate: !!fields.expirationDate,
            securityCode: !!fields.securityCode
          });
        }
        
        setSecureFields(fields);
        
        // Atualiza estilos customizados para os campos (mesmo que estejam ocultos)
        MercadoPagoService.updateFieldsStyle({
          "font-size": "16px",
          "color": "#ffffff",
          "font-weight": "400",
          "background-color": "transparent",
          "border": "none",
          "padding": "0 12px",
          "box-sizing": "border-box",
          "height": "100%",
          "width": "100%",
          "position": "static"
        });
        
        console.log('[DEBUG-MP-FIELDS] Campos seguros criados com sucesso');
      } catch (error) {
        console.error('[DEBUG-MP-FIELDS] Erro ao criar campos seguros:', error);
        console.error('[DEBUG-MP-FIELDS] Mensagem:', error.message);
        console.error('[DEBUG-MP-FIELDS] Stack:', error.stack);
        setFormError('Houve um problema ao inicializar os campos do formulário. Por favor, recarregue a página.');
      }
    };
    
    setupSecureFields();
  }, [isLoading]);
  
  // Efeito para colocar foco no primeiro campo ao carregar
  useEffect(() => {
    if (!isLoading && cardNumberRef.current) {
      setTimeout(() => {
        cardNumberRef.current.focus();
      }, 300);
    }
  }, [isLoading]);
  
  useEffect(() => {
    // Função para corrigir posicionamento e estilo do container
    const fixContainerStyles = () => {
      try {
        // Selecionando o container principal
        const containerElement = containerRef.current;
        if (!containerElement) return;
        
        // Aplicando estilos para garantir que não haja vazamento
        containerElement.style.maxWidth = '100%';
        containerElement.style.width = '100%';
        containerElement.style.overflow = 'hidden';
        containerElement.style.boxSizing = 'border-box';
        
        // Corrigindo todos os elementos filho
        const childElements = containerElement.querySelectorAll('*');
        childElements.forEach(el => {
          el.style.maxWidth = '100%';
          el.style.boxSizing = 'border-box';
          
          // Se for um input ou select, ajustar largura
          if (el.tagName === 'INPUT' || el.tagName === 'SELECT') {
            el.style.width = '100%';
          }
        });
        
        // Especificamente corrigindo os containers do MP
        const mpContainers = containerElement.querySelectorAll('.mp-mercadopago-iframe-container');
        mpContainers.forEach(container => {
          container.style.position = 'static';
          container.style.maxWidth = '100%';
          container.style.width = '100%';
          container.style.height = '48px';
        });
    } catch (error) {
        console.error('Erro ao corrigir estilos:', error);
      }
    };
    
    // Executar a função várias vezes para garantir que pegue o momento certo
    setTimeout(fixContainerStyles, 100);
    setTimeout(fixContainerStyles, 500);
    setTimeout(fixContainerStyles, 1000);
    setTimeout(fixContainerStyles, 2000);
    
    // Adicionar um observador para detectar mudanças no DOM
    const observer = new MutationObserver(() => {
      setTimeout(fixContainerStyles, 100);
    });
    
    // Observar mudanças no container
    if (containerRef.current) {
      observer.observe(containerRef.current, {
        childList: true,
        subtree: true,
        attributes: true
      });
    }
    
    return () => {
      observer.disconnect();
    };
  }, []);

  // Referência para o container principal
  const containerRef = useRef(null);
  
  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
        <LoadingText>
          Preparando seu formulário de pagamento...
        </LoadingText>
      </LoadingContainer>
    );
  }
  
  if (formError) {
    return (
      <FormContainer>
        <ErrorMessage>{formError}</ErrorMessage>
        <ButtonContainer>
          <SubmitButton onClick={() => window.location.reload()}>
            Tentar novamente
          </SubmitButton>
        </ButtonContainer>
      </FormContainer>
    );
  }
  
  return (
    <FormContainer className="payment-form-container" ref={containerRef}>
      <FormTitle>Plano {getPlanName()}</FormTitle>
      
      <FormStyles>
        <form id="form-checkout" ref={formRef} onSubmit={handleFormSubmit} style={{ width: '100%', maxWidth: '100%', boxSizing: 'border-box' }}>
          <div>
            <label htmlFor="custom-card-number" className="form-label">Número do Cartão</label>
            <div className={`container ${cardNumberFocused ? 'focused' : ''} ${cardNumberError ? 'has-error' : ''}`}>
              <CustomInput
                id="custom-card-number"
                type="text"
                value={cardNumber}
                onChange={handleCardNumberChange}
                onFocus={() => handleFocus('cardNumber')}
                onBlur={() => handleBlur('cardNumber')}
                placeholder="XXXX XXXX XXXX XXXX"
                autoComplete="cc-number"
                ref={cardNumberRef}
              />
            </div>
            {cardNumberError && <span className="field-error">{cardNumberError}</span>}
            
            {/* Campo seguro oculto */}
            <div className="secure-field-container">
              <div id="form-checkout__cardNumber"></div>
            </div>
          </div>
          
          <div className="row">
            <div className="col">
              <label htmlFor="custom-expiration-date" className="form-label">Validade</label>
              <div className={`container ${expirationDateFocused ? 'focused' : ''} ${expirationDateError ? 'has-error' : ''}`}>
                <CustomInput
                  id="custom-expiration-date"
                  type="text"
                  value={expirationDate}
                  onChange={handleExpirationDateChange}
                  onFocus={() => handleFocus('expirationDate')}
                  onBlur={() => handleBlur('expirationDate')}
                  placeholder="MM/YY"
                  autoComplete="cc-exp"
                  ref={expirationDateRef}
                />
              </div>
              {expirationDateError && <span className="field-error">{expirationDateError}</span>}
              
              {/* Campo seguro oculto */}
              <div className="secure-field-container">
                <div id="form-checkout__expirationDate"></div>
              </div>
            </div>
            
            <div className="col">
              <label htmlFor="custom-security-code" className="form-label">CVV</label>
              <div className={`container ${securityCodeFocused ? 'focused' : ''} ${securityCodeError ? 'has-error' : ''}`}>
                <CustomInput
                  id="custom-security-code"
                  type="text"
                  value={securityCode}
                  onChange={handleSecurityCodeChange}
                  onFocus={() => handleFocus('securityCode')}
                  onBlur={() => handleBlur('securityCode')}
                  placeholder="Código"
                  autoComplete="cc-csc"
                  maxLength="4"
                  ref={securityCodeRef}
                />
              </div>
              {securityCodeError && <span className="field-error">{securityCodeError}</span>}
              
              {/* Campo seguro oculto */}
              <div className="secure-field-container">
                <div id="form-checkout__securityCode"></div>
              </div>
            </div>
          </div>
          
          <div className="row">
            <div className="col">
              <label htmlFor="custom-cardholder-name" className="form-label">Nome no Cartão</label>
              <div className={`container ${cardholderNameFocused ? 'focused' : ''} ${cardholderNameError ? 'has-error' : ''}`}>
                <CustomInput
                  id="custom-cardholder-name"
                  type="text"
                  value={cardholderName}
                  onChange={handleCardholderNameChange}
                  onFocus={() => handleFocus('cardholderName')}
                  onBlur={() => handleBlur('cardholderName')}
                  placeholder="Nome completo"
                  autoComplete="cc-name"
                  ref={cardholderNameRef}
                />
              </div>
              {cardholderNameError && <span className="field-error">{cardholderNameError}</span>}
              
              {/* Campo original oculto */}
              <div className="secure-field-container">
                <input type="text" id="form-checkout__cardholderName" />
              </div>
            </div>
            
            <div className="col">
              <label htmlFor="custom-cpf" className="form-label">CPF</label>
              <div className={`container ${cpfFocused ? 'focused' : ''} ${cpfError ? 'has-error' : ''}`}>
                <CustomInput
                  id="custom-cpf"
                  type="text"
                  value={cpf}
                  onChange={handleCpfChange}
                  onFocus={() => handleFocus('cpf')}
                  onBlur={() => handleBlur('cpf')}
                  placeholder="000.000.000-00"
                  ref={cpfRef}
                />
              </div>
              {cpfError && <span className="field-error">{cpfError}</span>}
              
              {/* Campo original oculto */}
              <div className="secure-field-container">
                <input type="text" id="form-checkout__identificationNumber" />
              </div>
            </div>
          </div>
          
          {/* Campo oculto de parcelas para atender à exigência do MP */}
          <div className="secure-field-container">
            <select id="form-checkout__installments" defaultValue="1">
              <option value="1">1x sem juros</option>
            </select>
          </div>
          
          <TermsText>
            <p>O faturamento começa hoje, ({getCurrentDate()}),
             e será renovado automaticamente a cada mês. Não há reembolso ou estorno de valores, com base no direito de arrependimento, 
             pois a NOAR oferece a seus clientes a possibilidade de teste prévio antes da contratação dos serviços.
             Cancele nas configurações para evitar cobranças futuras.</p>
            <p>Ao continuar, você concorda com estes termos. <strong>Pagamento recorrente</strong>.</p>
          </TermsText>
          
          <ButtonContainer>
            <SubmitButton id="form-checkout__submit" type="submit" disabled={isProcessing}>
              {isProcessing ? 'Processando...' : 'Assinar'}
            </SubmitButton>
          </ButtonContainer>
          
          <progress value="0" className="progress-bar" style={{ display: isProcessing ? 'block' : 'none' }}>Carregando...</progress>
        </form>
      </FormStyles>
    </FormContainer>
  );
};

export default UnifiedPaymentComponent; 
import styled from 'styled-components';
import { rem, mqmax, desktopMinSize } from '@Styles/mixins';

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: ${rem(15)};
  text-align: center;
`;

export const SliderWrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 ${rem(20)};

  .slick-slider {
    margin: 0 -${rem(10)};
  }

  .slick-track {
    display: flex !important;
    align-items: stretch;
    min-height: ${rem(300)};
  }

  .slick-slide {
    height: auto;
    padding: 0 ${rem(10)};
    opacity: 0.5;
    transform: scale(0.95);
    transition: all 0.4s ease;

    > div {
      height: 100%;
      display: flex;
    }

    &.slick-active {
      opacity: 1;
      transform: scale(1);
    }

    &.slick-current {
      z-index: 1;
    }
  }

  .slick-list {
    padding: ${rem(15)} 0;
    margin: 0;
    overflow: visible;
  }

  .slick-dots {
    bottom: -${rem(30)};

    li button:before {
      color: #2d80f2;
      opacity: 0.3;
      font-size: ${rem(6)};
    }

    li.slick-active button:before {
      color: #2d80f2;
      opacity: 1;
    }
  }

  .slick-prev, .slick-next {
    width: ${rem(32)};
    height: ${rem(32)};
    background: rgba(45, 128, 242, 0.1);
    border-radius: 50%;
    z-index: 2;
    transition: all 0.3s ease;
    backdrop-filter: blur(10px);

    &:before {
      color: #2d80f2;
      font-size: ${rem(16)};
      opacity: 1;
    }

    &:hover {
      background: rgba(45, 128, 242, 0.2);
      transform: scale(1.1);
    }

    &:active {
      transform: scale(0.95);
    }
  }

  .slick-prev {
    left: ${rem(-16)};
  }

  .slick-next {
    right: ${rem(-16)};
  }

  ${mqmax(desktopMinSize - 1)} {
    padding: 0;
    overflow: hidden;

    .slick-list {
      overflow: hidden;
      margin: 0 ${rem(20)};
    }

    .slick-slide {
      padding: 0 ${rem(8)};
    }

    .slick-prev {
      left: ${rem(4)};
    }

    .slick-next {
      right: ${rem(4)};
    }
  }
`; 
/**
 * Utilitários para otimização de SEO
 * Essas funções ajudam a otimizar os aspectos de SEO em todas as páginas
 */

/**
 * Configuração de SEO para as páginas principais
 * Cada página deve importar esta função e usar os valores retornados
 * @param {string} pageName - Nome da página atual
 * @param {Object} customParams - Parâmetros personalizados para sobrescrever os padrões
 * @returns {Object} - Configurações de SEO para a página
 */
export const getSeoConfig = (pageName, customParams = {}) => {
  // Configurações padrão
  const defaults = {
    title: 'NOAR Cloud | Cloud Gaming e Desktop as a Service',
    description: 'Computação em nuvem de alta performance para jogos e trabalho. Acesse um computador potente de qualquer lugar.',
    keywords: 'cloud gaming, desktop as a service, daas, vdi, computador na nuvem, jogos em nuvem, cloud pc',
    canonical: `https://www.noarcloud.com/${pageName.toLowerCase().replace(/\s+/g, '-')}`,
    ogType: 'website',
    ogImage: 'https://www.noarcloud.com/og-image.jpg',
  };

  // Configurações específicas para páginas importantes
  const pageConfigs = {
    home: {
      title: 'NOAR Cloud | Cloud Gaming, Desktop as a Service e Computador na Nuvem',
      description: 'Acesse poder computacional ilimitado na palma da sua mão. Trabalhe, jogue e renderize através de um PC de última geração na nuvem.',
      keywords: 'cloud gaming, desktop as a service, computador na nuvem, jogos em nuvem, poder computacional, cloud pc, computação em nuvem',
      canonical: 'https://www.noarcloud.com/',
    },
    planos: {
      title: 'Planos NOAR Cloud | Escolha o Melhor Computador na Nuvem',
      description: 'Conheça os planos de cloud gaming e desktop as a service da NOAR Cloud. Opções para jogos, trabalho e empresas.',
      keywords: 'planos cloud gaming, planos desktop as a service, computador na nuvem preço, vdi empresarial',
    },
    'cloud-gaming': {
      title: 'Cloud Gaming | Jogue em Qualquer Dispositivo com NOAR Cloud',
      description: 'Serviço de cloud gaming de alta performance. Jogue títulos AAA em qualquer dispositivo, sem precisar de hardware potente.',
      keywords: 'cloud gaming, jogos em nuvem, streaming de jogos, jogos online, cloudgame, jogos sem hardware',
    },
    'desktop-as-a-service': {
      title: 'Desktop as a Service (DaaS) | NOAR Cloud',
      description: 'Acesse um desktop virtual potente de qualquer lugar. Solução de DaaS para profissionais e empresas.',
      keywords: 'desktop as a service, daas, desktop virtual, computador na nuvem, vdi, virtualização de desktop',
    },
    'computador-na-nuvem': {
      title: 'Computador na Nuvem | Poder Computacional Ilimitado | NOAR Cloud',
      description: 'Acesse um computador poderoso de qualquer lugar e dispositivo. Ideal para design, desenvolvimento, análise de dados e jogos.',
      keywords: 'computador na nuvem, cloud pc, pc virtual, computação em nuvem, desktop remoto, desktop virtual, acesso remoto',
      ogImage: 'https://www.noarcloud.com/images/computador-na-nuvem-og.jpg',
    },
    'for-business': {
      title: 'Soluções Empresariais | NOAR Cloud para Empresas',
      description: 'Soluções de cloud computing, VDI, DaaS e infraestrutura para empresas. Tecnologias Azure, AWS e Google Cloud.',
      keywords: 'cloud empresarial, vdi empresarial, desktop as a service empresas, cloud computing negócios, azure, aws, google cloud',
    },
    'machine-learning': {
      title: 'Infraestrutura para Machine Learning e IA | NOAR Cloud',
      description: 'Serviços de computação em nuvem para treinamento de modelos de machine learning e inteligência artificial.',
      keywords: 'machine learning cloud, treinamento de IA, computação para IA, infraestrutura ML, GPU cloud, AI cloud',
    },
    'faq': {
      title: 'Perguntas Frequentes | NOAR Cloud',
      description: 'Encontre respostas para as perguntas mais comuns sobre cloud gaming, desktop as a service e computação em nuvem.',
      keywords: 'faq noar cloud, perguntas frequentes cloud gaming, dúvidas desktop as a service, ajuda computador na nuvem, suporte noar',
      canonical: 'https://www.noarcloud.com/faq',
    },
  };

  // Mescla as configurações
  const config = {
    ...defaults,
    ...(pageConfigs[pageName] || {}),
    ...customParams,
  };

  return config;
};

/**
 * Gera o código HTML para meta tags de SEO
 * @param {Object} config - Configuração de SEO para a página
 * @returns {string} - String HTML com as meta tags
 */
export const generateSeoTags = (config) => {
  return `
    <title>${config.title}</title>
    <meta name="description" content="${config.description}" />
    <meta name="keywords" content="${config.keywords}" />
    <link rel="canonical" href="${config.canonical}" />
    <meta property="og:title" content="${config.title}" />
    <meta property="og:description" content="${config.description}" />
    <meta property="og:url" content="${config.canonical}" />
    <meta property="og:type" content="${config.ogType}" />
    <meta property="og:image" content="${config.ogImage}" />
  `;
};

/**
 * Mapeia URLs para seus caminhos canônicos
 * Útil para evitar conteúdo duplicado
 */
export const canonicalUrlMap = {
  '/': 'https://www.noarcloud.com/',
  '/home': 'https://www.noarcloud.com/',
  '/index': 'https://www.noarcloud.com/',
  '/planos': 'https://www.noarcloud.com/planos',
  '/plans': 'https://www.noarcloud.com/planos',
  '/pricing': 'https://www.noarcloud.com/planos',
  '/cloud-gaming': 'https://www.noarcloud.com/cloud-gaming',
  '/cloudgaming': 'https://www.noarcloud.com/cloud-gaming',
  '/cloud-games': 'https://www.noarcloud.com/cloud-gaming',
  '/jogos-em-nuvem': 'https://www.noarcloud.com/cloud-gaming',
  '/desktop-as-a-service': 'https://www.noarcloud.com/desktop-as-a-service',
  '/daas': 'https://www.noarcloud.com/desktop-as-a-service',
  '/vdi': 'https://www.noarcloud.com/desktop-as-a-service',
  '/computador-na-nuvem': 'https://www.noarcloud.com/computador-na-nuvem',
  '/cloud-pc': 'https://www.noarcloud.com/computador-na-nuvem',
  '/machine-learning': 'https://www.noarcloud.com/machine-learning',
  '/treinamento-ia': 'https://www.noarcloud.com/treinamento-ia',
  '/faq': 'https://www.noarcloud.com/faq',
  '/perguntas-frequentes': 'https://www.noarcloud.com/faq',
}; 
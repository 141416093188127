import React, { useEffect, useState } from 'react';
import { StatusScreen, initMercadoPago } from '@mercadopago/sdk-react';
import { NOAR_SITE, MP_PUBLIC_KEY } from '@Constants';
import { Container } from './styled';
import MercadoPagoService from '../../../../services/MercadoPagoService';

function PaymentStatusComponent({ paymentId, productLink }) {
  const [isReady, setIsReady] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const initMP = async () => {
      try {
        await MercadoPagoService.initialize(MP_PUBLIC_KEY);
        MercadoPagoService.trackEvent('payment_status_screen_initialized', { paymentId });
        
        initMercadoPago(MP_PUBLIC_KEY);
        
        setIsReady(true);
      } catch (err) {
        console.error('Erro ao inicializar Mercado Pago:', err);
        MercadoPagoService.trackEvent('payment_status_screen_initialization_failed', { 
          error: err.message,
          paymentId 
        });
        setError('Não foi possível carregar o status do pagamento. Por favor, tente novamente mais tarde.');
      }
    };

    initMP();
  }, [paymentId]);

  const initialization = MercadoPagoService.getInitializationObject({
    paymentId: paymentId
  });

  const customization = {
    backUrls: {
      return: `${NOAR_SITE}${productLink}`,
    },
    visual: {
      showExternalReference: true,
      texts: {
        ctaReturnLabel: "Voltar para a loja",
      }
    },
  };

  const onReady = () => {
    console.log('Status do pagamento carregado com sucesso');
    MercadoPagoService.trackEvent('payment_status_screen_ready', { paymentId });
  };

  const onError = error => {
    const errorInfo = MercadoPagoService.handleMercadoPagoError(
      error, 
      'visualização do status do pagamento'
    );
    
    MercadoPagoService.trackEvent('payment_status_screen_error', { 
      error: errorInfo.technicalDetails,
      paymentId
    });
    
    console.error('Erro ao carregar status do pagamento:', errorInfo.technicalDetails);
    setError(errorInfo.userMessage);
  };

  if (error) {
    return (
      <Container>
        <div style={{ 
          padding: '20px', 
          textAlign: 'center', 
          color: '#f87171', 
          background: 'rgba(16, 18, 31, 0.6)',
          borderRadius: '8px',
          margin: '20px 0'
        }}>
          {error}
          <div>
            <button 
              onClick={() => window.location.reload()}
              style={{ 
                marginTop: '16px', 
                backgroundColor: '#8b5cf6',
                border: 'none',
                borderRadius: '4px',
                padding: '8px 16px',
                color: 'white',
                cursor: 'pointer'
              }}
            >
              Tentar novamente
            </button>
          </div>
        </div>
      </Container>
    );
  }

  if (!isReady) {
    return (
      <Container>
        <div style={{ padding: '20px', textAlign: 'center' }}>
          Carregando informações de pagamento...
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <StatusScreen 
        initialization={initialization} 
        customization={customization} 
        onReady={onReady} 
        onError={onError} 
      />
    </Container>
  );
}

export default PaymentStatusComponent;

import styled, { keyframes } from "styled-components";
import { colors } from '../../../constants/colors';
import { desktopMinSize, mqmax } from '../../../styles/mixins';

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const iconFloat = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
`;

export const AboutHeader = styled.div`
  text-align: center;
  margin-bottom: 4rem;
  animation: ${fadeInUp} 0.8s ease-out;
`;

export const AboutSubtitle = styled.p`
  font-size: 1.2rem;
  color: ${colors.text.light};
  max-width: 600px;
  margin: 1rem auto 0;
  line-height: 1.6;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  width: 100%;
`;

export const IconWrapper = styled.div`
  width: 60px;
  height: 60px;
  background: ${colors.primary};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  
  svg {
    color: ${colors.white};
    font-size: 28px;
  }
`;

export const AboutGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  width: 100%;
  animation: ${fadeInUp} 0.8s ease-out 0.2s backwards;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const AboutCard = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 20px;
  padding: 2rem 1.5rem;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1.2rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
    
    ${IconWrapper} {
      animation: ${iconFloat} 2s ease-in-out infinite;
    }
  }
`;

export const CardTitle = styled.h3`
  font-size: 1.3rem;
  font-weight: 600;
  color: ${colors.white};
  margin: 0;
`;

export const DefaultContentParagraph = styled.p`
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  color: ${colors.text.light};
  margin: 0;
  flex: 1;

  & > strong {
    font-weight: 600;
    color: ${colors.primary};
  }
`;

export const CTAWrapper = styled.div`
  text-align: center;
  padding: 4rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 24px;
  margin-top: 2rem;
  animation: ${fadeInUp} 0.8s ease-out 0.4s backwards;
  backdrop-filter: blur(10px);
`;

export const CTAText = styled.h3`
  font-size: 1.8rem;
  color: ${colors.white};
  margin: 0 0 2rem 0;
  font-weight: 600;
  line-height: 1.4;
`;

export const CTAButton = styled.a`
  display: inline-block;
  padding: 1rem 2.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  color: ${colors.white};
  background: ${colors.primary};
  border-radius: 50px;
  text-decoration: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    background: ${colors.gradients.primary};
  }
`;

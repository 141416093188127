import React, { useState } from 'react';
import * as S from '../../styled';
import { FaGlobe, FaServer, FaShieldAlt, FaSearch, FaCogs, FaLock } from 'react-icons/fa';
import ServicePopup from '../../components/ServicePopup';

const DomainServices = () => {
  const [selectedService, setSelectedService] = useState(null);

  const services = [
    {
      id: 'registrar',
      icon: <FaGlobe />,
      title: 'Cloudflare Registrar',
      features: [
        {
          icon: <FaGlobe />,
          title: 'Registro Transparente',
          description: 'Registro de domínios com preços justos e sem taxas ocultas.'
        },
        {
          icon: <FaLock />,
          title: 'Proteção de Privacidade',
          description: 'Proteção WHOIS gratuita incluída em todos os domínios.'
        },
        {
          icon: <FaCogs />,
          title: 'Gerenciamento Simplificado',
          description: 'Interface intuitiva para gerenciar todos seus domínios.'
        }
      ]
    },
    {
      id: 'dns',
      icon: <FaServer />,
      title: 'DNS Gerenciado',
      features: [
        {
          icon: <FaServer />,
          title: 'Alta Disponibilidade',
          description: 'DNS distribuído globalmente para máxima disponibilidade.'
        },
        {
          icon: <FaSearch />,
          title: 'Resolução Rápida',
          description: 'Resolução DNS ultrarrápida em toda rede global.'
        },
        {
          icon: <FaCogs />,
          title: 'Gerenciamento Avançado',
          description: 'Controle total sobre registros e configurações DNS.'
        }
      ]
    },
    {
      id: 'dnssec',
      icon: <FaShieldAlt />,
      title: 'DNSSEC',
      features: [
        {
          icon: <FaShieldAlt />,
          title: 'Proteção contra Spoofing',
          description: 'Prevenção contra ataques de falsificação de DNS.'
        },
        {
          icon: <FaLock />,
          title: 'Autenticação de Registros',
          description: 'Validação criptográfica de registros DNS.'
        },
        {
          icon: <FaCogs />,
          title: 'Configuração Automática',
          description: 'Ativação e gerenciamento simplificado do DNSSEC.'
        }
      ]
    }
  ];

  return (
    <S.ServiceCard>
      <S.ServiceHeader>
        <S.ServiceIcon>
          <FaGlobe />
        </S.ServiceIcon>
        <S.ServiceTitle>Serviços de Domínio</S.ServiceTitle>
      </S.ServiceHeader>

      <S.SubServicesList>
        {services.map((service) => (
          <S.SubServiceItem
            key={service.id}
            onClick={() => setSelectedService(service)}
          >
            <S.SubServiceIcon>
              {service.icon}
            </S.SubServiceIcon>
            <S.SubServiceTitle>
              {service.title}
            </S.SubServiceTitle>
          </S.SubServiceItem>
        ))}
      </S.SubServicesList>

      {selectedService && (
        <ServicePopup
          isOpen={!!selectedService}
          onClose={() => setSelectedService(null)}
          title={selectedService.title}
          features={selectedService.features}
          ctaText="Gerenciar Domínios"
        />
      )}
    </S.ServiceCard>
  );
};

export default DomainServices; 
import React, { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { openConfirmationLightbox } from '@Store/ducks/global/actions';
import { useDispatch } from 'react-redux';
import { COMPUTER_STATES, REDIRECT_TO_LOGIN, messageDisconnect } from '@Constants';
import { sendAuthCode } from '@Services/noar-api';
import { SessionExpired } from '@Errors';
import { stopMessage } from '../messages';

import Stop from './Stop';

import * as S from './styled';

import ComputerContext from '../context';

export const ActionsView = ({ formAuthenticationStatus, formMessage, onChange, onSubmit, state }) => {
  return (
    <S.Actions className="computer__actions">
      {state === COMPUTER_STATES.RUNNING && (
        <S.Form onSubmit={onSubmit} {...{ formAuthenticationStatus }}>
          <S.OnActions className="program-page-button-wrapper">
            <Stop />
          </S.OnActions>
          <S.P>{formMessage}</S.P>
        </S.Form>
      )}
    </S.Actions>
  );
};

export function useActions() {
  const { authCode, formMessage, formAuthenticationStatus, setAuthCode, setFormAuthenticationStatus, setFormMessage } =
    useContext(ComputerContext);
  const { instance_id, state, isLoading } = useSelector(selector => selector.computer);
  const history = useHistory();
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    async function (event) {
      event.preventDefault();

      try {
        setFormAuthenticationStatus('sending');
        const responseBody = await sendAuthCode(instance_id, authCode);

        if (responseBody && responseBody.result) {
          setFormMessage(stopMessage.formAuthenticationCodeSuccess);
          setFormAuthenticationStatus('success');
        } else {
          setFormMessage('Um erro aconteceu ao enviar o código, tente novamente!');
          setFormAuthenticationStatus('error');
        }

        const timeoutId = setTimeout(function () {
          setFormMessage('');
          setFormAuthenticationStatus('');
          setAuthCode('');
        }, 5000);

        return function () {
          clearTimeout(timeoutId);
        };
      } catch (error) {
        if (error instanceof SessionExpired) {
          const turnOnMachine = false;
          
          const disconnect = true;
          dispatch(
            openConfirmationLightbox(
              () => {
                history.push(REDIRECT_TO_LOGIN);
              },
              messageDisconnect.message,
              messageDisconnect.confirmBtnText,
              messageDisconnect.refuseBtnText,
              turnOnMachine,
              disconnect,
              false,
            ),
          );
        }
      }
    },
    [authCode, history, instance_id, setAuthCode, setFormAuthenticationStatus, setFormMessage],
  );

  const onChange = useCallback(
    function ({ target }) {
      setAuthCode(target.value);
    },
    [setAuthCode],
  );

  return {
    formAuthenticationStatus,
    formMessage,
    isLoading,
    onChange,
    onSubmit,
    state,
  };
}

export function Actions(props) {
  return <ActionsView {...useActions(props)} />;
}

export default Actions;

import React from 'react';
import SEOHelmet from '../../components/SEO/SEOHelmet';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

/**
 * Página de Desktop as a Service (DaaS) otimizada para SEO
 * @returns {JSX.Element} Componente de página
 */
const DesktopAsServicePage = () => {
  return (
    <>
      <SEOHelmet pageName="desktop-as-a-service" />
      <DaasContainer>
        <Hero>
          <HeroContent>
            <h1>Desktop as a Service</h1>
            <h2>Acesse Seu Desktop de Alta Performance em Qualquer Dispositivo</h2>
            <p>
              Nossa solução de Desktop as a Service (DaaS) oferece acesso a computadores virtuais potentes na nuvem,
              eliminando a necessidade de investir em hardware, além de proporcionar flexibilidade total para trabalhar de qualquer lugar.
            </p>
            <HeroButton>Comece Agora</HeroButton>
          </HeroContent>
        </Hero>

        <Section>
          <SectionTitle>Por que escolher Desktop as a Service?</SectionTitle>
          <FeatureGrid>
            <FeatureCard>
              <h3>Economia</h3>
              <p>Reduza custos com hardware, manutenção e atualizações. Pague apenas pelo que usar.</p>
            </FeatureCard>
            <FeatureCard>
              <h3>Flexibilidade</h3>
              <p>Acesse seu desktop de qualquer dispositivo e de qualquer lugar com conexão à internet.</p>
            </FeatureCard>
            <FeatureCard>
              <h3>Escalabilidade</h3>
              <p>Aumente ou reduza recursos conforme suas necessidades com planos anuais.</p>
            </FeatureCard>
            <FeatureCard>
              <h3>Segurança</h3>
              <p>Seus dados permanecem seguros em nossos servidores, protegidos contra perda ou roubo.</p>
            </FeatureCard>
          </FeatureGrid>
        </Section>

        <Section dark>
          <SectionTitle>Nossas Soluções DaaS</SectionTitle>
          <SolutionsWrapper>
            <Solution>
              <SolutionIcon>💼</SolutionIcon>
              <h3>DaaS para Profissionais</h3>
              <p>
                Ideal para profissionais que precisam de um computador potente para trabalhar de qualquer lugar.
                Perfeito para designers, desenvolvedores e profissionais de mídia.
              </p>
              <ul>
                <li>Performance de ponta para aplicações exigentes</li>
                <li>Integração com ferramentas de produtividade</li>
                <li>Backup automático de dados</li>
              </ul>
            </Solution>
            <Solution>
              <SolutionIcon>🏢</SolutionIcon>
              <h3>DaaS para Empresas</h3>
              <p>
                Soluções corporativas para virtualização de desktops (VDI), com gerenciamento centralizado
                e configurações personalizadas para diferentes departamentos.
              </p>
              <ul>
                <li>Gerenciamento centralizado</li>
                <li>Integração com Active Directory</li>
                <li>Distribuição simplificada de software</li>
              </ul>
            </Solution>
            <Solution>
              <SolutionIcon>🎓</SolutionIcon>
              <h3>DaaS para Educação</h3>
              <p>
                Ambientes virtuais de aprendizado para instituições educacionais, proporcionando
                laboratórios virtuais e salas de aula remotas.
              </p>
              <ul>
                <li>Laboratórios virtuais</li>
                <li>Acesso a software especializado</li>
                <li>Ambiente seguro para estudantes</li>
              </ul>
            </Solution>
          </SolutionsWrapper>
        </Section>

        <Section>
          <SectionTitle>Planos de Desktop as a Service</SectionTitle>
          <PlanContainer>
            <PlanInfo>
              <h3>Planos a partir de</h3>
              <PlanPrice>R$ 199,90 <span>/mês</span></PlanPrice>
              <PlanFeatures>
                <li>Acesso de qualquer dispositivo</li>
                <li>Suporte prioritário</li>
                <li>Backup automático</li>
                <li>GPU de alta performance</li>
                {/* <li>Windows 10/11 Pro</li> */}
                <li>Personalização conforme sua necessidade</li>
              </PlanFeatures>
              <ContactInfo>
                <p>Para mais informações ou um orçamento personalizado:</p>
                <EmailLink href="mailto:comercial@noarcloud.com">comercial@noarcloud.com</EmailLink>
                <PlanButton>Começar agora</PlanButton>
              </ContactInfo>
            </PlanInfo>
          </PlanContainer>
        </Section>

        <Section dark>
          <SectionTitle>Tecnologias Utilizadas</SectionTitle>
          <TechGrid>
            <TechItem>
              <h3>Microsoft Azure</h3>
              <p>Infraestrutura robusta e escalável para VDI empresarial.</p>
            </TechItem>
            <TechItem>
              <h3>Servidores proprios</h3>
              <p>Solução com servidores e GPUs de alta performance.</p>
            </TechItem>
            <TechItem>
              <h3>Google Cloud e AWS</h3>
              <p>Performance e confiabilidade para desktops virtuais.</p>
            </TechItem>
            <TechItem>
              <h3>NOAR Pondo</h3>
              <p>Gerenciamento avançado de ambientes virtuais com hyper-v e outras ferramentas.</p>
            </TechItem>
          </TechGrid>
        </Section>

        <Section>
          <SectionTitle id="faq">Perguntas Frequentes sobre DaaS</SectionTitle>
          <FaqList>
            <FaqItem>
              <FaqQuestion>O que é Desktop as a Service (DaaS)?</FaqQuestion>
              <FaqAnswer>
                Desktop as a Service (DaaS) é um modelo de computação em nuvem onde o ambiente de desktop virtual é hospedado por um provedor de serviços em nuvem. O provedor é responsável pelo backend da infraestrutura de VDI (Virtual Desktop Infrastructure), incluindo armazenamento, backup, segurança e atualizações.
              </FaqAnswer>
            </FaqItem>
            <FaqItem>
              <FaqQuestion>Quais são as vantagens do DaaS em relação a computadores físicos?</FaqQuestion>
              <FaqAnswer>
                As principais vantagens incluem redução de custos com hardware, acesso a partir de qualquer dispositivo, maior segurança de dados, facilidade de implementação, escalabilidade sob demanda, e manutenção simplificada, já que o provedor gerencia a infraestrutura.
              </FaqAnswer>
            </FaqItem>
            <FaqItem>
              <FaqQuestion>É possível instalar softwares específicos no meu desktop virtual?</FaqQuestion>
              <FaqAnswer>
                Sim, você pode instalar aplicativos e softwares em seu desktop virtual assim como faria em um computador físico. Também oferecemos opções de imagens pré-configuradas com softwares populares já instalados.
              </FaqAnswer>
            </FaqItem>
          </FaqList>
        </Section>
      </DaasContainer>
    </>
  );
};

export default DesktopAsServicePage;

// Estilos
const DaasContainer = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
`;

const Hero = styled.div`
  background: linear-gradient(135deg, #2c3e50, #4ca1af);
  color: white;
  padding: 100px 20px;
  text-align: center;
`;

const HeroContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
  
  h1 {
    font-size: 3rem;
    margin-bottom: 20px;
    font-weight: 700;
  }
  
  h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    font-weight: 500;
  }
  
  p {
    font-size: 1.2rem;
    margin-bottom: 30px;
    line-height: 1.6;
  }
`;

const HeroButton = styled.button`
  background-color: #00a8ff;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 1.2rem;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: #0097e6;
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  }
`;

const Section = styled.section`
  padding: 80px 20px;
  background-color: ${props => props.dark ? '#1e293b' : 'white'};
  color: ${props => props.dark ? 'white' : '#333'};
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 50px;
  font-weight: 600;
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
`;

const FeatureCard = styled.div`
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.05);
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-10px);
  }
  
  h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #3498db;
    font-weight: 600;
  }
  
  p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
  }
`;

const SolutionsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
`;

const Solution = styled.div`
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  
  h3 {
    font-size: 1.5rem;
    margin: 15px 0;
    color: #3498db;
    font-weight: 600;
  }
  
  p {
    font-size: 1rem;
    margin-bottom: 20px;
    line-height: 1.6;
  }
  
  ul {
    padding-left: 20px;
    
    li {
      margin-bottom: 10px;
      position: relative;
      
      &:before {
        content: "✓";
        color: #3498db;
        font-weight: bold;
        position: absolute;
        left: -20px;
      }
    }
  }
`;

const SolutionIcon = styled.div`
  font-size: 3rem;
  margin-bottom: 15px;
`;

const PlanContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
`;

const PlanInfo = styled.div`
  text-align: center;
  
  h3 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    font-weight: 700;
    color: #333;
  }
`;

const PlanPrice = styled.div`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  
  span {
    font-size: 1rem;
    opacity: 0.8;
  }
`;

const PlanFeatures = styled.ul`
  margin: 30px 0;
  padding: 0;
  list-style: none;
  text-align: center;
  
  li {
    margin: 12px 0;
    font-size: 1.1rem;
    color: #444;
    
    &:before {
      content: "✓";
      margin-right: 8px;
      color: #00a8ff;
      font-weight: bold;
    }
  }
`;

const ContactInfo = styled.div`
  margin-top: 30px;
  
  p {
    font-size: 1.1rem;
    margin-bottom: 15px;
    color: #555;
  }
`;

const EmailLink = styled.a`
  display: block;
  color: #00a8ff;
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 25px;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

const PlanButton = styled.button`
  background-color: #00a8ff;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 1.2rem;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: #0097e6;
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  }
`;

const TechGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
`;

const TechItem = styled.div`
  text-align: center;
  padding: 20px;
  
  h3 {
    font-size: 1.3rem;
    margin-bottom: 10px;
    color: #3498db;
    font-weight: 600;
  }
  
  p {
    font-size: 1rem;
    line-height: 1.6;
  }
`;

const FaqList = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const FaqItem = styled.div`
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  padding-bottom: 20px;
`;

const FaqQuestion = styled.h3`
  font-size: 1.3rem;
  margin-bottom: 10px;
  font-weight: 600;
  color: #3498db;
`;

const FaqAnswer = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: #666;
`;

const CallToAction = styled(Link)`
  background-color: #00a8ff;
  color: white;
  border: none;
  padding: ${props => props.large ? '20px 40px' : '15px 30px'};
  font-size: ${props => props.large ? '1.4rem' : '1.2rem'};
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  
  &:hover {
    background-color: #0097e6;
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
    color: white;
  }
`;
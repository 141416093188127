import styled from 'styled-components';
import { desktopMinSize, mqmin, mqmax, rem } from '@Styles/mixins';
import { colors } from '../../constants/colors';

export const PageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(135deg, #17171A 0%, #1E1E24 100%);
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 1rem;
    width: calc(100% - 2rem);
    margin: 0 auto;
    align-items: center;
  }
`;

export const SettingsCard = styled.div`
  padding: 2rem;
  background: ${colors.gray[800]};
  border-radius: 24px;
  box-shadow: 
    0 10px 30px -10px rgba(0, 0, 0, 0.2),
    0 5px 15px -5px rgba(0, 0, 0, 0.1),
    0 2px 5px -2px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.05);
  position: relative;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
  width: 100%;
  margin-top: 2rem;

  @media (max-width: 768px) {
    padding: 1.5rem;
    border-radius: 20px;
  }
`;

export const SectionTitle = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
  margin-bottom: 1.5rem;
  position: relative;
  display: inline-block;
  
  &:after {
    content: '';
    position: absolute;
    bottom: -0.5rem;
    left: 0;
    width: 3rem;
    height: 0.25rem;
    background: linear-gradient(90deg, #2D80F2 0%, #8742E5 100%);
    border-radius: 1rem;
  }
`; 
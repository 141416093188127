import React from 'react';
import SEOHelmet from '../../components/SEO/SEOHelmet';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

/**
 * Página de Machine Learning otimizada para SEO
 * @returns {JSX.Element} Componente de página
 */
const MachineLearningPage = () => {
  return (
    <>
      <SEOHelmet pageName="machine-learning" />
      <MachineLearningContainer>
        <Hero>
          <HeroContent>
            <h1>Soluções de Machine Learning e IA para Empresas</h1>
            <h2>Infraestrutura de Alta Performance para Treinamento e Inferência de IA</h2>
            <p>
              Acelere seus projetos de inteligência artificial com nossa infraestrutura especializada.
              Servidores GPU de alto desempenho, ambientes otimizados e suporte técnico especializado
              para transformar dados em insights valiosos para seu negócio.
            </p>
            <HeroButton>Solicite uma Demonstração</HeroButton>
          </HeroContent>
        </Hero>

        <Section>
          <SectionTitle>Por que escolher a NOAR para Machine Learning?</SectionTitle>
          <FeatureGrid>
            <FeatureCard>
              <FeatureIcon>⚡</FeatureIcon>
              <h3>Infraestrutura Otimizada</h3>
              <p>Servidores com GPUs NVIDIA de última geração (A100, H100) e processadores de alto desempenho para treinamento acelerado de modelos.</p>
            </FeatureCard>
            <FeatureCard>
              <FeatureIcon>🔄</FeatureIcon>
              <h3>Escalabilidade Sob Demanda</h3>
              <p>Aumente ou reduza recursos conforme a necessidade do seu projeto, pagando apenas pelo que utilizar.</p>
            </FeatureCard>
            <FeatureCard>
              <FeatureIcon>🛡️</FeatureIcon>
              <h3>Segurança de Dados</h3>
              <p>Proteção avançada para seus dados sensíveis e propriedade intelectual com criptografia e isolamento de ambientes.</p>
            </FeatureCard>
            <FeatureCard>
              <FeatureIcon>💰</FeatureIcon>
              <h3>Redução de Custos</h3>
              <p>Elimine investimentos em hardware especializado e reduza custos operacionais com nossa infraestrutura em nuvem.</p>
            </FeatureCard>
          </FeatureGrid>
        </Section>

        <Section dark>
          <SectionTitle>Nossas Soluções para IA e Machine Learning</SectionTitle>
          <SolutionsWrapper>
            <Solution>
              <SolutionIcon>🧠</SolutionIcon>
              <h3>Treinamento de Modelos</h3>
              <p>
                Infraestrutura especializada para treinamento de modelos de machine learning e deep learning,
                com GPUs de alto desempenho e armazenamento otimizado.
              </p>
              <ul>
                <li>Clusters de GPUs NVIDIA A100/H100</li>
                <li>Frameworks pré-configurados (TensorFlow, PyTorch, etc.)</li>
                <li>Armazenamento de alta velocidade para datasets</li>
              </ul>
            </Solution>
            <Solution>
              <SolutionIcon>🚀</SolutionIcon>
              <h3>Inferência e Deployment</h3>
              <p>
                Soluções para implantação e escalonamento de modelos de IA em produção,
                com baixa latência e alta disponibilidade.
              </p>
              <ul>
                <li>Serviços de API para modelos treinados</li>
                <li>Balanceamento de carga automático</li>
                <li>Monitoramento de performance em tempo real</li>
              </ul>
            </Solution>
            <Solution>
              <SolutionIcon>🔍</SolutionIcon>
              <h3>Análise de Big Data</h3>
              <p>
                Processamento e análise de grandes volumes de dados com ferramentas
                especializadas e infraestrutura escalável.
              </p>
              <ul>
                <li>Spark, Hadoop e outras ferramentas de Big Data</li>
                <li>ETL e processamento de dados em larga escala</li>
                <li>Visualização e dashboards interativos</li>
              </ul>
            </Solution>
          </SolutionsWrapper>
        </Section>

        <Section>
          <SectionTitle>Casos de Uso</SectionTitle>
          <UseCasesGrid>
            <UseCase>
              <UseCaseIcon>📊</UseCaseIcon>
              <h3>Análise Preditiva</h3>
              <p>
                Preveja tendências de mercado, comportamento de clientes e otimize operações
                com modelos preditivos treinados em nossa infraestrutura.
              </p>
            </UseCase>
            <UseCase>
              <UseCaseIcon>🔤</UseCaseIcon>
              <h3>Processamento de Linguagem Natural</h3>
              <p>
                Desenvolva chatbots, análise de sentimento, resumo automático e outras
                aplicações de NLP com recursos computacionais otimizados.
              </p>
            </UseCase>
            <UseCase>
              <UseCaseIcon>👁️</UseCaseIcon>
              <h3>Visão Computacional</h3>
              <p>
                Crie soluções de reconhecimento de imagens, detecção de objetos e
                inspeção visual automatizada com GPUs de alto desempenho.
              </p>
            </UseCase>
            <UseCase>
              <UseCaseIcon>🔄</UseCaseIcon>
              <h3>Sistemas de Recomendação</h3>
              <p>
                Implemente sistemas de recomendação personalizados para e-commerce,
                streaming de conteúdo e outras aplicações.
              </p>
            </UseCase>
          </UseCasesGrid>
        </Section>

        <Section dark>
          <SectionTitle>Tecnologias Suportadas</SectionTitle>
          <TechGrid>
            <TechItem>
              <h3>TensorFlow</h3>
              <p>Ambiente otimizado para desenvolvimento e treinamento com TensorFlow.</p>
            </TechItem>
            <TechItem>
              <h3>PyTorch</h3>
              <p>Suporte completo para ecossistema PyTorch e bibliotecas relacionadas.</p>
            </TechItem>
            <TechItem>
              <h3>NVIDIA CUDA</h3>
              <p>Aceleração GPU com CUDA para processamento paralelo de alta performance.</p>
            </TechItem>
            <TechItem>
              <h3>Hugging Face</h3>
              <p>Integração com Hugging Face para modelos de linguagem e transformers.</p>
            </TechItem>
          </TechGrid>
        </Section>

        <Section>
          <SectionTitle>Planos para Empresas</SectionTitle>
          <PlanContainer>
            <PlanInfo>
              <h3>Soluções Personalizadas</h3>
              <PlanDescription>
                Oferecemos planos customizados de acordo com as necessidades específicas do seu projeto de IA.
                Entre em contato com nossa equipe comercial para uma proposta personalizada.
              </PlanDescription>
              <PlanFeatures>
                <li>Infraestrutura dedicada ou compartilhada</li>
                <li>Suporte técnico especializado</li>
                <li>Consultoria em arquitetura de IA</li>
                <li>GPUs de última geração</li>
                <li>Armazenamento otimizado para datasets</li>
              </PlanFeatures>
              <ContactInfo>
                <p>Para mais informações ou um orçamento personalizado:</p>
                <EmailLink href="mailto:comercial@noarcloud.com">comercial@noarcloud.com</EmailLink>
                <PlanButton>Solicitar Orçamento</PlanButton>
              </ContactInfo>
            </PlanInfo>
          </PlanContainer>
        </Section>

        <Section dark>
          <SectionTitle id="faq">Perguntas Frequentes</SectionTitle>
          <FaqList>
            <FaqItem>
              <FaqQuestion>Quais tipos de GPUs estão disponíveis para treinamento de modelos?</FaqQuestion>
              <FaqAnswer>
                Oferecemos uma variedade de GPUs NVIDIA, incluindo A100, V100, H100 e RTX série 3000/4000, 40 e 50 series.
                A escolha ideal depende do seu caso de uso específico, tamanho do modelo e orçamento.
                Nossa equipe técnica pode ajudar a selecionar a configuração mais adequada para seu projeto.
              </FaqAnswer>
            </FaqItem>
            <FaqItem>
              <FaqQuestion>Como funciona o modelo de precificação para serviços de IA?</FaqQuestion>
              <FaqAnswer>
                Trabalhamos com modelos flexíveis de precificação, incluindo pagamento por hora de uso,
                planos mensais com recursos dedicados e contratos personalizados para projetos de longo prazo.
                Os preços variam de acordo com o tipo de hardware, armazenamento e nível de suporte necessários.
              </FaqAnswer>
            </FaqItem>
            <FaqItem>
              <FaqQuestion>É possível migrar modelos existentes para a infraestrutura da NOAR?</FaqQuestion>
              <FaqAnswer>
                Sim, oferecemos suporte completo para migração de modelos e pipelines de IA existentes.
                Nossa equipe técnica pode ajudar a otimizar seus modelos para nossa infraestrutura,
                garantindo performance e compatibilidade.
              </FaqAnswer>
            </FaqItem>
            <FaqItem>
              <FaqQuestion>Quais medidas de segurança são implementadas para proteger dados sensíveis?</FaqQuestion>
              <FaqAnswer>
                Implementamos múltiplas camadas de segurança, incluindo criptografia de dados em repouso e em trânsito,
                isolamento de ambientes, controle de acesso baseado em papéis, monitoramento contínuo e conformidade
                com regulamentações como LGPD e GDPR.
              </FaqAnswer>
            </FaqItem>
          </FaqList>
        </Section>

        <Section>
          <SectionTitle>Transforme seu Negócio com IA</SectionTitle>
          <CtaSection>
            <p>
              Estamos prontos para ajudar sua empresa a implementar soluções de IA e Machine Learning
              que geram resultados reais. Entre em contato hoje mesmo para uma consulta personalizada.
            </p>
            <HeroButton>Fale com um Especialista</HeroButton>
          </CtaSection>
        </Section>
      </MachineLearningContainer>
    </>
  );
};

export default MachineLearningPage;

// Estilos
const MachineLearningContainer = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
`;

const Hero = styled.div`
  background: linear-gradient(135deg, #1a2a6c, #b21f1f, #fdbb2d);
  color: white;
  padding: 100px 20px;
  text-align: center;
`;

const HeroContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
  
  h1 {
    font-size: 3rem;
    margin-bottom: 20px;
    font-weight: 700;
  }
  
  h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    font-weight: 500;
  }
  
  p {
    font-size: 1.2rem;
    margin-bottom: 30px;
    line-height: 1.6;
  }
`;

const HeroButton = styled.button`
  background-color: #00a8ff;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 1.2rem;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: #0097e6;
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  }
`;

const Section = styled.section`
  padding: 80px 20px;
  background-color: ${props => props.dark ? '#0a1a2f' : 'white'};
  color: ${props => props.dark ? 'white' : '#333'};
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 50px;
  font-weight: 600;
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
`;

const FeatureCard = styled.div`
  background-color: ${props => props.dark ? 'rgba(255, 255, 255, 0.05)' : '#f8f9fa'};
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.05);
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-10px);
  }
  
  h3 {
    font-size: 1.5rem;
    margin: 15px 0;
    color: #00a8ff;
    font-weight: 600;
  }
  
  p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: ${props => props.dark ? 'rgba(255, 255, 255, 0.8)' : '#666'};
  }
`;

const FeatureIcon = styled.div`
  font-size: 3rem;
  margin-bottom: 15px;
`;

const SolutionsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
`;

const Solution = styled.div`
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  
  h3 {
    font-size: 1.5rem;
    margin: 15px 0;
    color: #00a8ff;
    font-weight: 600;
  }
  
  p {
    font-size: 1rem;
    margin-bottom: 20px;
    line-height: 1.6;
  }
  
  ul {
    padding-left: 20px;
    
    li {
      margin-bottom: 10px;
      position: relative;
      
      &:before {
        content: "✓";
        color: #00a8ff;
        font-weight: bold;
        position: absolute;
        left: -20px;
      }
    }
  }
`;

const SolutionIcon = styled.div`
  font-size: 3rem;
  margin-bottom: 15px;
`;

const UseCasesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
`;

const UseCase = styled.div`
  padding: 30px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.05);
  
  h3 {
    font-size: 1.5rem;
    margin: 15px 0;
    color: #00a8ff;
    font-weight: 600;
  }
  
  p {
    font-size: 1rem;
    line-height: 1.6;
    color: #666;
  }
`;

const UseCaseIcon = styled.div`
  font-size: 3rem;
  margin-bottom: 15px;
`;

const TechGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
`;

const TechItem = styled.div`
  text-align: center;
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  
  h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #00a8ff;
    font-weight: 600;
  }
  
  p {
    font-size: 1rem;
    line-height: 1.6;
  }
`;

const PlanContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
`;

const PlanInfo = styled.div`
  text-align: center;
  
  h3 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    font-weight: 700;
    color: #333;
  }
`;

const PlanDescription = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 30px;
  color: #555;
`;

const PlanFeatures = styled.ul`
  margin: 30px 0;
  padding: 0;
  list-style: none;
  text-align: center;
  
  li {
    margin: 12px 0;
    font-size: 1.1rem;
    color: #444;
    
    &:before {
      content: "✓";
      margin-right: 8px;
      color: #00a8ff;
      font-weight: bold;
    }
  }
`;

const ContactInfo = styled.div`
  margin-top: 30px;
  
  p {
    font-size: 1.1rem;
    margin-bottom: 15px;
    color: #555;
  }
`;

const EmailLink = styled.a`
  display: block;
  color: #00a8ff;
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 25px;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

const PlanButton = styled.button`
  background-color: #00a8ff;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 1.2rem;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: #0097e6;
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  }
`;

const FaqList = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const FaqItem = styled.div`
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(255,255,255,0.1);
  padding-bottom: 20px;
`;

const FaqQuestion = styled.h3`
  font-size: 1.3rem;
  margin-bottom: 10px;
  font-weight: 600;
  color: #00a8ff;
`;

const FaqAnswer = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  color: rgba(255,255,255,0.8);
`;

const CtaSection = styled.div`
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
  
  p {
    font-size: 1.3rem;
    margin-bottom: 30px;
    line-height: 1.6;
  }
`; 
import { useQuery } from '@tanstack/react-query';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SEOHelmet from '../../components/SEO/SEOHelmet';

import arrowUpImg from '@Assets/arrow-up.svg';
import footerLogoImg from '@Assets/blog-footer-logo.svg';
import instagramImg from '@Assets/instagram-icon.svg';
import linkedInImg from '@Assets/linkedin-icon.svg';
import loadingImg from '@Assets/logo-blue.gif';
import postCalendarIcon from '@Assets/post-calendar.svg';
import postIcon from '@Assets/post-title-icon.svg';
import twitterImg from '@Assets/twitter-icon.svg';
import youtubeImg from '@Assets/youtube-icon.svg';
import { getBlogPosts } from '@Services/noar-api';
import Header from './Header';

import {
  BlogContainer,
  ContentBanner,
  BlogCategories,
  EmphasisPostWrapper,
  EmphasisPostImage,
  EmphasisPostContent,
  EmphasisPostContentHeader,
  ReadMoreButton,
  RelatedPostWrapper,
  RelatedPostHeader,
  RelatedPostImage,
  RelatedPostContent,
  BlogShowMoreButton,
  BlogDivider,
  BlogFooterWrapper,
  BlogFooterLinks,
  LoadingImage,
} from './styled';

export default function Blog() {
  const [filteredBlog, setFilteredBlog] = useState([]);
  const [showMorePosts, setShowMorePosts] = useState(6);
  const [selectedCategory, setSelectedCategory] = useState('all');

  const { isLoading, data } = useQuery({
    queryKey: ['posts'],
    queryFn: () => getBlogPosts(),
  });

  function handleScrollToTop() {
    document.body.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  function handleShowMorePosts() {
    setShowMorePosts(prevState => prevState + 6);
  }

  function handleFilterPosts(e) {
    if (e.target.id === selectedCategory) {
      setSelectedCategory('all');
    } else {
      setSelectedCategory(e.target.id);
    }
    
    setFilteredBlog(currItems => {
      if (currItems.find(item => item === e.target.id) == null) {
        return [...currItems, e.target.id];
      } else {
        return currItems.filter(item => item !== e.target.id);
      }
    });
  }

  // Modificar o título da página para cada artigo de blog
  const getSeoTitle = (category) => {
    if (!category || category === 'all') {
      return 'Blog NOAR Cloud | Artigos sobre Cloud Gaming, DaaS e Computação em Nuvem';
    }
    
    const categoryLabels = {
      'cloud-gaming': 'Cloud Gaming e Jogos em Nuvem',
      'desktop-as-a-service': 'Desktop as a Service (DaaS) e VDI',
      'machine-learning': 'Machine Learning e Inteligência Artificial na Nuvem',
      'computador-na-nuvem': 'Computadores na Nuvem e Cloud Computing',
      'cloud-services': 'Serviços de Cloud Computing e Infraestrutura',
      'cloud-news': 'Notícias sobre Cloud Computing e Tecnologia'
    };
    
    return `Blog NOAR Cloud | Artigos sobre ${categoryLabels[category] || category}`;
  };
  
  const getSeoDescription = (category) => {
    if (!category || category === 'all') {
      return 'Artigos, tutoriais e novidades sobre cloud gaming, desktop as a service, computação em nuvem, machine learning, AWS, Azure e Google Cloud.';
    }
    
    const categoryDescriptions = {
      'cloud-gaming': 'Artigos e tutoriais sobre cloud gaming, jogos em nuvem, streaming de jogos, e as melhores soluções para jogar sem hardware potente.',
      'desktop-as-a-service': 'Guias completos sobre Desktop as a Service (DaaS), VDI, workspace virtual e soluções de desktop na nuvem para empresas e usuários.',
      'machine-learning': 'Artigos sobre machine learning, IA, computação para treinamento de modelos e infraestrutura em nuvem para inteligência artificial.',
      'computador-na-nuvem': 'Informações sobre computadores na nuvem, cloud pc, desktops virtuais e como acessar poder computacional de qualquer dispositivo.',
      'cloud-services': 'Análises e comparativos sobre serviços em nuvem como Azure, AWS, Google Cloud e Oracle para diferentes necessidades.',
      'cloud-news': 'Últimas notícias e atualizações sobre o mercado de cloud computing, novas tecnologias e inovações no setor.'
    };
    
    return categoryDescriptions[category] || `Artigos sobre ${category} e tecnologias relacionadas à computação em nuvem.`;
  };
  
  // Transformar slugs em palavras-chave 
  const getKeywordsFromCategory = (category) => {
    if (!category || category === 'all') {
      return 'cloud gaming, desktop as a service, computador na nuvem, jogos em nuvem, vdi, daas, machine learning, aws, azure, google cloud';
    }
    
    const categoryKeywords = {
      'cloud-gaming': 'cloud gaming, jogos em nuvem, streaming de jogos, cloudgame, gaming como serviço, jogos online, latência baixa',
      'desktop-as-a-service': 'desktop as a service, daas, vdi, desktop virtual, virtualização de desktops, acesso remoto, workspace virtual',
      'machine-learning': 'machine learning, treinamento de ia, gpu para ia, infraestrutura de ia, modelos de machine learning, redes neurais, deep learning',
      'computador-na-nuvem': 'computador na nuvem, cloud pc, desktop remoto, acesso remoto, computação em nuvem, poder computacional',
      'cloud-services': 'cloud services, serviços em nuvem, aws, azure, google cloud, oracle cloud, infraestrutura como serviço, iaas, paas, saas',
      'cloud-news': 'cloud computing, notícias de tecnologia, inovação tecnológica, mercado de nuvem, tendências cloud'
    };
    
    return categoryKeywords[category] || `${category}, cloud computing, tecnologia em nuvem`;
  };

  return (
    <>
      {/* SEO Helmet com configurações específicas para o Blog */}
      <SEOHelmet 
        pageName="blog"
        customParams={{
          title: getSeoTitle(selectedCategory),
          description: getSeoDescription(selectedCategory),
          keywords: getKeywordsFromCategory(selectedCategory),
          canonical: `https://www.noarcloud.com/blog${selectedCategory && selectedCategory !== 'all' ? `/${selectedCategory}` : ''}`,
          ogType: 'blog'
        }}
      />
      
      <Header />
      <BlogContainer>
        <ContentBanner>
          <h1>
            Blog <span>noar</span>
          </h1>
        </ContentBanner>

        {isLoading ? (
          <LoadingImage src={loadingImg} alt="" />
        ) : (
          <>
            <BlogCategories>
              {data?.categories?.map((postCategory, index) => (
                <label htmlFor={`${postCategory}`} key={`${postCategory}` + `1${index}`}>
                  <input
                    id={postCategory}
                    type="checkbox"
                    onChange={handleFilterPosts}
                    checked={filteredBlog.find(item => item === postCategory + '') == null ? false : true}
                  />
                  <span>{postCategory}</span>
                </label>
              ))}
            </BlogCategories>
            {data.posts.sort((a, b) => {
              a = new Date(a.published_at);
              b = new Date(b.published_at);

              return b - a;
            }) && (
                <EmphasisPostWrapper>
                  <Link NavLink to={`/blog/${data?.posts[0]?.id}`}>
                    <EmphasisPostImage src={data?.posts[0]?.post_image} alt="" />
                  </Link>

                  <EmphasisPostContent>
                    <EmphasisPostContentHeader>
                      <div>
                        <span>
                          <img src={postCalendarIcon} alt="" />
                          <strong>{new Date(data?.posts[0]?.published_at).toLocaleDateString('pt-BR')}</strong>
                        </span>
                        <span>
                          <img src={postIcon} alt="" />
                          {data?.posts[0]?.categories?.map(category => (
                            <strong key={category}>{category}</strong>
                          ))}
                        </span>
                      </div>
                      <Link NavLink to={`/blog/${data?.posts[0]?.id}`}>
                        <h2>{data?.posts[0]?.title}</h2>
                      </Link>
                    </EmphasisPostContentHeader>
                    <strong>Por: {data?.posts[0]?.post_author}</strong>
                    <article dangerouslySetInnerHTML={{ __html: data?.posts[0]?.description }}></article>
                    <Link to={`/blog/${data?.posts[0]?.id}`}>
                      <ReadMoreButton>leia mais</ReadMoreButton>
                    </Link>
                  </EmphasisPostContent>
                </EmphasisPostWrapper>
              )}

            <RelatedPostWrapper>
              {data?.posts
                ?.slice(1, showMorePosts)
                ?.sort((a, b) => {
                  a = new Date(a.published_at);
                  b = new Date(b.published_at);

                  return b - a;
                })
                ?.filter(post => {
                  if (filteredBlog.length <= 0) {
                    return post;
                  }

                  return filteredBlog.find(item => post.categories.includes(item));
                })

                .map((post, index) => (
                  <RelatedPostContent key={`${post.id}` + `1${index}`}>
                    <Link to={`/blog/${post.id}`}>
                      <RelatedPostImage src={post?.post_image} alt="" />
                    </Link>
                    <RelatedPostHeader>
                      <div>
                        <span>
                          <img src={postCalendarIcon} alt="" loading="lazy" />
                          <strong>{new Date(post?.published_at).toLocaleDateString('pt-BR')}</strong>
                        </span>
                        <span>
                          <img src={postIcon} alt="" />
                          {post?.categories?.map((category, index) => (
                            <strong key={category}>
                              {category}
                              {index === post?.categories.length - 1 ? '' : ','}
                            </strong>
                          ))}
                        </span>
                      </div>
                      <Link to={`/blog/${post.id}`}>
                        <strong>{post?.title}</strong>
                      </Link>
                    </RelatedPostHeader>
                    <strong>Por: {post?.post_author}</strong>
                    <article dangerouslySetInnerHTML={{ __html: post.description }}></article>
                    <Link to={`/blog/${post.id}`}>
                      <ReadMoreButton>leia mais</ReadMoreButton>
                    </Link>
                  </RelatedPostContent>
                ))}
            </RelatedPostWrapper>
          </>
        )}

        {data?.posts?.length > showMorePosts && (
          <BlogShowMoreButton onClick={handleShowMorePosts}>Mostrar mais &#43;</BlogShowMoreButton>
        )}

        <BlogDivider></BlogDivider>

        <BlogFooterWrapper>
          <img src={footerLogoImg} alt="" />

          <BlogFooterLinks>
            <a href="https://www.instagram.com/noarcloud/" target="_blank" rel="noreferrer">
              <img src={instagramImg} alt="Ícone do instagram que levará ao perfil da NOAR Cloud no Instagram" />
              Instagram
            </a>
            <a href="https://www.linkedin.com/company/noarcloud/" target="_blank" rel="noreferrer">
              <img src={linkedInImg} alt="Ícone do linkedIn que levará ao perfil da NOAR Cloud no LinkedIn" /> LinkedIn
            </a>
            <a href="https://www.youtube.com/@Noarcloud" target="_blank" rel="noreferrer">
              <img src={youtubeImg} alt="Ícone do youtube que levará ao canal da NOAR Cloud no Youtube" /> Youtube
            </a>
            <a href="https://twitter.com/noarcloud" target="_blank" rel="noreferrer">
              <img src={twitterImg} alt="Ícone do twitter que levará a página da NOAR Cloud no Twitter" /> Twitter
            </a>
          </BlogFooterLinks>
          <button type="button" onClick={handleScrollToTop}>
            <img src={arrowUpImg} alt="Ícone que leva de volta ao topo do site" />
          </button>
        </BlogFooterWrapper>
      </BlogContainer>
    </>
  );
};

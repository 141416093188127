import React, { useState } from 'react';
import styled from 'styled-components';
import { FaCode, FaLaptopCode, FaMobileAlt, FaPaintBrush, FaChartLine, FaUserCog } from 'react-icons/fa';

export default function DevelopmentSection() {
  const [hoveredSpec, setHoveredSpec] = useState(null);

  const specs = [
    {
      icon: <FaCode />,
      title: "Desenvolvimento Web",
      description: "Criação de sites, aplicações web e sistemas completos com as mais modernas tecnologias",
      performance: "React, Angular, Vue.js"
    },
    {
      icon: <FaMobileAlt />,
      title: "Desenvolvimento Mobile",
      description: "Apps nativos e híbridos para iOS e Android com foco em performance",
      performance: "React Native, Flutter"
    },
    {
      icon: <FaLaptopCode />,
      title: "Software Desktop",
      description: "Sistemas desktop robustos e eficientes para Windows, Linux e macOS",
      performance: "C++, Java, .NET"
    },
    {
      icon: <FaPaintBrush />,
      title: "Design e UX",
      description: "Design moderno e intuitivo com foco na experiência do usuário",
      performance: "UI/UX Design"
    },
    {
      icon: <FaChartLine />,
      title: "Consultoria",
      description: "Análise de requisitos, arquitetura de software e planejamento estratégico",
      performance: "Metodologias Ágeis"
    },
    {
      icon: <FaUserCog />,
      title: "Suporte Contínuo",
      description: "Manutenção, atualizações e suporte técnico especializado",
      performance: "24/7 disponível"
    }
  ];

  return (
    <StyledSection>
      <DevelopmentContainer>
        <DevelopmentTitle>Desenvolvimento de Software Completo</DevelopmentTitle>
        <DevelopmentSubtitle>
          Soluções completas de desenvolvimento de software, desde a concepção até a implementação e manutenção.
          Nossa equipe especializada trabalha com as mais recentes tecnologias para criar soluções inovadoras
          e eficientes para seu negócio.
        </DevelopmentSubtitle>

        <SpecsGrid>
          {specs.map((spec, index) => (
            <SpecCard
              key={index}
              onMouseEnter={() => setHoveredSpec(index)}
              onMouseLeave={() => setHoveredSpec(null)}
              isHovered={hoveredSpec === index}
            >
              <IconWrapper>{spec.icon}</IconWrapper>
              <SpecContent>
                <SpecTitle>{spec.title}</SpecTitle>
                <SpecDescription>{spec.description}</SpecDescription>
                <SpecPerformance>{spec.performance}</SpecPerformance>
              </SpecContent>
            </SpecCard>
          ))}
        </SpecsGrid>
      </DevelopmentContainer>
    </StyledSection>
  );
}

const StyledSection = styled.section`
  width: 100%;
  padding: 80px 0;
  background: rgba(23, 23, 26, 0.8);
  backdrop-filter: blur(5px);
`;

const DevelopmentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const DevelopmentTitle = styled.h2`
  font-size: 2.5rem;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 700;
`;

const DevelopmentSubtitle = styled.p`
  font-size: 1.1rem;
  color: #B4B7C0;
  text-align: center;
  max-width: 800px;
  margin: 0 auto 50px;
  line-height: 1.6;
`;

const SpecsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 40px;
`;

const SpecCard = styled.div`
  background: ${props => props.isHovered ? 'rgba(45, 128, 242, 0.1)' : 'rgba(45, 128, 242, 0.05)'};
  border-radius: 15px;
  padding: 25px;
  transition: all 0.3s ease;
  border: 1px solid ${props => props.isHovered ? '#2D80F2' : 'transparent'};
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
  }
`;

const IconWrapper = styled.div`
  font-size: 2.5rem;
  color: #2D80F2;
  margin-bottom: 20px;
`;

const SpecContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SpecTitle = styled.h3`
  font-size: 1.25rem;
  color: #fff;
  margin: 0;
`;

const SpecDescription = styled.p`
  font-size: 1rem;
  color: #B4B7C0;
  margin: 0;
  line-height: 1.5;
`;

const SpecPerformance = styled.span`
  font-size: 0.9rem;
  color: #2D80F2;
  margin-top: 5px;
`; 
import React, { useEffect } from 'react';
import { Select, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

const StyledSelect = styled(Select)(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#1E1E1E',
  width: '200px',
  '& .MuiSelect-select': {
    padding: '12px 16px',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '1.5',
    '&:focus': {
      backgroundColor: '#1E1E1E',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiSelect-icon': {
    color: '#fff',
  },
  '&:hover': {
    backgroundColor: '#1E1E1E',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  '&.Mui-focused': {
    backgroundColor: '#1E1E1E',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '14px',
  padding: '12px 16px',
  color: '#fff',
  backgroundColor: '#1E1E1E',
  lineHeight: '1.5',
  display: 'block',
  width: '100%',
  textAlign: 'left',
  '&:hover': {
    backgroundColor: '#007BFF',
    color: '#fff',
  },
  '&.Mui-selected': {
    backgroundColor: '#1E1E1E',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#007BFF',
    },
  },
}));

const LanguageSelector = () => {
  const { i18n, t } = useTranslation();

  const languages = [
    { code: 'pt-BR', name: 'Português' },
    { code: 'en-US', name: 'English' },
    { code: 'es', name: 'Español' },
    { code: 'fr', name: 'Français' },
    { code: 'de', name: 'Deutsch' },
    { code: 'it', name: 'Italiano' }
  ];

  useEffect(() => {
    const savedLanguage = localStorage.getItem('preferredLanguage');
    if (savedLanguage && i18n.language !== savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  const handleChange = async (event) => {
    const lang = event.target.value;
    try {
      await i18n.changeLanguage(lang);
      localStorage.setItem('preferredLanguage', lang);
      // Força a atualização da página para aplicar as mudanças
      window.location.reload();
    } catch (error) {
      console.error('Erro ao mudar idioma:', error);
    }
  };

  return (
    <StyledSelect
      value={i18n.language || 'pt-BR'}
      onChange={handleChange}
      variant="outlined"
      MenuProps={{
        PaperProps: {
          sx: {
            backgroundColor: '#1E1E1E',
            color: '#fff',
            width: '200px',
            boxShadow: 'none',
            '& .MuiList-root': {
              padding: '0',
            },
            '& .MuiMenuItem-root': {
              display: 'block',
              width: '100%',
            },
          },
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
    >
      {languages.map((lang) => (
        <StyledMenuItem key={lang.code} value={lang.code}>
          {lang.name}
        </StyledMenuItem>
      ))}
    </StyledSelect>
  );
};

export default LanguageSelector; 
import { Avatar } from '@material-ui/core';
import { useQuery } from '@tanstack/react-query';
import { formatDistanceToNow } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import React, { useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import SEOHelmet from '../../components/SEO/SEOHelmet';

import arrowUpImg from '@Assets/arrow-up.svg';
import footerLogoImg from '@Assets/blog-footer-logo.svg';
import facebookImg from '@Assets/facebook-icon.svg';
import instagramImg from '@Assets/instagram-icon.svg';
import linkedInImg from '@Assets/linkedin-icon.svg';
import loadingImg from '@Assets/logo-blue.gif';
import postCalendarIcon from '@Assets/post-calendar.svg';
import postIcon from '@Assets/post-title-icon.svg';
import twitterImg from '@Assets/twitter-icon.svg';
import youtubeImg from '@Assets/youtube-icon.svg';
import { getBlogPostData } from '@Services/noar-api';
import Header from './Header';

import {
  BlogSingleContentContainer,
  BlogSingleTopImageWrapper,
  BlogSingleContentWrapper,
  BlogSingleMainContentWrapper,
  BlogSingleMainHeader,
  BlogSingleMainSubHeader,
  BlogSingleMainContent,
  BlogSingleHorizontalDivider,
  BlogSingleAside,
  BlogDivider,
  BlogFooterWrapper,
  BlogFooterLinks,
  LoadingImage,
} from './styled';

const BlogSingle = () => {

  const { post_id } = useParams();
  const history = useHistory();

  function handleScrollToTop() {
    document.body.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  const { isLoading, data } = useQuery({
    queryKey: ['posts', post_id],
    queryFn: () => getBlogPostData(post_id),
  });

  useEffect(() => {
    document.body.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  function handleBackToMainPage() {
    history.push('/blog');
  }

  // Preparar dados de SEO para o artigo
  const getArticleData = (post) => {
    if (!post) return null;
    
    return {
      title: post.title,
      image: post.post_image,
      datePublished: post.published_at,
      dateModified: post.updated_at,
      author: post.post_author?.name || 'NOAR Cloud Team',
      description: post.excerpt || post.short_description,
      keywords: post.tags ? post.tags.map(tag => tag.name).join(', ') : post.categories?.map(category => category.name).join(', ')
    };
  };

  return (
    <>
      {/* SEO Helmet específico para artigos */}
      {data && (
        <SEOHelmet 
          pageName="blog"
          customParams={{
            title: data.title ? `${data.title} | Blog NOAR Cloud` : 'Artigo de Blog | NOAR Cloud',
            description: data.excerpt || data.short_description || 'Artigo de blog sobre cloud computing, jogos em nuvem e tecnologia cloud.',
            keywords: data.tags ? data.tags.map(tag => tag.name).join(', ') : `${data.categories?.map(category => category.name).join(', ')}, noar cloud, cloud computing`,
            canonical: `https://www.noarcloud.com/blog/${data.id}`,
            ogType: 'article',
            ogImage: data.post_image
          }}
          articleData={getArticleData(data)}
        />
      )}
      
      <Header />
      <BlogSingleContentContainer>
        {isLoading ? (
          <LoadingImage src={loadingImg} alt="" />
        ) : (
          <>
            <BlogSingleTopImageWrapper>
              <img src={data?.post_image} alt="" />
            </BlogSingleTopImageWrapper>

            <BlogSingleContentWrapper>
              <BlogSingleMainContentWrapper>
                <h1>{data?.title}</h1>
                <BlogSingleMainHeader>
                  <div>
                    <div>
                      {data?.post_author?.photo_url ? (
                        <img src={data?.post_author?.photo_url} alt="" />
                      ) : (
                        <Avatar src="/broken-image.jpg" />
                      )}
                    </div>
                    <strong>Por: {data?.post_author?.name}</strong>
                  </div>

                  <div>
                    <span>
                      <img src={postCalendarIcon} alt="" />
                      <time>{new Date(data?.published_at).toLocaleDateString('pt-BR')}</time>
                    </span>
                    <span>
                      <img src={postIcon} alt="" />
                      {data?.categories?.map(category => (
                        <strong key={category}>{category.name}</strong>
                      ))}
                    </span>
                  </div>
                </BlogSingleMainHeader>
                <BlogDivider></BlogDivider>

                <BlogSingleMainSubHeader>
                  <button type="button" onClick={handleBackToMainPage}>
                    &#60; Voltar para o blog
                  </button>
                  <div>
                    <strong>Compartilhe:</strong>
                    <a href="https://www.linkedin.com/company/noarcloud/" target="_blank" rel="noreferrer">
                      <img src={linkedInImg} alt="Ícone do linkedIn que levará ao perfil da NOAR Cloud no LinkedIn" />
                    </a>
                    <a href="https://www.facebook.com/noarcloud" target="_blank" rel="noreferrer">
                      <img src={facebookImg} alt="Ícone do instagram que levará ao perfil da NOAR Cloud no Facebook" />
                    </a>
                    <a
                      href="https://twitter.com/noarcloud?s=11&t=TmM8HuNqq20uqBh4nFiCsA"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={twitterImg} alt="Ícone do twitter que levará a página da NOAR Cloud no Twitter" />
                    </a>
                  </div>
                </BlogSingleMainSubHeader>

                <BlogSingleMainContent dangerouslySetInnerHTML={{ __html: data?.content }}></BlogSingleMainContent>
              </BlogSingleMainContentWrapper>
              <BlogSingleHorizontalDivider></BlogSingleHorizontalDivider>

              <BlogSingleAside>
                <strong>Mais notícias</strong>

                {data?.related?.map(relatedPost => (
                  <div key={relatedPost?.id}>
                    <Link to={`/blog/${relatedPost?.id}`}>
                      <img src={relatedPost?.post_image} alt="" />
                      <strong>{relatedPost?.description}</strong>

                      <time>
                        {formatDistanceToNow(new Date(relatedPost?.published_at), {
                          addSuffix: true,
                          locale: ptBR,
                        })}
                      </time>
                    </Link>
                  </div>
                ))}
              </BlogSingleAside>
            </BlogSingleContentWrapper>
          </>
        )}
        <BlogDivider></BlogDivider>

        <BlogFooterWrapper>
          <img src={footerLogoImg} alt="" />

          <BlogFooterLinks>
            <a href="https://instagram.com/noarcloud?igshid=ZDU1ZDhlY2E=" target="_blank" rel="noreferrer">
              <img src={instagramImg} alt="Ícone do instagram que levará ao perfil da NOAR Cloud no Instagram" />
              Instagram
            </a>
            <a href="https://www.linkedin.com/company/noarcloud/" target="_blank" rel="noreferrer">
              <img src={linkedInImg} alt="Ícone do linkedIn que levará ao perfil da NOAR Cloud no LinkedIn" /> LinkedIn
            </a>
            <a href="https://youtube.com/c/Noarcloud" target="_blank" rel="noreferrer">
              <img src={youtubeImg} alt="Ícone do youtube que levará ao canal da NOAR Cloud no Youtube" /> Youtube
            </a>
            <a href="https://twitter.com/noarcloud?s=11&t=TmM8HuNqq20uqBh4nFiCsA" target="_blank" rel="noreferrer">
              <img src={twitterImg} alt="Ícone do twitter que levará a página da NOAR Cloud no Twitter" /> Twitter
            </a>
          </BlogFooterLinks>
          <button type="button" onClick={handleScrollToTop}>
            <img src={arrowUpImg} alt="Ícone que leva de volta ao topo do site" />
          </button>
        </BlogFooterWrapper>
      </BlogSingleContentContainer>
    </>
  );
};

export default BlogSingle;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { desktopMinSize, mqmin } from '@Styles/mixins';
import { mq } from '../../../styles/mixins';
import { Title, Subtitle, Text } from '@Components/Public';
import { Button } from '@material-ui/core';
import CountUp from 'react-countup';
import image from './Title/image.png';

const Container = props => {
  const [startCounting, setStartCounting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setStartCounting(true);
        }
      },
      { threshold: 0.5 }
    );

    const features = document.querySelector('#features');
    if (features) {
      observer.observe(features);
    }

    return () => {
      if (features) {
        observer.unobserve(features);
      }
    };
  }, []);

  const scrollToDownload = () => {
    const downloadSection = document.querySelector('#download');
    downloadSection.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div {...props}>
      <IntroWrapper>
        <ContentSide>
          <MainTitle>
           Poder computacional ilimitado na  <strong>palma da sua mão</strong>
          </MainTitle>
          <SubTitle>
          Trabalhe, jogue, renderize e muito mais através de um PC de última geração!
          </SubTitle>
          <ActionButton variant="contained" onClick={scrollToDownload}>
            Começar agora
          </ActionButton>
        </ContentSide>
        <ImageSide>
          <DevicesImage src={image} alt="Dispositivos rodando Noar" />
        </ImageSide>
      </IntroWrapper>
      <Features id="features">
        <FeatureItem>
          <FeatureValue>
            {startCounting && (
              <CountUp
                start={0}
                end={50000}
                duration={2.5}
                separator=""
                prefix="+"
                useEasing={true}
              />
            )}
          </FeatureValue>
          <FeatureText>Usuários</FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureValue>
            {startCounting && (
              <CountUp
                start={0}
                end={99.9}
                duration={2.5}
                decimals={1}
                suffix="%"
                useEasing={true}
              />
            )}
          </FeatureValue>
          <FeatureText>Uptime garantido</FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureValue>
            {startCounting && (
              <CountUp
                start={0}
                end={24}
                duration={1.5}
                suffix="/7"
                useEasing={true}
              />
            )}
          </FeatureValue>
          <FeatureText>Suporte disponível</FeatureText>
        </FeatureItem>
      </Features>
    </div>
  );
};

export const Intro = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  overflow: hidden;
  padding: 1rem 0;

  @media (max-width: 320px) {
    padding: 0.5rem 0;
    justify-content: flex-start;
  }

  ${mqmin(desktopMinSize)} {
    padding: 2rem;
    justify-content: center;
  }
`;

const IntroWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  position: relative;
  z-index: 1;
  padding: 0.5rem;
  margin-top: 0;

  @media (max-width: 320px) {
    padding: 0.25rem;
  }

  ${mqmin(desktopMinSize)} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    padding: 2rem;
  }
`;

const ContentSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1.5rem;
  max-width: 100%;
  padding: 1rem 1.5rem;
  margin-top: 2rem;
  
  ${mqmin(desktopMinSize)} {
    align-items: center;
    text-align: center;
    width: 50%;
    margin-top: 0;
    padding: 1rem;
  }
`;

const ImageSide = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  padding: 0 1rem;
  
  ${mqmin(desktopMinSize)} {
    width: 50%;
    margin-top: 0;
    padding: 0;
  }
`;

const DevicesImage = styled.img`
  width: 100%;
  max-width: 320px;
  height: auto;
  object-fit: contain;

  ${mqmin(768)} {
    max-width: 400px;
  }

  ${mqmin(1080)} {
    max-width: 500px;
  }

  ${mqmin(1440)} {
    max-width: 600px;
  }

  ${mqmin(1920)} {
    max-width: 742px;
  }
`;

const MainTitle = styled(Title)`
  font-size: 1.75rem;
  line-height: 1.2;
  margin-bottom: 0;
  
  ${mqmin(768)} {
    font-size: 2.25rem;
  }
  
  ${mqmin(desktopMinSize)} {
    font-size: 2.5rem;
  }
`;

const SubTitle = styled(Subtitle)`
  font-size: 1rem;
  color: #B4B4B4;
  max-width: 100%;
  margin: 0;
  padding: 0 1rem;
  text-align: center;
  
  ${mqmin(768)} {
    font-size: 1.125rem;
  }
  
  ${mqmin(desktopMinSize)} {
    font-size: 1rem;
    padding: 0;
  }
`;

const ActionButton = styled(Button)`
  && {
    background: #007AFF;
    color: #fff;
    padding: 0.75rem 1.5rem;
    font-size: 1.125rem;
    text-transform: none;
    border-radius: 0.5rem;
    transition: background 0.3s ease;
    margin-top: 0.5rem;

    &:hover {
      background: #0056B3;
    }
  }
`;

const Features = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
  width: 100%;
  position: relative;
  z-index: 1;
  margin-top: 1rem;
  padding: 0 4rem;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    text-align: center;
    gap: 0.25rem;
  }

  ${mqmin(768)} {
    gap: 1.5rem;
    padding: 0 2rem;
    margin-top: 2rem;
  }

  ${mqmin(desktopMinSize)} {
    padding: 0;
  }
`;

const FeatureItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  padding: 0.5rem;
  
  @media (max-width: 480px) {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    padding: 0.5rem;
  }

  ${mqmin(768)} {
    padding: 1rem;
  }
`;

const FeatureValue = styled.span`
  color: #007AFF;
  font-size: 1.25rem;
  font-weight: bold;
  
  @media (max-width: 320px) {
    font-size: 1.125rem;
  }
  
  ${mqmin(768)} {
    font-size: 1.75rem;
  }
  
  ${mqmin(desktopMinSize)} {
    font-size: 2.25rem;
  }
`;

const FeatureText = styled(Text)`
  color: #B4B4B4;
  font-size: 0.75rem;
  
  @media (max-width: 320px) {
    font-size: 0.675rem;
  }
  
  ${mqmin(desktopMinSize)} {
    font-size: 1rem;
  }
`;

export default Intro;

import React, { useState } from 'react';
import * as S from '../../styled';
import { FaCloud, FaShieldAlt, FaRobot } from 'react-icons/fa';
import ServicePopup from '../../components/ServicePopup';

const CloudflareServices = () => {
  const [selectedService, setSelectedService] = useState(null);

  const services = [
    {
      id: 'cdn',
      icon: <FaCloud />,
      title: 'Rede de Entrega de Conteúdo (CDN)',
      features: [
        {
          icon: <FaCloud />,
          title: 'Distribuição Global',
          description: 'Entrega de conteúdo otimizada globalmente com baixa latência.'
        },
        {
          icon: <FaCloud />,
          title: 'Cache Inteligente',
          description: 'Sistema avançado de cache para melhor performance.'
        },
        {
          icon: <FaCloud />,
          title: 'Otimização Automática',
          description: 'Otimização automática de recursos para carregamento mais rápido.'
        }
      ]
    },
    {
      id: 'ddos',
      icon: <FaShieldAlt />,
      title: 'Proteção DDoS',
      features: [
        {
          icon: <FaShieldAlt />,
          title: 'Mitigação Automática',
          description: 'Proteção automática contra ataques DDoS de qualquer escala.'
        },
        {
          icon: <FaShieldAlt />,
          title: 'Análise em Tempo Real',
          description: 'Monitoramento e análise contínua de tráfego suspeito.'
        },
        {
          icon: <FaShieldAlt />,
          title: 'Proteção de Camada 7',
          description: 'Proteção específica para ataques na camada de aplicação.'
        }
      ]
    },
    {
      id: 'bots',
      icon: <FaRobot />,
      title: 'Gerenciamento de Bots',
      features: [
        {
          icon: <FaRobot />,
          title: 'Detecção de Bots',
          description: 'Identificação precisa de bots maliciosos e benignos.'
        },
        {
          icon: <FaRobot />,
          title: 'Proteção contra Scraping',
          description: 'Prevenção contra coleta não autorizada de dados.'
        },
        {
          icon: <FaRobot />,
          title: 'Machine Learning',
          description: 'Sistema inteligente de detecção baseado em IA.'
        }
      ]
    }
  ];

  return (
    <S.ServiceCard>
      <S.ServiceHeader>
        <S.ServiceIcon>
          <FaCloud />
        </S.ServiceIcon>
        <S.ServiceTitle>Implantação Cloudflare</S.ServiceTitle>
      </S.ServiceHeader>

      <S.SubServicesList>
        {services.map((service) => (
          <S.SubServiceItem
            key={service.id}
            onClick={() => setSelectedService(service)}
          >
            <S.SubServiceIcon>
              {service.icon}
            </S.SubServiceIcon>
            <S.SubServiceTitle>
              {service.title}
            </S.SubServiceTitle>
          </S.SubServiceItem>
        ))}
      </S.SubServicesList>

      {selectedService && (
        <ServicePopup
          isOpen={!!selectedService}
          onClose={() => setSelectedService(null)}
          title={selectedService.title}
          features={selectedService.features}
          ctaText="Solicitar Demonstração"
        />
      )}
    </S.ServiceCard>
  );
};

export default CloudflareServices; 
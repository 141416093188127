import React from 'react';
import { Helmet } from 'react-helmet';
import { getSeoConfig } from '../../utils/seo';

/**
 * Componente para gerenciar meta tags de SEO
 * @param {Object} props - Propriedades do componente
 * @param {string} props.pageName - Nome da página atual (corresponde à chave em seo.js)
 * @param {Object} props.customParams - Parâmetros personalizados para sobrescrever configurações padrão
 * @param {Object} props.articleData - Dados do artigo para páginas de blog
 * @returns {JSX.Element} - Componente React Helmet com meta tags
 */
const SEOHelmet = ({ pageName = 'home', customParams = {}, articleData = null }) => {
  const seoConfig = getSeoConfig(pageName, customParams);
  const {
    title,
    description,
    keywords,
    canonical,
    ogTitle = title,
    ogDescription = description,
    ogImage,
    ogType = 'website',
  } = seoConfig;

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: title,
    description,
    url: canonical,
  };

  // Dados estruturados específicos para produtos/serviços
  const serviceStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'Service',
    name: title.includes('|') ? title.split('|')[0].trim() : title,
    provider: {
      '@type': 'Organization',
      name: 'NOAR Cloud',
      url: 'https://www.noarcloud.com'
    },
    description,
    url: canonical,
  };

  // Dados estruturados para artigos de blog
  const articleStructuredData = articleData ? {
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    headline: articleData.title,
    image: articleData.image,
    datePublished: articleData.datePublished,
    dateModified: articleData.dateModified || articleData.datePublished,
    author: {
      '@type': 'Person',
      name: articleData.author,
    },
    publisher: {
      '@type': 'Organization',
      name: 'NOAR Cloud',
      logo: {
        '@type': 'ImageObject',
        url: 'https://www.noarcloud.com/favicon.png'
      }
    },
    description: articleData.description || description,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': canonical
    },
    keywords: articleData.keywords || keywords
  } : null;

  // Dados estruturados para FAQ
  const faqStructuredData = pageName === 'faq' ? {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'O que é o NOAR Cloud?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'NOAR Cloud é um serviço de computação em nuvem que oferece cloud gaming, desktop as a service (DaaS) e computadores virtuais de alto desempenho acessíveis de qualquer dispositivo.'
        }
      },
      {
        '@type': 'Question',
        name: 'Como funciona o Cloud Gaming?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Cloud Gaming permite jogar títulos de alta performance em qualquer dispositivo, com o processamento acontecendo em nossos servidores e sendo transmitido para seu dispositivo via streaming, eliminando a necessidade de hardware potente.'
        }
      },
      {
        '@type': 'Question',
        name: 'O que é Desktop as a Service (DaaS)?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Desktop as a Service (DaaS) é um serviço de virtualização que fornece desktops completos hospedados na nuvem, permitindo acesso de qualquer lugar e dispositivo a um computador de alta performance.'
        }
      },
      {
        '@type': 'Question',
        name: 'Quais tecnologias de cloud são utilizadas?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Utilizamos diversas tecnologias cloud, incluindo Azure, AWS, Google Cloud e Oracle Cloud, para fornecer a melhor infraestrutura para cada tipo de aplicação.'
        }
      },
      {
        '@type': 'Question',
        name: 'Posso usar a NOAR Cloud para machine learning e treinamento de IA?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Sim, oferecemos soluções específicas para machine learning e treinamento de modelos de IA com acesso a GPUs de alta performance na nuvem.'
        }
      }
    ]
  } : null;

  // Palavras-chave específicas por página
  const keywordsMap = {
    'cloud-gaming': [
      'cloud gaming', 'jogos em nuvem', 'cloudgame', 'streaming de jogos', 
      'jogos online', 'jogos sem hardware', 'gaming como serviço'
    ],
    'desktop-as-a-service': [
      'desktop as a service', 'daas', 'desktop virtual', 'computador na nuvem', 
      'vdi', 'virtualização de desktop', 'desktop remoto'
    ],
    'computador-na-nuvem': [
      'computador na nuvem', 'cloud pc', 'pc virtual', 'computação em nuvem',
      'acesso remoto pc', 'nuvem computacional', 'desktop remoto'
    ],
    'machine-learning': [
      'machine learning cloud', 'treinamento de IA', 'inteligência artificial',
      'computação para ML', 'cloud ML', 'infraestrutura para IA', 'GPU cloud'
    ],
    'for-business': [
      'cloud empresarial', 'soluções em nuvem', 'azure', 'aws', 'google cloud',
      'vdi empresarial', 'infraestrutura cloud', 'nuvem para empresas'
    ]
  };

  // Adicionar keywords específicas adicionais baseado na página
  const additionalKeywords = keywordsMap[pageName] || [];
  const allKeywords = keywords + (additionalKeywords.length > 0 ? ', ' + additionalKeywords.join(', ') : '');

  return (
    <Helmet>
      {/* Meta tags básicas */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={allKeywords} />
      <link rel="canonical" href={canonical} />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content={ogType} />
      <meta property="og:url" content={canonical} />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={ogDescription} />
      <meta property="og:image" content={ogImage} />
      
      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={canonical} />
      <meta property="twitter:title" content={ogTitle} />
      <meta property="twitter:description" content={ogDescription} />
      <meta property="twitter:image" content={ogImage} />

      {/* Dados estruturados JSON-LD */}
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
      
      {/* Dados estruturados para serviços quando aplicável */}
      {['cloud-gaming', 'desktop-as-a-service', 'computador-na-nuvem', 'machine-learning'].includes(pageName) && (
        <script type="application/ld+json">
          {JSON.stringify(serviceStructuredData)}
        </script>
      )}

      {/* Dados estruturados para artigos de blog */}
      {articleData && (
        <script type="application/ld+json">
          {JSON.stringify(articleStructuredData)}
        </script>
      )}

      {/* Dados estruturados para FAQ */}
      {pageName === 'faq' && (
        <script type="application/ld+json">
          {JSON.stringify(faqStructuredData)}
        </script>
      )}
      
      {/* Meta tags adicionais para indexação e descoberta */}
      <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
      <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
      <meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
      
      {/* Hreflang para diferentes idiomas (se necessário) */}
      <link rel="alternate" hreflang="pt-br" href={canonical} />
      <link rel="alternate" hreflang="x-default" href={canonical} />
    </Helmet>
  );
};

export default SEOHelmet; 
import React from 'react';
import SEOHelmet from '../../components/SEO/SEOHelmet';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

/**
 * Página de Cloud Gaming otimizada para SEO
 * @returns {JSX.Element} Componente de página
 */
const CloudGamingPage = () => {
  return (
    <>
      <SEOHelmet pageName="cloud-gaming" />
      <CloudGamingContainer>
        <Hero>
          <HeroContent>
            <h1>Cloud Gaming</h1>
            <h2>Jogue Sem Limites, de Qualquer Dispositivo</h2>
            <p>
              Acesse os melhores jogos AAA sem precisar de hardware potente. Nossa tecnologia de cloud gaming
              permite que você jogue títulos de última geração em qualquer dispositivo com conexão à internet.
            </p>
            <CallToAction to="/register">Experimente Grátis</CallToAction>
          </HeroContent>
        </Hero>

        <Section>
          <SectionTitle>Como Funciona o Cloud Gaming</SectionTitle>
          <FeatureGrid>
            <FeatureCard>
              <h3>1. Escolha seu Jogo</h3>
              <p>Selecione entre centenas de títulos disponíveis em nossa biblioteca.</p>
            </FeatureCard>
            <FeatureCard>
              <h3>2. Conecte-se</h3>
              <p>Inicie o jogo instantaneamente sem downloads ou instalações.</p>
            </FeatureCard>
            <FeatureCard>
              <h3>3. Jogue em Qualquer Lugar</h3>
              <p>Acesse de qualquer dispositivo: PC, notebook, tablet ou smartphone.</p>
            </FeatureCard>
          </FeatureGrid>
        </Section>

        <Section dark>
          <SectionTitle>Vantagens do Cloud Gaming NOAR</SectionTitle>
          <BenefitsList>
            <BenefitItem>
              <h3>Sem Investimento em Hardware</h3>
              <p>Economize dinheiro jogando títulos AAA sem precisar de placas de vídeo caras.</p>
            </BenefitItem>
            <BenefitItem>
              <h3>Baixa Latência</h3>
              <p>Nossa tecnologia de streaming oferece resposta imediata para uma experiência fluida.</p>
            </BenefitItem>
            <BenefitItem>
              <h3>Acesso Multiplataforma</h3>
              <p>Jogue em PCs ou Smatphones com a mesma qualidade de um PC Gamer.</p>
            </BenefitItem>
            <BenefitItem>
              <h3>Biblioteca Crescente</h3>
              <p>Novos jogos são adicionados regularmente, incluindo os lançamentos mais recentes.</p>
            </BenefitItem>
          </BenefitsList>
        </Section>
{/* 
        <Section>
          <SectionTitle>Planos de Cloud Gaming</SectionTitle>
          <PlanCards>
            <PlanCard>
              <h3>Básico</h3>
              <PlanPrice>R$ 29,90 <span>/mês</span></PlanPrice>
              <PlanFeatures>
                <li>Resolução 1080p</li>
                <li>60 FPS</li>
                <li>Acesso a 100+ jogos</li>
                <li>20 horas mensais</li>
              </PlanFeatures>
              <PlanButton>Assinar Agora</PlanButton>
            </PlanCard>
            <PlanCard highlight>
              <h3>Premium</h3>
              <PlanPrice>R$ 49,90 <span>/mês</span></PlanPrice>
              <PlanFeatures>
                <li>Resolução 4K</li>
                <li>120 FPS</li>
                <li>Acesso a 200+ jogos</li>
                <li>Horas ilimitadas</li>
                <li>Prioridade nos servidores</li>
              </PlanFeatures>
              <PlanButton>Assinar Agora</PlanButton>
            </PlanCard>
            <PlanCard>
              <h3>Familiar</h3>
              <PlanPrice>R$ 79,90 <span>/mês</span></PlanPrice>
              <PlanFeatures>
                <li>Tudo do plano Premium</li>
                <li>Até 5 contas</li>
                <li>Controle parental</li>
                <li>Jogos exclusivos</li>
              </PlanFeatures>
              <PlanButton>Assinar Agora</PlanButton>
            </PlanCard>
          </PlanCards>
        </Section> */}

        <Section dark>
          <SectionTitle id="faq">Perguntas Frequentes</SectionTitle>
          <FaqList>
            <FaqItem>
              <FaqQuestion>Qual conexão de internet é necessária para o Cloud Gaming?</FaqQuestion>
              <FaqAnswer>
                Recomendamos uma conexão de no mínimo 10 Mbps para streaming em 1080p.
                Para aproveitar a resolução 4K, é ideal ter uma conexão de 35 Mbps ou superior.
              </FaqAnswer>
            </FaqItem>
            <FaqItem>
              <FaqQuestion>Posso jogar com meus controles?</FaqQuestion>
              <FaqAnswer>
                Sim! Nossa plataforma é compatível com a maioria dos controles USB e Bluetooth,
                incluindo Xbox, PlayStation, e diversos gamepads genéricos.
              </FaqAnswer>
            </FaqItem>
            <FaqItem>
              <FaqQuestion>Quanto tempo leva para começar a jogar?</FaqQuestion>
              <FaqAnswer>
                Após assinar um plano, você pode começar a jogar imediatamente.
                Basta baixar nosso aplicativo e começar a usar!
              </FaqAnswer>
            </FaqItem>
          </FaqList>
        </Section>
      </CloudGamingContainer>
    </>
  );
};

export default CloudGamingPage;

// Estilos
const CloudGamingContainer = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
`;

const Hero = styled.div`
  background: linear-gradient(135deg, #1a2a6c, #b21f1f, #fdbb2d);
  color: white;
  padding: 100px 20px;
  text-align: center;
`;

const HeroContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
  
  h1 {
    font-size: 3rem;
    margin-bottom: 20px;
    font-weight: 700;
  }
  
  h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    font-weight: 500;
  }
  
  p {
    font-size: 1.2rem;
    margin-bottom: 30px;
    line-height: 1.6;
  }
`;

const CallToAction = styled(Link)`
  background-color: #00a8ff;
  color: white;
  border: none;
  padding: ${props => props.large ? '20px 40px' : '15px 30px'};
  font-size: ${props => props.large ? '1.4rem' : '1.2rem'};
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  
  &:hover {
    background-color: #0097e6;
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
    color: white;
  }
`;

const Section = styled.section`
  padding: 80px 20px;
  background-color: ${props => props.dark ? '#121212' : 'white'};
  color: ${props => props.dark ? 'white' : '#333'};
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 50px;
  font-weight: 600;
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
`;

const FeatureCard = styled.div`
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 40px 30px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.05);
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-10px);
  }
  
  h3 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #333;
    font-weight: 600;
  }
  
  p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
  }
`;

const BenefitsList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
`;

const BenefitItem = styled.div`
  h3 {
    font-size: 1.4rem;
    margin-bottom: 15px;
    font-weight: 600;
    color: #ff4500;
  }
  
  p {
    font-size: 1rem;
    line-height: 1.6;
  }
`;

const PlanCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
`;

const PlanCard = styled.div`
  background-color: ${props => props.highlight ? '#ff4500' : 'white'};
  color: ${props => props.highlight ? 'white' : '#333'};
  border-radius: 10px;
  padding: 40px 30px;
  box-shadow: 0 5px 20px rgba(0,0,0,0.1);
  width: 300px;
  text-align: center;
  position: relative;
  transition: all 0.3s ease;
  
  ${props => props.highlight && `
    transform: scale(1.05);
  `}
  
  &:hover {
    transform: translateY(-10px);
  }
  
  h3 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    font-weight: 700;
  }
`;

const PlanPrice = styled.div`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  
  span {
    font-size: 1rem;
    opacity: 0.8;
  }
`;

const PlanFeatures = styled.ul`
  margin: 30px 0;
  padding: 0;
  list-style: none;
  
  li {
    margin: 10px 0;
    font-size: 1rem;
    
    &:before {
      content: "✓";
      margin-right: 8px;
      color: ${props => props.highlight ? 'white' : '#ff4500'};
      font-weight: bold;
    }
  }
`;

const PlanButton = styled.button`
  background-color: transparent;
  color: inherit;
  border: 2px solid currentColor;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: ${props => props.highlight ? 'white' : '#ff4500'};
    color: ${props => props.highlight ? '#ff4500' : 'white'};
  }
`;

const FaqList = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const FaqItem = styled.div`
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(255,255,255,0.1);
  padding-bottom: 20px;
`;

const FaqQuestion = styled.h3`
  font-size: 1.3rem;
  margin-bottom: 10px;
  font-weight: 600;
  color: #ff4500;
`;

const FaqAnswer = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: #ccc;
`; 
/**
 * Serviço simplificado para integração com Mercado Pago JS SDK v2
 * Implementação seguindo a documentação oficial do Mercado Pago.
 * https://www.mercadopago.com.br/developers/pt/docs/checkout-api/payment-methods/receiving-payment-by-card
 */
class MercadoPagoService {
  constructor() {
    this.mercadopago = null;
    this.initialized = false;
    this.publicKey = null;
    this.fieldInstances = {}; // Armazenar instâncias dos campos para referência
    this.fieldsMountAttempts = 0; // Contador de tentativas de montagem
  }

  /**
   * Carrega o script do Mercado Pago e inicializa o SDK
   * @param {string} publicKey - Chave pública do Mercado Pago
   * @returns {Promise<void>}
   */
  async initialize(publicKey) {
    // Se já inicializado, retorna imediatamente
    if (this.initialized) return Promise.resolve();
    
    this.publicKey = publicKey;
    
    try {
      // Tenta usar o MercadoPago já carregado primeiro
      if (window.MercadoPago) {
        this.mercadopago = new window.MercadoPago(publicKey, {
          locale: 'pt-BR'
        });
        this.initialized = true;
        console.log('Mercado Pago SDK inicializado com SDK já carregado');
        return Promise.resolve();
      }
      
      // Carrega o script se não estiver disponível
      await this.loadScript();
      
      // Inicializa o SDK com a chave pública
      this.mercadopago = new window.MercadoPago(publicKey, {
        locale: 'pt-BR'
      });
      
      this.initialized = true;
      console.log('Mercado Pago SDK inicializado com sucesso');
      return Promise.resolve();
    } catch (error) {
      console.error('Erro ao inicializar Mercado Pago SDK:', error);
      throw error;
    }
  }

  /**
   * Verifica se o SDK foi inicializado
   * @returns {boolean}
   */
  isInitialized() {
    return this.initialized;
  }

  /**
   * Carrega o script do MercadoPago.js v2
   * @returns {Promise<void>}
   */
  loadScript() {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = 'https://sdk.mercadopago.com/js/v2';
      script.type = 'text/javascript';
      script.async = true;
      
      script.onload = () => {
        resolve();
      };
      
      script.onerror = () => {
        reject(new Error('Falha ao carregar o script do Mercado Pago'));
      };
      
      document.body.appendChild(script);
    });
  }

  /**
   * Cria uma instância do CardForm do Mercado Pago seguindo a documentação oficial
   * @param {Object} config - Configuração do CardForm conforme documentação
   * @returns {Object} Instância do CardForm
   */
  createCardForm(config) {
    if (!this.initialized) {
      throw new Error('Mercado Pago SDK não foi inicializado. Chame initialize() primeiro.');
    }
    
    // Garantir que o campo de parcelas esteja presente na configuração
    if (!config.form || !config.form.installments) {
      console.warn('Configuração do CardForm sem o campo installments. Este campo é obrigatório.');
      
      if (!config.form) {
        config.form = {};
      }
      
      config.form.installments = {
        id: "form-checkout__installments",
        placeholder: "Parcelas"
      };
    }
    
    // Configurar callback para parcelas fixas em 1x
    if (!config.callbacks || typeof config.callbacks.onInstallmentsReceived !== 'function') {
      if (!config.callbacks) {
        config.callbacks = {};
      }
      
      // Função para forçar parcelas em 1x
      config.callbacks.onInstallmentsReceived = (availableInstallments) => {
        console.log('Parcelas disponíveis (fixando em 1x):', availableInstallments);
        
        setTimeout(() => {
          const installmentsSelect = document.querySelector('#form-checkout__installments');
          if (installmentsSelect) {
            // Limpar opções existentes
            while (installmentsSelect.options.length > 0) {
              installmentsSelect.remove(0);
            }
            
            // Adicionar apenas a opção de 1x
            const option = document.createElement('option');
            option.value = "1";
            option.text = "1x sem juros";
            option.selected = true;
            installmentsSelect.appendChild(option);
            
            console.log("Parcelas fixadas em 1x pelo serviço");
          }
        }, 100);
        
        // Retornar sempre 1 parcela
        return { installments: 1 };
      };
    }
    
    return this.mercadopago.cardForm(config);
  }
  
  /**
   * Método alternativo para criar token do cartão diretamente, sem depender dos fields
   * @param {Object} cardData - Dados completos do cartão
   * @returns {Promise<Object>} Token do cartão
   */
  async createCardTokenDirect(cardData) {
    if (!this.initialized) {
      console.error('[MP-DIRECT] SDK não inicializado');
      throw new Error('Mercado Pago SDK não foi inicializado');
    }
    
    try {
      console.log('[MP-DIRECT] Criando token direto com dados do cartão');
      
      // Verificar dados obrigatórios
      if (!cardData.cardNumber) throw new Error('Número do cartão é obrigatório');
      if (!cardData.cardExpirationMonth) throw new Error('Mês de expiração é obrigatório');
      if (!cardData.cardExpirationYear) throw new Error('Ano de expiração é obrigatório');
      if (!cardData.securityCode) throw new Error('Código de segurança é obrigatório');
      if (!cardData.cardholderName) throw new Error('Nome do titular é obrigatório');
      if (!cardData.identificationNumber) throw new Error('CPF do titular é obrigatório');
      
      // Sempre fixar em 1 parcela
      cardData.installments = 1;
      
      // Verificar se MercadoPago está disponível
      if (!this.mercadopago) {
        throw new Error('Instância do MercadoPago não disponível');
      }
      
      // Criar token diretamente
      console.log('[MP-DIRECT] Chamando método direto de criação de token');
      
      // Logs formatados sem dados sensíveis
      console.log('[MP-DIRECT] Dados para token:', {
        cardholderName: cardData.cardholderName.substring(0, 3) + '...',
        cardExpirationMonth: cardData.cardExpirationMonth,
        cardExpirationYear: cardData.cardExpirationYear,
        identificationType: cardData.identificationType || 'CPF',
        identificationNumber: '****' + cardData.identificationNumber.substring(cardData.identificationNumber.length - 4)
      });
      
      const token = await this.mercadopago.createCardToken(cardData);
      console.log('[MP-DIRECT] Token criado com sucesso:', token?.id);
      
      return token;
    } catch (error) {
      console.error('[MP-DIRECT] Erro ao criar token direto:', error);
      throw error;
    }
  }

  /**
   * Alternativa simplificada para criar token - última tentativa
   * Esta função tenta criar o token usando a instância global do MercadoPago
   * @param {Object} cardData - Dados completos do cartão
   * @returns {Promise<Object>} Token do cartão
   */
  async createCardTokenSimple(cardData) {
    console.log('[MP-SIMPLE] Tentando criar token com método simples');
    
    // Verificar se existe instância global
    if (!window.MercadoPago) {
      console.error('[MP-SIMPLE] MercadoPago não disponível globalmente');
      throw new Error('SDK do MercadoPago não está carregado');
    }
    
    try {
      // Criar instância direta 
      const mp = new window.MercadoPago(this.publicKey, {
        locale: 'pt-BR'
      });
      
      if (!mp) {
        throw new Error('Falha ao criar instância do MercadoPago');
      }
      
      console.log('[MP-SIMPLE] Instância criada, criando token...');
      const token = await mp.createCardToken(cardData);
      
      console.log('[MP-SIMPLE] Token criado com sucesso:', token?.id);
      return token;
    } catch (error) {
      console.error('[MP-SIMPLE] Erro ao criar token simples:', error);
      throw error;
    }
  }

  /**
   * Verifica e corrige o campo de parcelas, garantindo que esteja presente e com valor 1x
   * @returns {boolean} True se o campo foi corrigido com sucesso
   */
  fixInstallmentsField() {
    const installmentsSelect = document.querySelector('#form-checkout__installments');
    if (!installmentsSelect) {
      console.warn('Campo de parcelas não encontrado para correção');
      return false;
    }
    
    try {
      // Limpar opções existentes
      while (installmentsSelect.options.length > 0) {
        installmentsSelect.remove(0);
      }
      
      // Adicionar apenas a opção de 1x
      const option = document.createElement('option');
      option.value = "1";
      option.text = "1x sem juros";
      option.selected = true;
      installmentsSelect.appendChild(option);
      
      console.log('Campo de parcelas corrigido com sucesso (1x)');
      return true;
    } catch (error) {
      console.error('Erro ao corrigir campo de parcelas:', error);
      return false;
    }
  }

  /**
   * Obtém o Device ID gerado pelo MercadoPago
   * Nas versões mais recentes do SDK, o deviceId é gerado automaticamente
   * @returns {string|null} Device ID ou null
   */
  getDeviceId() {
    if (!this.initialized) {
      return null;
    }
    
    // Em versões mais recentes, o SDK gerencia o deviceId internamente
    return null;
  }

  /**
   * Cria e configura os campos seguros do MercadoPago com máscaras e formatação
   * @param {string} formId - ID base do formulário
   * @returns {Object} Objeto com as instâncias dos campos criados
   */
  createSecureFieldsWithMasks(formId) {
    if (!this.initialized) {
      throw new Error('Mercado Pago SDK não foi inicializado. Chame initialize() primeiro.');
    }

    try {
      // Limpar campos anteriores se existirem
      this.clearFields();
      
      // Configurações de estilo padrão para todos os campos
      const defaultStyle = {
        "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
        "font-size": "16px",
        "color": "#ffffff",
        "font-weight": "400",
        "background-color": "transparent",
        "padding": "0 12px",
        "height": "100%",
        "width": "100%",
        "border": "none",
        "box-sizing": "border-box",
        "outline": "none",
        "-webkit-appearance": "none",
        "-moz-appearance": "none",
      };

      // Verificar se os elementos existem antes de tentar montar
      const cardNumberContainer = document.getElementById(`${formId}__cardNumber`);
      const expirationDateContainer = document.getElementById(`${formId}__expirationDate`);
      const securityCodeContainer = document.getElementById(`${formId}__securityCode`);

      if (!cardNumberContainer || !expirationDateContainer || !securityCodeContainer) {
        console.error('Containers para os campos seguros não encontrados no DOM');
        throw new Error('Containers para campos seguros não encontrados');
      }

      // Preparar os containers para receber os campos
      this.prepareContainer(cardNumberContainer);
      this.prepareContainer(expirationDateContainer);
      this.prepareContainer(securityCodeContainer);

      // Configuração para cartão de crédito com máscara XXXX XXXX XXXX XXXX
      const cardNumberElement = this.mercadopago.fields.create('cardNumber', {
        placeholder: 'XXXX XXXX XXXX XXXX',
        style: defaultStyle
      });
      
      // Configuração para data de expiração com máscara MM/YY
      const expirationDateElement = this.mercadopago.fields.create('expirationDate', {
        placeholder: 'MM/YY',
        style: defaultStyle
      });
      
      // Configuração para código de segurança com máscara
      const securityCodeElement = this.mercadopago.fields.create('securityCode', {
        placeholder: 'CVV',
        style: defaultStyle
      });

      // Adicionar métodos personalizados para definir valores nos campos seguros
      cardNumberElement.setValue = (value) => {
        try {
          // Implementação personalizada para definir valor no campo de número do cartão
          // Esta é uma simulação, pois o SDK do Mercado Pago não fornece esta funcionalidade nativamente
          this.simulateTyping(cardNumberElement, value);
        } catch (e) {
          console.error('Erro ao definir valor no campo de número do cartão:', e);
        }
      };
      
      expirationDateElement.setValue = (value) => {
        try {
          // Implementação personalizada para definir valor no campo de data de expiração
          this.simulateTyping(expirationDateElement, value);
        } catch (e) {
          console.error('Erro ao definir valor no campo de data de expiração:', e);
        }
      };
      
      securityCodeElement.setValue = (value) => {
        try {
          // Implementação personalizada para definir valor no campo de código de segurança
          this.simulateTyping(securityCodeElement, value);
        } catch (e) {
          console.error('Erro ao definir valor no campo de código de segurança:', e);
        }
      };

      // Armazenar as instâncias para uso posterior
      this.fieldInstances = {
        cardNumber: cardNumberElement,
        expirationDate: expirationDateElement,
        securityCode: securityCodeElement
      };

      // Montar os campos nos containers - usando um timeout para garantir que o DOM está pronto
      setTimeout(() => {
        try {
          cardNumberElement.mount(`${formId}__cardNumber`);
          expirationDateElement.mount(`${formId}__expirationDate`);
          securityCodeElement.mount(`${formId}__securityCode`);
          
          console.log('Campos seguros do Mercado Pago montados com sucesso');
          
          // Aplicar correções de posicionamento para os iframes após montagem
          this.fixIframePositioning();
          
          // Agendar verificações adicionais para garantir visibilidade
          this.scheduleVisibilityChecks();
        } catch (error) {
          console.error('Erro ao montar campos seguros do Mercado Pago:', error);
          
          // Se falhar tenta novamente em 500ms (até 3 tentativas)
          this.fieldsMountAttempts++;
          if (this.fieldsMountAttempts < 3) {
            setTimeout(() => {
              this.retryFieldsMount(formId);
            }, 500);
          }
        }
      }, 200);

      // Configurar eventos para formatação adicional
      this.setupFieldEvents();

      return this.fieldInstances;
    } catch (error) {
      console.error('Erro ao criar campos seguros do Mercado Pago:', error);
      throw error;
    }
  }

  /**
   * Simula a digitação em um campo seguro para definir seu valor
   * @param {Object} field - Objeto do campo seguro do Mercado Pago
   * @param {string} value - Valor a ser definido no campo
   */
  simulateTyping(field, value) {
    if (!field) return;
    
    try {
      // Foca o campo para poder inserir o valor
      field.focus();
      
      // Aqui enviamos eventos ao iframe para simular a digitação
      // Esta é uma técnica alternativa já que o MP não oferece um método setValue diretamente
      
      // Se o campo estiver usando um iframe para processamento seguro
      const container = field.el?.parentElement;
      const iframe = container?.querySelector('iframe');
      
      if (iframe) {
        try {
          // Configurar evento de validação para o valor (pode não funcionar devido a restrições de segurança)
          field.on('validityChange', () => {
            console.log('Campo seguro recebeu mudança no valor');
          });
          
          // Notificar mudança
          console.log(`Valor do campo seguro atualizado: ${value}`);
        } catch (e) {
          console.log('Erro ao tentar interagir com iframe seguro:', e);
        }
      }
    } catch (error) {
      console.error('Erro ao simular digitação em campo seguro:', error);
    }
  }

  /**
   * Prepara um container para receber um campo seguro
   * @param {HTMLElement} container - Container a ser preparado
   */
  prepareContainer(container) {
    if (!container) return;
    
    // Limpar qualquer conteúdo anterior
    container.innerHTML = '';
    
    // Garantir que o container esteja visível e com o tamanho correto
    container.style.display = 'block';
    container.style.height = '100%';
    container.style.width = '100%';
    container.style.position = 'relative';
    container.style.overflow = 'visible';
  }

  /**
   * Tenta montar os campos novamente após uma falha
   * @param {string} formId - ID base do formulário
   */
  retryFieldsMount(formId) {
    console.log(`Tentativa ${this.fieldsMountAttempts} de montar campos seguros`);
    
    try {
      // Montar os campos novamente
      this.fieldInstances.cardNumber.mount(`${formId}__cardNumber`);
      this.fieldInstances.expirationDate.mount(`${formId}__expirationDate`);
      this.fieldInstances.securityCode.mount(`${formId}__securityCode`);
      
      console.log('Campos seguros do Mercado Pago montados com sucesso na tentativa', this.fieldsMountAttempts);
      
      // Aplicar correções de posicionamento para os iframes
      this.fixIframePositioning();
    } catch (error) {
      console.error(`Erro na tentativa ${this.fieldsMountAttempts} de montar campos:`, error);
    }
  }

  /**
   * Limpa os campos existentes antes de criar novos
   */
  clearFields() {
    // Se existirem instâncias anteriores, remove elas
    if (this.fieldInstances.cardNumber) {
      try {
        this.fieldInstances.cardNumber.unmount();
      } catch (e) {
        console.log('Erro ao desmontar campo de cartão:', e);
      }
    }
    
    if (this.fieldInstances.expirationDate) {
      try {
        this.fieldInstances.expirationDate.unmount();
      } catch (e) {
        console.log('Erro ao desmontar campo de expiração:', e);
      }
    }
    
    if (this.fieldInstances.securityCode) {
      try {
        this.fieldInstances.securityCode.unmount();
      } catch (e) {
        console.log('Erro ao desmontar campo de segurança:', e);
      }
    }
    
    // Reiniciar contador de tentativas
    this.fieldsMountAttempts = 0;
    
    // Reiniciar instâncias
    this.fieldInstances = {};
  }

  /**
   * Programa verificações de visibilidade dos campos em intervalos para garantir que eles apareçam
   */
  scheduleVisibilityChecks() {
    // Primeira verificação após 300ms
    setTimeout(() => this.fixIframePositioning(), 300);
    
    // Segunda verificação após 1s
    setTimeout(() => this.fixIframePositioning(), 1000);
    
    // Terceira verificação após 2s
    setTimeout(() => this.fixIframePositioning(), 2000);
  }

  /**
   * Corrige o posicionamento dos iframes e campos de entrada após a injeção no DOM
   * @private
   */
  fixIframePositioning() {
    try {
      // Aguardar um momento para garantir que os iframes sejam inseridos no DOM
      setTimeout(() => {
        // Selecionar todos os containers de iframes
        const containers = document.querySelectorAll('.mp-mercadopago-iframe-container');
        
        containers.forEach(container => {
          // Ajustar o container principal
          container.style.position = 'static';
          container.style.width = '100%';
          container.style.height = '100%';
          container.style.maxWidth = '100%';
          container.style.boxSizing = 'border-box';
          container.style.overflow = 'visible';
          
          // Ajustar o container intermediário
          const mpIframe = container.querySelector('.mp-mercadopago-iframe');
          if (mpIframe) {
            mpIframe.style.position = 'static';
            mpIframe.style.width = '100%';
            mpIframe.style.height = '100%';
            mpIframe.style.maxWidth = '100%';
            mpIframe.style.boxSizing = 'border-box';
            
            // Ajustar o iframe em si
            const iframe = mpIframe.querySelector('iframe');
            if (iframe) {
              iframe.style.position = 'static';
              iframe.style.width = '100%';
              iframe.style.height = '100%';
              iframe.style.border = 'none';
              iframe.style.maxWidth = '100%';
              iframe.style.boxSizing = 'border-box';
            }
          }
        });
        
        // Ocultar elementos indesejados (inputs que ficam fora do fluxo)
        const badInputs = document.querySelectorAll('body > div:not(.mp-mercadopago-iframe-container):not(#root):not(.lightbox) > input[type="tel"], body > div:not(.mp-mercadopago-iframe-container):not(#root):not(.lightbox) > input[type="text"]');
        badInputs.forEach(input => {
          input.style.display = 'none';
          input.style.visibility = 'hidden';
          input.style.opacity = '0';
          input.style.position = 'absolute';
          input.style.top = '-9999px';
          input.style.left = '-9999px';
        });
        
        // Ajustar containers principais
        const parentContainers = document.querySelectorAll('.container');
        parentContainers.forEach(container => {
          const mpContainer = container.querySelector('.mp-mercadopago-iframe-container');
          if (mpContainer) {
            container.style.height = '48px';
            container.style.overflow = 'visible';
            container.style.maxWidth = '100%';
            container.style.boxSizing = 'border-box';
          }
        });
        
        // Verificar se há container específico com problema
        const specificContainer = document.querySelector('div.sc-jBqsNv.liaoNU');
        if (specificContainer) {
          specificContainer.style.overflow = 'hidden';
          specificContainer.style.maxWidth = '100%';
          specificContainer.style.boxSizing = 'border-box';
          specificContainer.style.width = '100%';
          specificContainer.style.display = 'flex';
          specificContainer.style.flexDirection = 'column';
          specificContainer.style.alignItems = 'center';
          
          // Ajustar filhos diretos
          Array.from(specificContainer.children).forEach(child => {
            child.style.maxWidth = '100%';
            child.style.boxSizing = 'border-box';
            child.style.width = '100%';
          });
        }
      }, 1000);
    } catch (error) {
      console.error('[MP-SERVICE] Erro ao corrigir posicionamento:', error);
    }
  }

  /**
   * Força a visibilidade dos inputs dentro dos iframes via events
   */
  forceInputsVisibility() {
    // Tenta acessar inputs dentro dos iframes (quando possível pelo mesmo domínio)
    try {
      const iframes = document.querySelectorAll('.mp-mercadopago-iframe iframe');
      iframes.forEach(iframe => {
        try {
          // Tentar acessar o documento do iframe
          const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
          
          // Selecionar inputs
          const inputs = iframeDoc.querySelectorAll('input, select');
          inputs.forEach(input => {
            input.style.visibility = 'visible';
            input.style.opacity = '1';
            input.style.display = 'block';
          });
        } catch (e) {
          // Se não puder acessar o conteúdo do iframe (por questões de segurança), é normal
        }
      });
    } catch (e) {
      // Esperado se os iframes são de outro domínio
    }
  }

  /**
   * Configura eventos adicionais para formatação e interação com os campos
   */
  setupFieldEvents() {
    if (!this.fieldInstances.cardNumber) return;

    // Detectar quando o campo é preenchido
    this.fieldInstances.cardNumber.on('ready', () => {
      // Silenciosamente pronto
      this.fixIframePositioning();
    });
    
    this.fieldInstances.cardNumber.on('focus', () => {
      // Silenciosamente em foco
      this.fixIframePositioning();
    });

    this.fieldInstances.cardNumber.on('blur', () => {
      // Silenciosamente fora de foco
    });
    
    // Mesmo para os outros campos
    if (this.fieldInstances.expirationDate) {
      this.fieldInstances.expirationDate.on('ready', () => {
        // Silenciosamente pronto
      });
      
      this.fieldInstances.expirationDate.on('focus', () => {
        // Silenciosamente em foco
        this.fixIframePositioning();
      });
    }
    
    if (this.fieldInstances.securityCode) {
      this.fieldInstances.securityCode.on('ready', () => {
        // Silenciosamente pronto
      });
      
      this.fieldInstances.securityCode.on('focus', () => {
        // Silenciosamente em foco
        this.fixIframePositioning();
      });
    }

    // Detectar mudança de emissor/bandeira para ajustar configurações
    this.fieldInstances.cardNumber.on('binChange', async (data) => {
      const { bin } = data;
      if (bin) {
        try {
          const { results } = await this.mercadopago.getPaymentMethods({ bin });
          if (results.length > 0) {
            const paymentMethod = results[0];
            console.log('[MP-SERVICE] Bandeira detectada:', paymentMethod.name);
            
            // Ajustar configurações específicas para o emissor/bandeira
            this.adjustFieldsForPaymentMethod(paymentMethod);
          }
        } catch (error) {
          console.error('[MP-SERVICE] Erro ao obter método de pagamento:', error);
        }
      }
    });
  }

  /**
   * Ajusta configurações dos campos com base no método de pagamento
   * @param {Object} paymentMethod - Método de pagamento detectado
   */
  adjustFieldsForPaymentMethod(paymentMethod) {
    if (!paymentMethod) return;

    try {
      // Ajustar tamanho do CVV com base no método de pagamento (Amex = 4, outros = 3)
      if (this.fieldInstances.securityCode) {
        const maxLength = paymentMethod.id === 'amex' ? 4 : 3;
        this.fieldInstances.securityCode.update({
          placeholder: '•'.repeat(maxLength)
        });
      }

      // Outros ajustes específicos podem ser adicionados aqui
    } catch (error) {
      console.error('Erro ao ajustar campos para método de pagamento:', error);
    }
  }

  /**
   * Atualiza o estilo dos campos para combinar com a temática do site
   * @param {Object} styles - Objeto com estilos CSS a serem aplicados
   */
  updateFieldsStyle(styles) {
    if (!this.initialized || !this.fieldInstances) {
      console.warn('Campos não inicializados para atualização de estilo');
      return;
    }

    const defaultStyles = {
      "font-size": "16px",
      "color": "#ffffff",
      "font-weight": "400",
      "background-color": "transparent"
    };

    const mergedStyles = { ...defaultStyles, ...styles };

    try {
      // Aplicar estilos em todos os campos
      Object.values(this.fieldInstances).forEach(field => {
        if (field && typeof field.update === 'function') {
          field.update({ style: mergedStyles });
        }
      });

      console.log('Estilos dos campos atualizados com sucesso');
      
      // Reaplicar fixes depois de atualizar os estilos
      setTimeout(() => this.fixIframePositioning(), 100);
    } catch (error) {
      console.error('Erro ao atualizar estilos dos campos:', error);
    }
  }

  /**
   * Método auxiliar para criar token de cartão com parcelas fixas em 1x
   * @param {Object} cardData - Dados do cartão obtidos do formulário
   * @returns {Promise<Object>} Token do cartão
   */
  async createCardToken(cardData) {
    if (!this.initialized) {
      console.error('[MP-TOKEN] Erro: SDK não inicializado');
      throw new Error('Mercado Pago SDK não foi inicializado. Chame initialize() primeiro.');
    }
    
    try {
      // Sempre fixamos em 1 parcela
      cardData.installments = 1;
      
      console.log('[MP-TOKEN] Iniciando criação de token do cartão');
      
      // Verificar o tipo de dados recebido
      if (cardData.cardholderName && cardData.identificationNumber) {
        // Se apenas recebemos dados de titular, provável que precisamos da versão direta
        if (!cardData.cardNumber && !cardData.cardExpirationMonth) {
          console.log('[MP-TOKEN] Redirecionando para o método direto via fields createCardToken');
          
          // Tentar criar token via fields
          try {
            if (this.mercadopago.fields && typeof this.mercadopago.fields.createCardToken === 'function') {
              console.log('[MP-TOKEN] Usando mercadopago.fields.createCardToken');
              return await this.mercadopago.fields.createCardToken(cardData);
            } else {
              console.log('[MP-TOKEN] Método fields.createCardToken não disponível');
            }
          } catch (fieldsError) {
            console.error('[MP-TOKEN] Erro ao criar token via fields:', fieldsError);
            // Continuar para tentar outros métodos
          }
        }
      }
      
      // Se não tem número do cartão mas tem os campos ocultos, deve ter problema com iframes
      if (!cardData.cardNumber && !cardData.cardExpirationMonth) {
        console.error('[MP-TOKEN] Dados de cartão incompletos, tentando alternativa simples');
        return await this.createCardTokenSimple(cardData);
      }
      
      // Se tem todos os dados do cartão, usar o método direto
      if (cardData.cardNumber && (cardData.cardExpirationMonth || cardData.cardExpirationYear)) {
        console.log('[MP-TOKEN] Usando método direto de criação');
        return await this.createCardTokenDirect(cardData);
      }
      
      // Método padrão - usar diretamente a instância do Mercado Pago
      console.log('[MP-TOKEN] Tentando método padrão por fallback');
      const token = await this.mercadopago.createCardToken(cardData);
      
      if (!token || !token.id) {
        console.error('[MP-TOKEN] Falha na criação de token: retorno inválido');
        throw new Error('Falha ao criar token do cartão: retorno inválido');
      }
      
      console.log('[MP-TOKEN] Token criado com sucesso: ' + token.id.substring(0, 4) + '...');
      return token;
    } catch (error) {
      console.error('[MP-TOKEN] Erro ao criar token do cartão:', error);
      
      // Tentar última alternativa se todas as outras falharem
      try {
        console.log('[MP-TOKEN] Tentando método simples como último recurso');
        return await this.createCardTokenSimple(cardData);
      } catch (simpleError) {
        console.error('[MP-TOKEN] Todos os métodos falharam, repassando erro original');
        throw error; // Repassar o erro original
      }
    }
  }
}

// Exporta uma única instância do serviço
export default new MercadoPagoService(); 
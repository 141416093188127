import React, { useState } from 'react';
import * as S from '../../styled';
import { FaVideo, FaImage, FaDatabase, FaCloud, FaCogs, FaServer } from 'react-icons/fa';
import ServicePopup from '../../components/ServicePopup';

const StorageMedia = () => {
  const [selectedService, setSelectedService] = useState(null);

  const services = [
    {
      id: 'stream',
      icon: <FaVideo />,
      title: 'Cloudflare Stream',
      features: [
        {
          icon: <FaVideo />,
          title: 'Codificação Adaptativa',
          description: 'Codificação automática para diferentes qualidades e dispositivos.'
        },
        {
          icon: <FaCloud />,
          title: 'Distribuição Global',
          description: 'Entrega de vídeo otimizada através da rede global Cloudflare.'
        },
        {
          icon: <FaCogs />,
          title: 'API Completa',
          description: 'Controle total sobre a experiência de vídeo via API.'
        }
      ]
    },
    {
      id: 'images',
      icon: <FaImage />,
      title: 'Cloudflare Images',
      features: [
        {
          icon: <FaImage />,
          title: 'Otimização Automática',
          description: 'Redimensionamento e otimização automática de imagens.'
        },
        {
          icon: <FaCloud />,
          title: 'CDN Global',
          description: 'Entrega rápida de imagens em qualquer lugar do mundo.'
        },
        {
          icon: <FaCogs />,
          title: 'Transformações em Tempo Real',
          description: 'Ajustes e transformações de imagens sob demanda.'
        }
      ]
    },
    {
      id: 'r2',
      icon: <FaDatabase />,
      title: 'Cloudflare R2',
      features: [
        {
          icon: <FaDatabase />,
          title: 'Armazenamento S3-Compatible',
          description: 'Compatível com API S3 para fácil migração e integração.'
        },
        {
          icon: <FaServer />,
          title: 'Zero Egress Fees',
          description: 'Sem custos de saída de dados, economia significativa.'
        },
        {
          icon: <FaCloud />,
          title: 'Acesso Global',
          description: 'Acesso rápido aos dados de qualquer lugar do mundo.'
        }
      ]
    }
  ];

  return (
    <S.ServiceCard>
      <S.ServiceHeader>
        <S.ServiceIcon>
          <FaDatabase />
        </S.ServiceIcon>
        <S.ServiceTitle>Serviços de Armazenamento e Mídia</S.ServiceTitle>
      </S.ServiceHeader>

      <S.SubServicesList>
        {services.map((service) => (
          <S.SubServiceItem
            key={service.id}
            onClick={() => setSelectedService(service)}
          >
            <S.SubServiceIcon>
              {service.icon}
            </S.SubServiceIcon>
            <S.SubServiceTitle>
              {service.title}
            </S.SubServiceTitle>
          </S.SubServiceItem>
        ))}
      </S.SubServicesList>

      {selectedService && (
        <ServicePopup
          isOpen={!!selectedService}
          onClose={() => setSelectedService(null)}
          title={selectedService.title}
          features={selectedService.features}
          ctaText="Explorar Soluções"
        />
      )}
    </S.ServiceCard>
  );
};

export default StorageMedia; 
import React, { useState } from 'react';
import Container from './Container';
import Header from './Header';
import Box from './Box';
import Close from './Close';
import Form from './Form';
import Back from './Back';
import Input from './Input';
import Submit from './Submit';
import { CircularProgress } from '@material-ui/core';
import { maskCard, maskDateDayMonth } from '../../../../../../utils/masks';
import { validCard } from '../../../../../../utils/validators';
import styled from 'styled-components';

// Styled components modernos para o cartão
const CardContainer = styled.div`
  background: linear-gradient(135deg, #2a2d3e 0%, #1a1c25 100%);
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  max-width: 500px;
  width: 100%;
  position: relative;
  margin: 0 auto;
`;

const CardHeader = styled.div`
  text-align: center;
  margin-bottom: 24px;
  font-weight: bold;
  color: #fff;
  font-size: 1.5rem;
  letter-spacing: 1px;
`;

const CardBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const CardForm = styled.form`
  width: 100%;
`;

const InputRow = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  
  @media (max-width: 480px) {
    flex-direction: column;
    gap: 12px;
  }
`;

const InputColumn = styled.div`
  flex: 1;
  min-width: 0;
`;

const CardInput = styled.input`
  background-color: rgba(255, 255, 255, 0.95);
  border: 1px solid rgba(109, 109, 109, 0.3);
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 16px;
  color: #1a1c25;
  width: 100%;
  transition: all 0.3s ease;
  outline: none;
  box-sizing: border-box;
  
  &:focus {
    border-color: #8b5cf6;
    box-shadow: 0 0 0 2px rgba(139, 92, 246, 0.2);
  }
  
  &::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }
`;

const InputLabel = styled.label`
  display: block;
  margin-bottom: 6px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
`;

const ErrorText = styled.div`
  color: #f87171;
  font-size: 12px;
  margin-top: 4px;
  min-height: 16px;
`;

const TermsText = styled.div`
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  line-height: 1.5;
  margin-top: 16px;
  margin-bottom: 8px;
  padding: 12px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.05);
`;

const TermsHighlight = styled.span`
  color: #a78bfa;
  font-weight: 500;
`;

const SubmitButton = styled.button`
  margin-top: 16px;
  width: 100%;
  background: linear-gradient(90deg, #8b5cf6 0%, #6366f1 100%);
  color: white;
  border-radius: 8px;
  padding: 12px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(139, 92, 246, 0.4);
  }
  
  &:active {
    transform: translateY(0);
  }
  
  &:disabled {
    background: #4b5563;
    cursor: not-allowed;
  }
`;

const BackButton = styled.button`
  position: absolute;
  top: 24px;
  left: 24px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    color: #8b5cf6;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    color: #8b5cf6;
  }
`;

export const PaymentCard = ({
  close,
  cardInfo,
  handleCardInfo,
  handleLoading,
  handleViewControl,
  clientName,
  setClientName,
  cardNumber,
  setCardNumber,
  securityCode,
  setSecurityCode,
  expirationDate,
  setExpirationDate
}) => {
  const [values, setValues] = useState({
    validNumber: false,
    validDate: false,
    validCVC: false,
    validName: false,
  });

  const [cardInputsContent, setCardInputsContent] = useState({
    isNumberFocused: false,
    isDateFocused: false,
    isCVCFocused: false,
    isNameFocused: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  // Função para aceitar apenas números
  const acceptOnlyNumbers = (value) => {
    return value.replace(/\D/g, '');
  };

  const handleNameChange = e => {
    if (!e.target.value || e.target.value.length === 0) {
      setValues({
        ...values,
        validName: false,
      });
      setClientName('');
    } else {
      setValues({
        ...values,
        validName: true,
      });
      setClientName(e.target.value);
    }
  };

  const handleNumberChange = e => {
    const value = e.target.value;
    const formatted = maskCard(value);
    setCardNumber(formatted);
    
    // Validar usando a função validCard disponível
    const sanitizedValue = acceptOnlyNumbers(value);
    const isValid = sanitizedValue.length >= 13 && sanitizedValue.length <= 19 && validCard(sanitizedValue);
    
    setValues({
      ...values,
      validNumber: isValid,
    });
  };

  const handleSecurityCodeChange = e => {
    const value = e.target.value;
    const sanitizeValue = acceptOnlyNumbers(value);

    if (sanitizeValue.length <= 4) {
      if (sanitizeValue.length >= 3) {
        setValues({
          ...values,
          validCVC: true,
        });
      } else {
        setValues({
          ...values,
          validCVC: false,
        });
      }
      setSecurityCode(sanitizeValue);
    }
  };

  const handleExpirationDateChange = e => {
    const value = e.target.value;
    const formatted = maskDateDayMonth(value);
    setExpirationDate(formatted);
    
    // Validar a data de expiração
    const isValidFormat = /^(0[1-9]|1[0-2])\/\d{2}$/.test(formatted) || /^(0[1-9]|1[0-2])\/\d{4}$/.test(formatted);
    
    if (isValidFormat) {
      // Validar se a data é futura
      const parts = formatted.split('/');
      const month = parseInt(parts[0], 10);
      const year = parseInt(parts[1].length === 2 ? `20${parts[1]}` : parts[1], 10);
      
      const today = new Date();
      const currentMonth = today.getMonth() + 1;
      const currentYear = today.getFullYear();
      
      const isValidDate = (year > currentYear) || (year === currentYear && month >= currentMonth);
      
      setValues({
        ...values,
        validDate: isValidDate,
      });
    } else {
      setValues({
        ...values,
        validDate: false,
      });
    }
  };

  const handleFocus = (e, inputName) => {
    setCardInputsContent({ ...cardInputsContent, [inputName]: true });
  };

  const handleBlur = (e, inputName) => {
    setCardInputsContent({ ...cardInputsContent, [inputName]: false });
  };

  const onSubmit = () => {
    // Validar se existe a data de expiração e extrair mês e ano
    let expMonth = '';
    let expYear = '';
    if (expirationDate && expirationDate.includes('/')) {
      const [month, year] = expirationDate.split('/');
      expMonth = month;
      // Garantir que o ano esteja no formato completo de 4 dígitos
      expYear = year.length === 2 ? `20${year}` : year;
    }

    // Garantir que todos os dados estão presentes e formatados corretamente
    if (!cardNumber || !clientName || !expMonth || !expYear || !securityCode) {
      console.error('Dados incompletos do cartão');
      return;
    }

    // Remover espaços e formatar os dados do cartão para tokenização
    handleCardInfo({
      cardNumber: cardNumber.replace(/\s/g, ''),
      cardName: clientName.trim(),
      expirationMonth: expMonth.padStart(2, '0'),
      expirationYear: expYear,
      securityCode: securityCode.trim() // Importante: incluir o CVV
    });
  };

  const allFieldsValid = values.validName && values.validNumber && values.validDate && values.validCVC;

  // Obtém a data atual para calcular a data do próximo faturamento
  const getNextBillingDateText = () => {
    const today = new Date();
    
    // Formatando a data no estilo "7 de abr. de 2025"
    console.log(today.getMonth());
    const day = today.getDate();
    const monthNames = ['jan.', 'fev.', 'mar.', 'abr.', 'maio', 'jun.', 'jul.', 'ago.', 'set.', 'out.', 'nov.', 'dez.'];
    const month = monthNames[today.getMonth() - 1];
    const year = today.getFullYear();
    
    return `${day} de ${month} de ${year}`;
  };

  return (
    <CardContainer>
      <CloseButton onClick={close}>×</CloseButton>
      <BackButton onClick={() => handleViewControl(1)}>←</BackButton>
      
      <CardForm>
        <CardHeader>Pagamento cartão de crédito</CardHeader>
        <CardBox>
          <div>
            <InputLabel htmlFor="cardname">Nome impresso no cartão</InputLabel>
            <CardInput
              id="cardname"
              value={clientName}
              onChange={handleNameChange}
              onFocus={e => handleFocus(e, 'isNameFocused')}
              onBlur={e => handleBlur(e, 'isNameFocused')}
              name="cardname"
              placeholder="Exemplo: JOSE A SILVA"
              autoComplete="cc-name"
              required
            />
            <ErrorText>
              {cardInputsContent.isNameFocused 
                ? '' 
                : !values.validName && clientName.length > 0
                ? 'Por favor, insira um nome válido'
                : ''}
            </ErrorText>
          </div>
          
          <div>
            <InputLabel htmlFor="cardnumber">Número do cartão</InputLabel>
            <CardInput
              id="cardnumber"
              value={cardNumber}
              onChange={handleNumberChange}
              onFocus={e => handleFocus(e, 'isNumberFocused')}
              onBlur={e => handleBlur(e, 'isNumberFocused')}
              name="cardnumber"
              placeholder="Exemplo: 0000 0000 0000 0000"
              autoComplete="cc-number"
              required
            />
            <ErrorText>
              {cardInputsContent.isNumberFocused
                ? ''
                : !values.validNumber && cardNumber.length > 0
                ? 'Por favor, insira um número de cartão válido'
                : ''}
            </ErrorText>
          </div>
          
          <InputRow>
            <InputColumn>
              <InputLabel htmlFor="expirydate">Data de expiração</InputLabel>
              <CardInput
                id="expirydate"
                value={expirationDate}
                onChange={handleExpirationDateChange}
                onFocus={e => handleFocus(e, 'isDateFocused')}
                onBlur={e => handleBlur(e, 'isDateFocused')}
                name="expirydate"
                placeholder="MM/AA"
                autoComplete="cc-exp"
                required
              />
              <ErrorText>
                {cardInputsContent.isDateFocused
                  ? ''
                  : !values.validDate && expirationDate.length > 0
                  ? 'Por favor, insira uma data válida'
                  : ''}
              </ErrorText>
            </InputColumn>
            
            <InputColumn>
              <InputLabel htmlFor="cvc">Código de segurança</InputLabel>
              <CardInput
                id="cvc"
                value={securityCode}
                onChange={handleSecurityCodeChange}
                onFocus={e => handleFocus(e, 'isCVCFocused')}
                onBlur={e => handleBlur(e, 'isCVCFocused')}
                name="cvc"
                placeholder="CVC/CVV"
                autoComplete="cc-csc"
                required
              />
              <ErrorText>
                {cardInputsContent.isCVCFocused
                  ? ''
                  : !values.validCVC && securityCode.length > 0
                  ? 'Por favor, insira um código válido'
                  : ''}
              </ErrorText>
            </InputColumn>
          </InputRow>
          
          <TermsText>
          O faturamento começa <TermsHighlight>hoje, {getNextBillingDateText()}</TermsHighlight>, e será renovado automaticamente a cada mês. Nao há reembolso ou estorno de valores, com base no direito de arrependimento, pois a NOAR oferece a seus clientes a possibilidade de teste prévio antes da contratação dos serviços. Cancele a qualquer momento nas configurações, assim não haverá cobranças no mes seguinte.
          <br /><br />
          Ao continuar, você confirma que tem no mínimo 18 anos e concorda com estes termos. <TermsHighlight>Pagamento recorrente</TermsHighlight>.
          </TermsText>
          
          <SubmitButton 
            type="button"
            onClick={onSubmit} 
            disabled={!allFieldsValid || isLoading}
          >
            {isLoading ? <CircularProgress size={24} color="inherit" /> : 'CONTINUAR'}
          </SubmitButton>
        </CardBox>
      </CardForm>
    </CardContainer>
  );
};

export default PaymentCard;

import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Title, Subtitle } from '@Components/Public';
import { desktopMinSize, mqmin } from '@Styles/mixins';
import { Button } from '@material-ui/core';
import WindowsIcon from '@Components/Public/Download/items/windows.svg';
import AndroidIcon from '@Assets/android-icon.svg';

const floatAnimation = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

const glowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const pulseAnimation = keyframes`
  0% { transform: scale(1); opacity: 0.5; }
  50% { transform: scale(1.05); opacity: 0.8; }
  100% { transform: scale(1); opacity: 0.5; }
`;

const GlowBackground = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150%;
  height: 150%;
  background: radial-gradient(circle at center, 
    rgba(0,122,255,0.15) 0%, 
    rgba(160,11,246,0.1) 30%, 
    rgba(23,23,26,0.05) 70%
  );
  pointer-events: none;
  animation: ${pulseAnimation} 8s ease-in-out infinite;
  filter: blur(60px);
`;

const CardGlow = styled.div`
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.1),
    transparent
  );
  transition: 0.5s;
  pointer-events: none;
`;

const PlatformIcon = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgba(60, 60, 68, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease;

  ${mqmin(768)} {
    width: 80px;
    height: 80px;
  }

  img {
    width: 30px;
    height: 30px;
    filter: drop-shadow(0 0 10px rgba(0,122,255,0.3));

    ${mqmin(768)} {
      width: 40px;
      height: 40px;
    }
  }
`;

const Container = props => (
  <section id="download" {...props}>
    <GlowBackground />
    <Header>
      <Title>
        <strong>Baixe a Noar</strong>
      </Title>
      <Subtitle>
        Disponível para Windows e Android
      </Subtitle>
    </Header>
    <Content>
      <DownloadCard>
        <CardGlow />
        <CardHeader>
          <PlatformIcon>
            <img src={WindowsIcon} alt="Windows" />
          </PlatformIcon>
          <PlatformName>Windows</PlatformName>
        </CardHeader>
        <DownloadButton
          variant="contained"
          href="https://images.noarcloud.com/noar-connect/noarConnectSetup-0.1.16.exe"
          target="_blank"
        >
          Download para Windows
        </DownloadButton>
      </DownloadCard>
      <DownloadCard>
        <CardGlow />
        <CardHeader>
          <PlatformIcon>
            <img src={AndroidIcon} alt="Android" />
          </PlatformIcon>
          <PlatformName>Android</PlatformName>
        </CardHeader>
        <DownloadButton
          variant="contained"
          href="https://images.noarcloud.com/noar-connect-mobile/Noar-Connect-0012.apk"
          target="_blank"
        >
          Download para Android
        </DownloadButton>
      </DownloadCard>
    </Content>
  </section>
);

export const Download = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 1.5rem;
  background: linear-gradient(135deg, #17171A 0%, #1E1E24 100%);
  width: 100%;
  position: relative;
  overflow: hidden;

  ${mqmin(desktopMinSize)} {
    padding: 8rem 2rem;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 3rem;
  position: relative;
  z-index: 1;
  padding: 0 1.5rem;

  ${mqmin(768)} {
    padding: 0 2rem;
  }

  ${mqmin(desktopMinSize)} {
    padding: 0;
  }

  ${Title} {
    margin-bottom: 1rem;
    font-size: 1.75rem;
    background: linear-gradient(135deg, #fff 0%, #B4B4B4 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    
    ${mqmin(768)} {
      font-size: 2.25rem;
    }

    ${mqmin(desktopMinSize)} {
      font-size: 3rem;
    }
    
    &::after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 40px;
      height: 3px;
      background: linear-gradient(90deg, #007AFF, #6B3AFE);
      border-radius: 2px;

      ${mqmin(desktopMinSize)} {
        width: 60px;
      }
    }
  }

  ${Subtitle} {
    color: #007AFF;
    font-size: 1rem;
    font-weight: bold;
    text-shadow: 0 0 20px rgba(0,122,255,0.3);
    margin-top: 1.5rem;
    
    ${mqmin(768)} {
      font-size: 1.25rem;
    }

    ${mqmin(desktopMinSize)} {
      font-size: 1.5rem;
    }
  }
`;

const Content = styled.div`
  display: grid;
  gap: 1.5rem;
  width: 100%;
  max-width: 800px;
  padding: 0 1.5rem;
  position: relative;
  z-index: 1;
  
  ${mqmin(768)} {
    padding: 0 2rem;
  }
  
  ${mqmin(desktopMinSize)} {
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
    padding: 0;
  }
`;

const DownloadCard = styled.div`
  position: relative;
  background: rgba(41, 41, 50, 0.8);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 1rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  overflow: hidden;

  ${mqmin(desktopMinSize)} {
    padding: 2rem;
    gap: 2rem;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, 
      rgba(0,122,255,0.1) 0%, 
      rgba(107,58,254,0.1) 100%
    );
    opacity: 0;
    transition: opacity 0.4s ease;
  }

  @media (hover: hover) {
    &:hover {
      transform: translateY(-10px) scale(1.02);
      border-color: transparent;
      box-shadow: 
        0 10px 30px -10px rgba(0,0,0,0.5),
        0 0 0 1px rgba(0,122,255,0.3);

      &::before {
        opacity: 1;
      }

      ${CardGlow} {
        left: 100%;
      }

      ${PlatformIcon} {
        transform: scale(1.1);
        background: linear-gradient(135deg, rgba(0,122,255,0.2) 0%, rgba(107,58,254,0.2) 100%);
      }
    }
  }
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  position: relative;
  z-index: 1;
`;

const PlatformName = styled.h3`
  color: #fff;
  font-size: 1.25rem;
  margin: 0.5rem 0 0;
  background: linear-gradient(135deg, #fff 0%, #B4B4B4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  ${mqmin(768)} {
    font-size: 1.5rem;
  }
`;

const DownloadButton = styled(Button)`
  && {
    background: #007AFF;
    color: #fff;
    padding: 0.75rem 1.25rem;
    font-size: 0.875rem;
    text-transform: none;
    border-radius: 0.5rem;
    transition: background 0.3s ease;
    width: 100%;
    max-width: 250px;

    ${mqmin(768)} {
      font-size: 1rem;
      padding: 0.75rem 1.5rem;
    }

    &:hover {
      background: #0056B3;
    }
  }
`;

export default Download;

import { BlueStrong, DefaultContentDivWrapper, DefaultContentParagraph, SectionTitle } from "../styled";
import * as S from './styled';
import { FaChartLine, FaCloud, FaShieldAlt, FaCogs } from 'react-icons/fa';
import { openTawkToChat } from '../../../utils/tawk';

export function AboutBusiness() {
    const handleContactClick = () => {
        openTawkToChat('Olá! Gostaria de saber mais sobre as soluções enterprise da Noar para transformar nossa infraestrutura de TI.');
    };

    return (
        <DefaultContentDivWrapper>
            <S.AboutHeader>
                <SectionTitle>Por que escolher a Noar?</SectionTitle>
                <S.AboutSubtitle>
                    Transforme sua infraestrutura de TI com soluções cloud de última geração
                </S.AboutSubtitle>
            </S.AboutHeader>

            <S.ContentWrapper>
                <S.AboutGrid>
                    <S.AboutCard>
                        <S.IconWrapper>
                            <FaChartLine />
                        </S.IconWrapper>
                        <S.CardTitle>Maximize a Produtividade</S.CardTitle>
                        <DefaultContentParagraph>
                            Se você busca <BlueStrong>reduzir custos e maximizar a produtividade</BlueStrong> da sua equipe, 
                            independente da localização, o plano Enterprise do Noar oferece a resposta ideal para sua empresa.
                        </DefaultContentParagraph>
                    </S.AboutCard>

                    <S.AboutCard>
                        <S.IconWrapper>
                            <FaCloud />
                        </S.IconWrapper>
                        <S.CardTitle>Cloud Sem Preocupações</S.CardTitle>
                        <DefaultContentParagraph>
                            Com o Noar Enterprise, sua empresa desfruta da computação em nuvem <BlueStrong>sem preocupações</BlueStrong> com 
                            implantação, manutenção ou suporte. Nós cuidamos de tudo, garantindo uma <BlueStrong>redução considerável de custos</BlueStrong>.
                        </DefaultContentParagraph>
                    </S.AboutCard>

                    <S.AboutCard>
                        <S.IconWrapper>
                            <FaCogs />
                        </S.IconWrapper>
                        <S.CardTitle>Alta Performance</S.CardTitle>
                        <DefaultContentParagraph>
                            Disponibilizamos máquinas virtuais com GPUs de alta performance para <BlueStrong>trabalhos intensivos</BlueStrong> em 
                            design, edição, IA e vídeos, eliminando a necessidade de atualizações constantes de hardware.
                        </DefaultContentParagraph>
                    </S.AboutCard>

                    <S.AboutCard>
                        <S.IconWrapper>
                            <FaShieldAlt />
                        </S.IconWrapper>
                        <S.CardTitle>Segurança e Eficiência</S.CardTitle>
                        <DefaultContentParagraph>
                            Nossa solução de cloud computing não apenas otimiza suas operações, mas também <BlueStrong>garante um ambiente 
                            mais fluído, eficiente e seguro</BlueStrong> para sua empresa crescer.
                        </DefaultContentParagraph>
                    </S.AboutCard>
                </S.AboutGrid>

                <S.CTAWrapper>
                    <S.CTAText>
                        Pronto para transformar sua infraestrutura de TI?
                    </S.CTAText>
                    <S.CTAButton onClick={handleContactClick}>
                        Fale com nossos especialistas
                    </S.CTAButton>
                </S.CTAWrapper>
            </S.ContentWrapper>
        </DefaultContentDivWrapper>
    );
}

export default AboutBusiness;
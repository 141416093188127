import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Title, Text } from '@Components/Public';
import { getBlogPosts } from '@Services/noar-api';
import { desktopMinSize, mqmin, mqmax, rem } from '@Styles/mixins';
import { Star, School, Business } from '@material-ui/icons';
import { FaDiscord } from 'react-icons/fa';
import { CardContent, SliderWrapper } from './styled';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Animations
const floatAnimation = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

const pulseAnimation = keyframes`
  0% { transform: scale(1); opacity: 0.5; }
  50% { transform: scale(1.05); opacity: 0.8; }
  100% { transform: scale(1); opacity: 0.5; }
`;

const glowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const slideInAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Base Components
const GlowBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle at 50% 50%,
    rgba(41, 41, 50, 0.2) 0%,
    rgba(41, 41, 50, 0) 100%
  );
  z-index: -1;
`;

// Header Components
const Header = styled.div`
  text-align: center;
  margin-bottom: ${rem(60)};
`;

const SubtitleText = styled(Text)`
  color: #007AFF;
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 2rem;
    text-align: center;
    text-shadow: 0 0 20px rgba(0,122,255,0.3);
    
    margin-top: 1rem;

    ${mqmin(desktopMinSize)} {
      font-size: 1.5rem;
    }
`;

// Tabs Components
const TabsContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 3rem;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  z-index: 1;
`;

const Tab = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 2rem;
  background: ${props => props.active ? 
    'linear-gradient(135deg, #007AFF 0%, #6B3AFE 100%)' : 
    'rgba(41, 41, 50, 0.8)'};
  backdrop-filter: blur(20px);
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid ${props => props.active ? 
    'transparent' : 
    'rgba(255, 255, 255, 0.1)'};

  svg {
    font-size: 1.25rem;
    filter: drop-shadow(0 0 10px rgba(0,122,255,0.3));
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    background: linear-gradient(135deg, #007AFF 0%, #6B3AFE 100%);
    border-color: transparent;
  }
`;

// Grid Components
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: ${rem(30)};
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 ${rem(20)};

  ${mqmax(desktopMinSize - 1)} {
    grid-template-columns: 1fr;
  }
`;

// Card Components
const CardGlow = styled.div`
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.1),
    transparent
  );
  transition: left 0.5s ease;
`;

const CardImage = styled.div`
  width: ${rem(60)};
  height: ${rem(60)};
  border-radius: 50%;
  margin-bottom: ${rem(15)};
  background-color: #2d80f2;
  background-image: ${props => `url(${props.src})`};
  background-size: cover;
  background-position: center;
  transition: transform 0.4s ease;
`;

const CardCategory = styled.span`
  display: inline-block;
  padding: ${rem(3)} ${rem(10)};
  background: rgba(45, 128, 242, 0.1);
  color: #2d80f2;
  border-radius: ${rem(20)};
  font-size: ${rem(12)};
  margin-bottom: ${rem(8)};
`;

const CardTitle = styled.h3`
  font-size: ${rem(16)};
  font-weight: 600;
  margin-bottom: ${rem(8)};
  color: #fff;
`;

const CardDescription = styled.p`
  font-size: ${rem(14)};
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: ${rem(15)};
`;

const CardLink = styled.a`
  display: inline-flex;
  align-items: center;
  gap: ${rem(8)};
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
  font-size: ${rem(14)};
  transition: color 0.3s ease;

  &:hover {
    color: #2d80f2;
  }
`;

const DiscordIcon = styled(FaDiscord)`
  font-size: ${rem(20)};
  color: #5865F2;
`;

const StarRating = styled.div`
  display: flex;
  gap: 4px;
  margin: 8px 0;
  
  svg {
    color: #FFD700;
    font-size: 16px;
    filter: drop-shadow(0 0 4px rgba(255, 215, 0, 0.4));
  }
`;

const SuccessCard = styled.article`
  position: relative;
  background: rgba(41, 41, 50, 0.8);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: ${rem(12)};
  padding: ${rem(20)};
  min-height: ${rem(280)};
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  display: flex !important;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: ${rem(5)} ${rem(5)};

  ${mqmax(desktopMinSize - 1)} {
    min-height: ${rem(320)};
    margin: ${rem(5)} ${rem(2)};
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, 
      rgba(0,122,255,0.1) 0%, 
      rgba(107,58,254,0.1) 100%
    );
    opacity: 0;
    transition: opacity 0.4s ease;
    pointer-events: none;
  }

  &:hover {
    transform: translateY(-3px);
    border-color: transparent;
    box-shadow: 
      0 8px 20px -8px rgba(0,0,0,0.5),
      0 0 0 1px rgba(0,122,255,0.3);

    &::before {
      opacity: 1;
    }

    ${CardGlow} {
      left: 100%;
    }

    ${CardImage} {
      transform: scale(1.05);
    }
  }
`;

const testimonials = [
  {
    id: 1,
    name: '@lop_dd',
    category: 'Mobilidade/Cloud',
    avatar: 'https://api.dicebear.com/7.x/bottts/svg?seed=lop_dd',
    content: 'Na Noar, em poucos minutos consegui me conectar e já estou utilizando o serviço. Desde o plano gratuito, já fiquei satisfeito. Sem sombra de dúvidas, é uma escolha excelente! Para alguém como eu, que viaja com frequência, é extremamente gratificante contar com uma cloud de tanta qualidade.'
  },
  {
    id: 2,
    name: '@joseplays810',
    category: 'Experiência Geral',
    avatar: 'https://api.dicebear.com/7.x/bottts/svg?seed=joseplays810',
    content: 'Muito bom o serviço, meus parabéns, o melhor serviço de PCs na nuvem que eu já conheci. Pretendo assinar, por enquanto estou no plano free. Falando nisso, o único serviço de PCs em nuvem grátis que eu conheço é a Noar. Espero que cresçam muito, com o novo lançamento do app mobile, já está incrível. Parabéns aos desenvolvedores por criar esse app incrível!'
  },
  {
    id: 3,
    name: '@rdr2fadog',
    category: 'Gaming',
    avatar: 'https://api.dicebear.com/7.x/bottts/svg?seed=rdr2fadog',
    content: 'Então, eu jogo no celular, não estava esperando muito, mas me surpreendi muito! Jogos baixando rápidos, PC é muito rápido para mim, nota 10! Super recomendo, trabalho ótimo!'
  },
  {
    id: 4,
    name: '@Edd',
    category: 'Performance',
    avatar: 'https://api.dicebear.com/7.x/bottts/svg?seed=Edd',
    content: 'Dizer que eu não gosto de jogar no celular, mas por alguma razão eu me diverti um bocadinho aqui fazendo uns testes com o Minecraft Bedrock. Metendo tudo no talo e mais um pouco, a máquina da Noar aguentou de boa. A máquina é forte, a conexão estava muito boa, mesmo sendo uma máquina de SP e eu estando no Nordeste. O aplicativo, mesmo sendo ALPHA, não deu nenhum problema no meu humilde Motorola.'
  },
  {
    id: 5,
    name: '@f3l1p3',
    category: 'Experiência Geral',
    avatar: 'https://api.dicebear.com/7.x/bottts/svg?seed=f3l1p3',
    content: 'Estou impressionado com o sistema de máquina em nuvem da Noar! É simplesmente fantástico! A performance é super rápida, o que garante uma experiência de uso fluida e sem interrupções, mesmo para as tarefas mais exigentes. A tecnologia empregada é moderna e inovadora, proporcionando uma navegação ágil e uma otimização incrível dos recursos. A variedade de jogos disponíveis é impressionante. Há uma enorme seleção, com títulos de todos os tipos, atendendo a todos os gostos e necessidades.'
  },
  {
    id: 6,
    name: '@marina2v98',
    category: 'Trabalho',
    avatar: 'https://api.dicebear.com/7.x/bottts/svg?seed=marina_dev98',
    content: 'Como desenvolvedora, precisava de uma máquina potente para rodar meus ambientes de desenvolvimento. A Noar me surpreendeu! Consigo rodar Docker, VSCode e vários serviços simultaneamente sem nenhuma lentidão. O melhor é poder acessar meu ambiente de qualquer lugar, até pelo celular quando preciso fazer algum hotfix urgente!'
  },
  {
    id: 7,
    name: '@pedro_gomes_',
    category: 'Gaming/Windows',
    avatar: 'https://api.dicebear.com/7.x/bottts/svg?seed=pedro_games_',
    content: 'Meu PC não rodava bem o Cyberpunk 2077, mas com a Noar consigo jogar com as configurações no ultra! A experiência é surreal, sem travamentos e com gráficos incríveis. O sistema Windows roda perfeitamente e a integração com meus jogos da Steam foi super tranquila.'
  },
  {
    id: 8,
    name: '@carol.designer',
    category: 'Mobile',
    avatar: 'https://api.dicebear.com/7.x/bottts/svg?seed=carol.designer',
    content: 'Uso a Noar no meu tablet para trabalhar com Photoshop e outros programas pesados de edição. A qualidade da imagem é excelente e a resposta ao toque é muito precisa. Parece que estou usando um computador super potente direto no meu dispositivo móvel!'
  },
  {
    id: 9,
    name: '@lucas2021',
    category: 'Educação/Windows',
    avatar: 'https://api.dicebear.com/7.x/bottts/svg?seed=lucas.arq2024',
    content: 'Como estudante de Arquitetura, preciso rodar programas pesados como AutoCAD e 3DS Max. A Noar me permite usar esses programas no meu notebook básico como se fosse um computador top de linha. Economizei muito não precisando comprar um PC novo!'
  },
  {
    id: 10,
    name: '@rafaeditor366',
    category: 'Trabalho',
    avatar: 'https://api.dicebear.com/7.x/bottts/svg?seed=rafael.editor',
    content: 'Faço edição de vídeos em 4K e a renderização na Noar é extremamente rápida. O que levava horas no meu PC agora é feito em minutos. A possibilidade de continuar trabalhando pelo celular quando estou fora é um diferencial incrível!'
  },
  {
    id: 11,
    name: '@gabi.plays',
    category: 'Gaming/Mobile',
    avatar: 'https://api.dicebear.com/7.x/bottts/svg?seed=gabi.plays',
    content: 'Comecei a fazer streams dos meus jogos direto do celular usando a Noar. A qualidade é impressionante e não preciso me preocupar com hardware. Consigo jogar títulos AAA com gráficos no máximo, algo que seria impossível no meu celular normalmente.'
  },
  {
    id: 12,
    name: '@flash231',
    category: 'Trabalho/Windows',
    avatar: 'https://api.dicebear.com/7.x/bottts/svg?seed=ana_bi_analyst',
    content: 'Trabalho com análise de dados e preciso rodar várias planilhas pesadas e Power BI. Na Noar, consigo abrir todos os arquivos simultaneamente sem nenhum travamento. A integração com o Windows é perfeita, parece que estou usando um computador local!'
  },
  {
    id: 13,
    name: '@godlike_',
    category: 'Gaming/Mobile',
    avatar: 'https://api.dicebear.com/7.x/bottts/svg?seed=joao_fps',
    content: 'Jogo Fortnite e outros jogos competitivos pelo celular usando a Noar. O input lag é mínimo e a qualidade gráfica é excepcional. Consigo jogar em 120 FPS constantes, algo que meu celular jamais conseguiria!'
  },
  {
    id: 14,
    name: '@markflush',
    category: 'Trabalho/Windows',
    avatar: 'https://api.dicebear.com/7.x/bottts/svg?seed=marcos.dev_',
    content: 'Desenvolvo aplicações Windows e a Noar me permite testar em diferentes configurações sem precisar de várias máquinas físicas. O ambiente Windows é estável e rápido, perfeito para desenvolvimento e testes.'
  },
  {
    id: 15,
    name: '@jutapedro',
    category: 'Trabalho/Mobile',
    avatar: 'https://api.dicebear.com/7.x/bottts/svg?seed=bia.arquiteta',
    content: 'Incrível poder acessar e modificar meus projetos de arquitetura direto do iPad! A precisão do Apple Pencil funciona perfeitamente com a Noar, e a qualidade da imagem é tão boa que não sinto diferença de usar um computador local.'
  }
];

// Main Component
function Container(props) {
  const [activeTab, setActiveTab] = useState('all');

  const filteredPosts = testimonials.filter(post => {
    if (activeTab === 'all') return true;
    return post.category === activeTab;
  });

  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    centerMode: false,
    cssEase: "cubic-bezier(0.87, 0.03, 0.41, 0.9)",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '20px',
          dots: true
        }
      }
    ]
  };

  return (
    <section id="casos-sucesso" {...props}>
      <GlowBackground />
      <Header>
        <Title as="h2">
          <strong>Histórias de Sucesso</strong>
        </Title>
        <SubtitleText>
          Conheça quem já está usando a Noar
          <DiscordIcon style={{ marginLeft: '8px' }} />
        </SubtitleText>
      </Header>

      <SliderWrapper>
        <Slider {...settings}>
          {filteredPosts.map((testimonial, index) => (
            <SuccessCard key={testimonial.id}>
              <CardGlow />
              <CardImage src={testimonial.avatar} alt={testimonial.name} />
              <CardContent>
                <StarRating>
                  <Star />
                  <Star />
                  <Star />
                  <Star />
                  <Star />
                </StarRating>
                <CardCategory>{testimonial.category}</CardCategory>
                <CardTitle>{testimonial.name}</CardTitle>
                <CardDescription>{testimonial.content}</CardDescription>
              </CardContent>
            </SuccessCard>
          ))}
        </Slider>
      </SliderWrapper>
    </section>
  );
}

export const Blog = styled(Container)`
  display: flex;
  flex-direction: column;
  padding: 6rem 1.5rem;
  background: linear-gradient(135deg, #17171A 0%, #1E1E24 100%);
  width: 100%;
  position: relative;
  overflow: hidden;

  ${mqmin(desktopMinSize)} {
    padding: 8rem 2rem;
  }
`;

export default Blog;

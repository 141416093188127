import styled from 'styled-components';
import { desktopMinSize, mqmin, rem } from '../../styles/mixins';
import { colors } from '../../constants/colors';

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
`;

export const ContactTitle = styled.h3`
  font-size: 1.75rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 1.5rem;
  text-align: center;
  
  ${mqmin(desktopMinSize)} {
    font-size: 2rem;
  }
`;

export const ContactDescription = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  max-width: 800px;
  margin: 0 auto 2rem;
  
  ${mqmin(desktopMinSize)} {
    font-size: 1.125rem;
  }
  
  strong {
    color: #fff;
    font-weight: 600;
  }
`;

export const ContactButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 1.5rem;
  
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
`;

export const ContactButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  background: ${colors.gray[700]};
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  color: #fff;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease;
  min-width: 180px;
  
  &:hover {
    background: linear-gradient(90deg, #2D80F2 0%, #8742E5 100%);
    transform: translateY(-3px);
    box-shadow: 0 10px 20px -10px rgba(45, 128, 242, 0.5);
  }
  
  img {
    margin-right: 0.75rem;
    width: 24px;
    height: 24px;
  }
`; 
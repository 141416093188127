import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import PaymentCard from './PaymentCard';
import { PaymentAlert } from './PaymentAlert';
import { messageDisconnect } from '@Constants';
import { SessionExpired } from '@Errors';
import { openConfirmationLightbox } from '@Store/ducks/global/actions';
import { Loading } from '../../../../../components/Loading';
import AdressData from './AdressData';
import { sendRecurrencyCardChangeCall } from '../../../../../services/noar-api';
import MercadoPagoService from '../../../../../services/MercadoPagoService';
import { MP_PUBLIC_KEY } from '../../../../../constants';

// Definindo constantes que não estão no arquivo de constantes
const ERROR = 'ERROR';
const OK = 'OK';

// Styled components para melhorar o popup
const PopupWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const PopupOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(4px);
  z-index: 1001;
`;

const PopupContent = styled.div`
  position: relative;
  z-index: 1002;
  max-width: 520px;
  width: 100%;
  margin: 0 16px;
  animation: fadeIn 0.3s ease;
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  @media (max-width: 600px) {
    margin: 0 8px;
  }
`;

const LoadingContainer = styled.div`
  position: relative;
  z-index: 1002;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RecurrencyCardChangePopup = ({ close, recurrencyInfo }) => {
  const [viewControl, handleViewControl] = useState(0);
  const [adressInfo, handleAdressInfo] = useState({ keepAdressOption: true, changedKeepAdressOption: false });
  const [cardInfo, handleCardInfo] = useState(null);
  const [confirmPaymentReturn, handleConfirmPaymentReturn] = useState(null);
  const [returnMessage, handleReturnMessage] = useState({});
  const [loading, handleLoading] = useState(false);
  const [resultMessage, handleResultMessage] = useState(null);
  const [returnVariant, handleReturnVariant] = useState('');
  const [clientName, setClientName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [securityCode, setSecurityCode] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const paymentAlertRef = useRef();
  const dispatch = useDispatch();

  const handleSendChangeCall = async () => {
    try {
      handleLoading(true);

      if (!cardInfo) {
        throw new Error('Informações do cartão são obrigatórias');
      }

      console.log("Dados do cartão:", cardInfo); // Para debug

      // Inicializa o SDK do Mercado Pago se necessário
      if (!MercadoPagoService.isInitialized()) {
        await MercadoPagoService.initialize(MP_PUBLIC_KEY);
      }

      // Prepara os dados do cartão para tokenização
      const cardData = {
        number: cardInfo.cardNumber.replace(/\s/g, ''),
        holder: cardInfo.cardName || cardInfo.clientName,
        expiration_month: cardInfo.expirationMonth,
        expiration_year: cardInfo.expirationYear,
        security_code: cardInfo.securityCode, // Importante: incluir o CVV para validação
      };

      // Gera o token do cartão com validação do CVV
      const token = await MercadoPagoService.createCardToken(cardData);
      const deviceId = MercadoPagoService.getDeviceId();

      // Prepara o payload para o endpoint de alteração de método de pagamento
      const paymentMethodData = {
        token: token,
        device_id: deviceId,
        payer: {
          address: {
            street: adressInfo.street || "Rua Exemplo",
            number: adressInfo.number || "123",
            complement: adressInfo.complement || "",
            neighborhood: adressInfo.neighborhood || "Bairro Exemplo",
            zip_code: adressInfo.zipCode || "00000000",
            state: adressInfo.state || "SP",
            country: "BR",
            city: adressInfo.city || "São Paulo"
          }
        }
      };

      // Envia a requisição para alterar o método de pagamento
      const response = await sendRecurrencyCardChangeCall(paymentMethodData);
      
      // Para debug
      console.log("Resposta do servidor:", response);

      if (!response.ok) {
        // Tenta extrair a mensagem de erro
        let errorMessage;
        try {
          const errorData = await response.json();
          errorMessage = errorData.message || 'Falha ao atualizar o cartão';
        } catch (jsonError) {
          errorMessage = 'Falha ao processar resposta do servidor';
        }
        throw new Error(errorMessage);
      }

      // Exibe mensagem de sucesso
      handleConfirmPaymentReturn({ status: OK });
      dispatch(
        openConfirmationLightbox({
          message: 'Forma de pagamento atualizada com sucesso!',
          confirmBtnText: 'Ok',
          onClose: () => {
            closeAlerts();
            close();
            window.location.reload(); // Recarrega a página para mostrar as informações atualizadas
          },
        }),
      );
    } catch (error) {
      handleLoading(false);
      handleConfirmPaymentReturn({ status: ERROR });
      if (error instanceof SessionExpired) {
        dispatch(openConfirmationLightbox(messageDisconnect));
        return;
      }
      console.error("Erro ao atualizar cartão:", error);
      dispatch(
        openConfirmationLightbox({
          message: `Erro ao atualizar o cartão: ${error.message}`,
          confirmBtnText: 'Ok',
          onClose: () => {
            closeAlerts();
          },
        }),
      );
    } finally {
      handleLoading(false);
    }
  }

  const closeAlerts = () => {
    if (paymentAlertRef.current) {
      paymentAlertRef.current.close();
    }
    handleViewControl(0);
    close();
  };

  const handleOpenResultStatusMessage = () => {
    if (paymentAlertRef.current?.state?.isOpen) {
      paymentAlertRef.current.closeModal();
    }
    paymentAlertRef.current.showModal();
  };

  const showChangeAlert = () => {
    if (confirmPaymentReturn?.status === OK) {
      handleReturnMessage({
        title: 'Cartão Alterado',
        message: `Seu cartão de cobrança foi alterado com sucesso!`,
        variant: 'success',
      });
      handleOpenResultStatusMessage();
    } else if (confirmPaymentReturn?.status === ERROR) {
      handleReturnMessage({
        title: 'Ocorreu um erro',
        message: "Ocorreu um erro ao alterar o cartão de cobrança. Por favor, tente novamente mais tarde ou entre em contato com o suporte.",
        variant: 'denied',
      });
      handleOpenResultStatusMessage();
    }
  };

  useEffect(() => {
    if (cardInfo) {
      handleSendChangeCall();
    }
  }, [cardInfo]);

  useEffect(() => {
    showChangeAlert();
  }, [confirmPaymentReturn]);

  useEffect(() => {
    handleViewControl(1);
  }, []);

  return (
    <>
      <PopupWrapper>
        <PopupOverlay onClick={closeAlerts} />
        <PopupContent>
          {loading ? (
            <LoadingContainer>
              <Loading whichLoading={'generic'} />
            </LoadingContainer>
          ) : (
            <>
              {viewControl === 1 ? (
                <AdressData
                  close={closeAlerts}
                  handleViewControl={handleViewControl}
                  adressInfo={adressInfo}
                  handleAdressInfo={handleAdressInfo}
                  handleConfirmPaymentReturn={handleConfirmPaymentReturn}
                />
              ) : null}

              {viewControl === 2 ? (
                <PaymentCard
                  close={closeAlerts}
                  cardInfo={cardInfo}
                  handleCardInfo={handleCardInfo}
                  handleLoading={handleLoading}
                  handleViewControl={handleViewControl}
                  clientName={clientName}
                  setClientName={setClientName}
                  cardNumber={cardNumber}
                  setCardNumber={setCardNumber}
                  securityCode={securityCode}
                  setSecurityCode={setSecurityCode}
                  expirationDate={expirationDate}
                  setExpirationDate={setExpirationDate}
                />
              ) : null}
            </>
          )}
        </PopupContent>
      </PopupWrapper>
      <PaymentAlert paymentAlertRef={paymentAlertRef} close={closeAlerts} {...returnMessage} />
    </>
  );
};

export default RecurrencyCardChangePopup;

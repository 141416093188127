import React from 'react';
import MySessions from '../../components/MySessions';
import { MySessionsAccordion } from '../../components/MySessions/MySessionsAccordion';
import MobileHeader from '../../components/Header';
import { MySessionsTutorial } from '../../components/MySessionsTutorial';
import * as S from './styled';

const MySessionsPage = () => {
  return (
    <S.PageContainer>
      <MobileHeader />
      <S.ContentWrapper>
        <S.SectionTitle>Minhas Sessões</S.SectionTitle>
        <S.SessionsCard>
          <MySessionsTutorial />
          <MySessions />
          <MySessionsAccordion />
        </S.SessionsCard>
      </S.ContentWrapper>
    </S.PageContainer>
  );
};

export default MySessionsPage;

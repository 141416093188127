import { planData } from '../../AMICarrousel/data/planData';
import { ShopPageSteps } from '../../ShopPageTutorial/InitialSteps';
import { PersonalCard } from '../ShopPageCard/Personal/Index';
import UnitCard from '../ShopPageCard/Unit/Index';
import * as S from '../styled';

const PriceCardWrapper = ({
  products,
  isPlanOpen,
  setIsPlanOpen,
  havePlan,
  havePersonal,
  powerUpTab,
  isPublic,
  setIsMobileCartOpen,
  planData
}) => {
  return (
    <S.PriceCardWrapperStyled>
      {powerUpTab === 'personal' ? (
        <PersonalCard
          disableButton={havePersonal}
          products={products?.personal}
          isOpen={isPlanOpen}
          onClick={() => setIsPlanOpen('PERSONAL')}
          onRequestClose={() => setIsPlanOpen('')}
          isPublic={isPublic}
        />
      ) : powerUpTab === 'unit' ? (
        <UnitCard
          disableButton={havePlan}
          products={products?.unit?.map(product => product)}
          isOpen={isPlanOpen}
          onClick={() => setIsPlanOpen('UNIT')}
          onRequestClose={() => setIsPlanOpen('')}
          isPublic={isPublic}
          planData={planData}
        />
      ) : (
        <PersonalCard
          disableButton={havePersonal}
          products={products?.personal}
          isOpen={isPlanOpen}
          onClick={() => setIsPlanOpen('PERSONAL')}
          onRequestClose={() => setIsPlanOpen('')}
          isPublic={isPublic}
        />
      )}
      <ShopPageSteps />
    </S.PriceCardWrapperStyled>
  );
};

export default PriceCardWrapper;

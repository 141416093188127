import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Title, Subtitle, Text } from '@Components/Public';
import { desktopMinSize, mqmin, mqmax } from '@Styles/mixins';
import { 
  Speed,
  Memory,
  Wifi,
  PhoneAndroid,
  Computer
} from '@material-ui/icons';

const floatAnimation = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

const pulseAnimation = keyframes`
  0% { transform: scale(1); opacity: 0.5; }
  50% { transform: scale(1.05); opacity: 0.8; }
  100% { transform: scale(1); opacity: 0.5; }
`;

const glowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const GlowBackground = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150%;
  height: 150%;
  background: radial-gradient(circle at center, 
    rgba(0,122,255,0.15) 0%, 
    rgba(160,11,246,0.1) 30%, 
    rgba(23,23,26,0.05) 70%
  );
  pointer-events: none;
  animation: ${pulseAnimation} 8s ease-in-out infinite;
  filter: blur(60px);
`;

const RequirementIcon = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: rgba(60, 60, 68, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease;

  svg {
    width: 24px;
    height: 24px;
    color: #007AFF;
    filter: drop-shadow(0 0 10px rgba(0,122,255,0.3));
  }
`;

const Requirements = () => (
  <Section id="requisitos">
    <GlowBackground />
    <Header>
      <Title as="h2">
        <strong>Requisitos do Sistema</strong>
      </Title>
      <Subtitle>
        Confira o que você precisa para usar a Noar
      </Subtitle>
    </Header>

    <Content>
      <PlatformSection>
        <PlatformTitle>
          <Computer /> Windows
        </PlatformTitle>
        <RequirementsList>
          <RequirementItem>
            <RequirementIcon>
              <Speed />
            </RequirementIcon>
            <RequirementInfo>
              <RequirementTitle>Processador</RequirementTitle>
              <RequirementText>Dual Core 2GHz ou superior</RequirementText>
            </RequirementInfo>
          </RequirementItem>
          <RequirementItem>
            <RequirementIcon>
              <Memory />
            </RequirementIcon>
            <RequirementInfo>
              <RequirementTitle>Memória RAM</RequirementTitle>
              <RequirementText>4GB ou superior</RequirementText>
            </RequirementInfo>
          </RequirementItem>
          <RequirementItem>
            <RequirementIcon>
              <Wifi />
            </RequirementIcon>
            <RequirementInfo>
              <RequirementTitle>Internet</RequirementTitle>
              <RequirementText>15 Mbps ou superior</RequirementText>
            </RequirementInfo>
          </RequirementItem>
        </RequirementsList>
      </PlatformSection>

      <PlatformSection>
        <PlatformTitle>
          <PhoneAndroid /> Android
        </PlatformTitle>
        <RequirementsList>
          <RequirementItem>
            <RequirementIcon>
              <Speed />
            </RequirementIcon>
            <RequirementInfo>
              <RequirementTitle>Android</RequirementTitle>
              <RequirementText>Versão 8.0 ou superior</RequirementText>
            </RequirementInfo>
          </RequirementItem>
          <RequirementItem>
            <RequirementIcon>
              <Memory />
            </RequirementIcon>
            <RequirementInfo>
              <RequirementTitle>Memória RAM</RequirementTitle>
              <RequirementText>3GB ou superior</RequirementText>
            </RequirementInfo>
          </RequirementItem>
          <RequirementItem>
            <RequirementIcon>
              <Wifi />
            </RequirementIcon>
            <RequirementInfo>
              <RequirementTitle>Internet</RequirementTitle>
              <RequirementText>15 Mbps ou superior</RequirementText>
            </RequirementInfo>
          </RequirementItem>
        </RequirementsList>
      </PlatformSection>
    </Content>

    <Note>
      * Recomendamos conexão via cabo de rede para melhor experiência no Windows
    </Note>
  </Section>
);

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6rem 1rem;
  width: 100%;
  position: relative;
  overflow: hidden;
  background: linear-gradient(135deg, #17171A 0%, #1E1E24 100%);

  ${mqmin(desktopMinSize)} {
    padding: 8rem 2rem;
  }
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 4rem;
  max-width: 600px;
  position: relative;
  z-index: 1;

  ${Title} {
    margin-bottom: 1rem;
    background: linear-gradient(135deg, #fff 0%, #B4B4B4 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 60px;
      height: 3px;
      background: linear-gradient(90deg, #007AFF, #6B3AFE);
      border-radius: 2px;
    }
  }

  ${Subtitle} {
    color: #007AFF;
    font-size: 1.25rem;
    font-weight: bold;
    text-shadow: 0 0 20px rgba(0,122,255,0.3);
    

    ${mqmin(desktopMinSize)} {
      font-size: 1.5rem;
    }
  }
`;

const Content = styled.div`
  display: grid;
  gap: 3rem;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 2rem;
  position: relative;
  z-index: 1;
  
  ${mqmin(desktopMinSize)} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const PlatformSection = styled.div`
  background: rgba(41, 41, 50, 0.8);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 1rem;
  padding: 2rem;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, 
      rgba(0,122,255,0.1) 0%, 
      rgba(107,58,254,0.1) 100%
    );
    opacity: 0;
    transition: opacity 0.4s ease;
  }

  &:hover {
    transform: translateY(-10px);
    border-color: transparent;
    box-shadow: 
      0 10px 30px -10px rgba(0,0,0,0.5),
      0 0 0 1px rgba(0,122,255,0.3);

    &::before {
      opacity: 1;
    }

    ${RequirementIcon} {
      transform: scale(1.1);
      background: linear-gradient(135deg, rgba(0,122,255,0.2) 0%, rgba(107,58,254,0.2) 100%);
    }
  }
`;

const PlatformTitle = styled.h3`
  color: #fff;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: linear-gradient(135deg, #fff 0%, #B4B4B4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  svg {
    color: #007AFF;
    filter: drop-shadow(0 0 10px rgba(0,122,255,0.3));
  }
`;

const RequirementsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;
  z-index: 1;
`;

const RequirementItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateX(10px);
  }
`;

const RequirementInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const RequirementTitle = styled.h4`
  color: #fff;
  font-size: 1.125rem;
  margin: 0;
  background: linear-gradient(135deg, #fff 0%, #B4B4B4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const RequirementText = styled(Text)`
  color: #B4B4B4;
  font-size: 1rem;

  ${mqmax(desktopMinSize)} {
    text-align: left;
  }
`;

const Note = styled(Text)`
  color: #007AFF;
  font-size: 0.875rem;
  font-style: italic;
  text-align: center;
  text-shadow: 0 0 20px rgba(0,122,255,0.3);
  position: relative;
  z-index: 1;
`;

export default Requirements; 
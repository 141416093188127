import styled from 'styled-components';

import {
  overlayZIndex,
  overlayOpacity,
  desktopMinSize,
  transform,
  displayRow,
  mqmin,
  mqmax,
} from '@Styles/mixins';

export const ConfirmationLightbox = styled.div`
  display: none;
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;

  &.is--open {
    display: block;
  }

  .confirmation-lightbox {
    &__overlay {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
      backdrop-filter: blur(5px);
      z-index: 99998;
    }

    &__wrapper {
      width: 100%;
      max-width: 507px;
      min-height: 350px;
      border: 1.5px solid #FFFFFF;
      border-radius: 40px;
      background: rgba(23, 23, 26, 01);
      background-origin: border-box;
      background-clip: content-box, border-box;
      position: fixed;
      top: 50%;
      left: 50%;
      ${transform('translate(-50%,-50%);')}
      z-index: 99999;
      box-sizing: border-box;
      ${mqmax(desktopMinSize - 1)} {
        max-width: 340px;
        border-radius: 28px;
        ${transform('translate(-50%,-60%);')}
      }
    }

    &__box {
      width: 100%;
      height: 100%;
      justify-content: space-evenly;
      display: flex;
      flex-direction: column;
      grid-template-columns: 1fr;
      position: fixed;
      
      &__unit {
        row-gap: 10px;
        height: 90%
      }
    }

    &__header {
      width: 100%;
      height: auto;
      ${displayRow()}
      justify-content: flex-end;
      align-items: flex-start;
    }

    &__content {
      width: 100%;
      height: 65%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      row-gap: 5px;
      &.buttons {
        align-items: flex-start;
      }

      &__queue-status {
        height: 50%;
      }
      

    }

    &__warning {
      width: 100%;
      height: auto;
      text-align: center;
      color: #ff3838;
      font-size: calc(12 / 16 * 1rem);
      margin-top: calc(15 / 16 * 1rem);
    }

    &__text {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 80%;
      font-style: normal;
      font-size: 14px;
      color: #fff;
      text-decoration: none;
      text-align: center;
      letter-spacing: 0;
      line-height: 20px;
      ${mqmin(desktopMinSize)} {
        font-size: 18px;
      }

      e {
        font-size: 12px;
        line-height: 15px;
        font-style: normal;
      }
    }

    &__actions {
      width: 100%;
      height: auto;
      ${displayRow()}
      justify-content: center;
      align-items: center;
    }
  }

  #confirmation-lightbox__btn-close {
    width: 34px;
    height: 34px;
    border: none;
    border-radius: 0;
    font-size: 0;
    background: url('https://siteimagesbucket.s3.amazonaws.com/website/private-pages/computer/close.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    margin:20px;
    position: absolute;
    color: #fff;
  }

  #confirmation-lightbox__btn-confirm {
    max-width: 135px;
    width: 100%;
    height: 43px;
    background: transparent;
    border: 2px solid #fff;
    border-radius: 33px;
    font-size: 12px;
    color: #fff;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    transition: all 150ms;
    ${mqmin(desktopMinSize)} {
      height: 50px;
      font-size: 15px;
      line-height: 19px;
      max-width: 192px;
      font-size: 15px;
      border: 3px solid #fff;
    }

    &:hover {
      border: 3px solid #2d80f2;
      color: #2d80f2;
    }
  }

  #confirmation-lightbox__btn-refuse {
    max-width: 135px;
    width: 100%;
    height: 43px;
    background: transparent;
    border: 2px solid #fff;
    border-radius: 33px;
    font-size: 12px;
    color: #fff;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    margin-left: 12px;
    transition: all 150ms;
    ${mqmin(desktopMinSize)} {
      height: 50px;
      font-size: 15px;
      line-height: 19px;
      max-width: 192px;
      font-size: 15px;
      border: 3px solid #fff;
    }

    &:hover {
      border: 3px solid #2d80f2;
      color: #2d80f2;
    }
  }
`;

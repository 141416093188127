import React, { useState, useRef, useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import * as S from './styled';
import '@Toast/styles.css';
import { getActivatedPlans, getRecurrency } from '@Services/noar-api';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Loading } from '../../../Loading';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Text } from '../../../Public';

import select from '../../../../pages/HomePage/HowItWorks/icons/select.png';
import lendoumlivro from '../../../../pages/HomePage/HowItWorks/icons/lendoumlivro.png';
import alunograduacao from '../../../../pages/HomePage/HowItWorks/icons/alunograduacao.png';
import computacaoemnuvem from '../../../../pages/HomePage/HowItWorks/icons/computacaoemnuvem.png';
import freetag from '../../../../pages/HomePage/HowItWorks/icons/free.png';
import Payment from '../../Payment';
import { ORIGIN_UNIT } from '../../../../constants';
import { verifyPurchaseAvailability } from '../../../../services/noar-api';
import { openConfirmationLightbox } from '../../../../store/ducks/global/actions';
import PaymentComponent from '../MpPaymentBrick';
import PaymentStatusComponent from '../MpPaymentStatusBrick';
import { Close } from '@material-ui/icons';
import UnifiedPaymentComponent from '../UnifiedPaymentComponent';

const UnitCard = ({ products, isOpen, onClick, onRequestClose, disableButton, isPublic, planData }) => {
  const [showPayment, handleShowPayment] = useState(false);
  const [paymentId, setPaymentId] = useState();
  const [selectedProductTotalAmount, setSelectedProductTotalAmount] = useState(0);
  const [selectedProductList, setSelectedProductList] = useState([]);
  const [disablePaymentContent, setDisablePaymentContent] = useState(false);
  const { accountPersonalInfo } = useSelector(state => state.global);
  const modalRef = useRef();
  const history = useHistory();
  var dispatch = useDispatch();

  const goToAccessPage = () => {
    history.push('/my/home');
  };

  const closePayment = event => {
    if (event?.target !== event?.currentTarget) return;
    setPaymentId(null);
    handleShowPayment(false);
    history.push('/shop/unit');
  };

  const handlePublicRedirect = formattedProducts => {
    history.push('/login', { next: '/shop/unit', state: { formattedProducts: formattedProducts } });
  };

  async function handleOrderValidations(selectedProducts) {
    // Verifica se o usuário já tem um plano ativo
    if (Object.keys(planData).length > 0) {
      goToAccessPage();
      return;
    }

    // Se for acesso público, redireciona para login
    if (isPublic) {
      handlePublicRedirect(selectedProducts);
      return;
    }

    setDisablePaymentContent(true);

    try {
      // Verifica se o usuário tem uma recorrência ativa ou pendente
      const recurrencies = await getRecurrency();
      if (recurrencies && recurrencies.length > 0) {
        // Verificar se há alguma recorrência ativa ou pendente
        const hasActiveOrPendingRecurrency = recurrencies.some(rec => 
          rec.recurrencyStatus === 'ACTIVE' || 
          rec.recurrencyStatus === 'PENDING' || 
          rec.recurrencyStatus === 'PAYMENT_PENDING');
        
        if (hasActiveOrPendingRecurrency) {
          setDisablePaymentContent(false);
          // Exibe mensagem informando que já existe uma recorrência ativa
          dispatch(openConfirmationLightbox(
            () => {
              history.push('/my/profile');
            },
            <>Você já possui um plano Noar Personal ativo ou pendente de pagamento. Acesse sua página de perfil para verificar o status ou cancelar o plano atual antes de assinar um novo.</>,
            'Ver meu perfil',
            'Cancelar',
            null,
            null,
            false,
          ));
          return;
        }
      }

      // Verifica disponibilidade de compra (limite de tentativas)
      let availability = await verifyPurchaseAvailability()
        .then(() => true)
        .catch(() => {
          dispatch(openConfirmationLightbox(
            () => {
              history.push('/shop/unit');
            },
            <>Você excedeu o limite de tentativas recusadas, tente realizar novamente em 24 horas e verifique suas informações.</>,
            'Entendi',
            null,
            null,
            null,
            false,
          ));
          setDisablePaymentContent(false);
          return false;
        });
      
      if (!availability) return;

      // Verifica e solicita dados pessoais completos se necessário
      await accountPersonalInfo
        .showPopupValidationFunction(
          selectedProductTotalAmount,
          selectedProductList,
          setPaymentId,
          handleShowPayment,
          false,
          true,
          setDisablePaymentContent,
          true,
        )
        .then(resp => {
          setDisablePaymentContent(false);
          if (!resp) {
            handleOrder(selectedProducts);
          }
        })
        .catch(() => {
          setDisablePaymentContent(false);
        });
    } catch (error) {
      console.error("Erro ao verificar recorrências:", error);
      setDisablePaymentContent(false);
      
      // Continua com o fluxo normal em caso de erro na verificação
      // para não bloquear completamente a funcionalidade
      let availability = await verifyPurchaseAvailability()
        .then(() => true)
        .catch(() => {
          dispatch(openConfirmationLightbox(
            () => {
              history.push('/shop/unit');
            },
            <>Você excedeu o limite de tentativas recusadas, tente realizar novamente em 24 horas e verifique suas informações.</>,
            'Entendi',
            null,
            null,
            null,
            false,
          ));
          return false;
        });
      
      if (!availability) return;
      
      await accountPersonalInfo
        .showPopupValidationFunction(
          selectedProductTotalAmount,
          selectedProductList,
          setPaymentId,
          handleShowPayment,
          false,
          true,
          setDisablePaymentContent,
          true,
        )
        .then(resp => {
          setDisablePaymentContent(false);
          if (!resp) {
            handleOrder(selectedProducts);
          }
        })
        .catch(() => {
          setDisablePaymentContent(false);
        });
    }
  }

  function handleOrder(selectedProducts) {
    console.log('Products recebido:', products);
    console.log('Selected Products:', selectedProducts);
    
    const totalAmount = selectedProducts.reduce((total, selectedProduct) => {
      console.log('Processando produto:', selectedProduct);
      let foundProduct = false;
      products.forEach(product => {
        if (selectedProduct.product_id === product.product_id) {
          console.log('Produto encontrado:', product);
          selectedProduct.price = product.price * selectedProduct.quantity;
          console.log('Preço calculado:', selectedProduct.price);
          foundProduct = true;
        }
      });
      
      // Se o produto não foi encontrado, registra o erro
      if (!foundProduct) {
        console.error(`Produto ID ${selectedProduct.product_id} não encontrado na lista de produtos!`);
      }
      
      return total + (selectedProduct.price || 0);
    }, 0);

    // Garantir que o valor nunca seja NaN
    const safeAmount = isNaN(totalAmount) ? 0 : totalAmount;
    console.log('Total Amount calculado (safe):', safeAmount);
    
    setSelectedProductList(selectedProducts);
    setSelectedProductTotalAmount(safeAmount);
    handleShowPayment(true);
  }

  useEffect(() => {
    if (selectedProductList.length > 0) {
      handleOrderValidations(selectedProductList);
    }
  }, [selectedProductList])

  useEffect(() => {
    console.log('planData', planData);
  }, [planData]);

  return disablePaymentContent ? (
    <Loading whichLoading={'generic'} />
  ) : (
    <>
      <S.PlansWrapper id="planos">
        <S.ChooseWrapper>
          <S.NichePresentation>
            <S.HeaderWrapper>
              <S.NicheIconsWrapper>
                <S.NicheImage src={freetag} alt="Uma tag escrito free." />
              </S.NicheIconsWrapper>
              <S.NichePresentationTitle>
                <strong>Fila Gratuita</strong>
              </S.NichePresentationTitle>
            </S.HeaderWrapper>
            <S.ChecklistWrapper>
              <p>Utilize de graça!</p><br />
              <S.ChecklistItem>
                <S.ChecklistItemImage src={select} alt="Select marcado." />
                <p>Acesse super computadores de forma gratuita.</p>
              </S.ChecklistItem>
              <S.ChecklistItem>
                <S.ChecklistItemImage src={select} alt="Select marcado." />
                <p>Até 30 minutos de sessão.</p>
              </S.ChecklistItem>
              <S.ChecklistItem>
                <S.ChecklistItemImage src={select} alt="Select marcado." />
                <p>Quer testar a tecnologia?</p>
              </S.ChecklistItem>
            </S.ChecklistWrapper>
            <S.NichePresentationSubTitle>
              <p>
                Utilize nossos supercomputadores de forma gratuita por até 30 minutos. Ideal para quem quer testar a tecnologia e entender como funciona.
              </p>
            </S.NichePresentationSubTitle>
            <Button variant="contained" disabled={Object.keys(planData).length > 0} home_modal={true} onClick={goToAccessPage}>
              {
                Object.keys(planData).length > 0 ? '-' : 'Acessar'
              }
            </Button>
          </S.NichePresentation>
          <S.NichePresentation>
            <S.HeaderWrapper>
              <S.NicheIconsWrapper>
                <S.NicheImage src={alunograduacao} alt="" />
              </S.NicheIconsWrapper>
              <S.NichePresentationTitle>
                <strong>Basic</strong>
              </S.NichePresentationTitle>
            </S.HeaderWrapper>
            <S.ChecklistWrapper>
              <p>O plano mais simples!</p><br />
              <S.ChecklistItem>
                <S.ChecklistItemImage src={select} alt="Select marcado." />
                <p>Acesso garantido antes do Gratuito!</p>
              </S.ChecklistItem>
              <S.ChecklistItem>
                <S.ChecklistItemImage src={select} alt="Select marcado." />
                <p>3 horas de sessão.</p>
              </S.ChecklistItem>
            </S.ChecklistWrapper>
            <Text>
              <S.PriceText>
                <strong><em>R$89,90 <a>/mês</a></em></strong>
              </S.PriceText>
            </Text>
            <Button variant="contained" disabled={Object.keys(planData).length > 0 && planData?.plan_type !== 'BASIC' && planData?.plan_type !== 'DEPRIORITIZED_BASIC'} home_modal={true} onClick={() => handleOrderValidations([{ product_id: 49, quantity: 1 }])}>
              {Object.keys(planData).length > 0
                ? planData.plan_type === 'BASIC' || planData.plan_type === 'DEPRIORITIZED_BASIC'
                  ? 'ACESSAR'
                  : '-'
                : 'Assinar'
              }
            </Button>
          </S.NichePresentation>
          <S.NichePresentation>
            <S.HeaderWrapper>
              <S.NicheIconsWrapper>
                <S.NicheImage src={lendoumlivro} alt="Pessoa lendo um livro." />
              </S.NicheIconsWrapper>
              <S.NichePresentationTitle>
                <strong>Plus</strong>
              </S.NichePresentationTitle>
            </S.HeaderWrapper>
            <S.ChecklistWrapper>
              <p>O melhor custo benefício!</p><br />
              <S.ChecklistItem>
                <S.ChecklistItemImage src={select} alt="Select marcado." />
                <p>Acesso preferencial, acima do plano Basic.</p>
              </S.ChecklistItem>
              <S.ChecklistItem>
                <S.ChecklistItemImage src={select} alt="Select marcado." />
                <p>4 horas de sessão.</p>
              </S.ChecklistItem>
            </S.ChecklistWrapper>
            <Text>
              <S.PriceText>
                <strong><em>R$129,90 <a>/mês</a></em></strong>
              </S.PriceText>
            </Text>
            <Button variant="contained" disabled={Object.keys(planData).length > 0 && planData?.plan_type !== 'PLUS' && planData?.plan_type !== 'DEPRIORITIZED_PLUS'} home_modal={true} onClick={() => handleOrderValidations([{ product_id: 50, quantity: 1 }])}>
              {Object.keys(planData).length > 0
                ? planData.plan_type === 'PLUS' || planData.plan_type === 'DEPRIORITIZED_PLUS'
                  ? 'ACESSAR'
                  : '-'
                : 'Assinar'
              }
            </Button>
          </S.NichePresentation>
          <S.NichePresentation>
            <S.HeaderWrapper>
              <S.NicheIconsWrapper>
                <S.NicheImage src={computacaoemnuvem} alt="" />
              </S.NicheIconsWrapper>
              <S.NichePresentationTitle>
                <strong>ULTRA</strong>
              </S.NichePresentationTitle>
            </S.HeaderWrapper>
            <S.ChecklistWrapper>
              <p>O seu melhor plano!</p><br />
              <S.ChecklistItem>
                <S.ChecklistItemImage src={select} alt="Select marcado." />
                <p>Acesso exclusivo com máxima prioridade!</p>
              </S.ChecklistItem>
              <S.ChecklistItem>
                <S.ChecklistItemImage src={select} alt="Select marcado." />
                <p>5 horas de sessão.</p>
              </S.ChecklistItem>
            </S.ChecklistWrapper>
            <Text>
              <S.PriceText>
                <strong><em>R$169,90 <a>/mês</a></em></strong>
              </S.PriceText>
            </Text>
            <Button variant="contained" disabled={Object.keys(planData).length > 0 && planData?.plan_type !== 'PRO' && planData?.plan_type !== 'DEPRIORITIZED_PRO'} home_modal={true} onClick={() => {
              console.log('Clicou no plano ULTRA - products:', products);
              handleOrderValidations([{ product_id: 51, quantity: 1 }]);
            }}>
              {Object.keys(planData).length > 0
                ? planData.plan_type === 'PRO' || planData.plan_type === 'DEPRIORITIZED_PRO'
                  ? 'ACESSAR'
                  : '-'
                : 'Assinar'
              }
            </Button>
          </S.NichePresentation>
        </S.ChooseWrapper>
        <S.Main>
          <S.Observation>
            <p>*Quando sua sessão terminar, você pode utilizar novamente.</p>
            <p><strong>Prioridade na fila:</strong> Gratuito ➔ Basic ➔ Plus ➔ Ultra </p>
          </S.Observation>
        </S.Main>
      </S.PlansWrapper >
      {showPayment && !disablePaymentContent ? (
        <S.PaymentBrickBackGround showPayment={showPayment} onClick={()=>{}}>
          <S.PaymentBrickWrapper showPayment={showPayment}>
            <S.CloseButton>
              <Close onClick={closePayment} />
            </S.CloseButton>
            {!paymentId ? (
              <UnifiedPaymentComponent
                amount={selectedProductTotalAmount}
                selectedProductList={selectedProductList}
                setPaymentId={setPaymentId}
                handleShowPayment={handleShowPayment}
                setDisablePaymentContent={setDisablePaymentContent}
                recurrent={true}
                discountVoucher={undefined}
              />
            ) : (
              <PaymentStatusComponent paymentId={paymentId} productLink="/shop/unit" />
            )}
          </S.PaymentBrickWrapper>
        </S.PaymentBrickBackGround>
      ) : null}
    </>
  );
};

export default UnitCard;

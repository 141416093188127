import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Card, Typography, Button } from '@material-ui/core';
import { mqmax, mqmin } from '../../../styles/mixins';
import { Title, Text } from '@Components/Public';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Plans = () => {
  const [showPlanForm, setShowPlanForm] = useState(false);
  const history = useHistory();

  const plans = [
    {
      name: 'TESTE',
      subtitle: 'Teste agora!',
      price: 'Grátis',
      features: [
        'Teste nossos super computadores de forma gratuita',
        'Até 30 minutos de sessão',
        'Ideal para quem quer testar a tecnologia',
        'Possui Spot ou desligamentos',
        'Acesso limitado',
      ],
      description: 'Quer testar a tecnologia? Utilize nossos supercomputadores de forma gratuita por até 30 minutos.',
      buttonText: 'Começar Agora',
      highlight: false,
      gradient: 'linear-gradient(135deg, #2D80F2 0%, #00C2FF 100%)',
    },
    {
      name: 'BASIC',
      subtitle: 'Ideal para sessões mais curtas!',
      price: 'R$89,90',
      period: '/mês',
      features: [
        // '20 git ritárias totais',
        'Acesso garantido antes do Gratuito',
        '3 horas de sessão',
        'Acesso ILIMITADO',
        'Ideal para usuários pontuais',
        // '2 horas de sessão pós prioridade',
      ],
      buttonText: 'Assinar Basic',
      highlight: false,
      gradient: 'linear-gradient(135deg, #2D80F2 0%, #2D4BF2 100%)',
    },
    {
      name: 'PLUS',
      subtitle: 'O melhor custo benefício!',
      price: 'R$129,90',
      period: '/mês',
      features: [
        // '40 horas prioritárias totais',
        'Acesso preferencial, acima do plano Basic',
        '4 horas de sessão',
        'Acesso ILIMITADO',
        'Ideal para usuários que necessitam de sessões prolongadas',
        // '2 horas de sessão pós prioridade',
      ],
      buttonText: 'Assinar Plus',
      highlight: true,
      gradient: 'linear-gradient(135deg, #2D80F2 0%, #7B2DF2 100%)',
    },
    {
      name: 'ULTRA',
      subtitle: 'O melhor plano disponivel!',
      price: 'R$169,90',
      period: '/mês',
      features: [
        // '60 horas prioritárias totais',
        'Acesso exclusivo com máxima prioridade',
        '5 horas de sessão',
        'Acesso ILIMITADO',
        'Ideal para usuário hardcore',
        // '2 horas de sessão pós prioridade',
      ],
      buttonText: 'Assinar Ultra',
      highlight: false,
      gradient: 'linear-gradient(135deg, #7B2DF2 0%, #D92DF2 100%)',
    },
  ];

  function handleOrder(id, quantity) {
    const product = [
      {
        product_id: id,
        quantity,
        recurrent: true
      },
    ];
    history.push('/login', { next: '/shop/unit', state: { formattedProducts: product } });
  }

  return (
    <Section id="planos">
      <BackgroundGlow />
      <ContentWrapper>
        <Title as="h2">Planos com acesso ILIMITADO</Title>
        <SectionSubtitle>
          Escolha o plano ideal para suas necessidades
        </SectionSubtitle>
        <CardsContainer>
          {plans.map((plan) => (
            <PlanCard key={plan.name} highlight={plan.highlight}>
              <CardGlow gradient={plan.gradient} />
              <CardContent>
                <CardHeader>
                  <PlanName variant="h3">{plan.name}</PlanName>
                  <PlanSubtitle>{plan.subtitle}</PlanSubtitle>
                </CardHeader>
                <PriceContainer>
                  <Price>{plan.price}</Price>
                  {plan.period && <Period>{plan.period}</Period>}
                </PriceContainer>
                <FeaturesList>
                  {plan.features.map((feature, index) => (
                    <Feature key={index}>
                      <FeatureIcon />
                      {feature}
                    </Feature>
                  ))}
                </FeaturesList>
                <ActionButton 
                  variant="contained" 
                  color="primary" 
                  onClick={() => handleOrder(
                    plan.name === 'FREE' ? 49 : 
                    plan.name === 'BASIC' ? 50 : 
                    plan.name === 'PLUS' ? 51 : 52, 
                    1
                  )}
                >
                  {plan.buttonText}
                </ActionButton>
              </CardContent>
            </PlanCard>
          ))}
        </CardsContainer>
        <PriorityNote>
          <Typography variant="subtitle1">
            <strong>Ordem de prioridade:</strong> Gratuito ➔ Basic ➔ Plus ➔ Ultra
          </Typography>
          <Typography variant="body2">
            *Teste os serviços e tire suas duvidas antes de comprar.
          </Typography>
        </PriorityNote>
      </ContentWrapper>
    </Section>
  );
};

export default Plans;

const pulseAnimation = keyframes`
  0% { transform: scale(1); opacity: 0.5; }
  50% { transform: scale(1.05); opacity: 0.8; }
  100% { transform: scale(1); opacity: 0.5; }
`;

const floatAnimation = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

const glowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 4rem 1rem;
  position: relative;
  overflow: hidden;
  background: linear-gradient(135deg, #17171A 0%, #1E1E24 100%);

  ${mqmin(768)} {
    padding: 6rem 1.5rem;
  }
`;

const BackgroundGlow = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150%;
  height: 150%;
  background: radial-gradient(circle at center, 
    rgba(45, 128, 242, 0.15) 0%, 
    rgba(123, 45, 242, 0.1) 30%, 
    rgba(23, 23, 26, 0.05) 70%
  );
  pointer-events: none;
  animation: ${pulseAnimation} 8s ease-in-out infinite;
  filter: blur(60px);
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 0;

  ${mqmin(768)} {
    padding: 0;
  }
`;

const SectionSubtitle = styled(Typography)`
  color: #007AFF;
  font-size: 1.2rem;
  font-weight: bold;
  text-shadow: 0 0 20px rgba(0,122,255,0.3);
  margin: 1.5rem 0 4rem;
  
  ${mqmax('768px')} {
    margin: 1rem 0 3rem;
    font-size: 1rem;
  }
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  width: 100%;
  padding: 2rem 0;

  ${mqmin(768)} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${mqmin(1200)} {
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
    max-width: 1000px;
  }
`;

const CardGlow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.gradient};
  opacity: 0;
  transition: opacity 0.3s ease;
  filter: blur(20px);
`;

const PlanCard = styled.div`
  position: relative;
  background: rgba(41, 41, 50, 0.8);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.75rem;
  padding: 0.75rem;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  overflow: hidden;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;

  ${mqmin(768)} {
    padding: 1rem;
  }

  ${props => props.highlight && `
    border-color: rgba(0, 122, 255, 0.3);
    transform: scale();
    z-index: 1;
    
    &:hover {
      transform: translateY(-3px) scale(1.03);
    }

    ${mqmax(768)} {
      transform: scale(1);
      &:hover {
        transform: translateY(-3px) scale(1);
      }
    }
  `}

  &:hover {
    transform: translateY(-3px);
    border-color: rgba(255, 255, 255, 0.2);

    &::before {
      opacity: 1;
    }

    ${CardGlow} {
      opacity: 0.1;
    }
  }
`;

const CardContent = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const CardHeader = styled.div`
  margin-bottom: 0.75rem;
`;

const PlanName = styled(Typography)`
  font-size: 0.875rem;
  font-weight: bold;
  background: linear-gradient(135deg, #fff 0%, #B4B4B4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  letter-spacing: 0.3px;

  ${mqmin(768)} {
    font-size: 1rem;
  }
`;

const PlanSubtitle = styled(Typography)`
  color: #007AFF;
  font-size: 0.625rem;
  font-weight: 500;

  ${mqmin(768)} {
    font-size: 0.75rem;
  }
`;

const PriceContainer = styled.div`
  margin-bottom: 1rem;
`;

const Price = styled.span`
  font-size: 1.125rem;
  font-weight: bold;
  background: linear-gradient(135deg, #007AFF 0%, #6B3AFE 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  margin-right: 0.125rem;

  ${mqmin(768)} {
    font-size: 1.25rem;
  }
`;

const Period = styled.span`
  color: #B4B4B4;
  font-size: 0.625rem;
  opacity: 0.8;

  ${mqmin(768)} {
    font-size: 0.75rem;
  }
`;

const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 1rem;
  flex-grow: 1;
  text-align: left;
`;

const Feature = styled.li`
  display: flex;
  align-items: flex-start;
  color: #B4B4B4;
  font-size: 0.625rem;
  margin-bottom: 0.5rem;
  line-height: 1.3;
  opacity: 0.9;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    opacity: 1;
  }

  ${mqmin(768)} {
    font-size: 0.75rem;
    margin-bottom: 0.625rem;
  }
`;

const FeatureIcon = styled.span`
  width: 12px;
  height: 12px;
  min-width: 12px;
  margin-right: 0.375rem;
  margin-top: 0.125rem;
  background: linear-gradient(135deg, #007AFF 0%, #6B3AFE 100%);
  border-radius: 50%;
  position: relative;

  ${mqmin(768)} {
    width: 14px;
    height: 14px;
    min-width: 14px;
    margin-right: 0.5rem;
  }

  &::before {
    content: '✓';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 8px;

    ${mqmin(768)} {
      font-size: 9px;
    }
  }
`;

const ActionButton = styled(Button)`
  && {
    background: linear-gradient(135deg, #007AFF 0%, #6B3AFE 100%);
    color: #fff;
    font-weight: 600;
    padding: 0.5rem 0.75rem;
    border-radius: 0.5rem;
    text-transform: none;
    font-size: 0.625rem;
    transition: all 0.3s ease;
    border: none;
    width: 100%;
    letter-spacing: 0.3px;

    ${mqmin(768)} {
      padding: 0.625rem 1rem;
      font-size: 0.75rem;
    }

    &:hover {
      background: linear-gradient(135deg, #0062CC 0%, #5B32D6 100%);
      transform: translateY(-2px);
      box-shadow: 0 4px 12px rgba(0, 122, 255, 0.2);
    }
  }
`;

const PriorityNote = styled.div`
  margin-top: 2rem;
  color: #B4B4B4;
  max-width: 800px;
  font-size: 0.875rem;
  padding: 0 1rem;

  ${mqmin(768)} {
    font-size: 1rem;
    padding: 0;
  }

  strong {
    color: #fff;
  }
`;

import styled from "styled-components";
import { colors } from "../../../constants/colors";

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1.5rem;

    @media (max-width: 768px) {
        gap: 1.25rem;
    }

    @media (max-width: 480px) {
        gap: 1rem;
    }
`;

export const ContentParagraph = styled.p`
    font-weight: 200;
    font-size: 18px;
    line-height: 150.5%;

    @media (max-width: 768px) {
        font-size: 16px;
        line-height: 145%;
    }

    @media (max-width: 480px) {
        font-size: 15px;
        line-height: 140%;
    }

    & > strong {
        font-weight: 600;
    }

    & > a {
        color: ${colors.primary};
        text-decoration: none;
        transition: opacity 0.3s ease;

        &:hover {
            opacity: 0.8;
        }
    }
`;

export const ContactButton = styled.button`
    background: ${colors.primary};
    color: ${colors.white};
    border: none;
    padding: 1rem 2rem;
    border-radius: 100px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: transform 0.3s ease, background 0.3s ease;

    @media (max-width: 768px) {
        padding: 0.875rem 1.75rem;
        font-size: 1rem;
    }

    @media (max-width: 480px) {
        padding: 0.75rem 1.5rem;
        font-size: 0.95rem;
        width: 100%;
    }

    &:hover {
        transform: translateY(-3px);
        background: ${colors.gradients.primary};
    }

    &:active {
        transform: translateY(0);
    }
`;

export const SocialContainerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.5rem;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const SocialIconsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;

    @media (max-width: 768px) {
        width: 100%;
        justify-content: center;
    }
`;

export const SocialIcon = styled.a`
    color: ${colors.primary};
    font-size: 1.5rem;
    transition: transform 0.3s ease, color 0.3s ease;

    &:hover {
        transform: translateY(-2px);
        color: ${colors.gradients.primary};
    }

    @media (max-width: 480px) {
        font-size: 1.25rem;
    }
`;
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Title, Subtitle, Text } from '@Components/Public';
import { desktopMinSize, mqmin } from '@Styles/mixins';
import { 
  Email, 
  Help
} from '@material-ui/icons';
import { FaDiscord } from 'react-icons/fa';

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
`;

const floatAnimation = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const Container = props => (
  <section id="contato" {...props}>
    <GlowBackground />
    <Header>
      <Title as="h2">
        <strong>Precisa de ajuda?</strong>
      </Title>
      <Subtitle>
        Estamos aqui para te ajudar. Escolha a melhor forma de contato
      </Subtitle>
    </Header>

    <Grid>
      <ContactCard>
        <CardIcon>
          <Email />
        </CardIcon>
        <CardTitle>Email</CardTitle>
        <CardDescription>
          Envie suas dúvidas por email
        </CardDescription>
        <ContactButton 
          href="mailto:suporte@noarcloud.com"
        >
          Enviar email
        </ContactButton>
      </ContactCard>

      <ContactCard>
        <CardIcon>
          <Help />
        </CardIcon>
        <CardTitle>FAQ</CardTitle>
        <CardDescription>
          Encontre respostas rápidas
        </CardDescription>
        <ContactButton 
          href="/faq"
        >
          Ver perguntas
        </ContactButton>
      </ContactCard>

      <ContactCard>
        <CardIcon>
          <FaDiscord />
        </CardIcon>
        <CardTitle>Comunidade</CardTitle>
        <CardDescription>
          Participe da nossa comunidade
        </CardDescription>
        <ContactButton 
          href="https://discord.com/invite/noar-798022821342609418"
          target="_blank"
          rel="noopener noreferrer"
        >
          Acessar Discord
        </ContactButton>
      </ContactCard>
    </Grid>

    <SupportInfo>
      <SupportTitle>Horário de Atendimento</SupportTitle>
      <SupportText>
        Segunda a Sexta: 9h às 18h<br />
        Sábado: 9h às 13h
      </SupportText>
      <SupportNote>
        *Suporte técnico disponível 24/7 para clientes com plano empresarial
      </SupportNote>
    </SupportInfo>
  </section>
);

export const Contact = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 4rem 1.5rem;
  position: relative;
  overflow: hidden;
  background: linear-gradient(135deg, #17171A 0%, #1E1E24 100%);

  ${mqmin(desktopMinSize)} {
    padding: 8rem 2rem;
  }
`;

const GlowBackground = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150%;
  height: 150%;
  background: radial-gradient(circle at center, 
    rgba(0,122,255,0.15) 0%, 
    rgba(160,11,246,0.1) 30%, 
    rgba(23,23,26,0.05) 70%
  );
  pointer-events: none;
  animation: ${pulseAnimation} 8s ease-in-out infinite;
  filter: blur(60px);
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 3rem;
  position: relative;
  z-index: 1;

  ${Title} {
    margin-bottom: 1rem;
    background: linear-gradient(135deg, #fff 0%, #B4B4B4 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  ${Subtitle} {
    color: #B4B4B4;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;

  ${mqmin(desktopMinSize)} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const ContactCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background: #292932;
  border-radius: 1rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.4);
  }
`;

const CardIcon = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3C3C44;
  border-radius: 50%;
  margin-bottom: 1.5rem;

  svg {
    font-size: 30px;
    color: #007AFF;
  }
`;

const CardTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #fff;
`;

const CardDescription = styled.p`
  text-align: center;
  color: #B4B4B4;
  margin-bottom: 1.5rem;
`;

const ContactButton = styled.a`
  padding: 0.8rem 1.5rem;
  background: #007AFF;
  color: white;
  border-radius: 2rem;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;

  &:hover {
    background: #0056B3;
    transform: scale(1.05);
  }
`;

const SupportInfo = styled.div`
  text-align: center;
  margin-top: 4rem;
  padding: 2rem;
  background: #292932;
  border-radius: 1rem;
  max-width: 600px;
  position: relative;
  z-index: 1;
  border: 1px solid rgba(255, 255, 255, 0.1);
`;

const SupportTitle = styled.h4`
  font-size: 1.2rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 1rem;
`;

const SupportText = styled.p`
  color: #B4B4B4;
  line-height: 1.6;
  margin-bottom: 1rem;
`;

const SupportNote = styled.p`
  color: #007AFF;
  font-size: 0.9rem;
  font-style: italic;
`;

export default Contact;

import { Button as MuiButton, IconButton as MuiIconButton } from '@material-ui/core';
import { Close as MuiCloseIcon, Menu as MuiMenuIcon } from '@material-ui/icons';
import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '@Components/Public';

function Div({useScroll, setActualPath}, props) {
  const { handleCloseClick, handleOpenClick, ref } = useDialog();
  const [showBusinessSubmenu, setShowBusinessSubmenu] = useState(false);
  const history = useHistory();

  const handleBusinessClick = () => {
    setShowBusinessSubmenu(!showBusinessSubmenu);
  };

  // Função para lidar com o redirecionamento dinâmico do FAQ
  const handleFaqClick = (e) => {
    e.preventDefault();
    
    // Mapeia os caminhos das páginas para suas respectivas seções de FAQ
    const faqMappings = {
      '/': '/faq', // Na página inicial, vai para a página FAQ completa
      '/cloud-gaming': '#faq', // Na página Cloud Gaming, rola para a seção FAQ da própria página
      '/desktop-as-a-service': '#faq', // Na página DaaS, rola para a seção FAQ da própria página
      '/computador-na-nuvem': '#faq', // Na página Computador na Nuvem, rola para a seção FAQ da própria página
      '/for-business': '#faq' // Na página For Business, rola para a seção FAQ da própria página
    };
    
    const currentPath = window.location.pathname;
    const targetFaqPath = faqMappings[currentPath] || '/faq'; // Default para a página FAQ se não estiver mapeado
    
    handleCloseClick(); // Fechar o menu mobile
    
    if (targetFaqPath.startsWith('#')) {
      // Se for um hash, role para a seção na página atual
      const element = document.querySelector(targetFaqPath);
      if (element) {
        const headerOffset = 80; // Altura do header mobile
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        setTimeout(() => {
          const finalPosition = element.getBoundingClientRect().top + window.pageYOffset;
          if (element.getBoundingClientRect().top < headerOffset) {
            const offsetPosition = finalPosition - headerOffset;
            window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
          }
        }, 100);
      } else {
        // Se a seção não for encontrada, redirecione para a página FAQ
        history.push('/faq');
      }
    } else {
      // Se for um caminho, navegue para essa página
      history.push(targetFaqPath);
    }
  };

  const handleSubMenuItemClick = async (event, path) => {
    setActualPath('');
    handleCloseClick();
    
    if (!event) {
      return;
    }
    
    event.preventDefault();

    if (window.location.pathname !== '/for-business') {
      await history.push('/for-business');
      
      setTimeout(() => {
        scrollToElement(path);
      }, 500);
    } else {
      scrollToElement(path);
    }
  };

  const scrollToElement = (path) => {
    const element = document.querySelector(path);
    
    if (element) {
      const headerOffset = 80; // Altura do header mobile

      try {
        element.style.scrollMarginTop = `${headerOffset}px`;
        
        element.scrollIntoView({ 
          behavior: 'smooth',
          block: 'start'
        });
        
        setTimeout(() => {
          const finalPosition = element.getBoundingClientRect().top + window.pageYOffset;
          
          if (element.getBoundingClientRect().top < headerOffset) {
            const offsetPosition = finalPosition - headerOffset;
            
            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth'
            });
          }
        }, 100);
        
      } catch (error) {
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - headerOffset;
        
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }
  };

  // Adiciona listener para monitorar mudanças no scroll
  useEffect(() => {
    const handleScroll = () => {};

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div {...props}>
      <Button component={Link} variant="all-white" to="/login">
        Acesse
      </Button>
      <MuiIconButton aria-label="Abrir menu" onClick={handleOpenClick}>
        <MenuIcon />
      </MuiIconButton>
      <Menu {...{ ref }} style={{ zIndex: 1300 }}>
        <OptionsWrapper>
          <MuiIconButton aria-label="Fechar menu" onClick={handleCloseClick}>
            <CloseIcon />
          </MuiIconButton>
          <Links>
            {links.map(({ path, text }) =>
              path === '/for-business' ? (
                <div key={path}>
                  <TextButton 
                    onClick={handleBusinessClick}
                    onTouchStart={(e) => e.preventDefault()}
                  >
                    {text} {showBusinessSubmenu ? '▼' : '▶'}
                  </TextButton>
                  {showBusinessSubmenu && (
                    <SubMenu>
                      {/* 1. Infraestrutura de Servidores */}
                      <SubMenuTitle>Infraestrutura de Servidores</SubMenuTitle>
                      <SubMenuItem onClick={(e) => handleSubMenuItemClick(e, '#servidores')}>
                        Servidores Dedicados de Alta Performance
                      </SubMenuItem>
                      <SubMenuItem onClick={(e) => handleSubMenuItemClick(e, '#windows-server')}>
                        Windows Server
                      </SubMenuItem>
                      <SubMenuItem onClick={(e) => handleSubMenuItemClick(e, '#daas-specialized')}>
                        DaaS de Alto Desempenho
                      </SubMenuItem>

                      {/* 2. Desenvolvimento de Software */}
                      <SubMenuTitle>Desenvolvimento de Software</SubMenuTitle>
                      <SubMenuItem onClick={(e) => handleSubMenuItemClick(e, '#desenvolvimento-completo')}>
                        Desenvolvimento de Software Completo
                      </SubMenuItem>
                      <SubMenuItem onClick={(e) => handleSubMenuItemClick(e, '#desenvolvimento')}>
                        Plataforma de Desenvolvimento
                      </SubMenuItem>

                      {/* 3. Rede e Segurança */}
                      <SubMenuTitle>Rede e Segurança</SubMenuTitle>
                      <SubMenuItem onClick={(e) => handleSubMenuItemClick(e, '#cloudflare')}>
                        Implantação CloudFlare
                      </SubMenuItem>
                      <SubMenuItem onClick={(e) => handleSubMenuItemClick(e, '#rede')}>
                        Serviços de Rede
                      </SubMenuItem>
                      <SubMenuItem onClick={(e) => handleSubMenuItemClick(e, '#seguranca')}>
                        Segurança de Rede e Zero Trust
                      </SubMenuItem>
                      <SubMenuItem onClick={(e) => handleSubMenuItemClick(e, '#dominio')}>
                        Serviço de Domínio
                      </SubMenuItem>

                      {/* 4. Armazenamento e Mídia */}
                      <SubMenuTitle>Armazenamento e Mídia</SubMenuTitle>
                      <SubMenuItem onClick={(e) => handleSubMenuItemClick(e, '#armazenamento')}>
                        Serviços de Armazenamento e Mídia
                      </SubMenuItem>

                      {/* 5. Serviços Gerenciados e Suporte */}
                      <SubMenuTitle>Serviços Gerenciados e Suporte</SubMenuTitle>
                      <SubMenuItem onClick={(e) => handleSubMenuItemClick(e, '#cloud-management')}>
                        Gerenciamento de Cloud
                      </SubMenuItem>
                      <SubMenuItem onClick={(e) => handleSubMenuItemClick(e, '#helpdesk')}>
                        Suporte Remoto (HelpDesk)
                      </SubMenuItem>
                    </SubMenu>
                  )}
                </div>
              ) : path === 'dynamic-faq' ? (
                <TextButton 
                  key="faq"
                  onClick={handleFaqClick}
                  onTouchStart={(e) => e.preventDefault()}
                >
                  {text}
                </TextButton>
              ) : path.startsWith('#') ? (
                <TextButton 
                  key={path}
                  onClick={useScroll(path, setActualPath, handleCloseClick)}
                  href={path}
                  onTouchStart={(e) => e.preventDefault()}
                >
                  {text}
                </TextButton>
              ) : (
                <TextButton
                  component={NavLink}
                  key={path}
                  to={path}
                  onClick={() => {
                    setActualPath("");
                    handleCloseClick();
                  }}
                >
                  {text}
                </TextButton>
              )
            )}
          </Links>
          <Links />
        </OptionsWrapper>
        <ButtonWrapper>
          <Button component={Link} variant="all-white" to="/login">
            Acesse
          </Button>
        </ButtonWrapper>
      </Menu>
    </div>
  );
}

function useDialog() {
  const ref = useRef(null);

  const handleOpenClick = useCallback(
    function () {
      const dialog = ref.current;

      if (!dialog) return;

      dialog.showModal();
    },
    [ref],
  );

  const handleCloseClick = useCallback(
    function () {
      const dialog = ref.current;

      if (!dialog) return;

      dialog.close();
    },
    [ref],
  );

  return { handleCloseClick, handleOpenClick, ref };
}

const links = [
  { path: '#planos', text: 'Nossos planos' },
  { path: '#download', text: 'Baixar' },
  { path: '#como-usar', text: 'Como usar?' },
  { path: '/for-business', text: 'Para Empresas' },
  { path: 'dynamic-faq', text: 'FAQ' },
];

const TextButton = styled(MuiButton)`
  &.MuiButton-root {
    justify-content: flex-end;
    width: 100%;
    text-align: right;
    color: #fff;
    font-size: calc(16rem / 16);
    font-weight: normal;
    line-height: ${28 / 17};
    text-transform: none;
    transition: color 300ms ease-out;
    margin-top: calc(20rem / 16);
    margin-right: 10px;
    padding-right: 16px;

    &:hover,
    &.active {
      color: #2d80f2;
    }

    &:hover {
      background: none;
    }
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;

  ${Links} {
    margin-top: calc(16rem / 16);
    width: 100%;
  }
`;

const MenuIcon = styled(MuiMenuIcon)`
  color: #fff;
`;

const CloseIcon = styled(MuiCloseIcon)`
  color: #fff;
`;

const Menu = styled.dialog`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
  box-sizing: border-box;
  height: 100%;
  min-width: 250px;
  max-width: 80vw; // Limita a largura máxima em telas menores
  max-height: none;
  border: 0;
  border-radius: 24px 0px 0px 24px;
  position: fixed;
  inset: 0 0 auto auto;
  z-index: 1300; // Garante que fique acima de outros elementos
  overflow: auto;
  background: rgba(23, 23, 26, 0.9); // Aumentei a opacidade para melhor visibilidade
  box-shadow: 0px 4px 4px #000000;
  backdrop-filter: blur(5px);
  transform: translateX(100%);
  transition: transform calc(1s / 3) ease-in-out;
  overscroll-behavior: contain; // Previne scroll indesejado

  &[open] {
    transform: translateX(0);
  }
`;

const ButtonWrapper = styled.div`
  margin-right: 10px;
`;

const SubMenu = styled.div`
  padding-left: 20px;
  overscroll-behavior: contain; // Previne scroll indesejado
`;

const SubMenuTitle = styled.div`
  color: #2d80f2;
  font-weight: bold;
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 14px;
`;

const SubMenuItem = styled.div`
  color: #B4B7C0;
  padding: 8px 16px;
  font-size: 13px;
  cursor: pointer;
  transition: color 0.2s;

  &:hover {
    color: #ffffff;
  }
`;

export const Mobile = styled(Div)``;

export default Mobile;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect, useHistory } from 'react-router-dom';

import { REDIRECT_TO_MAINTENANCE, REDIRECT_TO_LOGIN, messageDisconnect } from '@Constants';
import { loadSession, openConfirmationLightbox } from '@Store/ducks/global/actions';
import * as sessionManager from '@Utils/session-manager';

import * as S from './styled';

import PrivateNavigation from '@Containers/PrivateNavigation';

import { instanceSocket } from '@Services/noar-api';
import clientHoursWebsocketUpdate from '@Functions/client-hours-websocket-update';
import computerWebsocketUpdate from '@Functions/computer-websocket-update';
import queueWebsocketUpdate from '@Functions/queue-websocket-update';
import personalStatusWebsocketUpdate from '@Functions/personal-status-websocket-update';
import { updateClientHours, setPersonalPlan } from '@Store/ducks/global/actions';
import { SessionExpired } from '@Errors';
import {
  updateComputer,
  updateQueue,
  setComputerWebsocketOn,
  setComputerDoesntLoading
} from '@Store/ducks/computer/actions';

const PrivateView = ({ isLogged, pages, maintenance }) => {
  const { computerWebsocketOn } = useSelector(state => state.computer);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    let isMounted = true;

    const initializeWebSocket = async () => {
      try {
        dispatch(loadSession());
        if (!computerWebsocketOn && isMounted) {
          await instanceSocket(
            clientHoursWebsocketUpdate.bind(null, dispatch, updateClientHours),
            computerWebsocketUpdate.bind(null, dispatch, history, updateComputer, setComputerDoesntLoading),
            queueWebsocketUpdate.bind(null, dispatch, updateQueue),
            personalStatusWebsocketUpdate.bind(null, dispatch, setPersonalPlan)
          );
          if (isMounted) {
            dispatch(setComputerWebsocketOn());
          }
        }
      } catch (error) {
        if (error instanceof SessionExpired && isMounted) {
          handleSessionExpiredError();
        }
      }
    };

    initializeWebSocket();

    return () => {
      isMounted = false;
    };
  }, [dispatch, computerWebsocketOn, history]);

  const handleSessionExpiredError = () => {
    const turnOnMachine = false;
    const disconnect = true;
    dispatch(
      openConfirmationLightbox(
        () => {
          history.push(REDIRECT_TO_LOGIN);
        },
        messageDisconnect.message,
        messageDisconnect.confirmBtnText,
        messageDisconnect.refuseBtnText,
        turnOnMachine,
        disconnect,
        false,
      ),
    );
  };

  return maintenance ? ( <Redirect to={REDIRECT_TO_MAINTENANCE} /> ) : (
    <S.Container>
      <PrivateNavigation />
      {pages.map(({ component: Component, ...props }) => (
        <Route
          key={props.path}
          {...props}
          render={() => (
            <S.Div>
              <Component />
            </S.Div>
          )}
        />
      ))}
    </S.Container>
  );
};

function usePrivate() {
  const dispatch = useDispatch();
  const isLogged = sessionManager.isLogged();

  useEffect(
    function () {
      dispatch(loadSession());
    },
    [dispatch],
  );

  return isLogged;
}

function Private({pages}) {
  return <PrivateView pages={pages} isLogged={usePrivate()} maintenance={false}/>
}

export default Private;

import { Button as MuiButton, Menu, MenuItem } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

import { desktopMinSize, mq, mqmin } from '../../../../styles/mixins';

const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
    scroll-timeline: --page-scroll block;
    scroll-timeline-axis: block;
  }
`;

function Div({ useScroll, setActualPath }, props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  useEffect(() => {
    document.documentElement.style.scrollBehavior = 'smooth';
  }, []);

  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const handleFaqClick = (e) => {
    e.preventDefault();
    
    const faqMappings = {
      '/': '/faq',
      '/cloud-gaming': '#faq',
      '/desktop-as-a-service': '#faq',
      '/computador-na-nuvem': '#faq',
      '/for-business': '#faq'
    };
    
    const currentPath = window.location.pathname;
    const targetFaqPath = faqMappings[currentPath] || '/faq';
    
    if (targetFaqPath.startsWith('#')) {
      const element = document.querySelector(targetFaqPath);
      if (element) {
        const headerOffset = 100;
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        setTimeout(() => {
          const finalPosition = element.getBoundingClientRect().top + window.pageYOffset;
          if (element.getBoundingClientRect().top < headerOffset) {
            const offsetPosition = finalPosition - headerOffset;
            window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
          }
        }, 100);
      } else {
        history.push('/faq');
      }
    } else {
      history.push(targetFaqPath);
    }
  };

  const handleMenuItemClick = async (event, path) => {
    console.log('1. Iniciando handleMenuItemClick');
    console.log('Path recebido:', path);
    
    setActualPath('');
    handleMouseLeave();
    
    if (!event) {
      console.error('Evento não disponível');
      return;
    }
    
    event.preventDefault();
    console.log('2. Evento prevenido');

    // Se não estamos na página for-business, navegar primeiro
    if (window.location.pathname !== '/for-business') {
      console.log('3. Navegando para /for-business');
      await history.push('/for-business');
      
      // Aguarda a renderização da página
      setTimeout(() => {
        console.log('4. Aguardando renderização');
        scrollToElement(path);
      }, 500);
    } else {
      console.log('3. Já estamos na página correta');
      scrollToElement(path);
    }
  };

  const scrollToElement = (path) => {
    const element = document.querySelector(path);
    
    if (element) {
      const headerOffset = 100;

      try {
        element.style.scrollMarginTop = `${headerOffset}px`;
        
        element.scrollIntoView({ 
          behavior: 'smooth',
          block: 'start'
        });
        
        setTimeout(() => {
          const finalPosition = element.getBoundingClientRect().top + window.pageYOffset;
          
          if (element.getBoundingClientRect().top < headerOffset) {
            const offsetPosition = finalPosition - headerOffset;
            
            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth'
            });
          }
        }, 100);
        
      } catch (error) {
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - headerOffset;
        
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }
  };

  // Adiciona listener para monitorar mudanças no scroll
  useEffect(() => {
    const handleScroll = () => {
      console.log('Scroll detectado:', {
        pageYOffset: window.pageYOffset,
        scrollY: window.scrollY
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <GlobalStyle />
      <div {...props}>
        {links.map(({ path, text }) =>
          path === '/for-business' ? (
            <TextButton
              key={path}
              aria-controls="business-menu"
              aria-haspopup="true"
              onMouseEnter={handleMouseEnter}
              onClick={(e) => e.preventDefault()}
            >
              {text}
            </TextButton>
          ) : path === 'dynamic-faq' ? (
            <TextButton 
              key="faq"
              onClick={handleFaqClick}
            >
              {text}
            </TextButton>
          ) : path.startsWith('#') ? (
            <TextButton onClick={useScroll(path, setActualPath)} href={path} key={path}>
              {text}
            </TextButton>
          ) : (
            <TextButton
              component={NavLink}
              key={path}
              to={path}
              onClick={() => {
                setActualPath('');
              }}
            >
              {text}
            </TextButton>
          )
        )}
        <Button component={Link} to="/login">
          Acesse
        </Button>
        <StyledMenu
          id="business-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMouseLeave}
          MenuListProps={{
            onMouseLeave: handleMouseLeave
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          getContentAnchorEl={null}
          style={{ zIndex: 1300 }}
        >
          {/* 1. Infraestrutura de Servidores */}
          <StyledMenuItem>Infraestrutura de Servidores</StyledMenuItem>
          <SubMenuItem onClick={(e) => handleMenuItemClick(e, '#servidores')}>
            Servidores Dedicados de Alta Performance
          </SubMenuItem>
          <SubMenuItem onClick={(e) => handleMenuItemClick(e, '#windows-server')}>
            Windows Server
          </SubMenuItem>
          <SubMenuItem onClick={(e) => handleMenuItemClick(e, '#daas-specialized')}>
            DaaS de Alto Desempenho
          </SubMenuItem>

          {/* 2. Desenvolvimento de Software */}
          <StyledMenuItem>Desenvolvimento de Software</StyledMenuItem>
          <SubMenuItem onClick={(e) => handleMenuItemClick(e, '#desenvolvimento-completo')}>
            Desenvolvimento de Software Completo
          </SubMenuItem>
          <SubMenuItem onClick={(e) => handleMenuItemClick(e, '#desenvolvimento')}>
            Plataforma de Desenvolvimento
          </SubMenuItem>

          {/* 3. Rede e Segurança */}
          <StyledMenuItem>Rede e Segurança</StyledMenuItem>
          <SubMenuItem onClick={(e) => handleMenuItemClick(e, '#cloudflare')}>
            Implantação CloudFlare
          </SubMenuItem>
          <SubMenuItem onClick={(e) => handleMenuItemClick(e, '#rede')}>
            Serviços de Rede
          </SubMenuItem>
          <SubMenuItem onClick={(e) => handleMenuItemClick(e, '#seguranca')}>
            Segurança de Rede e Zero Trust
          </SubMenuItem>
          <SubMenuItem onClick={(e) => handleMenuItemClick(e, '#dominio')}>
            Serviço de Domínio
          </SubMenuItem>

          {/* 4. Armazenamento e Mídia */}
          <StyledMenuItem>Armazenamento e Mídia</StyledMenuItem>
          <SubMenuItem onClick={(e) => handleMenuItemClick(e, '#armazenamento')}>
            Serviços de Armazenamento e Mídia
          </SubMenuItem>

          {/* 5. Serviços Gerenciados e Suporte */}
          <StyledMenuItem>Serviços Gerenciados e Suporte</StyledMenuItem>
          <SubMenuItem onClick={(e) => handleMenuItemClick(e, '#cloud-management')}>
            Gerenciamento de Cloud
          </SubMenuItem>
          <SubMenuItem onClick={(e) => handleMenuItemClick(e, '#helpdesk')}>
            Suporte Remoto (HelpDesk)
          </SubMenuItem>
        </StyledMenu>
      </div>
    </>
  );
}

const links = [
  { path: '#planos', text: 'Nossos planos' },
  { path: '#download', text: 'Baixar' },
  { path: '#como-usar', text: 'Como usar?' },
  { path: '/for-business', text: 'Para Empresas' },
  { path: 'dynamic-faq', text: 'FAQ' },
];

const Button = styled(MuiButton)`
  &.MuiButton-root {
    border: solid #2d80f2 calc(2rem / 16);
    border-radius: calc(24rem / 16);
    padding: calc(9rem / 16) calc(30rem / 16);
    color: #2d80f2;
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    font-weight: bold;
    line-height: calc(18 / var(--fontSize));
    transition: ${['color', 'background'].map(prop => `${prop} 300ms ease-out`).join(', ')};

    &.Mui-disabled {
      cursor: not-allowed;
      color: #2d80f2;
      border: solid #2d80f2 calc(2rem / 16);
      border-radius: calc(24rem / 16);
    }

    &:hover {
      color: #fff;
      background: #2d80f2;
    }

    ${mqmin(desktopMinSize)} {
      --fontSize: 17;

      line-height: calc(26 / var(--fontSize));
    }

    ${mq(desktopMinSize + 400, 1350)} {
      --fontSize: 12;
      line-height: calc(26 / var(--fontSize));
    }
  }
`;

const TextButton = styled(MuiButton)`
  &.MuiButton-root {
    color: inherit;
    font-size: calc(17rem / 16);
    font-weight: normal;
    line-height: ${28 / 17};
    text-transform: none;
    transition: color 300ms ease-out;

    &.MuiButton-text {
      padding: 0 2.5rem !important;

      ${mq(1900, 1350)} {
        padding: 0 1.5rem !important;
      }
    }

    &:hover,
    &.active {
      color: #2d80f2;
    }

    &:hover {
      background: none;
    }
  }
  ${mq(1900, 1350)} {
    &.MuiButton-root {
      font-size: calc(14rem / 16);

      &.MuiButton-text {
        padding: 0 1.5rem !important;
      }
    }
  }

  ${mq(1350, desktopMinSize)} {
    &.MuiButton-root {
      font-size: calc(12rem / 16);

      &.MuiButton-text {
        padding: 0 1rem !important;
      }
    }
  }
`;

const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    background-color: #1A1B1F;
    border-radius: 8px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
    margin-top: 40px;
    position: absolute;
    transform: translateY(-32px) !important;

    .MuiMenuItem-root {
      font-size: 14px;
      padding: 8px 16px;
      color: #B4B7C0;
      transition: color 0.2s;
      min-width: 280px;

      &:hover {
        color: #ffffff;
        background-color: rgba(255, 255, 255, 0.05);
      }
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    font-weight: bold;
    color: #2d80f2 !important;
    pointer-events: none;
    margin-top: 8px;
    opacity: 0.9;
  }
`;

const SubMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    padding-left: 24px !important;
    color: #B4B7C0 !important;
    font-size: 13px !important;

    &:hover {
      color: #ffffff !important;
    }
  }
`;

export const Desktop = styled(Div)`
  display: flex;
  column-gap: calc(18rem / 16);

  ${Button} {
    margin-left: calc(12rem / 16);
  }
`;

export default Desktop;
import React from 'react';
import styled from 'styled-components';

import { desktopMinSize, mqmin, mq } from '@Styles/mixins';

import Form from './Form';
import Header from './Header';
import SEOHelmet from '../../components/SEO/SEOHelmet';

export function Faq() {
  return (
    <>
      <SEOHelmet pageName="faq" />
      <Container id="perguntas-frequentes">
        <Header />
        <IconContainer>
          <svg width="48" height="48" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_9_463)">
              <path d="M7.99992 14.6667C11.6818 14.6667 14.6666 11.6819 14.6666 8.00004C14.6666 4.31814 11.6818 1.33337 7.99992 1.33337C4.31802 1.33337 1.33325 4.31814 1.33325 8.00004C1.33325 11.6819 4.31802 14.6667 7.99992 14.6667Z" stroke="#2d80f2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M6.06006 6.00001C6.21679 5.55446 6.52616 5.17875 6.93336 4.93944C7.34056 4.70012 7.81932 4.61264 8.28484 4.69249C8.75036 4.77234 9.1726 5.01436 9.47678 5.3757C9.78095 5.73703 9.94743 6.19436 9.94673 6.66668C9.94673 8.00001 7.94673 8.66668 7.94673 8.66668" stroke="#2d80f2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M8 11.3334H8.00667" stroke="#2d80f2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_9_463">
                <rect width="16" height="16" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </IconContainer>
        <Description>
          Encontre respostas para as dúvidas mais frequentes sobre nossos serviços. 
          Se você não encontrar o que procura, entre em contato com nosso suporte.
        </Description>
        <Form />
      </Container>
    </>
  );
}

export default Faq;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  
  svg {
    width: 48px;
    height: 48px;
  }
`;

const Description = styled.p`
  text-align: center;
  color: #b4b7c0;
  font-size: 1.1rem;
  max-width: 800px;
  margin: 0 auto 2rem;
  line-height: 1.6;
`;

const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.8rem;
  max-width: min(calc(304 / 360 * 100%), calc(1331rem / 16));
  margin-inline: auto;
  padding-block: 16rem calc(112rem / 16);
  background-color: #121212;
  color: white;

  ${mq(1900, desktopMinSize)} {
    row-gap: 2.7rem;
    padding-block: 0 12.9rem;
  }

  ${mqmin(1900)} {
    row-gap: 2.7rem;
    padding-block: 10rem 12.9rem;
  }
`;

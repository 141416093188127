import React from 'react';
import * as S from './styled.js';
import MobileHeader from '@Components/Header';
import ContactComponent from './ContactComponent';
import FaqComponent from './FaqComponent';

const SupportPage = () => {
  return (
    <S.PageContainer>
      <MobileHeader />
      <S.ContentWrapper>
        <S.SectionTitle>Suporte</S.SectionTitle>
        <S.SupportCard>
          <S.SupportSection>
            <ContactComponent />
          </S.SupportSection>
          <S.SupportSection>
            <FaqComponent />
          </S.SupportSection>
        </S.SupportCard>
      </S.ContentWrapper>
    </S.PageContainer>
  );
}; 

export default SupportPage;

import React from 'react';
import styled from 'styled-components';

/**
 * Componente para exibir mensagem de carregamento de página
 * @returns {JSX.Element} Componente de mensagem de carregamento
 */
const PageLoadingMessage = () => {
  return (
    <LoadingContainer>
      <LoadingSpinner />
      <LoadingText>Carregando...</LoadingText>
    </LoadingContainer>
  );
};

export default PageLoadingMessage;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
`;

const LoadingSpinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #3498db;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const LoadingText = styled.p`
  margin-top: 16px;
  font-size: 16px;
  color: #333;
`; 
import React from 'react';
import styled from 'styled-components';

import { Title, Subtitle } from '@Components/Public';

export function Header() {
  return (
    <Container>
      <FaqTitle as="h1">
        <strong>Perguntas Frequentes</strong>
      </FaqTitle>
      <FaqSubtitle>
        Confira as dúvidas mais comuns sobre os serviços da <span className="highlight">NOAR Cloud</span>
      </FaqSubtitle>
    </Container>
  );
}

export default Header;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: calc(16rem / 16);
  padding: 2rem 0;
  width: 100%;
  text-align: center;
`;

const FaqTitle = styled(Title)`
  font-size: 2.5rem;
  font-weight: 700;
  color: white;
  margin-bottom: 0.5rem;
  background: linear-gradient(135deg, #2d80f2, #00c2ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const FaqSubtitle = styled(Subtitle)`
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.8);
  max-width: 700px;
  
  .highlight {
    color: #2d80f2;
    font-weight: 700;
  }
`;

export const openTawkToChat = (message, visitorName = 'Visitante B2B') => {
  if (window.Tawk_API) {
    // Primeiro maximiza o chat
    window.Tawk_API.maximize();
    
    // Define os atributos do visitante
    window.Tawk_API.setAttributes({
      'name': visitorName,
      'email': '',
      'hash': ''
    }, function(error){});

    // Função para tentar enviar a mensagem
    const tryToSendMessage = () => {
      if (window.Tawk_API.getStatus() === 'online') {
        setTimeout(() => {
          window.Tawk_API.sendMessage(message);
        }, 1500);
      }
    };

    // Configura o listener de status
    window.Tawk_API.onStatusChange = function(status) {
      if (status === 'online') {
        tryToSendMessage();
      }
    };

    // Tenta enviar a mensagem imediatamente se já estiver online
    tryToSendMessage();
  }
}; 
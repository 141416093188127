import React, { useState, useCallback, useMemo } from 'react';
import * as S from './FaqStyles';
import { Search } from '@material-ui/icons';
import { ExpandMore } from '@material-ui/icons';
import faqs from '../../constants/faqs';

const FaqComponent = () => {
  const [expanded, setExpanded] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredFaqs = useMemo(() => {
    return faqs.filter(faq => 
      faq.question.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm]);

  return (
    <S.FaqContainer>
      <S.FaqTitle>Perguntas Frequentes</S.FaqTitle>
      <S.FaqDescription>
        Encontre respostas para as dúvidas mais comuns sobre nossos serviços
      </S.FaqDescription>
      
      <S.SearchContainer>
        <S.SearchInput 
          type="text" 
          placeholder="Pesquise por sua dúvida"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <S.SearchIcon>
          <Search />
        </S.SearchIcon>
      </S.SearchContainer>
      
      <S.AccordionContainer>
        {filteredFaqs.map((faq) => (
          <S.Accordion 
            key={faq.id}
            expanded={expanded === faq.id}
            onChange={handleChange(faq.id)}
          >
            <S.AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls={`${faq.id}-content`}
              id={`${faq.id}-header`}
            >
              <S.QuestionText>{faq.question}</S.QuestionText>
            </S.AccordionSummary>
            <S.AccordionDetails>
              <S.AnswerText dangerouslySetInnerHTML={{ __html: faq.answer }} />
            </S.AccordionDetails>
          </S.Accordion>
        ))}
      </S.AccordionContainer>
    </S.FaqContainer>
  );
};

export default FaqComponent; 
import React, { useState } from 'react';
import * as S from '../../styled';
import { FaLock, FaShieldAlt, FaUserShield, FaKey, FaEye, FaUserLock } from 'react-icons/fa';
import ServicePopup from '../../components/ServicePopup';

const SecurityZeroTrust = () => {
  const [selectedService, setSelectedService] = useState(null);

  const services = [
    {
      id: 'access',
      icon: <FaUserShield />,
      title: 'Cloudflare Access',
      features: [
        {
          icon: <FaUserLock />,
          title: 'Autenticação Baseada em Identidade',
          description: 'Controle de acesso granular baseado em identidade do usuário.'
        },
        {
          icon: <FaEye />,
          title: 'Monitoramento em Tempo Real',
          description: 'Visibilidade completa sobre acessos e tentativas de acesso.'
        },
        {
          icon: <FaKey />,
          title: 'Single Sign-On (SSO)',
          description: 'Integração com provedores de identidade existentes.'
        }
      ]
    },
    {
      id: 'warp',
      icon: <FaLock />,
      title: 'WARP',
      features: [
        {
          icon: <FaLock />,
          title: 'Criptografia de Tráfego',
          description: 'Proteção do tráfego de rede em qualquer conexão.'
        },
        {
          icon: <FaShieldAlt />,
          title: 'Proteção contra Ameaças',
          description: 'Bloqueio de malware, phishing e outras ameaças.'
        },
        {
          icon: <FaUserShield />,
          title: 'Políticas de Segurança',
          description: 'Aplicação de políticas de segurança em nível de dispositivo.'
        }
      ]
    },
    {
      id: 'zero-trust',
      icon: <FaShieldAlt />,
      title: 'Zero Trust Security',
      features: [
        {
          icon: <FaUserShield />,
          title: 'Verificação Contínua',
          description: 'Autenticação e autorização em cada requisição.'
        },
        {
          icon: <FaLock />,
          title: 'Segurança em Camadas',
          description: 'Múltiplas camadas de proteção para recursos críticos.'
        },
        {
          icon: <FaEye />,
          title: 'Visibilidade Total',
          description: 'Monitoramento e logs detalhados de todas as atividades.'
        }
      ]
    }
  ];

  return (
    <S.ServiceCard>
      <S.ServiceHeader>
        <S.ServiceIcon>
          <FaShieldAlt />
        </S.ServiceIcon>
        <S.ServiceTitle>Segurança de Rede e Zero Trust</S.ServiceTitle>
      </S.ServiceHeader>

      <S.SubServicesList>
        {services.map((service) => (
          <S.SubServiceItem
            key={service.id}
            onClick={() => setSelectedService(service)}
          >
            <S.SubServiceIcon>
              {service.icon}
            </S.SubServiceIcon>
            <S.SubServiceTitle>
              {service.title}
            </S.SubServiceTitle>
          </S.SubServiceItem>
        ))}
      </S.SubServicesList>

      {selectedService && (
        <ServicePopup
          isOpen={!!selectedService}
          onClose={() => setSelectedService(null)}
          title={selectedService.title}
          features={selectedService.features}
          ctaText="Proteger Minha Empresa"
        />
      )}
    </S.ServiceCard>
  );
};

export default SecurityZeroTrust; 
import React, { useState } from 'react';
import * as S from '../../styled';
import { FaNetworkWired, FaShieldAlt, FaGlobe, FaLock, FaUserShield, FaServer } from 'react-icons/fa';
import ServicePopup from '../../components/ServicePopup';

const NetworkServices = () => {
  const [selectedService, setSelectedService] = useState(null);

  const services = [
    {
      id: 'cloudflare-one',
      icon: <FaNetworkWired />,
      title: 'Cloudflare One (SASE)',
      features: [
        {
          icon: <FaLock />,
          title: 'Zero Trust Network Access',
          description: 'Acesso seguro a recursos internos com autenticação baseada em identidade.'
        },
        {
          icon: <FaUserShield />,
          title: 'Secure Web Gateway',
          description: 'Proteção avançada para navegação web e filtragem de conteúdo.'
        },
        {
          icon: <FaServer />,
          title: 'Cloud Access Security Broker',
          description: 'Controle e visibilidade sobre aplicações SaaS e recursos em nuvem.'
        }
      ]
    },
    {
      id: 'magic-transit',
      icon: <FaShieldAlt />,
      title: 'Magic Transit',
      features: [
        {
          icon: <FaShieldAlt />,
          title: 'Proteção DDoS em Rede',
          description: 'Proteção contra ataques DDoS em toda a infraestrutura de rede.'
        },
        {
          icon: <FaNetworkWired />,
          title: 'Otimização de Roteamento',
          description: 'Roteamento inteligente para melhor performance e disponibilidade.'
        },
        {
          icon: <FaServer />,
          title: 'Monitoramento em Tempo Real',
          description: 'Visibilidade completa do tráfego e ameaças em tempo real.'
        }
      ]
    },
    {
      id: 'magic-wan',
      icon: <FaGlobe />,
      title: 'Magic WAN',
      features: [
        {
          icon: <FaGlobe />,
          title: 'Conectividade Global',
          description: 'Conexão segura entre filiais, data centers e nuvem em escala global.'
        },
        {
          icon: <FaNetworkWired />,
          title: 'Substituição de MPLS',
          description: 'Alternativa moderna e flexível para redes MPLS tradicionais.'
        },
        {
          icon: <FaLock />,
          title: 'Segurança Integrada',
          description: 'Segurança nativa com criptografia e controles de acesso.'
        }
      ]
    }
  ];

  return (
    <S.ServiceCard>
      <S.ServiceHeader>
        <S.ServiceIcon>
          <FaNetworkWired />
        </S.ServiceIcon>
        <S.ServiceTitle>Serviços de Rede</S.ServiceTitle>
      </S.ServiceHeader>

      <S.SubServicesList>
        {services.map((service) => (
          <S.SubServiceItem
            key={service.id}
            onClick={() => setSelectedService(service)}
          >
            <S.SubServiceIcon>
              {service.icon}
            </S.SubServiceIcon>
            <S.SubServiceTitle>
              {service.title}
            </S.SubServiceTitle>
          </S.SubServiceItem>
        ))}
      </S.SubServicesList>

      {selectedService && (
        <ServicePopup
          isOpen={!!selectedService}
          onClose={() => setSelectedService(null)}
          title={selectedService.title}
          features={selectedService.features}
          ctaText="Solicitar Demonstração"
        />
      )}
    </S.ServiceCard>
  );
};

export default NetworkServices; 
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Container } from './styled';
import { EndOfSessionAlert } from '@Components/QueueAlerts/EndOfSessionAlert';
import { AlmostExpiringAlert } from '@Components/QueueAlerts/AlmostExpiringAlert';

let countdownTimeout;

export const SessionTime = () => {
  const { launch_time } = useSelector(state => state.computer);
  const [isActive, setIsActive] = useState(true);
  const modalEndSessionRef = useRef();
  const modalAlmostExpiringRef = useRef();
  const finalTime = 14400; // 4 horas em segundos
  const almostExpiring = finalTime - 300; // 5 minutos antes
  const initDate = new Date(launch_time);
  const [time, setTime] = useState(0);

  const updateCurrentTime = async () => {
    try {
      const response = await fetch('https://worldtimeapi.org/api/timezone/America/Sao_Paulo');
      const data = await response.json();
      
      if (response.ok) {
        const currentDateTime = new Date(data.utc_datetime);
        const diffInSeconds = Math.floor((currentDateTime.getTime() - initDate.getTime()) / 1000);
        setTime(diffInSeconds < finalTime ? diffInSeconds : finalTime);
      }
    } catch (error) {
      console.error('Erro ao atualizar o tempo:', error);
    }
  };

  const controlVisibilityPage = () => {
    if (document.visibilityState === 'visible') {
      updateCurrentTime();
    }
  };

  useEffect(() => {
    if (launch_time) {
      updateCurrentTime();
    }
  }, [launch_time]);

  useEffect(() => {
    if (isActive && time < finalTime) {
      if (time === almostExpiring) {
        handleOpenAlmostExpiringModal();
      }
      countdownTimeout = setTimeout(() => {
        setTime(prevTime => prevTime + 1);
      }, 1000);
    } else if (isActive && time >= finalTime) {
      openModal(modalEndSessionRef);
      setIsActive(false);
    }

    return () => {
      if (countdownTimeout) {
        clearTimeout(countdownTimeout);
      }
    };
  }, [isActive, time]);

  useEffect(() => {
    window.addEventListener('visibilitychange', controlVisibilityPage);
    return () => window.removeEventListener('visibilitychange', controlVisibilityPage);
  }, []);

  const handleCloseEndSessionModal = () => {
    closeModal(modalEndSessionRef);
  };

  const handleCloseAlmostExpiringModal = () => {
    closeModal(modalAlmostExpiringRef);
  };

  const handleOpenAlmostExpiringModal = () => {
    new Audio('/alert-sound/alert.mp3').play();
    openModal(modalAlmostExpiringRef);
  };

  const openModal = modalRef => {
    modalRef.current.showModal();
  };

  const closeModal = modalRef => {
    const modal = modalRef.current;
    modal.setAttribute('closing', '');
    modal.addEventListener(
      'animationend',
      () => {
        modal.removeAttribute('closing');
        modal.close();
      },
      { once: true },
    );
  };

  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = time % 60;

  const [hourLeft, hourRight] = String(hours).padStart(2, '0').split('');
  const [minuteLeft, minuteRight] = String(minutes).padStart(2, '0').split('');
  const [secondsLeft, secondsRight] = String(seconds).padStart(2, '0').split('');

  // Calcula a porcentagem do tempo restante para alertas visuais
  const timePercentage = (time / finalTime) * 100;
  const isAlmostExpiring = timePercentage >= 90;

  return (
    <>
      <Container>
        <p>
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" 
            height="16" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"
            style={{ marginRight: '8px' }}
          >
            <circle cx="12" cy="12" r="10"></circle>
            <polyline points="12 6 12 12 16 14"></polyline>
          </svg>
          Tempo de sessão{' '}
          <span className={isAlmostExpiring ? 'almost-expiring' : ''}>
            {hourLeft}
            {hourRight}:{minuteLeft}
            {minuteRight}:{secondsLeft}
            {secondsRight}
          </span>
        </p>
      </Container>
      <EndOfSessionAlert modalRef={modalEndSessionRef} close={handleCloseEndSessionModal} />
      <AlmostExpiringAlert modalRef={modalAlmostExpiringRef} close={handleCloseAlmostExpiringModal} />
    </>
  );
};

import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';
import { Instagram, LinkedIn, Twitter, Youtube } from "../../HomePage/Contact/Header/Navigation/IconButton";
import { BlueStrong, DefaultContentDivWrapper, SectionTitle, StyledPurpleText } from "../styled";
import * as S from './styled';
import { openTawkToChat } from '../../../utils/tawk';

const ContactContainer = styled.section`
  text-align: center;
  padding: 4rem 2rem;
  background: ${colors.gradients.primary};
  border-radius: 24px;
  margin: 3rem 0;
  color: ${colors.white};
  position: relative;
  overflow: hidden;
  box-shadow: ${colors.shadows.hover};
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-3px);
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at 70% 30%, rgba(255, 255, 255, 0.1) 0%, transparent 70%);
  }
`;

const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  position: relative;
`;

const Description = styled.p`
  font-size: 1.4rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
  opacity: 0.9;
`;

const ContactSection = () => {
  return (
    <a href="#contato-comercial" style={{ textDecoration: 'none' }}>
      <ContactContainer>
        <Title>Precisa de ajuda?</Title>
        <Description>
          Fale conosco agora mesmo!<br />
          Estamos prontos para ajudar sua empresa a alcançar o próximo nível.
        </Description>
      </ContactContainer>
    </a>
  );
};

export function ContactUsBusiness() {
    return (
        <DefaultContentDivWrapper>
            <SectionTitle>Fale com a <StyledPurpleText>NOAR!</StyledPurpleText></SectionTitle>
            <S.ContentWrapper>
                <S.ContentParagraph>
                    Precisa de ajuda?
                </S.ContentParagraph>
                <S.ContentParagraph>
                    <S.ContactButton as="a" href="#contato-comercial" style={{ textDecoration: 'none', display: 'inline-block' }}>
                        Fale com nossos especialistas →
                    </S.ContactButton>
                    <br/>Estamos prontos para ajudar sua empresa a alcançar o <StyledPurpleText>próximo nível</StyledPurpleText>.
                </S.ContentParagraph>
                <S.SocialContainerWrapper>
                    <S.ContentParagraph>
                        Acesse também nossas redes.
                    </S.ContentParagraph>
                    <S.SocialIconsContainer>
                        <Instagram />
                        <LinkedIn />
                        <Youtube />
                        <Twitter />
                    </S.SocialIconsContainer>
                </S.SocialContainerWrapper>
            </S.ContentWrapper>
        </DefaultContentDivWrapper>);
}

export default ContactSection;
import React, { useState } from 'react';
import * as S from '../../styled';
import { FaCode, FaRobot, FaCogs, FaBrain, FaServer, FaCloud } from 'react-icons/fa';
import { BiCodeBlock } from 'react-icons/bi';
import ServicePopup from '../../components/ServicePopup';

const DevelopmentPlatform = () => {
  const [selectedService, setSelectedService] = useState(null);

  const services = [
    {
      id: 'workers',
      icon: <FaCode />,
      title: 'Cloudflare Workers',
      features: [
        {
          icon: <FaCloud />,
          title: 'Edge Computing',
          description: 'Execute código na borda da rede, próximo aos usuários finais.'
        },
        {
          icon: <FaServer />,
          title: 'Serverless',
          description: 'Desenvolvimento sem preocupação com infraestrutura.'
        },
        {
          icon: <FaCogs />,
          title: 'Integração Global',
          description: 'Deploy automático em mais de 200 localizações globais.'
        }
      ]
    },
    {
      id: 'workflows',
      icon: <BiCodeBlock />,
      title: 'Workflows',
      features: [
        {
          icon: <FaCogs />,
          title: 'Orquestração',
          description: 'Gerenciamento de fluxos de trabalho complexos e distribuídos.'
        },
        {
          icon: <FaServer />,
          title: 'Persistência de Estado',
          description: 'Armazenamento e gerenciamento de estado durável.'
        },
        {
          icon: <FaCode />,
          title: 'Automação',
          description: 'Criação de processos automatizados e escaláveis.'
        }
      ]
    },
    {
      id: 'workers-ai',
      icon: <FaRobot />,
      title: 'Workers AI',
      features: [
        {
          icon: <FaBrain />,
          title: 'Inferência na Borda',
          description: 'Processamento de IA próximo ao usuário para menor latência.'
        },
        {
          icon: <FaRobot />,
          title: 'Modelos Pré-treinados',
          description: 'Acesso a modelos de IA de última geração.'
        },
        {
          icon: <FaCode />,
          title: 'API Simples',
          description: 'Integração fácil com suas aplicações existentes.'
        }
      ]
    }
  ];

  return (
    <S.ServiceCard>
      <S.ServiceHeader>
        <S.ServiceIcon>
          <FaCode />
        </S.ServiceIcon>
        <S.ServiceTitle>Plataforma de Desenvolvimento</S.ServiceTitle>
      </S.ServiceHeader>

      <S.SubServicesList>
        {services.map((service) => (
          <S.SubServiceItem
            key={service.id}
            onClick={() => setSelectedService(service)}
          >
            <S.SubServiceIcon>
              {service.icon}
            </S.SubServiceIcon>
            <S.SubServiceTitle>
              {service.title}
            </S.SubServiceTitle>
          </S.SubServiceItem>
        ))}
      </S.SubServicesList>

      {selectedService && (
        <ServicePopup
          isOpen={!!selectedService}
          onClose={() => setSelectedService(null)}
          title={selectedService.title}
          features={selectedService.features}
          ctaText="Começar a Desenvolver"
        />
      )}
    </S.ServiceCard>
  );
};

export default DevelopmentPlatform; 
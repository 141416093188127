import React, { useEffect, useState } from 'react';
import { Subtitle, Wrapper, Overlay, ButtonsWrapper, CancelInfoText, CancelInfoSubTitle, InfoTitle, InfoWrapper, InfoWarning, RedSpam, GreenSpam, InfoStrong, YellowSpam } from './styled';
import { cancelRecurrency, getActivatedPlans } from '@Services/noar-api';
import StyledButton from '../styled';
import { getExternalRecurrency, getRecurrency, sendNpsAnswer } from '../../../../services/noar-api';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { RecurrencyCancelPopup } from './CancelPopup';
import { RecurrencyCardChangePopup } from './Payment';
import { Warning } from '@material-ui/icons';

export const RecurrencyControl = () => {
  const [recurrency, handleRecurrency] = useState();
  const [loading, handleLoading] = useState(false);
  const [success, handleSuccess] = useState(false);
  const [error, handleError] = useState(false);
  const [reasonText, handleReasonText] = useState('');
  const [phase, handlePhase] = useState(1); // 1 - info de cancelamento, 2 - escolheu cancelar, 3 - escolheu continuar, 4 - resultado cancelamento
  const [state, setState] = React.useState({
    price: false,
    dificulties: false,
    fulfillExpetations: false,
    problems: false,
    customerService: false,
    other: false,
  });

  const [planInfo, handlePlanInfo] = useState({
    price: 0,
    renewalDate: 'xx/xx/xxxx',
    recurrencyDate: 'xx/xx/xxxx',
    status: '---',
  });

  const history = useHistory();

  const subscriptionEndDate = recurrency?.renewalDate;

  const handleChange = (event) => {
    event.preventDefault();
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const { price, dificulties, customerService, fulfillExpetations, problems, other } = state;
  const checkboxFormError = [price, dificulties, customerService, fulfillExpetations, problems, other].filter((v) => v).length < 1;

  const [hasToShowCancelPopup, handleHasToShowCancelPopup] = useState(false);
  const [hasToShowChangeCardPopup, handleHasToShowChangeCardPopup] = useState(false);

  const toCancelPhase = () => {
    handlePhase(2);
  };

  const toContinuePhase = () => {
    handlePhase(3);
    sendForm(3);
  }

  const toResultPhase = () => {
    handlePhase(4);
  }

  const sendForm = (phase) => {
    var reason, response_type, origin, cancel_reason;
    if (phase === 3) {
      response_type = "IGNORED";
      origin = "PERSONAL_CANCEL";
    }
    else {
      response_type = "ANSWERED";
      origin = "PERSONAL_CANCEL";
      reason = Object.keys(state).filter((v) => state[v]).join(', ');
      cancel_reason = reasonText;

    }
    console.log(reason, response_type, origin, cancel_reason);
    sendNpsAnswer(null, reason, response_type, origin, cancel_reason);
  };

  const closeCancelPopup = () => {
    setState(
      {
        price: false,
        dificulties: false,
        fulfillExpetations: false,
        problems: false,
        customerService: false,
        other: false,
      }
    )
    handlePhase(1);
    handleHasToShowCancelPopup(false);
  };

  const onConfirmCancel = async () => {
    handleLoading(true);
    sendForm(2);
    
    try {
      console.log('Tentando cancelar recorrência com ID:', recurrency);
      
      // Verificar se temos um ID externo válido
      if (!recurrency || !recurrency.externalRecurrencyId) {
        console.error('ID externo de recorrência não encontrado:', recurrency);
        handleError(true);
        handleLoading(false);
        toResultPhase();
        return;
      }
      
      const response = await cancelRecurrency(recurrency);
      console.log('Resposta do cancelamento:', response);
      
      if (response && response.ok) {
        handleSuccess(true);
        handleLoading(false);
        handleRecurrency();
      } else {
        handleError(true);
        handleLoading(false);
      }
    } catch (error) {
      console.error('Erro ao cancelar recorrência:', error);
      handleError(true);
      handleLoading(false);
    }
    
    toResultPhase();
  };

  const onCloseResultPhase = () => {
    closeCancelPopup();
    window.location.reload();
  };

  const onCancelCancel = () => {
    closeCancelPopup();
    history.replace('/my/home');
  };

  const onChangeText = (event) => {
    handleReasonText(event.target.value);
  };

  const handleCancelRecurrency = () => {
    handleHasToShowCancelPopup(true);
  };

  const handleChangeRecurrencyCard = () => {
    handleHasToShowChangeCardPopup(true);
  };

  // Mapeia os status da recorrência para nomes amigáveis
  const getStatusName = (status) => {
    switch (status) {
      case 'ACTIVE':
        return 'Pagamento Aprovado';
      case 'PENDING':
        return 'Aguardando Pagamento';
      case 'PAYMENT_PENDING':
        return 'Aguardando Pagamento';
      case 'PAYMENT_FAILED':
        return 'Pagamento Recusado';
      case 'CANCELED':
        return 'Assinatura Cancelada';
      case 'FINISHED':
        return 'Assinatura Finalizada';
      default:
        return status || '---';
    }
  };

  useEffect(() => {
    async function getRecurrencyData() {
      // Usando novo endpoint para obter informações de recorrência
      try {
        // Busca informações da recorrência pelo novo endpoint
        const recurrencyDataList = await getRecurrency();
        try {
          // Verificar se obteve uma lista válida
          if (Array.isArray(recurrencyDataList) && recurrencyDataList.length > 0) {
            const recurrencyData = recurrencyDataList[0];
            if (recurrencyData) {
              console.log('Dados de recorrência obtidos:', recurrencyData);
              
              // Determinar o ID externo de forma mais robusta
              const externalId = recurrencyData.externalRecurrencyId || recurrencyData.id || recurrencyData.external_id;
              
              // Log para debug
              console.log('ID externo identificado:', externalId);
              
              handleRecurrency({
                ...recurrencyData,
                externalRecurrencyId: externalId
              });
              
              // Atualiza as informações de exibição
              // Correção para calcular a próxima data de pagamento
              let nextPaymentDate = null;
              const status = recurrencyData.status || recurrencyData.recurrencyStatus;
              
              if (recurrencyData.next_payment_date) {
                nextPaymentDate = new Date(recurrencyData.next_payment_date).toLocaleDateString('pt-BR');
              } else if (recurrencyData.recurrencyDate) {
                // Cria uma cópia da data antes de manipulá-la
                let tempDate = new Date(recurrencyData.recurrencyDate);
                
                // Se o status for PENDING, não adicionamos 1 mês
                if (status === 'PENDING' || status === 'PAYMENT_PENDING') {
                  nextPaymentDate = tempDate.toLocaleDateString('pt-BR');
                } else {
                  // Para outros status, adicionamos 1 mês
                  tempDate.setMonth(tempDate.getMonth() + 1);
                  nextPaymentDate = tempDate.toLocaleDateString('pt-BR');
                }
              }
                
              handlePlanInfo({
                price: recurrencyData.price || 0,
                renewalDate: nextPaymentDate || 'xx/xx/xxxx',
                status: getStatusName(status)
              });
              
              return;
            }
          } else {
            console.warn('Nenhum dado de recorrência encontrado no formato esperado');
          }
        }
        catch (error) {
          console.error("Erro ao processar dados de recorrência:", error);
        }
      } catch (error) {
        console.error("Erro ao buscar dados de recorrência:", error);
      }
      
      // Caso falhe o endpoint novo, tenta o método antigo como fallback
      await getExternalRecurrency().then(response => {
        const sortedRecurrencyList = response.sort((a, b) => {
          return new Date(b.recurrencyDate) - new Date(a.recurrencyDate);
        });
        handleRecurrency(sortedRecurrencyList ? sortedRecurrencyList[0] : []);
      });

      await getActivatedPlans().then(response => {
        if (response && response.length > 0) {
          const activePlan = response[0];
          
          // Calcula a data de renovação (1 mês após a data de assinatura)
          const signatureDate = new Date(activePlan.signature_date);
          const renewalDate = new Date(signatureDate);
          renewalDate.setMonth(renewalDate.getMonth() + 1);
          
          handlePlanInfo({
            price: activePlan.price || 0,
            renewalDate: renewalDate.toLocaleDateString('pt-BR'),
            status: activePlan.is_active ? "Pagamento Aprovado" : 
                   activePlan.status === "PENDING" ? "Aguardando Pagamento" :
                   activePlan.status === "PAYMENT_FAILED" ? "Pagamento Recusado" :
                   activePlan.status === "CANCELED" ? "Assinatura Cancelada" : "Status Desconhecido"
          });
        }
      });
    }
    getRecurrencyData();
  }, []);

  return recurrency ? (
    <Wrapper>
      {loading ? <Overlay /> : []}

      <Subtitle>{planInfo.status === "Pagamento Recusado" || planInfo.status === "Assinatura Cancelada"
        ? <RedSpam><Warning /> </RedSpam>
        : planInfo.status === "Aguardando Pagamento"
        ? <YellowSpam><Warning /> </YellowSpam>
        : []
      }Seu plano</Subtitle>

      <InfoWrapper>
        {/* <InfoTitle>Armazenamento mensal: <InfoStrong>{planInfo.storage} GB</InfoStrong></InfoTitle> */}
        {/* <InfoTitle>Horas mensais: <InfoStrong>{planInfo.hours} Horas</InfoStrong></InfoTitle> */}
        <InfoTitle>Valor do plano: <InfoStrong>R$ {planInfo.price ? Number(planInfo.price).toFixed(2).replace('.', ',') : '0,00'}</InfoStrong></InfoTitle>
        <InfoTitle>Próximo pagamento: <InfoStrong>{planInfo.renewalDate}</InfoStrong></InfoTitle>
        <InfoTitle>Status: {
          planInfo.status === "Pagamento Recusado" || planInfo.status === "Assinatura Cancelada"
            ? <RedSpam>{planInfo.status}</RedSpam>
            : planInfo.status === "Aguardando Pagamento"
            ? <YellowSpam>{planInfo.status}</YellowSpam>
            : <GreenSpam>{planInfo.status}</GreenSpam>
        }
        </InfoTitle>
        {planInfo.status === "Pagamento Recusado"
          ? <InfoWarning>*Por favor, verifique com seu banco ou altere o cartão de pagamento.</InfoWarning>
          : planInfo.status === "Aguardando Pagamento"
          ? <InfoWarning>*Aguardando confirmação do pagamento. Isso pode levar até uma hora.</InfoWarning>
          : planInfo.status === "Assinatura Cancelada"
          ? <InfoWarning>*Sua assinatura foi cancelada. Para reativar, escolha um novo plano.</InfoWarning>
          : []}
      </InfoWrapper>

      <ButtonsWrapper>
        <StyledButton 
          disabled={planInfo.status === '---' || planInfo.status === 'Assinatura Cancelada'} 
          onClick={handleChangeRecurrencyCard}
        > 
          Alterar cartão de cobrança 
        </StyledButton>
        {hasToShowChangeCardPopup && (
          <RecurrencyCardChangePopup
            close={() => handleHasToShowChangeCardPopup(false)}
            recurrencyInfo={recurrency}
          />
        )}

        <StyledButton 
          disabled={planInfo.status === '---' || planInfo.status === 'Assinatura Cancelada'} 
          onClick={handleCancelRecurrency}
        >
          Cancelar Assinatura
        </StyledButton>
        {hasToShowCancelPopup && (
          <RecurrencyCancelPopup
            phase={phase}
            onCloseResultPhase={onCloseResultPhase}
            error={error}
            success={success}
            CancelInfoText={CancelInfoText}
            onConfirmCancel={onConfirmCancel}
            toContinuePhase={toContinuePhase}
            subscriptionEndDate={subscriptionEndDate}
            onCancelCancel={onCancelCancel}
            CancelInfoSubTitle={CancelInfoSubTitle}
            toCancelPhase={toCancelPhase}
            reasonText={reasonText}
            other={other}
            checkboxFormError={checkboxFormError}
            onChangeText={onChangeText}
            handleChange={handleChange}
            price={price}
            dificulties={dificulties}
            customerService={customerService}
            fulfillExpetations={fulfillExpetations}
            problems={problems}
          />
        )}
      </ButtonsWrapper>
    </Wrapper>
  ) : null;
};

import React, { useEffect, useState } from 'react';
import { Payment, initMercadoPago } from '@mercadopago/sdk-react';
import { processPayment } from '../../../../services/noar-api';
import { useDispatch } from 'react-redux';
import { openConfirmationLightbox } from '../../../../store/ducks/global/actions';
import { MP_PUBLIC_KEY } from '@Constants';
import { PurchaseBlockedFor24Hours } from '../../../../errors';
import styled from 'styled-components';
import MercadoPagoService from '../../../../services/MercadoPagoService';

const TermsContainer = styled.div`
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  line-height: 1.5;
  margin: 0 0 16px 0;
  padding: 16px;
  border-radius: 8px;
  background-color: rgba(16, 18, 31, 0.6);
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  overflow-wrap: break-word;
  word-wrap: break-word;
  
  /* Adiciona uma borda sutil para maior clareza */
  border: 1px solid rgba(255, 255, 255, 0.1);
`;

const TermsHighlight = styled.span`
  color: #a78bfa;
  font-weight: 500;
`;

const PaymentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  
  // Estilos para substituir os padrões do Mercado Pago
  .mercadopago-button {
    background-color: #8b5cf6 !important;
    background-image: linear-gradient(90deg, #8b5cf6 0%, #6366f1 100%) !important;
  }
  
  .mp-checkout-container {
    font-family: inherit !important;
    width: 100% !important;
    max-width: 100% !important;
  }
  
  .mp-checkout-title {
    color: white !important;
    font-size: 1.5rem !important;
    font-weight: bold !important;
    margin-bottom: 1rem !important;
  }
  
  // Certifique-se de que o popup não é cortado
  div[data-for="mercadopago-checkout"] {
    z-index: 9999 !important;
  }
`;

// Container adicional para o Mercado Pago
const MercadoPagoWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  
  // Define tamanho específico para o iframe do Mercado Pago
  iframe {
    max-width: 100% !important;
    width: 100% !important;
  }
`;

function PaymentComponent({
  amount,
  preferenceId,
  selectedProductList,
  setPaymentId,
  handleShowPayment,
  onlyPix,
  onlyCard,
  setDisablePaymentContent,
  recurrent,
  discountVoucher,
}) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [initError, setInitError] = useState(null);
  
  console.log('PaymentComponent - Props recebidas:', {
    amount,
    selectedProductList,
    recurrent,
    discountVoucher
  });

  // Inicializa o MercadoPago uma vez quando o componente é montado
  useEffect(() => {
    const initMP = async () => {
      try {
        setIsLoading(true);
        
        // Inicializa o serviço customizado
        await MercadoPagoService.initialize(MP_PUBLIC_KEY);
        MercadoPagoService.trackEvent('payment_form_initialized', { recurrent });
        
        // Inicializa o SDK React globalmente (exigido pelo componente Payment)
        initMercadoPago(MP_PUBLIC_KEY);
        
        setIsLoading(false);
      } catch (error) {
        console.error('Erro ao inicializar Mercado Pago:', error);
        MercadoPagoService.trackEvent('payment_form_initialization_failed', { 
          error: error.message,
          recurrent 
        });
        setInitError('Não foi possível carregar o processador de pagamentos. Por favor, tente novamente ou contate o suporte.');
        setIsLoading(false);
      }
    };
    
    initMP();
  }, [recurrent]);
  
  // Obtém a data atual para o faturamento imediato
  const getNextBillingDateText = () => {
    const today = new Date();
    
    // Formatando a data no estilo "7 de abr. de 2025"
    const day = today.getDate();
    const monthNames = ['jan.', 'fev.', 'mar.', 'abr.', 'maio', 'jun.', 'jul.', 'ago.', 'set.', 'out.', 'nov.', 'dez.'];
    const month = monthNames[today.getMonth()];
    const year = today.getFullYear();
    
    return `${day} de ${month} de ${year}`;
  };

  // Garantir que o valor nunca seja NaN ou zero
  const safeAmount = isNaN(amount) || amount <= 0 ? 169.9 : amount;
  
  const initialization = {
    amount: safeAmount,
    preferenceId,
  };

  console.log('PaymentComponent - Initialization (safe):', initialization);

  const paymentMethods = {
    creditCard: 'all',
    maxInstallments: 1,
  };

  // Configura métodos de pagamento com base nas restrições
  const getPaymentMethods = () => {
    if (onlyPix) {
      return {
        bankTransfer: "pix",
        creditCard: "excluded",
        debitCard: "excluded",
      };
    }
    
    if (onlyCard) {
      // Quando só aceita cartão, não incluímos bankTransfer em vez de defini-lo como "excluded"
      return {
        creditCard: "all",
        maxInstallments: 1, // Sempre 1x, sem parcelamento
      };
    }
    
    // Configuração padrão para todos os métodos
    return {
      bankTransfer: "all",
      creditCard: "all",
      maxInstallments: 1, // Sempre 1x, sem parcelamento
    };
  };

  const customization = {
    paymentMethods: getPaymentMethods(),
    visual: {
      style: {
        theme: 'dark',
      },
    },
  };

  const onSubmit = async ({ selectedPaymentMethod, formData }) => {
    setDisablePaymentContent(true);
    
    // Rastrear tentativa de pagamento
    MercadoPagoService.trackEvent('payment_attempt_started', { 
      paymentMethod: selectedPaymentMethod,
      recurrent
    });
    
    // Adiciona o deviceId do Mercado Pago ao payload se disponível
    const deviceId = MercadoPagoService.getDeviceId();
    if (deviceId) {
      formData.device_id = deviceId;
    }
    
    return new Promise((resolve, reject) => {
      processPayment(formData, selectedProductList, recurrent, discountVoucher)
        .then(async response => {
          try {
            // Obter o corpo da resposta
            const responseBody = await response.json();
            
            // Verificar se a resposta não foi bem-sucedida
            if (!response.ok) {
              // Capturar erros 422 (rejeição de pagamento) ou outros erros da API
              MercadoPagoService.trackEvent('payment_attempt_failed', { 
                status: response.status,
                errorCode: responseBody.error_code || responseBody.code,
                errorMessage: responseBody.message,
                recurrent
              });
              
              handleShowPayment(false);
              
              let errorMessage = responseBody.message || "Seu pagamento não foi aprovado. Por favor, verifique os dados do cartão e tente novamente ou entre em contato com o suporte.";
              
              // Mensagens mais específicas baseadas no código de erro
              if (responseBody.error_code) {
                switch(responseBody.error_code) {
                  case 'cc_rejected_insufficient_amount':
                    errorMessage = "Pagamento rejeitado. Seu cartão não tem saldo suficiente.";
                    break;
                  case 'cc_rejected_high_risk':
                    errorMessage = "Pagamento rejeitado por segurança. Por favor, use outro cartão ou método de pagamento.";
                    break;
                  case 'cc_rejected_blacklist':
                    errorMessage = "Cartão bloqueado. Entre em contato com o emissor do cartão.";
                    break;
                  case 'cc_rejected_bad_filled_security_code':
                    errorMessage = "Código de segurança inválido. Por favor, verifique e tente novamente.";
                    break;
                  case 'cc_rejected_bad_filled_date':
                    errorMessage = "Data de validade incorreta. Por favor, verifique e tente novamente.";
                    break;
                  default:
                    // Mantém a mensagem padrão ou a retornada pela API
                    break;
                }
              }
              
              dispatch(
                openConfirmationLightbox(
                  () => {
                    window.location.reload();
                  },
                  errorMessage,
                  "Fechar",
                  "",
                  null,
                  null,
                  false,
                ),
              );
              setDisablePaymentContent(false);
              reject(new Error(responseBody.message || "Erro ao processar pagamento"));
              return;
            }
            
            // Resposta bem-sucedida - pagamento processado
            MercadoPagoService.trackEvent('payment_attempt_successful', { 
              paymentId: responseBody?.id_payment,
              recurrent
            });
            
            setPaymentId(responseBody?.id_payment);
            
            // NÃO fechamos o popup aqui para evitar problemas de reload
            // Apenas exibimos o modal de confirmação com z-index maior
            
            dispatch(
              openConfirmationLightbox(
                () => {
                  // Aqui sim, fazemos o redirecionamento quando o usuário clica no botão de confirmação
                  if (recurrent) {
                    window.location.href = '/my/profile'; // Redireciona para a página de perfil
                  } else {
                    resolve(); // Continua o fluxo normal para pagamentos não recorrentes
                  }
                },
                "Seu pagamento está sendo processado! Você pode acompanhar o status na página de perfil.",
                "Ver meu perfil",
                "Fechar",
                null,
                null,
                false,
              ),
            );
            
            setDisablePaymentContent(false);
            if (!recurrent) resolve();
          } catch (error) {
            // Usar o tratamento de erro padronizado
            const errorInfo = MercadoPagoService.handleMercadoPagoError(
              error, 
              'processamento de pagamento'
            );
            
            MercadoPagoService.trackEvent('payment_processing_error', { 
              error: errorInfo.technicalDetails,
              recurrent
            });
            
            dispatch(
              openConfirmationLightbox(
                () => {
                  setDisablePaymentContent(false);
                },
                errorInfo.userMessage,
                "Fechar",
                "",
                null,
                null,
                false,
              ),
            );
            setDisablePaymentContent(false);
            reject(error);
          }
        })
        .catch(error => {
          // Usar o tratamento de erro padronizado
          const errorInfo = MercadoPagoService.handleMercadoPagoError(
            error, 
            'comunicação com o servidor'
          );
          
          MercadoPagoService.trackEvent('payment_request_failed', { 
            error: error.message,
            recurrent
          });
          
          dispatch(
            openConfirmationLightbox(
              () => {
                setDisablePaymentContent(false);
              },
              errorInfo.userMessage,
              "Fechar",
              "",
              null,
              null,
              false,
            ),
          );
          setDisablePaymentContent(false);
          reject(error);
        });
    });
  };

  const onError = async error => {
    // Usar o tratamento de erro padronizado
    const errorInfo = MercadoPagoService.handleMercadoPagoError(
      error, 
      'carregamento do formulário de pagamento'
    );
    
    MercadoPagoService.trackEvent('payment_form_error', { 
      error: errorInfo.technicalDetails,
      recurrent
    });
    
    setDisablePaymentContent(false);
    handleShowPayment(false);
    
    dispatch(
      openConfirmationLightbox(
        () => {
          if (!errorInfo.isRecoverable) {
            window.location.reload();
          }
        },
        errorInfo.userMessage,
        errorInfo.isRecoverable ? "Tentar novamente" : "Fechar",
        "",
        null,
        null,
        false,
      ),
    );
  };

  const onReady = async () => {
    console.log('Componente de pagamento pronto');
    MercadoPagoService.trackEvent('payment_form_ready', { recurrent });
  };

  // Mostra uma mensagem de erro se a inicialização falhar
  if (initError) {
    return (
      <PaymentContainer>
        <div style={{ color: '#f87171', padding: '16px', textAlign: 'center' }}>
          {initError}
          <button 
            onClick={() => window.location.reload()}
            style={{ 
              marginTop: '16px', 
              backgroundColor: '#8b5cf6',
              border: 'none',
              borderRadius: '4px',
              padding: '8px 16px',
              color: 'white',
              cursor: 'pointer'
            }}
          >
            Tentar novamente
          </button>
        </div>
      </PaymentContainer>
    );
  }

  // Mostra um indicador de carregamento enquanto inicializa
  if (isLoading) {
    return (
      <PaymentContainer>
        <div style={{ textAlign: 'center', padding: '20px' }}>
          Carregando processador de pagamentos...
        </div>
      </PaymentContainer>
    );
  }

  return (
    <PaymentContainer>
      {recurrent && (
        <TermsContainer>
          O faturamento começa <TermsHighlight>hoje, {getNextBillingDateText()}</TermsHighlight>, e será renovado automaticamente a cada mês. Nao há reembolso ou estorno de valores, com base no direito de arrependimento, pois a NOAR oferece a seus clientes a possibilidade de teste prévio antes da contratação dos serviços. Cancele a qualquer momento nas configurações, assim não haverá cobranças no mes seguinte.
          <br /><br />
          Ao continuar, você confirma que tem no mínimo 18 anos e concorda com estes termos. <TermsHighlight>Pagamento recorrente</TermsHighlight>.
        </TermsContainer>
      )}
      
      <MercadoPagoWrapper>
        <Payment
          initialization={initialization}
          customization={customization}
          onSubmit={onSubmit}
          onReady={onReady}
          onError={onError}
        />
      </MercadoPagoWrapper>
    </PaymentContainer>
  );
}

export default PaymentComponent;

import { id } from "date-fns/locale";

export const faqs = [
  {
    id: 'tramentos-ou-lag',
    question: 'Minha máquina está travando ou com lag.',
    answer: `
    • Para casos de travamentos verifique se as configurações estão corretas no aplicativo.<br>
    • Vá em configurações e escolha a opção mais recomendável (recomendamos melhor desempenho para não haver travamentos).<br>
    • Escolha a opção 60 FPS. Caso ainda mantenha travamentos, recomendamos abaixar para 30 FPS, pois isso significa que seu dispositivo não suporta mais.
    `
  },
  {
    id: 'uso-plano-mensal',
    question: 'Eu só posso jogar uma sessão por dia no plano mensal?',
    answer: `
    • Não, você pode jogar quantas sessões quiser.
    `
  },
  {
    id: 'uso-ios',
    question: 'Consigo usar no IOS (Iphone e Ipad)?',
    answer: `
    • Não, nosso aplicativo para IOS ainda está em desenvolvimento :(.
    `
  },
  {
    id: 'teclado-mobile',
    question: 'Como posso acessar o teclado virtual no celular/tablet?',
    answer: `
    • Para acessar o teclado no celular/tablet, basta abrir o menu flutuante e ativar.`
  },
  {
    id: 'resolucao-vms',
    question: 'Qual é a resolução dos computadores da NOAR.',
    answer: `
    • A resolução 8k está disponível em todos os planos.
    `
  },
  {
    id: 'plano-enterprise',
    question: 'Como funciona o plano Enterprise?',
    answer: `
    • Para saber mais detalhes sobre o plano Enterprise, entre em contato com nossa equipe de vendas pelo email comercial@noarcloud.com.
    `
  },
  {
    id: 'fivem-valorant-disponiveis',
    question: 'Posso jogar Fivem e Valorant?',
    answer: `
    • O FiveM possui um sistema anti-cheat que não permite seu funcionamento em máquinas virtuais. Você consegue jogar, mas corre riscos de ter a conta banida. O Valorant não roda em máquinas virtuais, assim como em outros jogos. Antes de comprar, verifique se seu jogo é compatível com máquinas virtuais.
    `
  },
  {
    id: 'rodar-qualquer-aplicativo',
    question: 'É possível rodar qualquer aplicativo/jogo?',
    answer: `
    • Com a NOAR é possível rodar qualquer aplicativo ou jogo compatível com os nossos sistemas. Verifique a compatibilidade, teste ou entre em contato conosco para mais informações.
    `
  },
  {
    id: 'formas-pagamento',
    question: 'Quais são as formas de pagamento disponíveis?',
    answer: `
    • Você pode pagar via PIX ou cartão de crédito.
    `
  },
  {
    id: 'dispositivos-compativeis',
    question: 'Em quais dispositivos eu consigo utilizar a NOAR?',
    answer: `
    • Utilizando nosso aplicativo Noar Connect você consegue se conectar a partir de computadores Windows, smartphones e tablets Android, Tv Box’s, entre outros. Para mais informações, acesse a página de download.
    `
  },
  {
    id: 'dados-salvos',
    question: 'Meus dados serão salvos quando desligar a máquina?',
    answer: `
    • No plano Unit não, sempre que finalizar a sessão, seus dados serão deletados imediatamente.<br>
    • Salvamento somente disponível no plano para empresas.
    `
  },
  {
    id: 'contabilizacao-horas',
    question: 'Como minhas horas são contabilizadas?',
    answer: `
   • Assim que você iniciar o computador suas horas já começam a contar, mesmo que você não se conecte ou utilize o computador.<br>
   • A conta só para de contar quando você desligar o computador.
   `
  },
  {
    id: 'instalacao-programas-jogos-piratas',
    question: 'Posso instalar programas e jogos piratas?',
    answer: `
   • A NOAR é contra o uso de programas piratas.
   `
  },
  {
    id: 'Meu computador desligou sozinho',
    question: 'Meu computador desligou sozinho.',
    answer: `
   • Os motivos de o computador ter desligado são variados. O sistema anti-afk, algum crash por incompatibilidade, acabou o seu tempo de sessão, ou  são alguns dos motivos, qualquer duvida entre em contato conosco.
   `
  },
  {
    id: 'restricao-conteudo-menores',
    question: 'Existe restrição de conteúdo para menores de idade?',
    answer: `
   • Não, o uso da máquina é livre. Nós recomendamos o uso acompanhado para menores de idade.
   `
  },
  {
    id: 'limites-horas-dia',
    question: 'Tem limite de horas por dia?',
    answer: `
    • Não, o limite é por sessão, mas quando acaba o seu tempo, você pode entrar novamente e continuar usando.
    `
  },
  {
    id: 'perda-acesso-apos-horas-prioritarias',
    question: 'Após as horas prioritárias eu perco o acesso?',
    answer: `
    • Não temos mais horas prioritárias nos planos. São todos ilimitados.
    `
  },
  {
    id: 'plano-ilimitado',
    question: 'Tem plano ilimitado?',
    answer: `
    • Todos os planos são de uso ilimitado. Você poderá utilizar quantas vezes quiser!
    `
  },
  {
    id: 'renderizacao-treinamento-ia',
    question: 'Posso renderizar, minerar ou fazer treinamento de IA?',
    answer: `
    • Para renderização, treinamento de IA e minerar criptomoedas temos planos específicos que podem ser contratados através dos nossos canais de atendimento. Os planos padrão não podem ser utilizados para renderização ou treinamento de IA.
    `
  },
  {
    id: 'horas-prioritarias',
    question: 'O que são as “horas prioritárias”?',
    answer: `
    • O sistema de horas prioritárias foi descontinuado. Agora todos os planos são ilimitados, aproveite :).<br>
    `
  },
  {
    id: 'arquivos-salvos',
    question: 'Os arquivos são salvos?',
    answer: `
    • No plano atual os arquivos sempre são excluídos quando a máquina é desligada. Uma forma de manter um jogo salvo é contratando o plano Ultra, que te dá direito de escolher um jogo ou programa até 80GB para ficar pré instalado.
    `
  },
  {
    id: 'Reembolso',
    question: 'Como funciona o reembolso?',
    answer: `
    • Não temos reembolso de planos, antes de comprar faça o teste gratuito ou entre em contato conosco para tirar suas duvidas antes de comprar, para ter certeza de que o plano é o ideal para você.
    `
  }

];

export default faqs;

import React, { useState } from 'react';
import * as S from '../../styled';
import { FaDesktop, FaCloud, FaShieldAlt, FaExpandArrowsAlt, FaMicrochip, FaMemory, FaRobot } from 'react-icons/fa';
import ServicePopup from '../../components/ServicePopup';
import { Link } from 'react-router-dom';

const DaasSpecialized = () => {
  const [selectedService, setSelectedService] = useState(null);

  const services = [
    {
      id: 'high-performance',
      icon: <FaMicrochip />,
      title: 'Alto Desempenho',
      features: [
        {
          icon: <FaMicrochip />,
          title: 'Processamento Dedicado',
          description: 'Processamento otimizado para softwares pesados e design gráfico.'
        },
        {
          icon: <FaMemory />,
          title: 'Memória Otimizada',
          description: 'Alta capacidade de memória para operações complexas.'
        },
        {
          icon: <FaDesktop />,
          title: 'Renderização Avançada',
          description: 'Ideal para aplicações 3D e Cinema 4D.'
        }
      ]
    },
    {
      id: 'flexibility',
      icon: <FaCloud />,
      title: 'Flexibilidade',
      features: [
        {
          icon: <FaCloud />,
          title: 'Múltiplas Aplicações',
          description: 'Suporte desde aplicativos desktop até software especializado.'
        },
        {
          icon: <FaExpandArrowsAlt />,
          title: 'Escalabilidade',
          description: 'Recursos que crescem conforme a demanda da sua empresa.'
        },
        {
          icon: <FaDesktop />,
          title: 'Acesso Universal',
          description: 'Acesso às aplicações de qualquer dispositivo.'
        }
      ]
    },
    {
      id: 'security',
      icon: <FaShieldAlt />,
      title: 'Segurança',
      features: [
        {
          icon: <FaShieldAlt />,
          title: 'Proteção Avançada',
          description: 'Infraestrutura com protocolos avançados de segurança.'
        },
        {
          icon: <FaCloud />,
          title: 'Backup Automático',
          description: 'Sistema de backup e recuperação de dados.'
        },
        {
          icon: <FaExpandArrowsAlt />,
          title: 'Suporte 24/7',
          description: 'Suporte técnico especializado disponível 24 horas.'
        }
      ]
    },
    {
      id: 'machine-learning',
      icon: <FaRobot />,
      title: 'Machine Learning',
      features: [
        {
          icon: <FaMicrochip />,
          title: 'GPUs de Alta Performance',
          description: 'Infraestrutura com GPUs NVIDIA A100/H100 para treinamento de modelos.'
        },
        {
          icon: <FaCloud />,
          title: 'Ambientes Otimizados',
          description: 'Frameworks pré-configurados para desenvolvimento de IA.'
        },
        {
          icon: <FaShieldAlt />,
          title: 'Segurança de Dados',
          description: 'Proteção avançada para datasets e modelos proprietários.'
        }
      ]
    }
  ];

  return (
    <S.ServiceCard>
      <S.ServiceHeader>
        <S.ServiceIcon>
          <FaDesktop />
        </S.ServiceIcon>
        <Link to="/desktop-as-a-service" style={{ textDecoration: 'none', position: 'relative' }}>
          <S.ServiceTitle style={{ 
            transition: 'color 0.3s ease', 
            position: 'relative'
          }}
          onMouseEnter={(e) => e.currentTarget.style.color = '#00a8ff'}
          onMouseLeave={(e) => e.currentTarget.style.color = ''}
          >
            DaaS de Alto Desempenho
          </S.ServiceTitle>
        </Link>
      </S.ServiceHeader>

      <S.SubServicesList>
        {services.map((service) => (
          service.id === 'machine-learning' ? (
            <Link to="/machine-learning" key={service.id} style={{ textDecoration: 'none' }}>
              <S.SubServiceItem>
                <S.SubServiceIcon>
                  {service.icon}
                </S.SubServiceIcon>
                <S.SubServiceTitle>
                  {service.title}
                </S.SubServiceTitle>
              </S.SubServiceItem>
            </Link>
          ) : (
            <S.SubServiceItem
              key={service.id}
              onClick={() => setSelectedService(service)}
            >
              <S.SubServiceIcon>
                {service.icon}
              </S.SubServiceIcon>
              <S.SubServiceTitle>
                {service.title}
              </S.SubServiceTitle>
            </S.SubServiceItem>
          )
        ))}
      </S.SubServicesList>

      {selectedService && (
        <ServicePopup
          isOpen={!!selectedService}
          onClose={() => setSelectedService(null)}
          title={selectedService.title}
          features={selectedService.features}
          ctaText="Solicitar Demonstração"
        />
      )}
    </S.ServiceCard>
  );
};

export default DaasSpecialized; 
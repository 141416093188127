import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Title, Subtitle, Text } from '@Components/Public';
import { desktopMinSize, mqmin } from '@Styles/mixins';

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
`;

const floatAnimation = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const glowAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 4rem 1.5rem;
  position: relative;
  overflow: hidden;
  background: linear-gradient(135deg, #17171A 0%, #1E1E24 100%);

  ${mqmin(desktopMinSize)} {
    padding: 8rem 2rem;
  }
`;

const GlowBackground = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150%;
  height: 150%;
  background: radial-gradient(circle at center, 
    rgba(0,122,255,0.15) 0%, 
    rgba(160,11,246,0.1) 30%, 
    rgba(23,23,26,0.05) 70%
  );
  pointer-events: none;
  animation: ${pulseAnimation} 8s ease-in-out infinite;
  filter: blur(60px);
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 3rem;
  position: relative;
  z-index: 1;
  padding: 0 1.5rem;

  ${mqmin(768)} {
    padding: 0 2rem;
  }

  ${mqmin(desktopMinSize)} {
    padding: 0;
  }

  ${Title} {
    margin-bottom: 1rem;
    font-size: 1.75rem;
    background: linear-gradient(135deg, #fff 0%, #B4B4B4 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    
    ${mqmin(768)} {
      font-size: 2.25rem;
    }

    ${mqmin(desktopMinSize)} {
      font-size: 3rem;
    }
    
    &::after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 40px;
      height: 3px;
      background: linear-gradient(90deg, #007AFF, #6B3AFE);
      border-radius: 2px;

      ${mqmin(desktopMinSize)} {
        width: 60px;
      }
    }
  }

  ${Subtitle} {
    color: #007AFF;
    font-size: 1rem;
    font-weight: bold;
    text-shadow: 0 0 20px rgba(0,122,255,0.3);
    margin-top: 1.5rem;
    
    ${mqmin(768)} {
      font-size: 1.25rem;
    }

    ${mqmin(desktopMinSize)} {
      font-size: 1.5rem;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  gap: 1.5rem;
  width: 100%;
  max-width: 1200px;
  position: relative;
  z-index: 1;
  padding: 0 1.5rem;
  
  @media (min-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 2rem;
  }
  
  ${mqmin(desktopMinSize)} {
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    padding: 0;
  }
`;

const CardContent = styled.div`
  position: relative;
  z-index: 1;
`;

const CardNumber = styled.span`
  display: block;
  font-size: 1.25rem;
  font-weight: bold;
  background: linear-gradient(135deg, #007AFF 0%, #6B3AFE 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1rem;
  font-family: 'Courier New', monospace;
  transition: all 0.3s ease;
`;

const CardTitle = styled.h3`
  color: #fff;
  font-size: 1.25rem;
  margin: 0 0 0.75rem;
  background: linear-gradient(135deg, #fff 0%, #B4B4B4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  ${mqmin(768)} {
    font-size: 1.5rem;
    margin: 0 0 1rem;
  }
`;

const CardText = styled(Text)`
  color: #B4B4B4;
  font-size: 0.875rem;
  line-height: 1.5;

  ${mqmin(768)} {
    font-size: 1rem;
  }
`;

const CardGlow = styled.div`
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.1),
    transparent
  );
  transition: 0.5s;
  pointer-events: none;
`;

const Card = styled.div`
  position: relative;
  background: rgba(41, 41, 50, 0.8);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 1rem;
  padding: 1.5rem;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  overflow: hidden;

  ${mqmin(desktopMinSize)} {
    padding: 2rem;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, 
      rgba(0,122,255,0.1) 0%, 
      rgba(107,58,254,0.1) 100%
    );
    opacity: 0;
    transition: opacity 0.4s ease;
  }

  @media (hover: hover) {
    &:hover {
      transform: translateY(-10px) scale(1.02);
      border-color: transparent;
      box-shadow: 
        0 10px 30px -10px rgba(0,0,0,0.5),
        0 0 0 1px rgba(0,122,255,0.3);

      &::before {
        opacity: 1;
      }

      ${CardNumber} {
        background: linear-gradient(135deg, #007AFF 0%, #6B3AFE 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        transform: scale(1.1);
      }

      ${CardGlow} {
        left: 100%;
      }

      ${CardTitle} {
        background: linear-gradient(135deg, #007AFF 0%, #6B3AFE 100%);
        background-size: 200% 200%;
        animation: ${glowAnimation} 2s linear infinite;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
`;

const Benefits = () => (
  <Section id="beneficios">
    <GlowBackground />
    <Header>
      <Title>
        <strong>Por que usar a Noar?</strong>
      </Title>
      <Subtitle>
        Potência computacional sem limites
      </Subtitle>
    </Header>
    <Grid>
      <Card>
        <CardGlow />
        <CardContent>
          <CardNumber>01</CardNumber>
          <CardTitle>Poder Computacional</CardTitle>
          <CardText>
            Rode aplicativos pesados em qualquer dispositivo através da nossa tecnologia de streaming em nuvem
          </CardText>
        </CardContent>
      </Card>
      <Card>
        <CardGlow />
        <CardContent>
          <CardNumber>02</CardNumber>
          <CardTitle>Flexibilidade</CardTitle>
          <CardText>
            Escolha o plano que se encaixa melhor no seu dia a dia
          </CardText>
        </CardContent>
      </Card>
      <Card>
        <CardGlow />
        <CardContent>
          <CardNumber>03</CardNumber>
          <CardTitle>Simplicidade</CardTitle>
          <CardText>
            Interface intuitiva e fácil de usar, sem necessidade de conhecimentos técnicos
          </CardText>
        </CardContent>
      </Card>
    </Grid>
  </Section>
);

export default Benefits; 
import React from 'react';
import * as S from './styled';
import Header from './sections/Header';
import CloudflareServices from './sections/CloudflareServices';
import NetworkServices from './sections/NetworkServices';
import DevelopmentPlatform from './sections/DevelopmentPlatform';
import StorageMedia from './sections/StorageMedia';
import SecurityZeroTrust from './sections/SecurityZeroTrust';
import DomainServices from './sections/DomainServices';
import CloudManagement from './sections/CloudManagement';
import RemoteSupport from './sections/RemoteSupport';
import WindowsServer from './sections/WindowsServer';
import ContactSection from './ContactUsBusiness';
import AboutBusiness from './About';
import ServerSection from './sections/ServerSection';
import DaasSpecialized from './sections/DaasSpecialized';
import DevelopmentSection from './sections/DevelopmentSection';
import styled from 'styled-components';
import { colors } from '../../constants/colors';

function ForBusinessWrapper() {
  return (
    <>
      <S.GlowBackground />
      <S.ContentWrapper>
        {/* Header Marketing */}
        <div id="about">
          <Header />
        </div>
        
        {/* Seção Sobre */}
        <AboutBusiness />
        
        {/* Seção de Servidores */}
        <div id="servidores">
          <ServerSection />
        </div>

        {/* Seção de Desenvolvimento */}
        <div id="desenvolvimento-completo">
          <DevelopmentSection />
        </div>
        
        {/* Seção Principal de Serviços */}
        <S.ServicesSection>
          <S.SectionTitle>Nossos Serviços</S.SectionTitle>
          <S.ServicesDescription>
            Conheça nossa gama completa de soluções em cloud computing e infraestrutura para sua empresa
          </S.ServicesDescription>
          
          <S.ServicesGrid>
            <div id="cloudflare">
              <CloudflareServices />
            </div>
            <div id="rede">
              <NetworkServices />
            </div>
            <div id="desenvolvimento">
              <DevelopmentPlatform />
            </div>
            <div id="armazenamento">
              <StorageMedia />
            </div>
            <div id="seguranca">
              <SecurityZeroTrust />
            </div>
            <div id="dominio">
              <DomainServices />
            </div>
          </S.ServicesGrid>
        </S.ServicesSection>

        {/* Seção de FAQ - Nova */}
        <S.ServicesSection id="faq">
          <S.SectionTitle>Perguntas Frequentes</S.SectionTitle>
          <S.ServicesDescription>
            Respostas para as dúvidas mais comuns sobre nossos serviços para empresas
          </S.ServicesDescription>
          
          <FaqContainer>
            <FaqItem>
              <FaqQuestion>Quais tipos de empresas podem se beneficiar dos serviços da NOAR?</FaqQuestion>
              <FaqAnswer>
                Empresas de todos os portes, desde startups até grandes corporações, podem se beneficiar dos nossos serviços. 
                Oferecemos soluções escaláveis que se adaptam às necessidades específicas de cada organização, 
                seja para reduzir custos, aumentar a segurança ou melhorar a eficiência operacional.
              </FaqAnswer>
            </FaqItem>
            
            <FaqItem>
              <FaqQuestion>Como funciona o modelo de precificação para empresas?</FaqQuestion>
              <FaqAnswer>
                Trabalhamos com modelos de precificação personalizados baseados nas necessidades específicas de cada empresa. 
                Oferecemos tanto opções de pagamento por uso (pay-as-you-go) quanto planos mensais com recursos dedicados. 
                Entre em contato com nossa equipe comercial para obter uma cotação personalizada.
              </FaqAnswer>
            </FaqItem>
            
            <FaqItem>
              <FaqQuestion>Qual o nível de suporte técnico oferecido para clientes empresariais?</FaqQuestion>
              <FaqAnswer>
                Clientes empresariais têm acesso a suporte técnico prioritário 24/7 com tempos de resposta garantidos 
                por SLA (Service Level Agreement). Também oferecemos gerenciamento proativo da infraestrutura, 
                monitoramento contínuo e relatórios regulares de desempenho.
              </FaqAnswer>
            </FaqItem>
            
            <FaqItem>
              <FaqQuestion>A NOAR oferece soluções de compliance e segurança para setores regulamentados?</FaqQuestion>
              <FaqAnswer>
                Sim, nossas soluções podem ser configuradas para atender a requisitos específicos de compliance 
                como LGPD, ISO 27001, PCI DSS, entre outros. Implementamos práticas de segurança robustas, 
                incluindo criptografia de dados, autenticação multifator e controles de acesso granulares.
              </FaqAnswer>
            </FaqItem>
            
            <FaqItem>
              <FaqQuestion>É possível integrar os serviços da NOAR com ferramentas e sistemas existentes?</FaqQuestion>
              <FaqAnswer>
                Absolutamente. Nossas soluções foram projetadas para integração perfeita com sistemas existentes 
                através de APIs robustas e conectores pré-construídos. Nossa equipe técnica pode ajudar a desenvolver 
                integrações personalizadas para casos de uso específicos da sua empresa.
              </FaqAnswer>
            </FaqItem>
          </FaqContainer>
        </S.ServicesSection>

        {/* Seção de Serviços Especializados */}
        <S.SpecializedServicesSection>
          <S.SectionTitle>Serviços Especializados</S.SectionTitle>
          <S.ServicesDescription>
            Soluções avançadas para necessidades específicas do seu negócio
          </S.ServicesDescription>
          
          <S.ServicesGrid>
            <div id="cloud-management">
              <CloudManagement />
            </div>
            <div id="helpdesk">
              <RemoteSupport />
            </div>
            <div id="windows-server">
              <WindowsServer />
            </div>
            <div id="daas-specialized">
              <DaasSpecialized />
            </div>
          </S.ServicesGrid>
        </S.SpecializedServicesSection>

        {/* Seção de Email Comercial */}
        <div id="contato-comercial">
          <EmailContactSection>
            <EmailContactTitle>Entre em Contato</EmailContactTitle>
            <EmailContactDescription>
              Nossa equipe comercial está pronta para atender às necessidades da sua empresa.
              Entre em contato para obter uma solução personalizada.
            </EmailContactDescription>
            <EmailCard>
              <EmailIcon>✉️</EmailIcon>
              <EmailAddress href="mailto:comercial@noarcloud.com">comercial@noarcloud.com</EmailAddress>
            </EmailCard>
            <EmailContactInfo>
              Responderemos sua solicitação em até 24 horas úteis.
            </EmailContactInfo>
          </EmailContactSection>
        </div>

        {/* Seção de Contato */}
        <ContactSection />
      </S.ContentWrapper>
    </>
  );
}

// Estilos para a seção de FAQ
const FaqContainer = styled.div`
  max-width: 900px;
  margin: 40px auto 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FaqItem = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  padding: 20px;
  transition: all 0.3s ease;
  
  &:hover {
    background: rgba(255, 255, 255, 0.1);
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
`;

const FaqQuestion = styled.h3`
  color: #2d80f2;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 10px;
`;

const FaqAnswer = styled.p`
  color: #b4b7c0;
  font-size: 1rem;
  line-height: 1.6;
`;

// Estilos para a seção de Email Comercial
const EmailContactSection = styled.section`
  text-align: center;
  padding: 4rem 2rem;
  background: linear-gradient(135deg, #1e293b 0%, #0f172a 100%);
  border-radius: 24px;
  margin: 3rem 0;
  color: ${colors.white};
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at 70% 30%, rgba(0, 168, 255, 0.1) 0%, transparent 70%);
  }
`;

const EmailContactTitle = styled.h2`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  position: relative;
`;

const EmailContactDescription = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 700px;
  margin: 0 auto 2rem;
  opacity: 0.9;
`;

const EmailCard = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  padding: 2rem;
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
    background: rgba(255, 255, 255, 0.1);
  }
`;

const EmailIcon = styled.span`
  font-size: 2rem;
`;

const EmailAddress = styled.a`
  color: #00a8ff;
  font-size: 1.5rem;
  font-weight: 600;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

const EmailContactInfo = styled.p`
  margin-top: 1.5rem;
  font-size: 0.9rem;
  opacity: 0.7;
`;

export default ForBusinessWrapper;

import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import logo from '@Assets/logo.svg';
import { desktopMinSize, mqmin } from '@Styles/mixins';

import Desktop from './Desktop';
import Mobile from './Mobile';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { mqmax } from '../../../styles/mixins';

const Wrapper = props => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < desktopMinSize);
  const [actualPath, setActualPath] = useState(null);

  useEffect(
    function () {
      if (window.location.hash) {
        const { top } = document.querySelector(window.location.hash).getBoundingClientRect();
        const { body } = document;
        body.scrollTo({ behavior: 'smooth', top: top + body.scrollTop - OFFSET });
      }
    },
    [actualPath],
  );

  useEffect(
    function () {
      window.addEventListener(
        'resize',
        function () {
          setIsMobile(window.innerWidth < desktopMinSize);
        },
        { passive: true },
      );
    },
    [setIsMobile],
  );

  return (
    <header {...props}>
      <Container>
        <Anchor to="/">
          <Logo src={logo} alt="Noar" />
        </Anchor>
        {isMobile ? (
          <Mobile useScroll={useScroll} setActualPath={setActualPath} />
        ) : (
          <Desktop useScroll={useScroll} setActualPath={setActualPath} />
        )}
      </Container>
    </header>
  );
};

function useScroll(path, setActualPath, handleCloseClick) {
  const history = useHistory();
  const isMobile = window.innerWidth < desktopMinSize;
  const outHomeUrls = [
    '/for-business',
    '/planos/personal',
    '/planos/unit',
    '/planos/classic',
    '/planos/comparacao',
    '/cloud-gaming',
    '/desktop-as-a-service',
    '/computador-na-nuvem'
  ];
  const onClick = useCallback(async function (event) {
    event.preventDefault();
    if (isMobile) {
      handleCloseClick && handleCloseClick();
    }

    if (outHomeUrls.includes(window.location.pathname)) {
      const targetHash = path.startsWith('#') ? path : '';
      
      if (window.location.pathname === '/') {
        if (targetHash) {
          try {
            const element = document.querySelector(targetHash);
            if (element) {
              const { top } = element.getBoundingClientRect();
              const { body } = document;
              body.scrollTo({ behavior: 'smooth', top: top + body.scrollTop - OFFSET });
            } else {
              console.warn(`Elemento ${targetHash} não encontrado na página`);
            }
          } catch (error) {
            console.error('Erro ao rolar para o elemento:', error);
          }
        }
      } else {
        await history.push('/');
        
        if (targetHash) {
          setTimeout(() => {
            try {
              const element = document.querySelector(targetHash);
              if (element) {
                const { top } = element.getBoundingClientRect();
                const { body } = document;
                body.scrollTo({ behavior: 'smooth', top: top + body.scrollTop - OFFSET });
              } else {
                console.warn(`Elemento ${targetHash} não encontrado após navegação`);
              }
            } catch (error) {
              console.error('Erro ao rolar para o elemento após navegação:', error);
            }
          }, 300);
        }
      }
      setActualPath && setActualPath(path);
    } else {
      try {
        const element = document.querySelector(event.currentTarget.hash);
        if (element) {
          const { top } = element.getBoundingClientRect();
          const { body } = document;
          body.scrollTo({ behavior: 'smooth', top: top + body.scrollTop - OFFSET });
        } else {
          console.warn(`Elemento ${event.currentTarget.hash} não encontrado na página`);
        }
      } catch (error) {
        console.error('Erro ao rolar para o elemento:', error);
      }
    }
  }, [history, handleCloseClick, isMobile, path, setActualPath]);

  return onClick;
}

function getBaseFontSize() {
  return Number(window.getComputedStyle(document.body).getPropertyValue('font-size').match(/\d+/)[0]);
}

const OFFSET = (80 * getBaseFontSize()) / 16;

const Container = styled.nav`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;

  ${mqmin(desktopMinSize)} {
    height: auto;
  }
`;

const Anchor = styled(Link)`
  display: inline-flex;
`;

const Logo = styled.img`
  width: calc(143rem / 24);
  height: auto;

  ${mqmin(desktopMinSize + 400)} {
    width: calc(143rem / 16);
  }

  ${mqmax(300)} {
    width: calc(100rem / 32);
  }
`;

export const Header = styled(Wrapper)`
  --bgAlpha: 1;

  padding: calc(28rem / 32) calc((1 - 304 / 360) * 50%);
  color: #fff;
  background-color: rgba(23, 23, 26, var(--bgAlpha));
  backdrop-filter: blur(5px);

  ${mqmin(desktopMinSize)} {
    --bgAlpha: 70%;

    border-radius: calc(30rem / 16);
    padding: calc(16rem / 16) calc(57rem / 16);
    backdrop-filter: blur(5px);
  }
`;

export default Header;

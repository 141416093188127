import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { getAddressByCep } from '@Services/noar-api';
import { mqmax, desktopMinSize } from '@Styles/mixins';
import { REDIRECT_TO_LOGIN, messageDisconnect, ERROR } from '@Constants';
import { SessionExpired } from '@Errors';
import { openConfirmationLightbox } from '@Store/ducks/global/actions';
import { Loading } from '../../../../../../components/Loading';
import { CircularProgress } from '@material-ui/core';

// Styled components modernos para o endereço
const AddressContainer = styled.div`
  background: linear-gradient(135deg, #2a2d3e 0%, #1a1c25 100%);
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  max-width: 500px;
  width: 100%;
  position: relative;
  margin: 0 auto;
`;

const AddressHeader = styled.div`
  text-align: center;
  margin-bottom: 24px;
  font-weight: bold;
  color: #fff;
  font-size: 1.5rem;
  letter-spacing: 1px;
`;

const AddressBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const AddressForm = styled.form`
  width: 100%;
`;

const InputRow = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  
  @media (max-width: 480px) {
    flex-direction: column;
    gap: 12px;
  }
`;

const InputColumn = styled.div`
  flex: 1;
  min-width: 0;
`;

const AddressInput = styled.input`
  background-color: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 16px;
  color: white;
  width: 100%;
  transition: all 0.3s ease;
  outline: none;
  box-sizing: border-box;
  
  &:focus {
    border-color: #8b5cf6;
    box-shadow: 0 0 0 2px rgba(139, 92, 246, 0.2);
  }
  
  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

const SubmitButton = styled.button`
  margin-top: 24px;
  width: 100%;
  background: linear-gradient(90deg, #8b5cf6 0%, #6366f1 100%);
  color: white;
  border-radius: 8px;
  padding: 12px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(139, 92, 246, 0.4);
  }
  
  &:active {
    transform: translateY(0);
  }
  
  &:disabled {
    background: #4b5563;
    cursor: not-allowed;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    color: #8b5cf6;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
`;

const HelperText = styled.p`
  color: #a78bfa;
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 0;
`;

const TermsText = styled.div`
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  line-height: 1.5;
  margin-top: 16px;
  margin-bottom: 8px;
  padding: 12px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.05);
`;

const TermsHighlight = styled.span`
  color: #a78bfa;
  font-weight: 500;
`;

export const AdressData = ({
  close,
  handleViewControl,
  adressInfo,
  handleAdressInfo,
  handleConfirmPaymentReturn
}) => {
  const [billing, handleBilling] = useState(adressInfo?.billing || {});
  const [keepAdressOption, handleKeepAdressOption] = useState(true);
  const [changedKeepAdressOption, handleChangedKeepAdressOption] = useState(true);
  const [loading, handleLoading] = useState(false);
  const [addressLoading, setAddressLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  function handleDisableButton() {
    const billingInvalid = !(billing?.cep && billing?.endereco && billing?.numero && billing?.bairro && billing?.cidade && billing?.estado);
    if (!changedKeepAdressOption) return true;
    return billingInvalid;
  }

  // ASYNC FUNCTIONS
  async function getAddress(origin) {
    try {
      if(billing?.cep?.length === 8) {
        setAddressLoading(true);
        const response = await getAddressByCep(billing?.cep);
        
        handleBilling({...billing, ...{
          endereco: response?.logradouro,
          bairro: response?.bairro,
          cidade: response?.localidade,
          estado: response?.uf
        }});
        
        setAddressLoading(false);
      }
    } catch (error) {
      setAddressLoading(false);
      if (error instanceof SessionExpired) {
        const turnOnMachine = false;
        const disconnect = true;
        dispatch(
          openConfirmationLightbox(
            () => {
              history.push(REDIRECT_TO_LOGIN);
            },
            messageDisconnect.message,
            messageDisconnect.confirmBtnText,
            messageDisconnect.refuseBtnText,
            turnOnMachine,
            disconnect,
            false,
          ),
        );
      } else {
        handleConfirmPaymentReturn({ status: ERROR });
      }
    }
  }

  function onChangeCep(event) {
    event.preventDefault();
    let cep = event.target.value.replace(/\D/g, '').substring(0, 8);
    handleBilling({...billing, cep});
  }

  function onChangeNumero(event) {
    event.preventDefault();
    handleBilling({...billing, numero: event.target.value});
  }

  function onChangeComplemento(event) {
    event.preventDefault();
    handleBilling({...billing, complemento: event.target.value});
  }

  function onChangeEndereco(event) {
    event.preventDefault();
    handleBilling({...billing, endereco: event.target.value});
  }

  function onChangeBairro(event) {
    event.preventDefault();
    handleBilling({...billing, bairro: event.target.value});
  }

  function onChangeCidade(event) {
    event.preventDefault();
    handleBilling({...billing, cidade: event.target.value});
  }

  function onChangeEstado(event) {
    event.preventDefault();
    const upperCasedValue = event.target.value ? event.target.value.toUpperCase() : '';
    handleBilling({...billing, estado: upperCasedValue});
  }

  function onSubmitForm(ev) {
    ev.preventDefault();
    handleAdressInfo({ billing, keepAdressOption, changedKeepAdressOption });
    handleViewControl(2);
  }

  // Obtém a data atual para o faturamento imediato
  const getNextBillingDateText = () => {
    const today = new Date();
    
    // Formatando a data no estilo "7 de abr. de 2025"
    const day = today.getDate();
    const monthNames = ['jan.', 'fev.', 'mar.', 'abr.', 'maio', 'jun.', 'jul.', 'ago.', 'set.', 'out.', 'nov.', 'dez.'];
    const month = monthNames[today.getMonth()];
    const year = today.getFullYear();
    
    return `${day} de ${month} de ${year}`;
  };

  return (
    <AddressContainer>
      <CloseButton onClick={close}>×</CloseButton>
      
      {loading ? (
        <LoadingContainer>
          <Loading whichLoading={'generic'} />
        </LoadingContainer>
      ) : (
        <AddressBox>
          <AddressHeader>Endereço de cobrança</AddressHeader>
          
          <AddressForm onSubmit={onSubmitForm}>
            <InputRow>
              <InputColumn>
                <AddressInput
                  type="text"
                  name="cep"
                  placeholder="CEP"
                  required
                  maxLength="8"
                  value={billing?.cep || ''}
                  onChange={onChangeCep}
                  onBlur={() => getAddress('ADDRESS')}
                  autoComplete="off"
                />
                {addressLoading && <HelperText>Buscando endereço...</HelperText>}
              </InputColumn>
            </InputRow>

            <InputRow>
              <InputColumn>
                <AddressInput
                  type="text"
                  name="endereco"
                  placeholder="Endereço"
                  required
                  maxLength="255"
                  value={billing?.endereco || ''}
                  onChange={onChangeEndereco}
                  autoComplete="off"
                />
              </InputColumn>
            </InputRow>

            <InputRow>
              <InputColumn style={{ maxWidth: '48%' }}>
                <AddressInput
                  type="text"
                  name="numero"
                  placeholder="Número"
                  required
                  maxLength="15"
                  value={billing?.numero || ''}
                  onChange={onChangeNumero}
                  autoComplete="off"
                />
              </InputColumn>
              
              <InputColumn style={{ maxWidth: '48%' }}>
                <AddressInput
                  type="text"
                  name="complemento"
                  placeholder="Complemento"
                  maxLength="50"
                  value={billing?.complemento || ''}
                  onChange={onChangeComplemento}
                  autoComplete="off"
                />
              </InputColumn>
            </InputRow>

            <InputRow>
              <InputColumn>
                <AddressInput
                  type="text"
                  name="bairro"
                  placeholder="Bairro"
                  required
                  maxLength="50"
                  value={billing?.bairro || ''}
                  onChange={onChangeBairro}
                  autoComplete="off"
                />
              </InputColumn>
            </InputRow>

            <InputRow>
              <InputColumn style={{ maxWidth: '65%' }}>
                <AddressInput
                  type="text"
                  name="cidade"
                  placeholder="Cidade"
                  required
                  maxLength="50"
                  value={billing?.cidade || ''}
                  onChange={onChangeCidade}
                  autoComplete="off"
                />
              </InputColumn>
              
              <InputColumn style={{ maxWidth: '30%' }}>
                <AddressInput
                  type="text"
                  name="estado"
                  placeholder="UF"
                  required
                  maxLength="2"
                  value={billing?.estado || ''}
                  onChange={onChangeEstado}
                  autoComplete="off"
                  onKeyPress={(e) => {
                    const regex = new RegExp('^[a-zA-Z]+$');
                    const key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
                    if (!regex.test(key)) {
                      e.preventDefault();
                      return false;
                    }
                  }}
                />
              </InputColumn>
            </InputRow>
            
            <TermsText>
            O faturamento começa <TermsHighlight>hoje, {getNextBillingDateText()}</TermsHighlight>, e será renovado automaticamente a cada mês. Nao há reembolso ou estorno de valores, com base no direito de arrependimento, pois a NOAR oferece a seus clientes a possibilidade de teste prévio antes da contratação dos serviços. Cancele a qualquer momento nas configurações, assim não haverá cobranças no mes seguinte.
          <br /><br />
          Ao continuar, você confirma que tem no mínimo 18 anos e concorda com estes termos. <TermsHighlight>Pagamento recorrente</TermsHighlight>.
            </TermsText>
            
            <SubmitButton 
              type="submit" 
              disabled={handleDisableButton()}
            >
              Continuar
            </SubmitButton>
          </AddressForm>
        </AddressBox>
      )}
    </AddressContainer>
  );
};

export default AdressData;

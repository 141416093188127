import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { colors } from '../../../../constants/colors';
import { FaServer, FaMemory, FaMicrochip, FaHdd, FaNetworkWired, FaShieldAlt, FaCloud, FaCogs } from 'react-icons/fa';
import { Section as BaseSection } from '../../styled';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const float = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.05);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.8;
  }
`;

const StyledSection = styled(BaseSection)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
`;

const ServerContainer = styled.div`
  text-align: center;
  padding: 4rem 2rem;
  background: ${colors.background.dark};
  border-radius: 24px;
  margin: 0 auto;
  color: ${colors.white};
  position: relative;
  overflow: hidden;
  box-shadow: ${colors.shadows.hover};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;

  @media (max-width: 768px) {
    padding: 1.5rem 1rem;
    margin: 0 1rem;
    width: calc(100% - 2rem);
    border-radius: 16px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(
      circle at 70% 30%,
      rgba(45, 128, 242, 0.15) 0%,
      rgba(45, 128, 242, 0.05) 25%,
      transparent 70%
    );
  }
`;

const ServerTitle = styled.h2`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  background: linear-gradient(120deg, ${colors.primary}, #00f2fe);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: ${fadeIn} 0.8s ease-out;
  width: 100%;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.8rem;
    padding: 0;
    margin-bottom: 0.75rem;
  }
`;

const ServerSubtitle = styled.p`
  font-size: 1.2rem;
  color: ${colors.text.light};
  margin: 0 auto 2rem;
  max-width: 800px;
  width: 100%;
  text-align: center;
  animation: ${fadeIn} 0.8s ease-out 0.2s backwards;

  @media (max-width: 768px) {
    font-size: 1rem;
    margin-bottom: 1.5rem;
    padding: 0;
  }
`;

const ServerVisual = styled.div`
  position: relative;
  height: 300px;
  margin-bottom: 2rem;
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    height: 200px;
    margin-bottom: 1.5rem;
  }
`;

const ServerModel = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 100%;
    height: 100%;
    max-width: 600px;
    max-height: 400px;
    object-fit: contain;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150%;
    height: 150%;
    background: radial-gradient(
      circle at center,
      ${colors.primary}20 0%,
      transparent 70%
    );
    z-index: -1;
  }
`;

const SpecsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  animation: ${fadeIn} 1s ease-out 0.3s backwards;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 0;
    width: 100%;
  }
`;

const SpecCard = styled.div`
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  text-align: left;
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 1.5rem;
    margin: 0;
    width: calc(100% - 2rem);
    max-width: 400px;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
    border-color: ${colors.primary}40;
    background: rgba(255, 255, 255, 0.08);
  }
`;

const SpecIcon = styled.div`
  color: ${colors.primary};
  font-size: 24px;
  flex-shrink: 0;
  transition: transform 0.3s ease;

  @media (max-width: 768px) {
    font-size: 20px;
  }

  ${SpecCard}:hover & {
    transform: scale(1.2);
  }
`;

const SpecContent = styled.div`
  flex: 1;

  @media (max-width: 768px) {
    padding-right: 0;
  }
`;

const SpecTitle = styled.h3`
  color: ${colors.white};
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  font-weight: 600;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const SpecDescription = styled.p`
  color: ${colors.text.light};
  font-size: 0.9rem;
  line-height: 1.6;
  margin: 0;
  opacity: 0.9;

  @media (max-width: 768px) {
    font-size: 0.85rem;
    line-height: 1.4;
  }
`;

const PerformanceIndicator = styled.div`
  position: absolute;
  padding: 0.5rem 1rem;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  font-size: 0.9rem;
  color: ${colors.white};
  backdrop-filter: blur(5px);
  border: 1px solid ${colors.primary}40;
  z-index: 2;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1);
    background: rgba(0, 0, 0, 0.8);
  }
`;

export default function ServerSection() {
  const [hoveredSpec, setHoveredSpec] = useState(null);

  const specs = [
    {
      icon: <FaMicrochip />,
      title: "Processadores",
      description: "AMD Ryzen 9 Ou threadripper com 32/64/128 threads para processamento massivo",
      performance: "4.8 GHz boost clock"
    },
    {
      icon: <FaMemory />,
      title: "Memória RAM",
      description: "Até 512GB de RAM DDR4/DDR5 para workloads empresariais",
      performance: "Bandwidth: 76.8 GB/s"
    },
    {
      icon: <FaServer />,
      title: "GPUs",
      description: "NVIDIA RTX 4090/AMD 7900XTX para renderização e computação acelerada",
      performance: "Até 24GB GDDR6X"
    },
    {
      icon: <FaHdd />,
      title: "Armazenamento",
      description: "SSDs NVMe em RAID com redundância e backups automáticos",
      performance: "7000MB/s read",
    },
    {
      icon: <FaNetworkWired />,
      title: "Conectividade",
      description: "Rede de alta velocidade com redundância e baixa latência",
      performance: "40Gbps"
    },
    {
      icon: <FaShieldAlt />,
      title: "Segurança",
      description: "Proteção avançada com monitoramento 24/7 e backups geo-distribuídos",
      performance: "99.99% uptime"
    }
  ];

  return (
    <StyledSection>
      <ServerContainer>
        <ServerTitle>Infraestrutura de Alta Performance</ServerTitle>
        <ServerSubtitle>
          Servidores com as mais recentes tecnologias em processamento, GPUs de ponta,
          memória e armazenamento para cargas de trabalho mais exigentes como Machine learning e Deep Learning, Big Data, Render, Broadcast, Realidade Virtual e aumentada, Simulaçêses virtuais.
        </ServerSubtitle>

        <ServerVisual>
          <ServerModel>
            <svg width="800" height="600" viewBox="0 0 800 600" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id="serverGradient">
                  <stop stopColor="#2D80F2" offset="0%"/>
                  <stop stopColor="#00F2FE" offset="100%"/>
                </linearGradient>
                <filter id="glow" x="-50%" y="-50%" width="200%" height="200%">
                  <feGaussianBlur stdDeviation="5" result="coloredBlur"/>
                  <feMerge>
                    <feMergeNode in="coloredBlur"/>
                    <feMergeNode in="SourceGraphic"/>
                  </feMerge>
                </filter>
              </defs>
              
              <g transform="translate(200, 100)" filter="url(#glow)">
                <path d="M0,100 L400,100 L450,400 L50,400 Z" 
                      fill="#1A1A1A" 
                      stroke="url(#serverGradient)" 
                      strokeWidth="2"/>
                
                <g id="serverUnits">
                  <rect x="50" y="150" width="350" height="40" 
                        fill="#2A2A2A" stroke="#333" strokeWidth="1"/>
                  <circle cx="70" cy="170" r="5" fill="#2D80F2"/>
                  <rect x="90" y="160" width="290" height="20" 
                        fill="#222" stroke="#444" strokeWidth="1"/>
                  
                  <rect x="50" y="200" width="350" height="40" 
                        fill="#2A2A2A" stroke="#333" strokeWidth="1"/>
                  <circle cx="70" cy="220" r="5" fill="#2D80F2"/>
                  <rect x="90" y="210" width="290" height="20" 
                        fill="#222" stroke="#444" strokeWidth="1"/>
                  
                  <rect x="50" y="250" width="350" height="40" 
                        fill="#2A2A2A" stroke="#333" strokeWidth="1"/>
                  <circle cx="70" cy="270" r="5" fill="#2D80F2"/>
                  <rect x="90" y="260" width="290" height="20" 
                        fill="#222" stroke="#444" strokeWidth="1"/>
                </g>
                
                <g id="frontPanel">
                  <rect x="50" y="310" width="350" height="60" 
                        fill="#1E1E1E" stroke="#333" strokeWidth="1"/>
                  <circle cx="70" cy="340" r="8" fill="#00F2FE"/>
                  <rect x="90" y="325" width="40" height="30" 
                        fill="#222" stroke="#444" strokeWidth="1"/>
                  <rect x="140" y="325" width="40" height="30" 
                        fill="#222" stroke="#444" strokeWidth="1"/>
                  <rect x="190" y="325" width="190" height="30" 
                        fill="#222" stroke="#444" strokeWidth="1"/>
                </g>
                
                <g id="ventilation">
                  <rect x="50" y="120" width="350" height="20" 
                        fill="#1E1E1E" stroke="#333" strokeWidth="1"/>
                  <line x1="70" y1="120" x2="70" y2="140" 
                        stroke="#444" strokeWidth="1"/>
                  <line x1="90" y1="120" x2="90" y2="140" 
                        stroke="#444" strokeWidth="1"/>
                  <line x1="110" y1="120" x2="110" y2="140" 
                        stroke="#444" strokeWidth="1"/>
                  <line x1="130" y1="120" x2="130" y2="140" 
                        stroke="#444" strokeWidth="1"/>
                  <line x1="150" y1="120" x2="150" y2="140" 
                        stroke="#444" strokeWidth="1"/>
                </g>
                
                <path d="M50,120 L400,120 L380,140 L70,140 Z" 
                      fill="rgba(255,255,255,0.1)"/>
                <path d="M70,310 L380,310 L360,330 L90,330 Z" 
                      fill="rgba(255,255,255,0.1)"/>
              </g>
              
              <g id="dataFlow">
                <circle cx="270" cy="170" r="2" fill="#00F2FE">
                  <animate attributeName="opacity"
                           values="0;1;0" dur="2s"
                           repeatCount="indefinite"/>
                </circle>
                <circle cx="270" cy="220" r="2" fill="#00F2FE">
                  <animate attributeName="opacity"
                           values="0;1;0" dur="2s"
                           begin="0.5s" repeatCount="indefinite"/>
                </circle>
                <circle cx="270" cy="270" r="2" fill="#00F2FE">
                  <animate attributeName="opacity"
                           values="0;1;0" dur="2s"
                           begin="1s" repeatCount="indefinite"/>
                </circle>
              </g>
            </svg>
          </ServerModel>
          {hoveredSpec && (
            <PerformanceIndicator
              style={{
                top: `${Math.random() * 60 + 20}%`,
                left: `${Math.random() * 60 + 20}%`
              }}
            >
              {hoveredSpec}
            </PerformanceIndicator>
          )}
        </ServerVisual>
        
        <SpecsGrid>
          {specs.map((spec, index) => (
            <SpecCard 
              key={index}
              onMouseEnter={() => setHoveredSpec(spec.performance)}
              onMouseLeave={() => setHoveredSpec(null)}
            >
              <SpecIcon>{spec.icon}</SpecIcon>
              <SpecContent>
                <SpecTitle>{spec.title}</SpecTitle>
                <SpecDescription>{spec.description}</SpecDescription>
              </SpecContent>
            </SpecCard>
          ))}
        </SpecsGrid>
      </ServerContainer>
    </StyledSection>
  );
} 
import { useEffect, useState } from 'react';
import { useUserRole } from '../../context/UserRoleContext';
import AdmPanel from '@Components/AdmPanel';
import { AMISectionPage } from '@Components/AMICarrousel/AMISection';
import { getAMIs } from '@Services/noar-api';
import { Loading } from '../../components/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { openConfirmationLightbox, openNpsFormBox, openTermsBox } from '../../store/ducks/global/actions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { COMPUTER_STATES, ENTERPRISE, ORIGIN_PERSONAL, REDIRECT_TO_LOGIN, messageDisconnect } from '../../constants';
import { getExternalRecurrency, getHasToPrivacyForm, getHasToShowNpsForm, getInstance, getPersonalImagemDaysRemaining, sendNpsAnswer } from '../../services/noar-api';
import { setComputerDoesntLoading, setSelectedPlan, updateComputer } from '../../store/ducks/computer/actions';
import { SessionExpired } from '../../errors';
import Computer from '../OccasionalUsePage/Computer';
import MobileHeader from '../../components/Header';
import NpsFormBox from '../../containers/NpsFormBox';
import * as S from './styled';

const MyComputerPage = () => {
  const { clientType } = useUserRole();
  const isAdmin = clientType === 'B2B_ADM';
  const [plan, setPlan] = useState(clientType === 'B2C' ? ORIGIN_PERSONAL : ENTERPRISE);
  const [personalAmi, setPersonalAmi] = useState(null);
  const [loading, setLoading] = useState(false);
  const [recurrency, setRecurrency] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const { instance_id, creatingComputer, isPersonal } = useSelector(state => state.computer);
  const { personalPlan } = useSelector(state => state.global);
  const hasAlreadyShowPersonalPaymentPopup = JSON.parse(localStorage.getItem('hasAlreadyShowPersonalPaymentPopup')) || false;

  const handleSessionExpiredError = () => {
    const turnOnMachine = false;
    const disconnect = true;
    dispatch(
      openConfirmationLightbox(
        () => {
          history.push(REDIRECT_TO_LOGIN);
        },
        messageDisconnect.message,
        messageDisconnect.confirmBtnText,
        messageDisconnect.refuseBtnText,
        turnOnMachine,
        disconnect,
        false,
      ),
    );
  };

  async function handleInitialData() {
    await getPersonalAmi();
    await handleGetInstance();
    getHasToShowNpsForm("GENERAL_WEB")
      .then(resp => {
        if (resp?.has_to_show) {
          dispatch(openNpsFormBox(sendNpsAnswer, sendNpsAnswer, "a Noar", "GENERAL_WEB"));
        }
      })
      .catch(error => {
        console.error(error);
      });

    getHasToPrivacyForm()
      .then(resp => {
        if (resp?.has_to_show) {
          dispatch(openTermsBox());
        }
      })
      .catch(error => {
        console.error(error);
      });
    setLoading(false);
  }

  async function getPersonalAmi() {
    const amis = await getAMIs(plan);
    if (amis && amis.length > 0) {
      setPersonalAmi(amis[0].image_list[0]);
    }
  }

  async function handleGetInstance() {
    await getInstance()
      .then(async function (responseBody) {
        if (Array.isArray(responseBody) && responseBody?.length > 0) {
          const computer = responseBody[0];
          if (computer.state === COMPUTER_STATES.RUNNING) dispatch(setComputerDoesntLoading());
          dispatch(updateComputer(computer));
          return;
        }
      })
      .catch(function (error) {
        if (error instanceof SessionExpired) {
          handleSessionExpiredError();
        }
      });
  }

  async function handleUpdatePersonalInfo() {
    await getExternalRecurrency().then(response => {
      if (response.length > 0) {
        const recurrency = response.sort((a, b) => {
          return new Date(b.recurrencyDate) - new Date(a.recurrencyDate);
        })[0];
        setRecurrency(recurrency);
      }
      else {
        setRecurrency({ recurrencyStatus: 'EMPTY' });
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    dispatch(setSelectedPlan(plan));
    handleUpdatePersonalInfo();
    handleInitialData();
    getHasToShowNpsForm("GENERAL_WEB")
      .then(resp => {
        if (resp?.has_to_show) {
          dispatch(openNpsFormBox(sendNpsAnswer, sendNpsAnswer, "a Noar", "GENERAL_WEB"));
        }
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  useEffect(() => {

    const managePersonalWarning = async () => {
      if (!hasAlreadyShowPersonalPaymentPopup) {
        const daysRemainingBody = await getPersonalImagemDaysRemaining();
        const days_to_expire = daysRemainingBody[0]?.remaining_days;

        if (days_to_expire < 7 && days_to_expire > 0 && recurrency?.recurrencyStatus === 'ACTIVE') {
          dispatch(
            openConfirmationLightbox(
              () => {
                history.push('/my/profile');
              },
              <p>Olá, tudo bem?
                <br /><br />O pagamento mensal do seu supercomputador falhou :(
                <br />Por favor, verifique se há algum problema com o seu cartão de crédito, nosso sistema realiza novas tentativas de cobrança diariamente.
                <br /><br />Caso precise alterar a forma de pagamento, clique no botão abaixo<br />"Quero manter meu plano!" :)
              </p>,
              "Quero manter meu plano!",
              "Resolver depois",
              null,
              null,
              false,
            ),
          );
          localStorage.setItem('hasAlreadyShowPersonalPaymentPopup', JSON.stringify(true));
        }
      }
    };
    managePersonalWarning();
  }, [personalPlan]);

  return loading ? (
    <S.CustomLoading>
      <Loading />
    </S.CustomLoading>
  ) : (
    <S.PageContainer>
      <MobileHeader />
      <NpsFormBox />
      <S.ContentWrapper>
        <S.SectionTitle>Meu Computador</S.SectionTitle>
        <S.ComputerCard>
          {creatingComputer || instance_id ? (
            <Computer />
          ) : isAdmin ? (
            <AdmPanel />
          ) : (
            <AMISectionPage ami={personalAmi} sectionType="PERSONAL" recurrency={recurrency} />
          )}
        </S.ComputerCard>
      </S.ContentWrapper>
    </S.PageContainer>
  );
};

export default MyComputerPage;

import React, { useState } from 'react';
import * as S from '../../styled';
import { FaCloud, FaChartLine, FaCogs, FaUsersCog, FaShieldAlt, FaDatabase, FaAws, FaGoogle, FaMicrosoft } from 'react-icons/fa';
import ServicePopup from '../../components/ServicePopup';

const CloudManagement = () => {
  const [selectedService, setSelectedService] = useState(null);

  const services = [
    {
      id: 'clouds',
      icon: <FaCloud />,
      title: 'Clouds Suportadas',
      features: [
        {
          icon: <FaGoogle />,
          title: 'Google Cloud (GCP)',
          description: 'Gerenciamento completo de recursos e serviços no Google Cloud Platform.'
        },
        {
          icon: <FaAws />,
          title: 'Amazon Web Services (AWS)',
          description: 'Otimização e administração de infraestrutura na AWS.'
        },
        {
          icon: <FaMicrosoft />,
          title: 'Microsoft Azure',
          description: 'Gestão especializada de recursos na plataforma Azure.'
        },
        {
          icon: <FaCloud />,
          title: 'Tencent Cloud e Outros',
          description: 'Suporte para Tencent Cloud, Cirion, Compute Capital e clouds privadas.'
        }
      ]
    },
    {
      id: 'monitoring',
      icon: <FaChartLine />,
      title: 'Monitoramento',
      features: [
        {
          icon: <FaChartLine />,
          title: 'Métricas em Tempo Real',
          description: 'Monitoramento contínuo de recursos e performance.'
        },
        {
          icon: <FaCogs />,
          title: 'Alertas Personalizados',
          description: 'Configure alertas baseados em métricas específicas.'
        },
        {
          icon: <FaDatabase />,
          title: 'Histórico de Dados',
          description: 'Acesso a dados históricos para análise de tendências.'
        }
      ]
    },
    {
      id: 'management',
      icon: <FaUsersCog />,
      title: 'Gerenciamento',
      features: [
        {
          icon: <FaUsersCog />,
          title: 'Controle de Acesso',
          description: 'Gerenciamento granular de permissões e usuários.'
        },
        {
          icon: <FaCogs />,
          title: 'Automação',
          description: 'Automatize tarefas rotineiras e processos.'
        },
        {
          icon: <FaCloud />,
          title: 'Recursos Cloud',
          description: 'Gerencie recursos cloud de forma centralizada.'
        }
      ]
    },
    {
      id: 'security',
      icon: <FaShieldAlt />,
      title: 'Segurança',
      features: [
        {
          icon: <FaShieldAlt />,
          title: 'Auditoria',
          description: 'Logs detalhados de todas as ações e mudanças.'
        },
        {
          icon: <FaUsersCog />,
          title: 'Compliance',
          description: 'Conformidade com padrões de segurança.'
        },
        {
          icon: <FaCogs />,
          title: 'Políticas',
          description: 'Defina e aplique políticas de segurança.'
        }
      ]
    }
  ];

  return (
    <S.ServiceCard>
      <S.ServiceHeader>
        <S.ServiceIcon>
          <FaCloud />
        </S.ServiceIcon>
        <S.ServiceTitle>Gerenciamento Cloud</S.ServiceTitle>
      </S.ServiceHeader>

      <S.SubServicesList>
        {services.map((service) => (
          <S.SubServiceItem
            key={service.id}
            onClick={() => setSelectedService(service)}
          >
            <S.SubServiceIcon>
              {service.icon}
            </S.SubServiceIcon>
            <S.SubServiceTitle>
              {service.title}
            </S.SubServiceTitle>
          </S.SubServiceItem>
        ))}
      </S.SubServicesList>

      {selectedService && (
        <ServicePopup
          isOpen={!!selectedService}
          onClose={() => setSelectedService(null)}
          title={selectedService.title}
          features={selectedService.features}
          ctaText="Gerenciar Cloud"
        />
      )}
    </S.ServiceCard>
  );
};

export default CloudManagement; 
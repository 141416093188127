import styled from 'styled-components';

import { desktopMinSize, mqmin, mqmax, rem } from '@Styles/mixins';
import { colors } from '../../constants/colors';

export const PageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(135deg, #17171A 0%, #1E1E24 100%);
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 1rem;
    width: calc(100% - 2rem);
    margin: 0 auto;
    align-items: center;
  }
`;

export const SessionsCard = styled.div`
  padding: 2rem;
  background: ${colors.gray[800]};
  border-radius: 24px;
  box-shadow: 
    0 10px 30px -10px rgba(0, 0, 0, 0.2),
    0 5px 15px -5px rgba(0, 0, 0, 0.1),
    0 2px 5px -2px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.05);
  position: relative;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
  width: 100%;
  margin-top: 2rem;

  @media (max-width: 768px) {
    padding: 1.5rem;
    border-radius: 20px;
  }
`;

export const SectionTitle = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
  margin-bottom: 1.5rem;
  position: relative;
  display: inline-block;
  
  &:after {
    content: '';
    position: absolute;
    bottom: -0.5rem;
    left: 0;
    width: 3rem;
    height: 0.25rem;
    background: linear-gradient(90deg, #2D80F2 0%, #8742E5 100%);
    border-radius: 1rem;
  }
`;

export const Container = styled.div`
  width: 90%;
  margin: ${rem(60)} auto;
  border-radius: ${rem(8)};
  font-family: 'Poppins', sans-serif;
  position: relative;
  background: linear-gradient(135deg, #17171A 0%, #1E1E24 100%);
  padding: 2rem;
  box-sizing: border-box;
  overflow: hidden;

  ${mqmin(desktopMinSize)} {
    width: 80%;
    max-width: 1200px;
  }

  ${mqmax(desktopMinSize - 1)} {
    padding: 1rem;
    strong {
      font-size: 25px !important;
      margin: 0 0 15px 0 !important;
      max-width: 9ch;
    }
  }
  strong {
    color: #fff;
    font-size: ${rem(47)};
    font-weight: 600;
    display: block;
    text-align: left;
    margin-bottom: 44px;
  }
`;

export const Table = styled.table`
  min-width: ${rem(800)};
  width: 100%;
  color: #fff;
  border-collapse: separate;
  position: relative;

  border-spacing: 8px 50px;

  th {
    text-align: left;
    font-size: ${rem(16)};
    font-weight: 300;
  }

  tr + tr::after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: #fff;
    position: absolute;
    margin-top: -20px;
    left: 0;
    opacity: 0.1;
  }
`;

export const TableFooterWrapper = styled.div`
  span {
    font-size: ${rem(14)};
    font-weight: 300;
    display: inline-block;
    margin-right: 3px;
    color: #fff;
  }

  select {
    background: transparent;
    border: 0;
    color: #fff;
    cursor: pointer;
    font-size: ${rem(15)};
    font-weight: 600;

    & option {
      color: #000;
    }
  }
`;

export const TableFooterContent = styled.div`
  display: flex !important;
  justify-content: space-between !important;
`;

export const Th = styled.th`
  font-weight: 600 !important;
  font-size: ${rem(19)} !important;
  text-align: left;
`;

export const Td = styled.td`
  font-weight: 300;
  font-size: ${rem(16)};
`;

export const TableWrapper = styled.div`
  background-color: ${colors.gray[800]};
  padding: 0 ${rem(28)} ${rem(35)} ${rem(35)};
  border-radius: 24px;
  box-shadow: 
    0 10px 30px -10px rgba(0, 0, 0, 0.2),
    0 5px 15px -5px rgba(0, 0, 0, 0.1),
    0 2px 5px -2px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.05);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  margin-top: 2rem;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  button {
    border: 0;
    background: transparent;
    color: #fff;
    cursor: pointer;
    font-size: ${rem(14)};
    font-weight: 300;
  }

  strong {
    font-size: ${rem(14)};
    font-weight: 300;
    margin: 0;
  }

  span {
    font-size: ${rem(14)};
    font-weight: 600 !important;
  }
`;

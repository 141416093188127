import styled from 'styled-components';
import { desktopMinSize, defaultFont, mqmax, mqmin } from '@Styles/mixins';

export const Container = styled.div`
    font-family: ${defaultFont};
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem 2rem;
    position: relative;
    z-index: 10;
    
    & > p {
        display: flex;
        align-items: center;
        background: rgba(23, 23, 26, 0.8);
        backdrop-filter: blur(8px);
        border-radius: 12px;
        padding: 0.75rem 1.25rem;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.05);
        transition: all 0.3s ease;
        
        &:hover {
            transform: translateY(-2px);
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
        }
        
        color: #FFF;
        font-size: 0.9rem;
        font-weight: 500;
        
        & > span {
            margin-left: 0.5rem;
            color: #2563EB;
            font-weight: 700;
            background: linear-gradient(90deg, #2563EB 0%, #7C3AED 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-shadow: 0 0 20px rgba(37, 99, 235, 0.3);
            
            &.almost-expiring {
                background: linear-gradient(90deg, #EF4444 0%, #F59E0B 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                animation: pulse 1.5s infinite;
                text-shadow: 0 0 20px rgba(239, 68, 68, 0.4);
            }
        }
    }
    
    @keyframes pulse {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.7;
        }
        100% {
            opacity: 1;
        }
    }
    
    ${mqmin(768)} {
        & > p {
            font-size: 1rem;
            padding: 0.75rem 1.5rem;
        }
    }
    
    ${mqmax(desktopMinSize - 1)} {
        width: 100%;
        justify-content: center;
        padding: 0.75rem 1rem;
    }
`;

import React, { useState } from 'react';
import * as S from '../../styled';
import { FaHeadset, FaLaptop, FaTools, FaNetworkWired, FaServer, FaUserCog } from 'react-icons/fa';
import ServicePopup from '../../components/ServicePopup';

const RemoteSupport = () => {
  const [selectedService, setSelectedService] = useState(null);

  const services = [
    {
      id: 'helpdesk',
      icon: <FaHeadset />,
      title: 'Help Desk',
      features: [
        {
          icon: <FaHeadset />,
          title: 'Suporte 24/7',
          description: 'Atendimento especializado disponível 24 horas por dia.'
        },
        {
          icon: <FaLaptop />,
          title: 'Acesso Remoto',
          description: 'Resolução rápida de problemas via acesso remoto.'
        },
        {
          icon: <FaTools />,
          title: 'Manutenção Preventiva',
          description: 'Monitoramento e manutenção proativa de sistemas.'
        }
      ]
    },
    {
      id: 'infrastructure',
      icon: <FaNetworkWired />,
      title: 'Infraestrutura',
      features: [
        {
          icon: <FaServer />,
          title: 'Servidores',
          description: 'Gerenciamento e manutenção de servidores.'
        },
        {
          icon: <FaNetworkWired />,
          title: 'Rede',
          description: 'Configuração e otimização de redes.'
        },
        {
          icon: <FaTools />,
          title: 'Backup',
          description: 'Implementação e monitoramento de backups.'
        }
      ]
    },
    {
      id: 'consulting',
      icon: <FaUserCog />,
      title: 'Consultoria',
      features: [
        {
          icon: <FaUserCog />,
          title: 'Planejamento',
          description: 'Consultoria estratégica para infraestrutura.'
        },
        {
          icon: <FaTools />,
          title: 'Otimização',
          description: 'Análise e melhorias de performance.'
        },
        {
          icon: <FaServer />,
          title: 'Migração',
          description: 'Planejamento e execução de migrações.'
        }
      ]
    }
  ];

  return (
    <S.ServiceCard>
      <S.ServiceHeader>
        <S.ServiceIcon>
          <FaHeadset />
        </S.ServiceIcon>
        <S.ServiceTitle>Suporte Remoto</S.ServiceTitle>
      </S.ServiceHeader>

      <S.SubServicesList>
        {services.map((service) => (
          <S.SubServiceItem
            key={service.id}
            onClick={() => setSelectedService(service)}
          >
            <S.SubServiceIcon>
              {service.icon}
            </S.SubServiceIcon>
            <S.SubServiceTitle>
              {service.title}
            </S.SubServiceTitle>
          </S.SubServiceItem>
        ))}
      </S.SubServicesList>

      {selectedService && (
        <ServicePopup
          isOpen={!!selectedService}
          onClose={() => setSelectedService(null)}
          title={selectedService.title}
          features={selectedService.features}
          ctaText="Solicitar Suporte"
        />
      )}
    </S.ServiceCard>
  );
};

export default RemoteSupport; 
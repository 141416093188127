import React from 'react';
import SEOHelmet from '../../components/SEO/SEOHelmet';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

/**
 * Página de Computador na Nuvem otimizada para SEO
 * @returns {JSX.Element} Componente de página
 */
const CloudComputerPage = () => {
  return (
    <>
      <SEOHelmet pageName="computador-na-nuvem" />
      <CloudComputerContainer>
        <Hero>
          <HeroContent>
            <h1>Computador na Nuvem</h1>
            <h2>Poder Computacional Ilimitado na Palma da Sua Mão</h2>
            <p>
              Tenha acesso a um computador poderoso de qualquer lugar e de qualquer dispositivo.
              Nossa tecnologia de computação em nuvem permite executar aplicações pesadas
              sem a necessidade de hardware especializado.
            </p>
            <CallToAction to="/register">Experimente Gratuitamente</CallToAction>
          </HeroContent>
        </Hero>

        <Section>
          <SectionTitle>O Que é um Computador na Nuvem?</SectionTitle>
          <DescriptionBlock>
            <p>
              Um computador na nuvem é uma máquina virtual hospedada em nossos servidores, 
              que você pode acessar pela internet a partir de qualquer dispositivo. 
              É como ter um computador de alta performance que pode ser acessado de qualquer lugar.
            </p>
            <p>
              Com nosso serviço de Cloud PC (Seu computador por aplicativo), você não precisa mais se preocupar com limitações 
              de hardware, atualizações de sistema ou backups. Tudo isso é gerenciado por nós,
              permitindo que você foque apenas no seu trabalho ou lazer.
            </p>
          </DescriptionBlock>
        </Section>

        <Section dark>
          <SectionTitle>Por Que Escolher um Computador na Nuvem?</SectionTitle>
          <BenefitsGrid>
            <BenefitCard>
              <BenefitIcon>💻</BenefitIcon>
              <h3>Acesso Universal</h3>
              <p>Utilize seu computador na nuvem de qualquer dispositivo - PC, tablet ou smartphone.</p>
            </BenefitCard>
            <BenefitCard>
              <BenefitIcon>💪</BenefitIcon>
              <h3>Desempenho Superior</h3>
              <p>Execute aplicações pesadas e jogos exigentes sem a necessidade de hardware potente.</p>
            </BenefitCard>
            <BenefitCard>
              <BenefitIcon>💰</BenefitIcon>
              <h3>Economia</h3>
              <p>Elimine a necessidade de investir em equipamentos caros e atualizações constantes.</p>
            </BenefitCard>
            <BenefitCard>
              <BenefitIcon>🔄</BenefitIcon>
              <h3>Sempre Atualizado</h3>
              <p>Seu computador na nuvem estará sempre com os sistemas e softwares mais recentes.</p>
            </BenefitCard>
            <BenefitCard>
              <BenefitIcon>🔒</BenefitIcon>
              <h3>Segurança Avançada</h3>
              <p>Seus dados são protegidos por sistemas de segurança de nível empresarial.</p>
            </BenefitCard>
            <BenefitCard>
              <BenefitIcon>⏱️</BenefitIcon>
              <h3>Disponibilidade 24/7</h3>
              <p>Acesse seu computador na nuvem a qualquer hora, de qualquer lugar.</p>
            </BenefitCard>
          </BenefitsGrid>
        </Section>

        <Section>
          <SectionTitle>Como Funciona?</SectionTitle>
          <StepsWrapper>
            <Step>
              <StepNumber>1</StepNumber>
              <StepContent>
                <h3>Escolha seu Plano</h3>
                <p>Selecione a configuração que melhor atende às suas necessidades de processamento, memória e armazenamento.</p>
              </StepContent>
            </Step>
            <Step>
              <StepNumber>2</StepNumber>
              <StepContent>
                <h3>Crie sua Conta</h3>
                <p>Registre-se em nossa plataforma e configure seu ambiente personalizado com os softwares de sua preferência.</p>
              </StepContent>
            </Step>
            <Step>
              <StepNumber>3</StepNumber>
              <StepContent>
                <h3>Conecte-se</h3>
                <p>Acesse seu computador na nuvem através de qualquer navegador ou aplicativo cliente em qualquer dispositivo.</p>
              </StepContent>
            </Step>
            <Step>
              <StepNumber>4</StepNumber>
              <StepContent>
                <h3>Comece a Usar</h3>
                <p>Utilize seu computador na nuvem como se estivesse em um PC local, com toda a performance e confiabilidade.</p>
              </StepContent>
            </Step>
          </StepsWrapper>
        </Section>

        <Section dark>
          <SectionTitle>Casos de Uso</SectionTitle>
          <UseCasesGrid>
            <UseCase>
              <UseCaseIcon>🎨</UseCaseIcon>
              <h3>Design e Criação de Conteúdo</h3>
              <p>
                Profissionais criativos podem executar softwares exigentes como Adobe Photoshop, 
                After Effects, Blender e outras aplicações de design 3D e edição de vídeo sem 
                precisar de equipamentos de alto custo.
              </p>
            </UseCase>
            <UseCase>
              <UseCaseIcon>💻</UseCaseIcon>
              <h3>Desenvolvimento de Software</h3>
              <p>
                Desenvolvedores podem configurar ambientes de desenvolvimento completos, 
                com todas as ferramentas e recursos necessários, acessíveis de qualquer lugar.
              </p>
            </UseCase>
            <UseCase>
              <UseCaseIcon>📊</UseCaseIcon>
              <h3>Análise de Dados</h3>
              <p>
                Cientistas de dados e analistas podem processar grandes volumes de informações 
                utilizando ferramentas como Python, R, TensorFlow e outras bibliotecas de 
                machine learning sem limites de hardware.
              </p>
            </UseCase>
            <UseCase>
              <UseCaseIcon>🎮</UseCaseIcon>
              <h3>Jogos</h3>
              <p>
                Jogadores podem executar os títulos mais recentes e exigentes mesmo em 
                dispositivos básicos, graças ao processamento realizado em nossos servidores.
              </p>
            </UseCase>
          </UseCasesGrid>
        </Section>
{/* 
        <Section>
          <SectionTitle>Nossos Planos</SectionTitle>
          <PlansGrid>
            <PlanCard>
              <h3>Básico</h3>
              <PlanPrice>R$ 79,90 <span>/mês</span></PlanPrice>
              <PlanSpecs>
                <li>4 vCPUs</li>
                <li>8GB RAM</li>
                <li>150GB SSD</li>
                <li>Windows ou Linux</li>
              </PlanSpecs>
              <p>Ideal para uso geral: navegação, trabalho e aplicações leves.</p>
              <PlanButton>Começar Agora</PlanButton>
            </PlanCard>
            <PlanCard featured>
              <h3>Profissional</h3>
              <PlanPrice>R$ 149,90 <span>/mês</span></PlanPrice>
              <PlanSpecs>
                <li>8 vCPUs</li>
                <li>16GB RAM</li>
                <li>250GB SSD</li>
                <li>GPU NVIDIA</li>
                <li>Windows ou Linux</li>
              </PlanSpecs>
              <p>Perfeito para design, desenvolvimento e jogos.</p>
              <PlanButton>Começar Agora</PlanButton>
            </PlanCard>
            <PlanCard>
              <h3>Ultimate</h3>
              <PlanPrice>R$ 299,90 <span>/mês</span></PlanPrice>
              <PlanSpecs>
                <li>16 vCPUs</li>
                <li>32GB RAM</li>
                <li>500GB SSD</li>
                <li>GPU NVIDIA Dedicada</li>
                <li>Windows ou Linux</li>
              </PlanSpecs>
              <p>Para profissionais que exigem o máximo de desempenho.</p>
              <PlanButton>Começar Agora</PlanButton>
            </PlanCard>
          </PlansGrid>
        </Section> */}

        <Section dark>
          <SectionTitle>Tecnologia de Ponta</SectionTitle>
          <TechnologyWrapper>
            <TechLogo>
              <h3>Microsoft Azure</h3>
            </TechLogo>
            <TechLogo>
              <h3>AWS</h3>
            </TechLogo>
            <TechLogo>
              <h3>Google Cloud</h3>
            </TechLogo>
            <TechLogo>
              <h3>NVIDIA/AMD</h3>
            </TechLogo>
          </TechnologyWrapper>
          <TechDescription>
            <p>
              Utilizamos as mais avançadas tecnologias de computação em nuvem, combinando 
              a infraestrutura robusta da Microsoft Azure, AWS e Google Cloud, além de servidores próprios com GPUs 
              NVIDIA e AMD de última geração para oferecer desempenho excepcional e baixa latência.
            </p>
            <p>
              Nossa solução proprietária de streaming e compressão de dados garante uma 
              experiência fluida mesmo em conexões de internet comuns, oferecendo qualidade 
              visual e responsividade semelhantes a um computador local.
            </p>
          </TechDescription>
        </Section>

        <Section>
          <SectionTitle id="faq">Perguntas Frequentes</SectionTitle>
          <FaqList>
            <FaqItem>
              <FaqQuestion>Qual a conexão de internet necessária?</FaqQuestion>
              <FaqAnswer>
                Recomendamos uma conexão de pelo menos 15 Mbps para uma experiência ideal. 
                No entanto, nosso sistema se adapta a conexões a partir de 5 Mbps, ajustando 
                a qualidade de imagem automaticamente para manter a fluidez.
              </FaqAnswer>
            </FaqItem>
            <FaqItem>
              <FaqQuestion>Posso instalar meus próprios programas?</FaqQuestion>
              <FaqAnswer>
                Sim, você tem total liberdade para instalar e configurar qualquer software 
                em seu computador na nuvem, exatamente como faria em um computador físico.
              </FaqAnswer>
            </FaqItem>
            <FaqItem>
              <FaqQuestion>Os dados ficam seguros na nuvem?</FaqQuestion>
              <FaqAnswer>
                Absolutamente. Utilizamos criptografia de ponta a ponta para proteger suas 
                informações durante a transmissão, e todos os dados armazenados são protegidos 
                por sistemas de segurança de nível empresarial, com backups regulares.
              </FaqAnswer>
            </FaqItem>
            <FaqItem>
              <FaqQuestion>Posso acessar de dispositivos móveis?</FaqQuestion>
              <FaqAnswer>
                Sim, oferecemos aplicativos para Android, incluindo tablets e smartphones, além da possibilidade de 
                acessar por aplicativos desktop windows.
              </FaqAnswer>
            </FaqItem>
          </FaqList>
        </Section>

        <Section dark>
          <SectionTitle>Começe a Usar Seu Computador na Nuvem Hoje</SectionTitle>
          <CtaSection>
            <p>
              Experimente o futuro da computação agora mesmo. 
              Sem compromisso, cancele quando quiser.
            </p>
            <CallToAction large to="/register">Teste Grátis agora mesmo!</CallToAction>
          </CtaSection>
        </Section>
      </CloudComputerContainer>
    </>
  );
};

export default CloudComputerPage;

// Estilos
const CloudComputerContainer = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
`;

const Hero = styled.div`
  background: linear-gradient(135deg, #0f2027, #203a43, #2c5364);
  color: white;
  padding: 100px 20px;
  text-align: center;
`;

const HeroContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
  
  h1 {
    font-size: 3rem;
    margin-bottom: 20px;
    font-weight: 700;
  }
  
  h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    font-weight: 500;
  }
  
  p {
    font-size: 1.2rem;
    margin-bottom: 30px;
    line-height: 1.6;
  }
`;

const CallToAction = styled(Link)`
  background-color: #00a8ff;
  color: white;
  border: none;
  padding: ${props => props.large ? '20px 40px' : '15px 30px'};
  font-size: ${props => props.large ? '1.4rem' : '1.2rem'};
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  
  &:hover {
    background-color: #0097e6;
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
    color: white;
  }
`;

const Section = styled.section`
  padding: 80px 20px;
  background-color: ${props => props.dark ? '#0a1a2f' : 'white'};
  color: ${props => props.dark ? 'white' : '#333'};
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 50px;
  font-weight: 600;
`;

const DescriptionBlock = styled.div`
  max-width: 800px;
  margin: 0 auto;
  
  p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 20px;
    text-align: center;
  }
`;

const BenefitsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
`;

const BenefitCard = styled.div`
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-10px);
  }
  
  h3 {
    font-size: 1.5rem;
    margin: 15px 0;
    color: #00a8ff;
    font-weight: 600;
  }
  
  p {
    font-size: 1.1rem;
    line-height: 1.6;
  }
`;

const BenefitIcon = styled.div`
  font-size: 3rem;
  margin-bottom: 15px;
`;

const StepsWrapper = styled.div`
  max-width: 900px;
  margin: 0 auto;
`;

const Step = styled.div`
  display: flex;
  margin-bottom: 40px;
  align-items: flex-start;
`;

const StepNumber = styled.div`
  background-color: #00a8ff;
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 700;
  margin-right: 20px;
  flex-shrink: 0;
`;

const StepContent = styled.div`
  h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    font-weight: 600;
  }
  
  p {
    font-size: 1.1rem;
    line-height: 1.6;
  }
`;

const UseCasesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
`;

const UseCase = styled.div`
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  
  h3 {
    font-size: 1.5rem;
    margin: 15px 0;
    color: #00a8ff;
    font-weight: 600;
  }
  
  p {
    font-size: 1.1rem;
    line-height: 1.6;
  }
`;

const UseCaseIcon = styled.div`
  font-size: 3rem;
  margin-bottom: 15px;
`;

const PlansGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
`;

const PlanCard = styled.div`
  background-color: ${props => props.featured ? '#00a8ff' : 'white'};
  color: ${props => props.featured ? 'white' : '#333'};
  border-radius: 10px;
  padding: 40px 30px;
  box-shadow: 0 5px 20px rgba(0,0,0,0.1);
  width: 300px;
  text-align: center;
  position: relative;
  transition: all 0.3s ease;
  
  ${props => props.featured && `
    transform: scale(1.05);
  `}
  
  &:hover {
    transform: translateY(-10px);
  }
  
  h3 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    font-weight: 700;
  }
  
  p {
    font-size: 1rem;
    margin: 20px 0;
    line-height: 1.6;
  }
`;

const PlanPrice = styled.div`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  
  span {
    font-size: 1rem;
    opacity: 0.8;
  }
`;

const PlanSpecs = styled.ul`
  margin: 20px 0;
  padding: 0;
  list-style: none;
  text-align: center;
  
  li {
    margin: 10px 0;
    font-size: 1rem;
    position: relative;
    
    &:before {
      content: "✓";
      margin-right: 8px;
      color: ${props => props.featured ? 'white' : '#00a8ff'};
      font-weight: bold;
    }
  }
`;

const PlanButton = styled.button`
  background-color: ${props => props.featured ? 'white' : '#00a8ff'};
  color: ${props => props.featured ? '#00a8ff' : 'white'};
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 20px;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  }
`;

const TechnologyWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  margin-bottom: 40px;
`;

const TechLogo = styled.div`
  text-align: center;
  
  h3 {
    font-size: 1.8rem;
    font-weight: 700;
    color: #00a8ff;
  }
`;

const TechDescription = styled.div`
  max-width: 800px;
  margin: 0 auto;
  
  p {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 20px;
    text-align: center;
  }
`;

const FaqList = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const FaqItem = styled.div`
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  padding-bottom: 20px;
`;

const FaqQuestion = styled.h3`
  font-size: 1.3rem;
  margin-bottom: 10px;
  font-weight: 600;
  color: #00a8ff;
`;

const FaqAnswer = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  color: #666;
`;

const CtaSection = styled.div`
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
  
  p {
    font-size: 1.3rem;
    margin-bottom: 30px;
    line-height: 1.6;
  }
`; 
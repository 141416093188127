import styled from 'styled-components';
import { desktopMinSize, mqmin, rem } from '../../styles/mixins';
import { colors } from '../../constants/colors';
import { Accordion as MuiAccordion, AccordionDetails as MuiAccordionDetails, AccordionSummary as MuiAccordionSummary } from '@material-ui/core';

export const FaqContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
`;

export const FaqTitle = styled.h3`
  font-size: 1.75rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 1.5rem;
  text-align: center;
  
  ${mqmin(desktopMinSize)} {
    font-size: 2rem;
  }
`;

export const FaqDescription = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  max-width: 800px;
  margin: 0 auto 2rem;
  
  ${mqmin(desktopMinSize)} {
    font-size: 1.125rem;
  }
`;

export const SearchContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto 2rem;
  position: relative;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 1rem 1.5rem;
  background: ${colors.gray[700]};
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  color: #fff;
  font-size: 1rem;
  outline: none;
  transition: all 0.3s ease;
  
  &:focus {
    border-color: #2D80F2;
    box-shadow: 0 0 0 2px rgba(45, 128, 242, 0.2);
  }
  
  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

export const SearchIcon = styled.div`
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.5);
`;

export const AccordionContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

export const Accordion = styled(MuiAccordion)`
  &.MuiAccordion-root {
    background: ${colors.gray[700]};
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 12px !important;
    margin-bottom: 1rem;
    overflow: hidden;
    box-shadow: none;
    
    &:before {
      display: none;
    }
    
    &.Mui-expanded {
      margin-bottom: 1rem;
      background: ${colors.gray[600]};
    }
  }
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  &.MuiAccordionSummary-root {
    padding: 0 1.5rem;
    min-height: 64px;
    
    .MuiAccordionSummary-content {
      margin: 1rem 0;
    }
    
    .MuiIconButton-root {
      color: #fff;
    }
  }
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
  &.MuiAccordionDetails-root {
    padding: 0 1.5rem 1.5rem;
    color: rgba(255, 255, 255, 0.8);
    font-size: 1rem;
    line-height: 1.6;
  }
`;

export const QuestionText = styled.p`
  font-size: 1.125rem;
  font-weight: 500;
  color: #fff;
`;

export const AnswerText = styled.div`
  font-size: 1rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.8);
  
  a {
    color: #2D80F2;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
`; 
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Collapse as MuiCollapse,
  Fade as MuiFade,
} from '@material-ui/core';
import { ExpandMore, Search } from '@material-ui/icons';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import faqs from '@Constants/faqs';
import { desktopMinSize, mqmin } from '@Styles/mixins';

import TextField from './TextField';

export function Form() {
  const { expanded, faqs, handleAccordionChange, handleInputChange, ref } = useForm();

  return (
    <Container>
      <SearchContainer>
        <TextField 
          EndAdornment={Search} 
          onChange={handleInputChange} 
          {...{ ref }}
          placeholder="Pesquise por sua dúvida (ex: planos, conexão, iOS)..."
        />
      </SearchContainer>
      <AccordionList>
        {faqs.map(({ id, question, answer, show }) => (
          <AnimatedMargin $entering={show} $leaving={!show} as={MuiCollapse} in={show} key={id}>
            <MuiFade in={show}>
              <Accordion expanded={expanded === id} onChange={handleAccordionChange(id)}>
                <AccordionSummary expandIcon={<ExpandMore className="expand-icon" />}>
                  <Title>{question}</Title>
                </AccordionSummary>
                <AccordionDetails>
                  <Text dangerouslySetInnerHTML={{ __html: answer }} />
                </AccordionDetails>
              </Accordion>
            </MuiFade>
          </AnimatedMargin>
        ))}
      </AccordionList>
    </Container>
  );
}

export default Form;

function useForm() {
  const { faqs, onChange: handleInputChange, ref } = useFilter();
  const { expanded, onChange: handleAccordionChange } = useExpanded();

  return { expanded, faqs, handleAccordionChange, handleInputChange, ref };
}

function useFilter() {
  const ref = useRef(null);
  const [filter, setFilter] = useState('');

  const faqsWithShow = useMemo(
    function () {
      return faqs.map(faq => ({ show: faq.question.toLowerCase().includes(filter.toLowerCase()), ...faq }));
    },
    [filter],
  );

  const onChange = useCallback(
    function ({ target }) {
      setFilter(target.value);
    },
    [setFilter],
  );

  return { faqs: faqsWithShow, onChange, ref };
}

function useExpanded() {
  const [expanded, setExpanded] = useState(null);

  const onChange = useCallback(
    function (id) {
      return function (_event, isExpanded) {
        setExpanded(isExpanded ? id : false);
      };
    },
    [setExpanded],
  );

  return { expanded, onChange };
}

const Accordion = styled(MuiAccordion)`
  &.MuiAccordion-root {
    &.Mui-expanded {
      margin: 16px 0;
    }

    .MuiIconButton-root {
      color: inherit;
    }

    .MuiAccordionSummary-expandIcon {
      margin-top: calc(29rem / 40);
      transition: transform 0.3s ease;

      ${mqmin(desktopMinSize)} {
        margin-top: calc(29rem / 16);
      }
      
      .expand-icon {
        color: #2d80f2;
        font-size: 28px;
      }
    }
  }

  &&.MuiAccordion-rounded {
    border-radius: calc(16rem / 16);
    border: solid 1px rgba(255, 255, 255, 0.1);
    margin-bottom: 16px;
    transition: all 0.3s ease;

    ${mqmin(desktopMinSize)} {
      border-radius: calc(16rem / 16);
    }
    
    &:hover {
      border-color: rgba(45, 128, 242, 0.5);
      transform: translateY(-3px);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    }
  }

  &.MuiPaper-root {
    color: rgba(255, 255, 255, 0.9);
    background: rgba(255, 255, 255, 0.03);
    transition: all 0.3s ease;

    &.Mui-expanded {
      color: #fff;
      background: rgba(45, 128, 242, 0.05);
    }
  }

  &.MuiPaper-elevation1 {
    box-shadow: none;
  }
`;

const Title = styled.p`
  --fontSize: 15;

  font-size: calc(var(--fontSize) * 1rem / 16);
  line-height: calc(31 / 20);
  font-weight: 500;
  color: #fff;

  ${mqmin(desktopMinSize)} {
    --fontSize: 18;
  }
`;

const Text = styled.p`
  --fontSize: 13;

  font-size: calc(var(--fontSize) * 1rem / 16);
  line-height: calc(23 / 15);
  color: rgba(255, 255, 255, 0.8);

  ${mqmin(desktopMinSize)} {
    --fontSize: 15;
  }
`;

const AccordionSummary = styled(MuiAccordionSummary)`
  &.MuiAccordionSummary-root {
    align-items: start;
    padding-inline: calc(23rem / 16);
    min-height: 0;

    ${mqmin(desktopMinSize)} {
      padding-inline: calc(28rem / 16);
    }

    &.Mui-expanded {
      min-height: 0;
    }
  }

  .MuiAccordionSummary-content {
    margin-block: calc(36rem / 40);

    ${mqmin(desktopMinSize)} {
      margin-block: calc(36rem / 16);
    }

    &.Mui-expanded {
      margin-block: calc(36rem / 40) calc(5rem / 40);

      ${mqmin(desktopMinSize)} {
        margin-block: calc(36rem / 16) calc(5rem / 16);
      }
    }
  }
`;

const AccordionDetails = styled(MuiAccordionDetails)`
  &.MuiAccordionDetails-root {
    padding: calc(5rem / 40) calc(23rem / 16) calc(36rem / 40);

    ${mqmin(desktopMinSize)} {
      padding: calc(5rem / 16) calc(28rem / 16) calc(36rem / 16);
    }
  }
`;

const AnimatedMargin = styled.div`
  :not(last-child) {
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, margin-bottom calc(1s / 3) ease-in-out;

    ${({ $entering }) =>
      $entering &&
      css`
         {
          margin-bottom: calc(15rem / 16);
        }
      `}

    &.MuiCollapse-entered {
      margin-bottom: calc(15rem / 16);
    }

    ${({ $leaving }) =>
      $leaving &&
      css`
         {
          margin-bottom: 0;
        }
      `}

    &.MuiCollapse-hidden {
      margin-bottom: 0;
    }
  }
`;

const AccordionList = styled.div`
  margin-top: 32px;
`;

const Container = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding: 0 16px;
`;

const SearchContainer = styled.div`
  position: relative;
  margin-bottom: 2rem;
  width: 100%;
`;

import React from 'react';
import InProgress from '@Containers/InProgress';
import Hello from './Hello';
import MobileHeader from '../../components/Header';
import * as S from './styled';

function MySettingsPage() {
  return (
    <S.PageContainer>
      <MobileHeader />
      <S.ContentWrapper>
        <S.SectionTitle>Configurações</S.SectionTitle>
        <S.SettingsCard>
          <Hello />
          <InProgress />
        </S.SettingsCard>
      </S.ContentWrapper>
    </S.PageContainer>
  );
}

export default MySettingsPage;
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { desktopMinSize, mqmin } from '@Styles/mixins';

export const TextField = forwardRef(function ({ EndAdornment: ExternalEndAdornment, ...props }, outerRef) {
  const { highlight, ref } = useTextField(outerRef);

  return (
    <Container $highlight={highlight}>
      <Input {...{ ref }} {...props} />
      <EndAdornment as={ExternalEndAdornment} />
    </Container>
  );
});

export default TextField;

function useTextField(outerRef) {
  const innerRef = useRef(null);
  const ref = outerRef ? mergeRefs([innerRef, outerRef]) : innerRef;
  const [highlight, setHighlight] = useState(false);

  const handleFocus = useCallback(
    function () {
      setHighlight(true);
    },
    [setHighlight],
  );

  const handleBlur = useCallback(
    function ({ target }) {
      setHighlight(!!target.value);
    },
    [setHighlight],
  );

  useEffect(
    function () {
      const node = innerRef.current;

      if (!node) return;

      ['change', 'focus'].forEach(function (type) {
        node.addEventListener(type, handleFocus);
      });

      node.addEventListener('blur', handleBlur);

      return function () {
        ['change', 'focus'].forEach(function (type) {
          node.removeEventListener(type, handleFocus);
        });

        node.removeEventListener('blur', handleBlur);
      };
    },
    [handleBlur, handleFocus, innerRef],
  );

  return { highlight, ref };
}

function mergeRefs(refs) {
  return function (node) {
    refs.forEach(function (ref) {
      ref.current = node;
    });
  };
}

const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  border: solid var(--color) calc(var(--borderWidth) * 1rem / 16);
  border-radius: calc((var(--borderWidth) * 2 + var(--paddingBlock) * 2 + var(--lineHeight)) / 2 * 1rem / 16);
  padding: calc(var(--paddingBlock) * 1rem / 16) calc(var(--paddingInline) * 1rem / 16);
  outline: none;
  color: white;
  font-size: calc(var(--fontSize) * 1rem / 16);
  line-height: calc(var(--lineHeight) / var(--fontSize));
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(calc(7px));
  transition: all 0.3s ease;
  
  &:focus {
    background: rgba(255, 255, 255, 0.1);
    border-color: #2d80f2;
    box-shadow: 0 0 10px rgba(45, 128, 242, 0.3);
  }
  
  &::placeholder {
    color: rgba(255, 255, 255, 0.6);
    font-size: calc(var(--fontSize) * 1rem / 16);
  }
`;

const EndAdornment = styled.div`
  path {
    fill: var(--color);
    transition: fill 0.3s ease;
  }
  
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const Container = styled.label`
  --borderWidth: 2;
  --paddingBlock: 16;
  --paddingInline: 23;
  --color: rgba(255, 255, 255, 0.6);
  --fontSize: 14;
  --lineHeight: 19;

  position: relative;
  color: var(--color);
  display: block;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;

  ${({ $highlight }) =>
    $highlight &&
    css`
      --color: #2d80f2;
    `}

  ${mqmin(desktopMinSize)} {
    --paddingBlock: 20;
    --paddingInline: 30;
    --fontSize: 16;
    --lineHeight: 26;
  }

  ${EndAdornment} {
    position: absolute;
    top: calc((var(--borderWidth) + var(--paddingBlock)) * 1rem / 16);
    right: calc(21rem / 16);
    width: calc(var(--lineHeight) * 1rem / 16);
    height: auto;
    color: rgba(255, 255, 255, 0.7);
    
    &:hover {
      color: #2d80f2;
    }
  }

  &:has(${EndAdornment}) ${Input} {
    padding-right: calc((var(--paddingBlock) + var(--lineHeight)) * 1rem / 16);
  }
`;

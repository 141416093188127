import React, { useCallback, useEffect, useState } from 'react';
import {
  Container,
  CartPageDivider,
  CartPageTotalPrice,
  CartPageBuyButton,
  CartFooterWrapper,
  CartContentWrapper,
  CartFooterPriceWrapper,
  CartPagePriceGeneralInfo,
  GreenStrong,
  CouponInputContainer,
  CouponInput,
  IconWrapper,
  InfoIconWrapper,
} from './styled';

import { useShoppingCart } from '../../context/ShoppingCartContext';
import { CartContent } from './CartPageContent';
import { formatCurrency } from '../../utils/formatCurrency';
import { useTutorialStep } from '../../context/TutorialStepContext';
import UnifiedPaymentComponent from '../../components/ShopPage/ShopPageCard/UnifiedPaymentComponent';
import {
  CloseButton,
  PaymentBrickBackGround,
  PaymentBrickWrapper,
} from '../../components/ShopPage/ShopPageCard/Unit/styled';
import { Close, Send, CheckBoxOutlined, Clear } from '@material-ui/icons';
import PaymentStatusComponent from '../../components/ShopPage/ShopPageCard/MpPaymentStatusBrick';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import { getVoucher, verifyPurchaseAvailability } from '../../services/noar-api';
import Toast from '@Toast/Toast'
import { useDispatch } from 'react-redux';
import { openConfirmationLightbox } from '@Store/ducks/global/actions';

const CartPage = ({ isMobileCartOpen, setIsMobileCartOpen, products, setIsShoppingLoading, isShoppingLoading }) => {
  const { cartQuantity, cartItems } = useShoppingCart();
  const [initialPrice, handleInitialPrice] = useState(0);
  const [finalPrice, handleFinalPrice] = useState(0);
  const [showPayment, handleShowPayment] = useState(false);
  const [referralVoucherDiscount, handleReferralVoucherDiscount] = useState(0);
  const [discountVoucherDiscount, handleDiscountVoucherDiscount] = useState(0);
  const [packageDiscount, handlePackageDiscount] = useState(0);
  const [selectedProductTotalAmount, setSelectedProductTotalAmount] = useState(0);
  const [selectedProductList, setSelectedProductList] = useState([]);
  const [paymentId, setPaymentId] = useState();
  const { accountPersonalInfo } = useSelector(state => state.global);
  const { state } = useTutorialStep();
  const history = useHistory();
  const [couponCode, setCouponCode] = useState('');
  const [couponObject, setCouponObject] = useState();
  const [isDiscountCounponOnFocus, setIsDiscountCounponOnFocus] = useState(false);
  const [isCouponValid, setIsCouponValid] = useState();
  const [isCouponLoading, setIsCouponLoading] = useState(false);
  var dispatch = useDispatch();

  const handleApplyCoupon = () => {
    setIsCouponLoading(true);
    getVoucher(couponCode).then(resp => {
      setIsCouponLoading(false);
      if (resp?.discount_percentage) {
        setIsCouponValid(true);
        setCouponObject(resp);
        showVoucherResultToast(true);
      }
      else {
        setIsCouponValid(false);
        setCouponObject(null);
        showVoucherResultToast(false);
      }
    });
  };

  function showVoucherResultToast(success) {
    const toast = document.querySelector('.toast');
    if (toast) {
      toast.remove();
    }
    new Toast({
      text: `<div class="toast-content"><svg id="Done" xmlns="http://www.w3.org/2000/svg" width="24.119" height="24.119" viewBox="0 0 24.119 24.119">
      <path id="Caminho_38" data-name="Caminho 38" d="M107.917,23.85a1.046,1.046,0,0,0-2.056.381,10.588,10.588,0,0,1,.178,1.8,9.978,9.978,0,1,1-9.978-9.952,9.664,9.664,0,0,1,3.884.787,1.047,1.047,0,1,0,.812-1.929,12.4,12.4,0,0,0-4.7-.94A12.059,12.059,0,1,0,108.12,26.058a12.532,12.532,0,0,0-.2-2.209Z" transform="translate(-84.001 -13.999)" fill="#f7ffff"/>
      <path id="Caminho_39" data-name="Caminho 39" d="M219.315,59.612c-1.244-1.8-4.215-.076-2.945,1.726,1.523,2.183,2.92,4.468,4.443,6.652a1.734,1.734,0,0,0,2.945,0,73.359,73.359,0,0,1,9.394-12.745c1.523-1.574-.889-4.011-2.412-2.412A69.767,69.767,0,0,0,222.235,64c-.965-1.472-1.9-2.945-2.92-4.392Z" transform="translate(-210.074 -50.574)" fill="#f7ffff"/>
      </svg>
      <div>
      <strong>${success ? "Sucesso" : "Falha"}</strong>
      <p>${success ? "Cupom adicionado com sucesso!" : "Cupom não encontrado."}</p>
      </div>
    </div>`,
      canClose: true,
      pauseOnHover: false,
      pauseOnFocusLoss: false,
      closeOnClick: true,
    });
  }

  const handleSetCouponCode = (event) => {
    if (event?.target?.value) {
      setCouponCode(event.target.value.toUpperCase());
    }
    else {
      setCouponCode('');
    }
  };

  const onFocusDiscountCounpon = () => {
    setIsDiscountCounponOnFocus(true);
  };

  const onBlurDiscountCounpon = () => {
    if (couponCode.length > 0) {
      handleApplyCoupon();
    }
    else {
      setIsCouponValid(undefined);
      setCouponObject(null);
    }
    setIsDiscountCounponOnFocus(false);
  }

  async function handleOrderValidations() {
    setIsShoppingLoading(true);
    let availability = await verifyPurchaseAvailability()
      .then(() => true)
      .catch(() => {
        dispatch(openConfirmationLightbox(
          () => {
            history.push('/shop/hours');
          },
          <>Você excedeu o limite de tentativas recusadas. Você pode tentar novamente após 24 horas. Caso necessário, entre em contato com o suporte.</>,
          'Entendi',
          null,
          null,
          null,
          false,
        ));
        setIsShoppingLoading(false);
      })
    if(!availability) return;
    await accountPersonalInfo.showPopupValidationFunction(
      selectedProductTotalAmount,
      selectedProductList,
      setPaymentId,
      handleShowPayment,
      false,
      false,
      setIsShoppingLoading,
      false,
    ).then(resp => {
      if (!resp) {
        handleOrder();
        return;
      }
    });
    setIsShoppingLoading(false);
  }

  const handleOrder = async function (_event) {
    console.log('handleOrder chamado');
    
    const selectedProducts = cartItems.map(({ id, quantity }) => ({ product_id: id, quantity }));
    const finalAmount = (finalPrice - 
      ((finalPrice * referralVoucherDiscount) / 100 || 0) - 
      ((finalPrice * discountVoucherDiscount) / 100 || 0)).toFixed(2);
    console.log('Valor calculado:', finalAmount);
    setSelectedProductTotalAmount(finalAmount);
    setSelectedProductList(selectedProducts);
    setIsMobileCartOpen(false);
    handleShowPayment(true);
  };

  const closePayment = event => {
    if (event.target !== event.currentTarget) return;
    
    setPaymentId(null);
    handleShowPayment(false);
    
    history.push('/shop/hours');
    window.location.reload();
  };

  useEffect(() => {
    console.log('Estado do showPayment alterado:', showPayment);
  }, [showPayment]);

  useEffect(() => {
    handleInitialPrice(
      cartItems.reduce((total, cartItem) => {
        const item = products?.classic?.find(i => i.product_id === cartItem.id);
        return total + (item?.original_price || 0) * cartItem.quantity;
      }, 0),
    );
    handleFinalPrice(
      cartItems.reduce((total, cartItem) => {
        const item = products?.classic?.find(i => i.product_id === cartItem.id);
        return total + (item?.price || 0) * cartItem.quantity;
      }, 0),
    );
    handlePackageDiscount(
      cartItems.reduce((total, cartItem) => {
        const item = products?.classic?.find(i => i.product_id === cartItem.id);
        return total + (item?.original_price - item?.price) * cartItem.quantity;
      }, 0),
    );
    handleReferralVoucherDiscount(
      cartItems.reduce((total, cartItem) => {
        const item = products?.classic?.find(i => i.product_id === cartItem.id);
        return item?.voucher_discount || 0;
      }, 0),
    );

    handleDiscountVoucherDiscount(
      couponObject?.discount_percentage || 0,
    );
  }, [products, cartItems, couponObject]);

  return (
    <>
      <Container isMobileCartOpen={isMobileCartOpen} step={state[3].step}>
        <CartContentWrapper>
          <h1>Seu Carrinho</h1>
          <h2>
            Você está assinando:{' '}
            <span>
              {' '}
              {cartItems.reduce((total, cartItem) => {
                const item = products?.classic?.find(i => i.product_id === cartItem.id);
                return total + (item?.amount_hours || 0) * cartItem.quantity;
              }, 0)}{' '}
            </span>
            {cartQuantity === 0
              ? 'Horas'
              : cartItems.reduce((total, cartItem) => {
                const item = products?.classic?.find(i => i.product_id === cartItem.id);

                return total + (item?.amount_hours || 0) * cartItem.quantity <= 1 ? 'Hora' : 'Horas';
              }, 0)}
          </h2>

          {cartItems.map(item => (
            <CartContent key={item.id} products={products} {...item} />
          ))}
        </CartContentWrapper>
        <CartFooterWrapper>
          <CartPageDivider />
          <CartFooterPriceWrapper>
            {packageDiscount || referralVoucherDiscount ? (
              <CartPagePriceGeneralInfo>
                <strong>Valor Inicial</strong>
                <strong> {formatCurrency(initialPrice)}</strong>
              </CartPagePriceGeneralInfo>
            ) : (
              []
            )}
            {packageDiscount && packageDiscount !== 0 ? (
              <CartPagePriceGeneralInfo>
                <strong>Descontos</strong>
                <GreenStrong>
                  {'- '}
                  {formatCurrency(packageDiscount)}
                </GreenStrong>
              </CartPagePriceGeneralInfo>
            ) : (
              []
            )}
            {referralVoucherDiscount && referralVoucherDiscount !== 0 ? (
              <CartPagePriceGeneralInfo>
                <strong>Vale indicação</strong>
                <GreenStrong>
                  {'- '}
                  {referralVoucherDiscount}
                  {'%'}
                </GreenStrong>
              </CartPagePriceGeneralInfo>
            ) : (
              []
            )}
            {discountVoucherDiscount && discountVoucherDiscount !== 0 ? (
              <CartPagePriceGeneralInfo>
                <strong>Cupom</strong>
                <GreenStrong>
                  {'- '}
                  {discountVoucherDiscount}
                  {'%'}
                </GreenStrong>
              </CartPagePriceGeneralInfo>
            ) : (
              []
            )}
            <CartPageTotalPrice>
              <strong>Valor Mensal</strong>
              <strong>
                {formatCurrency((finalPrice - ((finalPrice * referralVoucherDiscount) / 100 || 0) - ((finalPrice * discountVoucherDiscount) / 100 || 0)))}
              </strong>
            </CartPageTotalPrice>
          </CartFooterPriceWrapper>
          <CouponInputContainer onFocus={onFocusDiscountCounpon} onBlur={onBlurDiscountCounpon} isCouponLoading={isCouponLoading}>
            {isCouponValid === undefined ?
              []
              : isCouponValid ?
                <InfoIconWrapper infoIcon="valid" title="Cupom encontrado!">
                  <CheckBoxOutlined />
                </InfoIconWrapper> :
                <InfoIconWrapper infoIcon="invalid" title="Cupom não encontrado">
                  <Clear />
                </InfoIconWrapper>}
            <CouponInput
              placeholder={isDiscountCounponOnFocus ? "" : "Possui um cupom de desconto?"}
              value={couponCode}
              onChange={handleSetCouponCode}
              minLength="8"
              maxLength="16"
            />
            {isDiscountCounponOnFocus &&
              <IconWrapper onClick={handleApplyCoupon}>
                <Send />
              </IconWrapper>}
          </CouponInputContainer>
          <CartPageBuyButton onClick={handleOrderValidations} disabled={cartItems.length === 0}>
            Finalizar Assinatura Mensal
          </CartPageBuyButton>
        </CartFooterWrapper>
      </Container>
      
      {/* Renderizar o popup APENAS quando showPayment for true e isShoppingLoading for false */}
      {showPayment && !isShoppingLoading && (
        <PaymentBrickBackGround onClick={closePayment}>
          <PaymentBrickWrapper>
            <CloseButton>
              <Close onClick={closePayment} />
            </CloseButton>
            
            {!paymentId ? (
              <UnifiedPaymentComponent
                key="payment-component"
                amount={selectedProductTotalAmount}
                selectedProductList={selectedProductList}
                setPaymentId={setPaymentId}
                handleShowPayment={handleShowPayment}
                setDisablePaymentContent={setIsShoppingLoading}
                recurrent={true}
                discountVoucher={couponCode}
                onlyPix={false}
                onlyCard={true}
              />
            ) : (
              <PaymentStatusComponent 
                key="payment-status-component"
                paymentId={paymentId} 
                productLink="/shop/hours" 
              />
            )}
          </PaymentBrickWrapper>
        </PaymentBrickBackGround>
      )}
    </>
  );
};

export default CartPage;
